import { createAction } from 'redux-actions';
import { validatePromocodeApi } from '../../api';
import { errorHandleMiddleware } from '../errorHandleMiddleware';

const addCart = createAction('ADD_TO_CART');
const clearCart = createAction('CLEAR_CART');

export const AddToCart = product => {
  return dispatch => {
    dispatch(addCart(product));
  };
};

export const clearFromCart = () => {
  return dispatch => {
    dispatch(clearCart());
  };
};

export const clearBasketAction = () => {
  return dispatch => {
    dispatch(clearBasket());
  };
};
const clearBasket = createAction('CLEAR_BASKET');

export const setStepAction = step => {
  return dispatch => {
    dispatch(setStep({ step }));
  };
};
const setStep = createAction('SET_STEP');

export const clearPromocodeAction = () => {
  return dispatch => {
    dispatch(clearPromocode());
  };
};
const clearPromocode = createAction('CLEAR_PROMOCODE');

export const validatePromocodeAction = ({ promocode, platform }, { handledErrors }) => {
  return errorHandleMiddleware(async dispatch => {
    const response = await validatePromocodeApi({ promocode, platform });
    dispatch(validatePromocodeSuccess({ promocode: promocode, discount: response.discount }));
  }, handledErrors);
};
const validatePromocodeSuccess = createAction('VALIDATE_PROMOCODE_SUCCESS');
