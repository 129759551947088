import React from 'react';
import { useParams } from 'react-router-dom';
import tr from 'locale';

import { Button, WithIcon } from '@3divi/baseui';

function ButtonNuitrackAnalyticsServiceRedirect({
  license,
  disabled,
  variant,
  color,
  elastic,
  size,
}) {
  const id = useParams().id;
  const licenseId = license && license.id;
  const getId = () => {
    let redirectId = id ? id : licenseId;
    return redirectId ? `&license_id=${redirectId}` : '';
  };

  return (
    <Button
      as="a"
      href={`/api/service/redirect/?service=nuitrack-analytics${getId()}`}
      target="_blank"
      variant={variant ? variant : 'clear'}
      disabled={disabled}
      color={color}
      elastic={elastic}
      size={size}
      iconLeft="nuitrackAnalytics"
      text={
        <WithIcon
          icon="link"
          size="xxs"
          color={disabled ? 'disabled' : variant === 'contained' ? 'contrast' : 'default'}
        >
          {tr('go_to_nuitrack_analytics')}
        </WithIcon>
      }
    />
  );
}

export default ButtonNuitrackAnalyticsServiceRedirect;
