const allowedMap = {
  face: {
    all: 'Windows x86/x64, Linux x86/ARM 32/64-bit, Android ARM 32/64-bit, iOS ARM 64-bit',
    stantard: 'Windows x86/x64, Linux x86/ARM 32/64-bit, Android ARM 32/64-bit',
    usb_token: 'Windows x86/x64, Linux x86 32/64-bit',
  },
  nuitrack: {
    stantard: 'Windows x86/x64, Android ARMv7/ARM64, Linux x64/ARMv7',
    all: 'Windows x86/x64, Android ARMv7/ARM64, Linux x64/ARMv7, iOS (beta)',
    ai: 'Windows x64, Linux x64',
  },
};

const facePlatforms = {
  windows: 'Windows x86/x64',
  linux: 'Linux x86/ARM 32/64-bit',
  android: 'Android ARM 32/64-bit',
  ios: 'iOS ARM 64-bit',
};
const nuitrackPlatforms = {
  windows: 'Windows x86/x64',
  linux: 'Linux x64/ARMv7',
  android: 'Android ARMv7/ARM64',
};
/*
face license 'custom_tags.allowed_platform' viable values(any combination of 4 variants):
  - ios-arm-64,
  - android-arm-32,android-arm-64,
  - linux-arm-32,linux-arm-64,linux-x86-32,linux-x86-64,
  - windows-x86-32,windows-x86-64
*/

const getAllowedPlatforms = license => {
  if (!license) return;
  switch (license.type) {
    case 'face':
      if (license.config.custom_tags && license.config.custom_tags.allowed_platforms) {
        let allowedPlatforms = [];
        Object.keys(facePlatforms).forEach(os => {
          if (license.config.custom_tags.allowed_platforms.indexOf(os) >= 0)
            allowedPlatforms.push(facePlatforms[os]);
        });
        return allowedPlatforms.join(', ');
      } else if (license.config.tag === 'FaceSDK Trial') {
        return allowedMap.face.all;
      } else if (license.config.product === 'face_offline_perpetual') {
        return allowedMap.face.all;
      } else if (license.locked_to === 'usb_token') {
        return allowedMap.face.usb_token;
      } else if (license.config.custom_tags) {
        const configKeys = Object.keys(license.config.custom_tags);
        if (configKeys.some(key => key === 'ios_app_id')) return facePlatforms.ios;
        if (configKeys.some(key => key === 'android_app_id')) return facePlatforms.android;
        return;
      } else {
        /**
         * face_online_all_in_one
         * face_online_subscribe
         * face_online_custom_subscribe
         *
         */
        return allowedMap.face.stantard;
      }
    case 'nuitrack':
      if (typeof license.config.os_type === 'number') {
        switch (license.config.os_type) {
          case 0:
            return nuitrackPlatforms.windows;
          case 1:
            return nuitrackPlatforms.linux;
          case 2:
            return nuitrackPlatforms.android;
          default:
            return;
        }
      } else {
        switch (license.config.product) {
          case 'old':
            return allowedMap.nuitrack.all;
          case 'tvico':
            return nuitrackPlatforms.android;
          case 'nuitrack_ai_perpetual':
            return allowedMap.nuitrack.ai;
          /** and others:
           * nuitrack_trial
           * nuitrack_online_subscription_monthly
           * nuitrack_online_subscription_yearly
           * nuitrack_online
           * real_dance
           */
          default:
            return allowedMap.nuitrack.stantard;
        }
      }
    default:
      return;
  }
};

export default getAllowedPlatforms;
