import { createAction } from 'redux-actions';

const clearSteps = createAction('CLEAR_STEPS');
const setStep = createAction('SET_STEPS');
const setInitialSteps = createAction('SET_INITIAL_STEPS');

export const handlerSetInitialSteps = (platform, show) => {
  return dispatch => {
    dispatch(setInitialSteps({ platform, show }));
  };
};

export const handlerClearSteps = steps => {
  return dispatch => {
    dispatch(clearSteps({}));
  };
};

export const handlerSetStep = ({ platform, type, nextStep, email }) => {
  return dispatch => {
    dispatch(
      setStep({
        platform,
        type,
        nextStep,
        email,
      })
    );
  };
};
