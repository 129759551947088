import React, { useState } from 'react';
import tr from 'locale';
import { resetPassword } from 'api';

import CognitiveInfo from './CognitiveInfo';
import { useLocation } from 'react-router';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';
import { useDispatch } from 'react-redux';

function PasswordLinkExpired(props) {
  const { clientId } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [showMain, setShowMain] = useState(true);

  return showMain ? (
    <CognitiveInfo
      text={tr('set_password_link_expired')}
      locationState={{ ...location.state }}
      button={{
        text: tr('set_password_resend_link'),
        as: undefined,
        loading: loading,
        onClick: e => {
          e.preventDefault();
          setLoading(true);
          resetPassword({ id: parseInt(clientId, 10) }, 'nuitrack').then(
            success => {
              setLoading(false);
              setShowMain(false);
            },
            async failure => {
              setLoading(false);
              const { error_code: errorCode } = failure;
              const errorMsg = tr(errorCode || 'internal_server_error');
              if (errorMsg !== errorCode) {
                dispatch(
                  handleOpenModal({
                    component: ErrorModal,
                    name: 'error',
                    text: errorMsg,
                  })
                );
              } else {
                dispatch(
                  handleOpenModal({
                    component: ErrorModal,
                    name: 'error',
                    text: tr('network_error'),
                  })
                );
              }
            }
          );
        },
      }}
    />
  ) : (
    <CognitiveInfo
      text={[tr('reset_password_link_sended_1'), tr('reset_password_link_sended_2')]}
      locationState={{ ...location.state }}
      button={{
        text: tr('return_to_login_button'),
      }}
    />
  );
}

export default PasswordLinkExpired;
