import React, { useEffect } from 'react';
import tr from 'locale';
import { useDispatch } from 'react-redux';
import { onlyDigitsRegexp } from '../../../../../../helpers/regexp';
import { handleOpenModal } from '../../../../../../store/modals/actions';
import ErrorModal from '../../../../../modals/ErrorModal';
import { Redirect } from 'react-router';

export const useCheckGroupAviability = ({ id, group, loaded }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onlyDigitsRegexp.test(id) || (loaded && !group))
      dispatch(
        handleOpenModal({
          component: ErrorModal,
          name: 'error-63f560db',
          text: tr('63f560db'),
        })
      );
  }, [id, loaded, group]);

  return !onlyDigitsRegexp.test(id) || (loaded && !group) ? (
    <Redirect to={'/app/group-keys/'} />
  ) : null;
};
