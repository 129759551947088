import React from 'react';
import { CSSTransition } from 'react-transition-group';

import StepBlock from '../StepBlock/';

import './anim.css';

function StartBlocks({ steps, stepsStore, inTransition, ...others }) {
  return (
    <CSSTransition in={inTransition} appear timeout={350} classNames="steps">
      <>
        {steps.map((el, i) => (
          <StepBlock
            key={i}
            step={el.stepType && i}
            status={stepsStore.steps[el.stepType] && stepsStore.steps[el.stepType].status}
            {...el}
          />
        ))}
      </>
    </CSSTransition>
  );
}

export default StartBlocks;
