import React from 'react';
import { connect } from 'react-redux';
import AccountPageContent from './AccountPageContent';
import HeaderContainer from '../HeaderContainer';
import LoaderComponent from '../LoaderComponent';
import tr from 'locale';
import Header from 'Header';
import styled from 'styled-components';
import Footer from 'components/elements/Footer/';
import Feedback from '../elements/Feedback/';

const Box = styled.main`
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 32px 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 720px) {
    padding: 8px 16px 0;
  }
`;

const Content = styled.div`
  flex: 1 1 320px;
`;

class AccountPage extends React.Component {
  render() {
    return (
      <Box>
        <Content>
          <Feedback />
          <Header title={tr('tabtitle_account')} />
          <HeaderContainer key="head" pageName={tr('account_page')} />
          {this.props.loadComplete ? (
            <AccountPageContent
              accountInfo={this.props.clientInfo}
              corporate={this.props.clientInfo.options.corporate_member}
              platform={this.props.match.params.platform}
              dispatch={this.props.dispatch}
            />
          ) : (
            <LoaderComponent key="loading" />
          )}
        </Content>
        <Footer />
      </Box>
    );
  }
}

AccountPage.defaultProps = {
  match: { params: { platform: 'nuitrack' } },
};

function mapStateToProps(state) {
  return {
    clientInfo: state.clientdata.clientInfo,
    loadComplete: state.clientdata.loadComplete,
  };
}

export default connect(mapStateToProps)(AccountPage);
