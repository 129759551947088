import React from 'react';
import styled from 'styled-components';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import tr from 'locale';
import BasketHeader from './BasketHeader';
import LicenseSelection from './LicenseSelection';
import Header from 'Header';
import CreateCustomLicense from 'components/pages/create/';
import Page404 from '../404/Page404';
import CheckoutRoutes from './Checkout/CheckoutRoutes';

const StyledSection = styled.div`
  display: block;
  overflow-x: none;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.background.default};
`;

const StyledContent = styled.div`
  padding: 24px 0 0;
`;

function BasketComponent(props) {
  const { platform } = useParams();

  return (
    <Switch>
      <Route
        exact
        path={[
          `/app/(nuitrack|face|nuitrack-analytics)/basket/license-selection/(|create)`,
          `/app/(nuitrack|face|nuitrack-analytics)/basket/`,
        ]}
      >
        <StyledSection>
          <Header title={tr(`tabtitle_basket_${platform}`)} />
          <BasketHeader
            buttonText={tr('go_back')}
            platform={platform}
            backUrl={`/app/${platform}/dashboard/`}
          />
          <StyledContent>
            <Switch>
              <Route
                exact
                path={`/app/:platform(nuitrack|face|nuitrack-analytics)/basket/license-selection/(|create)`}
              >
                <LicenseSelection platform={platform} />
                <Route path={`/app/:platform(face)/basket/license-selection/create/`}>
                  <CreateCustomLicense />
                </Route>
              </Route>
              <Redirect
                exact
                from={`/app/:platform(nuitrack|face|nuitrack-analytics)/basket/`}
                to={`/app/:platform/basket/license-selection/`}
              />
            </Switch>
          </StyledContent>
        </StyledSection>
      </Route>
      <Route
        path={`/app/:platform(nuitrack|face|face-machine|nuitrack-analytics)/basket/checkout/`}
      >
        <CheckoutRoutes platform={platform} />
      </Route>
      <Route component={Page404} />
    </Switch>
  );
}

export default BasketComponent;
