import React, { useState } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button, Box, Form } from '@3divi/baseui';
import { useDispatch, useSelector } from 'react-redux';
import { updateClientInfoAction } from '../../../store/clientdata/actions';

const RegTitle = styled.h2`
  font: ${props => props.theme.font.xl};
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
`;

const Extra = styled.div`
  padding: 16px 0 0;
  text-align: center;
`;

const RegSubtitle = styled.h2`
  font: ${props => props.theme.font.s};
  text-align: center;
  padding-bottom: 16px;
  color: ${props => props.theme.text.secondary};
`;

function RegStepOne() {
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [site, setSite] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const clientInfo = useSelector(state => state.clientdata.clientInfo);
  const nextURL = useSelector(state => state.clientdata.registerNuitrackAnalytics.nextURL);
  return (
    <>
      <RegTitle>{tr('registration_na_steptwo_title')}</RegTitle>
      <RegSubtitle>{tr('registration_na_steptwo_subtitle')}</RegSubtitle>
      <Form
        type="blur"
        onSubmit={
          loading
            ? undefined
            : async () => {
                if (name || company || site) {
                  setLoading(true);
                  try {
                    const clientData = {
                      ...clientInfo,
                      name,
                      company: {
                        ...clientInfo.company,
                        name: company,
                        site,
                      },
                    };
                    await dispatch(updateClientInfoAction({ clientData }));
                  } finally {
                    setLoading(false);
                  }
                }
                window.location.href = nextURL;
              }
        }
      >
        <Form.Input
          label={tr('user_name')}
          name="name"
          tabIndex="1"
          autofocus={true}
          variant="column"
          size="m"
          maxlength={999}
          callback={setName}
          rules={[
            {
              validator: v => !v || v.length <= 256,
              message: tr('field_max_length_error', { field: 'name', maxLength: 256 }),
            },
          ]}
        />
        <Form.Input
          label={tr('company_name')}
          name="company"
          tabIndex="2"
          variant="column"
          size="m"
          maxlength={999}
          callback={setCompany}
          rules={[
            {
              validator: v => !v || v.length <= 256,
              message: tr('field_max_length_error', { field: 'company', maxLength: 256 }),
            },
          ]}
        />
        <Form.Input
          label={tr('company_site')}
          name="site"
          tabIndex="3"
          variant="column"
          size="m"
          maxlength={999}
          callback={setSite}
          rules={[
            {
              validator: v => !v || v.length <= 256,
              message: tr('field_max_length_error', { field: 'website', maxLength: 256 }),
            },
          ]}
        />
        <Box pt={2}>
          <Button
            tabIndex="4"
            variant="contained"
            size="m"
            elastic
            color="primary"
            text={tr('save_info_na_button')}
            disabled={!name && !company && !site}
            loading={loading}
          />
        </Box>
        <Extra>
          <Button
            tabIndex="5"
            color="primary"
            theme="clear"
            text={tr('skip_save_info_na_button')}
            onClick={() => {
              window.location.href = nextURL;
            }}
          />
        </Extra>
      </Form>
    </>
  );
}

export default RegStepOne;
