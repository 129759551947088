import { handleActions } from 'redux-actions';

const initialState = {
  modalStack: {},
};

const reducer = handleActions(
  {
    OPEN_MODAL: (state, action) => {
      const { modalData, hideAction } = action.payload;
      return {
        ...state,
        modalStack: {
          ...state.modalStack,
          [modalData.name]: {
            ...modalData,
            hideAction,
            isOpen: true,
          },
        },
      };
    },
    HIDE_MODAL: (state, action) => {
      const { modalName } = action.payload;
      return {
        ...state,
        modalStack: {
          ...state.modalStack,
          [modalName]: {
            ...state.modalStack[modalName],
            isOpen: false,
          },
        },
      };
    },
    CHANGE_MODAL: (state, action) => {
      const { modalName, modalData } = action.payload;
      return {
        ...state,
        modalStack: {
          ...state.modalStack,
          [modalName]: {
            ...state.modalStack[modalName],
            ...modalData,
          },
        },
      };
    },
    CLEAR_ALL_MODAL: () => {
      return initialState;
    },
  },
  initialState
);

export default reducer;
