import { createAction } from 'redux-actions';

const openModal = createAction('OPEN_MODAL');
const hideModal = createAction('HIDE_MODAL');
const changeModal = createAction('CHANGE_MODAL');
const clearAllModal = createAction('CLEAR_ALL_MODAL');

export const handleOpenModal = modalData => {
  return dispatch => {
    dispatch(openModal({ modalData, hideAction: () => dispatch(handleHideModal(modalData.name)) }));
  };
};

export const handleHideModal = modalName => {
  return dispatch => {
    dispatch(
      hideModal({
        modalName,
      })
    );
  };
};

export const handlerChangeModalData = (modalName, modalData) => {
  return dispatch => {
    dispatch(
      changeModal({
        modalName,
        modalData,
      })
    );
  };
};

export const handleHideAllModal = () => {
  return dispatch => {
    dispatch(clearAllModal());
  };
};
