import React from 'react';
import tr from 'locale';
import { updateClientData } from 'api';
import styled from 'styled-components';
import { updateClientInfo } from 'store/clientdata/actions';
import { connect } from 'react-redux';
import { Modal, Input, Button } from '@3divi/baseui';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';

const Buttons = styled.div`
  padding: 16px 0 8px;
  margin: 0 -8px;

  & > button {
    margin: 0 8px;
  }
`;

class ClientEdit extends React.Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.state = {
      name: this.props.accountInfo.name,
      lastname: this.props.accountInfo.last_name,
      phone: this.props.accountInfo.phone,
      email: this.props.accountInfo.email,
      position: this.props.accountInfo.company.position,
    };
  }

  saveChanges(event) {
    event.preventDefault();
    const { hideAction, dispatch, accountInfo, corporate } = this.props;
    let newInfo = Object.assign({}, accountInfo);
    newInfo.name = this.state.name;
    newInfo.last_name = this.state.lastname;
    newInfo.phone = this.state.phone;
    newInfo.email = this.state.email;
    newInfo.company.position = corporate ? this.state.position : '';
    updateClientData(newInfo).then(
      success => {
        dispatch(updateClientInfo({ newInfo: success.client_info }));
        hideAction();
      },
      async failure => {
        const { error_code: errorCode } = failure;
        const errorMsg = tr(errorCode || 'internal_server_error');
        if (errorMsg !== errorCode) {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: errorMsg,
            })
          );
        } else {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: tr('network_error'),
            })
          );
        }
      }
    );
  }

  render() {
    return (
      <Modal isOpen close={this.props.hideAction} title={tr('account_user_header')} fixed={400}>
        <form onSubmit={this.saveChanges}>
          <Input
            name="name"
            label={tr('user_name')}
            value={this.state.name || ''}
            onChange={val => this.setState({ name: val })}
            errorText=""
            maxlength={30}
            variant="column"
            size="m"
            autoFocus="autofocus"
          />
          <Input
            name="lastname"
            label={tr('user_lastname')}
            value={this.state.lastname || ''}
            onChange={val => this.setState({ lastname: val })}
            errorText=""
            maxlength={150}
            variant="column"
            size="m"
          />
          <Input
            name="phone"
            label={tr('user_phone')}
            value={this.state.phone || ''}
            onChange={val => this.setState({ phone: val })}
            errorText=""
            maxlength={30}
            variant="column"
            placeholder="+"
            size="m"
          />
          {this.props.corporate && (
            <Input
              name="position"
              label={tr('user_company_position')}
              value={this.state.position || ''}
              onChange={val => this.setState({ position: val })}
              errorText=""
              maxlength={150}
              variant="column"
              size="m"
            />
          )}
          <Buttons>
            <Button
              size="m"
              variant="contained"
              text={tr('account_change_save_button')}
              color="primary"
            />
            <Button size="m" text={tr('cancel_button')} onClick={this.props.hideAction} />
          </Buttons>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountInfo: state.clientdata.clientInfo,
  };
}
export default connect(mapStateToProps)(ClientEdit);
