import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button, RadioOrCheckbox, WithIcon, Box, Form } from '@3divi/baseui';
import { useDispatch, useSelector } from 'react-redux';
import { registerNuitrackAnalyticsAction } from '../../../store/clientdata/actions';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import TaggedText from '../../elements/TaggedText';
import {
  notAvailableSymbolRegexp,
  specialRegexp,
  upperLatinRegexp,
  lowerLatinRegexp,
  validEmailRegexp,
} from '../../../helpers/regexp';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';

const RegTitle = styled.h2`
  font: ${props => props.theme.font.xl};
  font-weight: 600;
  text-align: center;
  padding-bottom: 8px;
`;

const TextCenterDiv = styled.div`
  text-align: center;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.link.default};
  font: ${props => props.theme.font.s};
  padding: 2px 0;

  & path {
    fill: ${props => props.theme.link.default};
  }

  &:hover {
    color: ${props => props.theme.link.hover};
  }

  &:active {
    color: ${props => props.theme.link.active};
  }

  &:hover path {
    fill: ${props => props.theme.link.hover};
  }

  &:active path {
    color: ${props => props.theme.link.active};
  }
`;

const getHandledErrors = () => ['1fb53c53', '6352926b', '4f37c7da'];

function RegStepOne({ setStep }) {
  const [agree, setAgree] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [emailError, setEmailError] = useState();
  const dispatch = useDispatch();
  const { clientExist } = useSelector(state => state.clientdata.registerNuitrackAnalytics);
  const captchaRef = useRef();

  useEffect(() => {
    if (success)
      if (clientExist) setStep('exist');
      else setStep('fill_info');
  }, [clientExist, success]);

  return (
    <>
      <RegTitle>{tr('registration_na_title')}</RegTitle>
      <Form
        type="blur"
        onSubmit={
          loading || !captchaRef
            ? undefined
            : async values => {
                let reCaptchaToken = captchaRef.current.getValue();
                if (!reCaptchaToken)
                  try {
                    reCaptchaToken = await captchaRef.current.executeAsync();
                  } catch {
                    dispatch(
                      handleOpenModal({
                        name: 'error-captcha-unexpected',
                        component: ErrorModal,
                        text: tr('captcha_unavailable_error'),
                      })
                    );
                  }

                if (reCaptchaToken) {
                  setLoading(true);
                  try {
                    await dispatch(
                      registerNuitrackAnalyticsAction(
                        {
                          email: values.email,
                          password: values.password,
                          reCaptchaToken: reCaptchaToken,
                        },
                        { handledErrors: getHandledErrors() }
                      )
                    );
                    setSuccess(true);
                  } catch (requestError) {
                    const { error_code: errorCode } = requestError;
                    const errorMsg = tr(errorCode);
                    if (
                      errorCode === '1fb53c53' || // invalid token error
                      errorCode === '824d29c0' // potenial bot error
                    ) {
                      dispatch(
                        handleOpenModal({
                          name: 'error-invalid-captcha-token',
                          component: ErrorModal,
                          text: errorMsg,
                        })
                      );
                      captchaRef.current.reset(); // must be reseted to get new token after new submit attempt
                    } else if (errorCode === '4f37c7da')
                      // password contain part of email
                      setPasswordError(errorMsg);
                    else if (errorCode === '6352926b')
                      // invalid email format (domain > 63)
                      setEmailError(errorMsg);
                  } finally {
                    setLoading(false);
                  }
                }
              }
        }
      >
        <Form.Input
          label={tr('user_email')}
          name="email"
          tabIndex="1"
          autofocus={true}
          variant="column"
          size="m"
          maxlength={999}
          error={{ message: emailError }}
          rules={[
            { validator: v => !!v, message: tr('email_empty_error') },
            {
              validator: v => v && v.length <= 150,
              message: tr('field_max_length_error', { field: 'email', maxLength: 150 }),
            },
            { validator: v => validEmailRegexp.test(v), message: tr('email_validation_error') },
          ]}
        />
        <Form.Input
          label={tr('login_password_label')}
          type="password"
          name="password"
          tabIndex="2"
          variant="column"
          size="m"
          error={{ message: passwordError }}
          maxlength={999}
          rules={[
            { validator: v => !!v, message: tr('password_empty_error') },
            {
              validator: v => v && v.length <= 256,
              message: tr('field_max_length_error', { field: 'password', maxLength: 256 }),
            },
            {
              validator: v => v && v.length >= 8,
              message: tr('password_at_least_error', { atLeast: 8 }),
            },
            {
              validator: v => !notAvailableSymbolRegexp.test(v),
              message: tr('password_not_available_symbol_error'),
            },
            {
              validator: v =>
                specialRegexp.test(v) && upperLatinRegexp.test(v) && lowerLatinRegexp.test(v),
              message: tr('password_no_all_symbol_types_error'),
            },
          ]}
        />
        <Form.Input
          label={tr('repeat_password_label')}
          type="password"
          name="re_password"
          tabIndex="3"
          variant="column"
          size="m"
          maxlength={999}
          dependences={['password']}
          rules={[
            {
              validator: (v, deps) => deps && v === deps.password,
              message: tr('password_match_error'),
            },
          ]}
        />
        <Box py={1}>
          <RadioOrCheckbox
            type="checkbox"
            checked={agree}
            onClick={() => setAgree(agree => !agree)}
            title={useMemo(
              () => (
                <TaggedText
                  text={`I agree with the <a>${tr('signup_pp')}</a>`}
                  a={
                    <StyledLink href="http://3divi.com/downloads/privacy_policy.pdf">
                      <WithIcon as="span" icon="link" size="xxs"></WithIcon>
                    </StyledLink>
                  }
                />
              ),
              []
            )}
          />
        </Box>
        <Box pt={2}>
          <Button
            tabIndex="4"
            variant="contained"
            size="m"
            elastic
            color="primary"
            text={tr('register_na_button')}
            disabled={!agree}
            loading={loading || !captchaRef}
          />
        </Box>
        <Box pt={2}>
          <TextCenterDiv>
            <StyledLink as={Link} to={'/app/login/nuitrack-analytics/'} tabIndex="5">
              {tr('signup_already')}
            </StyledLink>
          </TextCenterDiv>
        </Box>
      </Form>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey="6LfuBo4aAAAAAI7XO7knbRQtoajLUtpwdELq9KLj"
        size="invisible"
      />
    </>
  );
}

export default RegStepOne;
