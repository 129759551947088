import React, { useState } from 'react';
import tr from 'locale';
import { confirmAccount } from 'api';
import { Button } from '@3divi/baseui';
import Header from 'Header';
import { StyledLoginContent, LoginTitle } from './styled/components';

function NotConfirmed(props) {
  let { platform, email, toLogin } = props;
  if (!platform) platform = 'nuitrack';

  const [loading, setLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    confirmAccount(email, platform).then(
      success => {
        setLoading(false);
        toLogin();
      },
      failure => {
        setLoading(false);
        toLogin();
      }
    );
  };

  return (
    <StyledLoginContent>
      <Header title={tr('tabtitle_registration')} />
      <LoginTitle>{tr('confirm_your_email_title')}</LoginTitle>
      <form>
        <div className="form-container__succes-text">
          <p className="confirm__text">
            {tr('confirm_your_account__text')}{' '}
            <a target="_top" className="link__mail" href="mailto:info@3divi.com">
              info@3divi.com
            </a>
          </p>
        </div>
        <Button
          onClick={onSubmit}
          text={tr('resend_email')}
          loading={loading}
          variant="contained"
          elastic
          size="m"
          color="primary"
        />
      </form>
    </StyledLoginContent>
  );
}

export default NotConfirmed;
