import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import './GroupObject.css';
import CopyBtn from 'components/elements/CopyBtn';
import { Col, Section, Button } from '@3divi/baseui';
import { withRouter } from 'react-router';

const StyledCopyBtn = styled(CopyBtn)`
  position: absolute;
  right: 16px;
  top: 8px;
`;

const StyledSection = styled(Section)`
  & button {
    opacity: 0;
    transition: opacity ease 0.12s;
  }

  &:hover button {
    opacity: 1;
  }
`;

class KeyInfo extends React.Component {
  render() {
    let keyClass = 'licenses__type' + (this.props.alert ? ' licenses__type_alert' : '');
    return (
      <div className="keys__info">
        <div className={keyClass}>{this.props.type}</div>
        <div className="keys__num">{this.props.count}</div>
        <div className="keys__line"></div>
        <div className="keys__platform-sub-title">{this.props.subTitle}</div>
      </div>
    );
  }
}

class GroupObject extends React.Component {
  copyKey = e => {
    e.preventDefault();
    let field = e.currentTarget.previousElementSibling;
    field.select();
    try {
      document.execCommand('copy');
    } catch (exc) {
      console.log(exc);
    }
  };

  render() {
    const total = this.props.used + this.props.limit;
    const available = this.props.limit;
    let usedPart = Math.floor((100 * this.props.used) / total);
    let unusedPart = 100 - usedPart;
    const isEmpty = total === this.props.used;
    let inputStyles = {
      border: 'none',
      width: '100%',
      background: 'none',
    };
    if (isEmpty) {
      inputStyles.color = '#CCC';
    }

    return (
      <Col xl={3} l={4} m={4} s={6} xs={6} xxs={12}>
        <StyledSection
          title={this.props.title}
          subtitle={this.props.subTitle}
          buttons={
            <Button
              round
              iconLeft="pen"
              size="s"
              onClick={() => this.props.history.push(`${this.props.id}/group-key-info/`)}
            />
          }
        >
          <div
            className={'keys__key' + (isEmpty ? ' keys__key_isempty' : '')}
            style={{ backgroundSize: `${unusedPart}%` }}
          >
            <input
              readOnly
              {...{ disabled: isEmpty }}
              value={this.props.token}
              style={inputStyles}
            />
            <StyledCopyBtn className="keys__copy" disabled={isEmpty} onClick={this.copyKey} />
          </div>
          <div className="keys__info-container">
            <KeyInfo
              key="available"
              type={tr('available')}
              count={available}
              // subTitle={this.props.limitTitle}
            />
            <KeyInfo
              key="total"
              type={tr('total')}
              count={total}
              // subTitle={this.props.usedTitle}
            />
          </div>
        </StyledSection>
      </Col>
    );
  }
}

export default withRouter(GroupObject);
