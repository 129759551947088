import styled from 'styled-components';

const ButtonLine = styled.div`
  padding: 16px 0 0;
`;

const Extra = styled.div`
  padding: 16px 0 0;
  text-align: center;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
`;

const StyledLoginContent = styled.div`
  padding: 36px 48px;
  max-width: 50%;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @media (max-width: 720px) {
    padding: 32px 24px;
    max-width: 100%;
  }
`;

const LoginTitle = styled.h2`
  font: ${({ theme }) => theme.font.xl};
  color: ${({ theme }) => theme.text.default};
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
`;

export { ButtonLine, Extra, StyledLoginContent, LoginTitle };
