import tr from 'locale';
import React from 'react';
import styled from 'styled-components';
import { WithIcon, Tooltip } from '@3divi/baseui';
import { themeDefault } from '../components/themes/themeDefault';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.link.default};
`;

const getToooltipText = license => {
  switch (license.locked_to) {
    case 'sensor_id':
      return tr('sensor_id_tooltip');
    case 'android_app_id':
      return tr('app_id_tooltip');
    case 'ios_app_id':
      return tr('app_id_tooltip');
    case 'hardware_id':
      return tr('hardware_id_tooltip');
    case 'no_bound':
      return tr('no_bound_tooltip');
    case 'fully_portable':
      return tr('fully_portable_tooltip');
    case 'usb_token':
      return tr('usb_token_tooltip');
    default:
      return '';
  }
};

const getLicenseLockedTo = license => {
  if (!license) return;
  const tooltipText = getToooltipText(license);
  const documentLink =
    license.type === 'nuitrack'
      ? 'https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/Install.md#license-activation'
      : license.locked_to === 'usb_token'
      ? 'https://docs.facesdk.3divi.com/docs/licenses#locking-to-a-usb-token'
      : license.type === 'face'
      ? 'https://docs.facesdk.3divi.com/docs/licenses#license-locking'
      : undefined;
  const tooltipComponent = (
    <div onClick={e => e.stopPropagation()}>
      {tooltipText}
      {documentLink && (
        <div>
          <StyledLink target="_blank" rel="noopener noreferrer" href={documentLink}>
            <WithIcon size="xxs" icon="link" color={themeDefault.themeGreenColor}>
              {tr('learn_more')}
            </WithIcon>
          </StyledLink>
        </div>
      )}
    </div>
  );

  return (
    <>
      {tr(license.locked_to || '')}
      {tooltipText ? (
        <Tooltip tooltipColor={themeDefault.whiteColor} component={tooltipComponent}>
          <WithIcon icon="info" color={themeDefault.themeGreenColor} />
        </Tooltip>
      ) : undefined}
    </>
  );
};

export default getLicenseLockedTo;
