// Hooks
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
// Actions
import { createCustomGroupAction } from '../../../store/licenses/actions';
// Other
import styled from 'styled-components';
import tr from 'locale';
import { getProductName, getLicenseType } from '../../../helpers';
import { onlyDigitsRegexp } from '../../../helpers/regexp';
import { themeDefault } from '../../themes/themeDefault';
// Components
import { Button, Input, Battery, Row, Col, Tooltip, WithIcon, Typography } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';

const Wrap = styled.div`
  padding: ${props => props.top || props.topBottom || 0}px
    ${props => props.right || props.leftRight || 0}px
    ${props => props.bottom || props.topBottom || 0}px
    ${props => props.left || props.leftRight || 0}px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.link.default};
  margin-left: 4px;
`;

const TextBlock = styled.div`
  flex-direction: row;
`;

function CreateGroupKeyModal(props) {
  const { hideAction } = props;

  const dispatch = useDispatch();

  const [storeKey, setStoreKey] = useState(Date.now());
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [count, setCount] = useState();
  const [countError, setCountError] = useState('');
  const [choosen, setChoosen] = useState();
  // selectors
  const availableDefaultGroupsSelector = useMemo(
    () =>
      createSelector(
        state =>
          state.licenses.groups.filter(
            gr => gr.is_default && gr.limit >= 1 && gr.type === 'nuitrack'
          ),
        res => res
      ),
    []
  );
  const availableGroups = useSelector(availableDefaultGroupsSelector);
  const { loading, success } = useSelector(
    state => state.licenses.createCustomGroup[storeKey] || state.licenses.default
  );

  const onGroupClick = (e, group) => {
    if (choosen !== group) {
      setChoosen(group);
      setCount(Math.min(2, group.limit));
    }
  };

  const redirectToDashboard = () => {
    hideAction();
  };

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  useEffect(() => {
    if (success) {
      hideAction();
    }
  }, [success]);

  return (
    <ModalComponent {...props} title={tr('create_group_key')}>
      {availableGroups.length > 0 ? (
        <>
          <Input
            innerRef={ref}
            name="group_key_name"
            label={tr('group_name')}
            value={name}
            onChange={v => {
              if (v.length > 256) {
                setNameError(tr('group_name_max_length_error', { maxLength: 256 }));
                return;
              }

              if (nameError.length > 0) setNameError('');
              setName(v);
            }}
            errorText={nameError}
            maxlength={257}
            theme="column"
            autoFocus={true}
            size="m"
          />
          <Wrap topBottom={8}>
            <Typography color="secondary">{tr('available_license_types')}</Typography>
          </Wrap>
          <Battery borderRadius="4px" direction="column" color="primary" variant="outlined">
            {availableGroups.map((gr, i) => (
              <Button
                key={`btn-cgkm-${gr.id}${i}`}
                text={`${tr(getProductName(gr))} | ${getLicenseType(gr)}`}
                onClick={e => onGroupClick(e, gr)}
                color="primary"
                variant={gr === choosen ? 'contained' : undefined}
              />
            ))}
          </Battery>
          <Wrap topBottom={8}>
            <Input
              label={tr('group_size')}
              afterLabelTooltip={
                <Tooltip
                  side="top-center"
                  tooltipColor={themeDefault.backgroundColor}
                  component={tr('license_count_in_new_group')}
                >
                  <WithIcon icon="info" color="primary" />
                </Tooltip>
              }
              value={count}
              onChange={v => {
                if (countError.length > 0) setCountError('');
                if (onlyDigitsRegexp.test(v) || v === '') setCount(v);
              }}
              onBlur={e => {
                if (choosen && e.target.value === '') setCount(0);
              }}
              errorText={countError}
              maxlength={257}
              theme="column"
              size="m"
              type="number"
              readOnly={!choosen}
            />
            {choosen && (
              <TextBlock>
                {tr('license_count_in_new_group_max_part1')}
                <Typography as="span" weight="500">
                  {choosen.limit}
                </Typography>
                {tr('license_count_in_new_group_max_part2')}
                <Tooltip
                  side="top-center"
                  tooltipColor={themeDefault.backgroundColor}
                  component={
                    <>
                      {tr('info_where_available_licenses')}
                      <StyledLink to={'/app/nuitrack/dashboard/'} onClick={redirectToDashboard}>
                        {tr('dashboard')}
                      </StyledLink>
                    </>
                  }
                >
                  <WithIcon icon="info" color="primary" />
                </Tooltip>
              </TextBlock>
            )}
          </Wrap>
          <Wrap top={16} bottom={0}>
            <Row>
              <Col xl={6}>
                <Button
                  size="m"
                  text={tr('create')}
                  variant="contained"
                  elastic
                  loading={loading}
                  color="primary"
                  onClick={() => {
                    let countInt = parseInt(count);
                    let wasError = false;
                    if (countInt > choosen.limit) {
                      setCountError(tr('license_in_group_limit_error', { limit: choosen.limit }));
                      wasError = true;
                    } else if (countInt === 0) {
                      setCountError(tr('group_size_is_zero'));
                      wasError = true;
                    }
                    if (name.trim().length === 0) {
                      setName('');
                      setNameError(tr('field_empty_error'));
                      wasError = true;
                    }

                    if (wasError) return;

                    dispatch(
                      createCustomGroupAction(
                        { sourceId: choosen.id, newLimit: countInt, newTitle: name.trim() },
                        { storeKey }
                      )
                    );
                  }}
                  disabled={!choosen}
                />
              </Col>
              <Col xl={6}>
                <Button elastic size="m" text={tr('cancel_button')} onClick={hideAction} />
              </Col>
            </Row>
          </Wrap>
        </>
      ) : (
        <>
          <Wrap topBottom={16}>
            <Typography size="15" height="20">
              {tr('have_no_available_licenses')}
            </Typography>
          </Wrap>
          <Button
            innerRef={ref}
            elastic
            variant="contained"
            color="primary"
            size="m"
            text={tr('ok')}
            onClick={hideAction}
            autoFocus={true}
          />
        </>
      )}
    </ModalComponent>
  );
}

export default CreateGroupKeyModal;
