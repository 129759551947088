import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import TableRow from './TableRow';
import { GRID_SIZE, Typography } from '@3divi/baseui';
import TableFooter from './TableFooter';

const TBody = styled.div`
  display: grid;
  grid-template-rows: max-content;

  ${props => props.css && props.css};
`;

const EmptyText = styled(Typography)`
  padding: 16px;
`;

const START_SHOW_COUNT = 20,
  LIMIT = 50;

function TableBody(props) {
  const { rows, rowClick, emptyText, columns, cells, rowsChangedTime, entitiesName } = props;
  const [offset, setOffset] = useState(START_SHOW_COUNT);
  const [lastChanged, setLastChanged] = useState(rowsChangedTime);

  const getCss = columns => {
    const xxs =
      columns.filter(column => column.visible === 'xxs').length > 0 &&
      `
      @media (${GRID_SIZE.xxs}) {
        grid-template-columns: ${
          columns.map(column => (column.visible ? '' : column.size)).join(' ') + ' minmax(0, 1fr)'
        };
      };
    `;
    const xs =
      columns.filter(column => column.visible === 'xs').length > 0 &&
      `
      @media (${GRID_SIZE.xs}) {
        grid-template-columns: ${
          columns
            .map(column => (column.visible === 'xxs' || !column.visible ? column.size : ''))
            .join(' ') + ' minmax(0, 1fr)'
        };
      };
    `;
    const s =
      columns.filter(column => column.visible === 's').length > 0 &&
      `
      @media (${GRID_SIZE.s}) {
        grid-template-columns: ${
          columns
            .map(column =>
              column.visible === 's' || column.visible === 'm' || column.visible === 'l'
                ? ''
                : column.size
            )
            .join(' ') + ' minmax(0, 1fr)'
        };
      };
    `;
    const m =
      columns.filter(column => column.visible === 'm').length > 0 &&
      `
      @media (${GRID_SIZE.m}) {
        grid-template-columns: ${
          columns
            .map(column => (column.visible === 'm' || column.visible === 'l' ? '' : column.size))
            .join(' ') + ' minmax(0, 1fr)'
        };
      };
    `;
    const l =
      columns.filter(column => column.visible === 'l').length > 0 &&
      `
      @media (${GRID_SIZE.l}) {
        grid-template-columns: ${
          columns.map(column => (column.visible === 'l' ? '' : column.size)).join(' ') +
          ' minmax(0, 1fr)'
        };
      };
    `;

    return `
      grid-template-columns: ${columns.map(column => column.size).join(' ') + ' minmax(0, 1fr)'};
      ${l ? l : ''}
      ${m ? m : ''}
      ${s ? s : ''}
      ${xs ? xs : ''}
      ${xxs ? xxs : ''}
    `;
  };

  useEffect(() => {
    setLastChanged(rowsChangedTime);
    if (rowsChangedTime) setOffset(START_SHOW_COUNT);
  }, [rowsChangedTime]);

  return (
    <>
      <TBody css={useCallback(getCss(columns), [columns])}>
        {cells && <TableRow header columns={columns} cells={cells} />}
        {rows && rows.length > 0 && (
          <>
            {rows.map((el, i) => {
              let showCount = offset;
              // checking rows changed, and reset offset for first re-render (before offset actually changed if useEffect)
              if (lastChanged !== rowsChangedTime) showCount = START_SHOW_COUNT;
              return (
                i < showCount && (
                  <TableRow
                    key={i}
                    id={el.cells[0]}
                    cells={el.cells}
                    columns={columns}
                    rowClick={rowClick}
                    {...el}
                  />
                )
              );
            })}
          </>
        )}
      </TBody>
      <TableFooter
        showed={rows.length < offset ? rows.length : offset}
        total={rows.length}
        button={{ onClick: () => rows.length > offset && setOffset(offset => offset + LIMIT) }}
        entitiesName={entitiesName}
      />
      {!(rows && rows.length > 0) && <EmptyText color="secondary">{emptyText}</EmptyText>}
    </>
  );
}

export default TableBody;
