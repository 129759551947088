import tr from 'locale';

export const getLicenseInfoList = (license, productName, licenseType) => {
  if (!license) return [];

  // define editable (or not) title field
  const title =
    license.is_fake || license.license_status === 'holded'
      ? { text: tr('title'), value: license.title }
      : { license, editKey: 'title' };

  let config = [
    productName,
    licenseType,
    title,
    {
      text: tr('license_status'),
      value: license && license.license_status && tr(license.license_status),
    },
  ];

  return config;
};
