import React from 'react';
import styled from 'styled-components';
import { Col, Typography } from '@3divi/baseui';
import { useSelector } from 'react-redux';

const GroupBtn = styled.div`
  position: relative;
  margin: 0 -16px;

  & span {
    text-align: left;
  }

  &:before {
    content: '';
    position: absolute;
    left: 16px;
    right: 16px;
    height: 1px;
    transition: background 0.2s;
    background: ${({ theme }) => theme.border.default};
  }

  ${props =>
    props.needHover &&
    `
    &:hover:before {
      background: ${({ theme }) => theme.button.primary};
  `}
`;

const Subitem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: 16px 16px 8px;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    background: ${({ theme }) => theme.border.default};
    top: 0;
    bottom: 0;
    right: -9px;
  }

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    background: ${({ theme }) => theme.border.default};
    bottom: -1px;
    left: -8px;
    right: -8px;
  }
`;

const EmptyElement = styled.div`
  height: 40px;
`;

const StyledNumber = styled.div`
  margin-top: auto;
  font: ${({ theme }) => theme.font.xxl};
  color: ${({ theme }) => theme.text.default};
  padding: 8px 0;
  box-sizing: border-box;
`;

const License = props => {
  const prices = useSelector(state => state.clientdata.prices);
  const { title, subtitle, availableLicenses, button, platform } = props;
  const isDeprecated = prices?.nuitrack?.old?.deprecated;
  const Button = button && button.component;
  if (
    title.props.children[0] === 'Nuitrack Pro | ' &&
    title.props.children[1] === 'Perpetual' &&
    isDeprecated &&
    availableLicenses === 0
  ) {
    return null;
  }
  return (
    <Col xl={3} l={3} m={4} s={6} xs={12} xxs={12}>
      <Subitem>
        <Typography height="20" size="15" weight="400">
          {title} {subtitle}
        </Typography>
        <StyledNumber>{availableLicenses}</StyledNumber>
        <GroupBtn needHover={button}>
          {button ? (
            <Button
              {...button.props}
              size="m"
              modal={button.props.modal}
              button={button.props.btn}
              elastic
              priority={true}
              platform={platform}
              color="primary"
              variant="clear"
            />
          ) : (
            <EmptyElement />
          )}
        </GroupBtn>
      </Subitem>
    </Col>
  );
};

export default License;
