import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Components
import { Portal } from '@3divi/baseui';

const TooltipCustom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  font-size: 0.85rem;
  pointer-events: none;
  max-width: 40%;

  &:after {
    content: ' ';
    position: absolute;
    border-style: solid;
    border-width: 5px;
  }

  &.top:after,
  &.top-left::after {
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-color: black transparent transparent transparent;
  }

  &.bottom:after {
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-color: transparent transparent black transparent;
  }

  &.left:after {
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-color: transparent transparent transparent black;
  }

  &.right:after {
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-color: transparent black transparent transparent;
  }
`;

const StyledText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tooltip = props => {
  const { children, spaces, text, side, callback, display } = props;
  const [show, setShow] = useState(false);
  const [styles, setStyles] = useState({});
  const refEl = useRef(null);
  const refPopEl = useRef(null);

  const enterPlaces = () => {
    setShow(true);
  };

  const leavePlaces = () => {
    setShow(false);
    setStyles({});
    callback && callback();
  };

  const addStyles = (el, type) => {
    let elCoord = refEl.current.getBoundingClientRect();
    let elPopCoord = refPopEl.current.getBoundingClientRect();

    const style = {};
    style.left = Math.floor(elCoord.left + elCoord.width / 2 - elPopCoord.width / 2);

    if (elCoord.height < elPopCoord.height) {
      style.top = Math.floor(
        elCoord.top + window.pageYOffset + (elPopCoord.height - elCoord.height) / 2
      );
    } else {
      style.top = Math.floor(
        elCoord.top + window.pageYOffset - (elPopCoord.height - elCoord.height) / 2
      );
    }

    switch (side) {
      case 'left':
        style.left = Math.floor(elCoord.left - spaces);
        style.transform = 'translateX(-100%)';
        break;
      case 'right':
        style.left = Math.floor(elCoord.left + elCoord.width + spaces);
        style.transform = 'translateX(0%)';
        break;
      case 'top':
        style.top -= spaces;
        style.transform = 'translateY(-100%)';
        break;
      case 'top-left':
        style.top = Math.floor(elCoord.top - spaces);
        style.left = Math.floor(elCoord.left);
        style.transform = 'translateY(-100%)';
        break;
      case 'bottom':
        style.top += spaces;
        style.transform = 'translateY(100%)';
        break;
      default:
        break;
    }

    setStyles(style);
  };

  useEffect(() => {
    if (refPopEl.current !== null) {
      addStyles();
    }
  }, [show]);

  const childElement = React.Children.only(children);
  return (
    <>
      {React.cloneElement(childElement, {
        ref: refEl,
        onMouseEnter: enterPlaces,
        onMouseLeave: leavePlaces,
      })}
      {display && show && (
        <Portal isOpen={show}>
          <TooltipCustom ref={refPopEl} className={side} style={styles}>
            <StyledText className="tooltip-custom__text">{text}</StyledText>
          </TooltipCustom>
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
