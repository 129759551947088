import React from 'react';
import { getLicenseType } from '../../../../helpers';
import { getFaceLicenseConfiguration } from '../../../../helpers/getFaceLicenseConfiguration';
import { useFacePreInfo } from './components/useFacePreInfo';
import FacePreInfoView from './components/FacePreInfoView';
import { useCheckAvailableLicense } from './components/hooks/useCheckAvailableLicense';

function FacePreInfo() {
  const {
    group,
    fakeLicense,
    platform,
    loaded,
    isHolded,
    activationInfo,
    getActivationInfoAction,
    metadata,
    licenseInfo,
    backUrl,
    urlId,
  } = useFacePreInfo();

  const redirect = useCheckAvailableLicense({ loaded, group, id: urlId, platform });

  if (redirect) return redirect;

  return (
    <FacePreInfoView
      header={{
        backUrl,
        title: fakeLicense && fakeLicense.title,
        subtitle: fakeLicense && getLicenseType(fakeLicense),
      }}
      isLoaded={loaded}
      activationInfo={{
        items: activationInfo,
        action: getActivationInfoAction(),
      }}
      licenseConfiguration={{ items: getFaceLicenseConfiguration(fakeLicense) }}
      metadata={metadata}
      info={licenseInfo}
    />
  );
}

export default FacePreInfo;
