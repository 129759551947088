import React, { useState, useEffect } from 'react';
import tr from 'locale';
import CompanyInformation from './CompanyInformation.js';
import ContactInformation from './ContactInformation.js';
import Notifications from './Notifications.js';
import { Section, Bricks, Button } from '@3divi/baseui';
import styled from 'styled-components';
import { AccountLink } from './AccountLinks';
import { handleOpenModal, handleHideModal } from 'store/modals/actions';
import ClientEdit from './modals/ClientEdit';
import CompanyEdit from './modals/CompanyEdit';
import PasswordChange from './modals/PasswordChange';
// import EmailChange from './modals/EmailChange/';
import ModalComponent from '../modals/ModalComponent';
import { updateClientInfoAction } from '../../store/clientdata/actions';
import { useSelector } from 'react-redux';

const Llist = styled.div`
  padding: 0 0 16px;
`;

function AccountPageContent(props) {
  const { dispatch, accountInfo, corporate } = props;

  const [storeKey, setStoreKey] = useState(Date.now());

  const { status, errorCode } = useSelector(
    state => state.clientdata.updateInfo[storeKey] || state.clientdata.default
  );

  const openClientEdit = () =>
    dispatch(
      handleOpenModal({
        component: ClientEdit,
        name: 'client_info_edit',
        accountInfo,
        corporate,
      })
    );
  const openCompanyEdit = () =>
    dispatch(
      handleOpenModal({
        component: CompanyEdit,
        name: 'company_info_edit',
        accountInfo,
      })
    );
  const openPasswordChange = () =>
    dispatch(
      handleOpenModal({
        component: PasswordChange,
        name: 'account_password_change',
        accountInfo,
        corporate,
      })
    );
  // const openEmailChange = () =>
  //   dispatch(
  //     handleOpenModal({
  //       component: EmailChange,
  //       name: 'account_email_change',
  //     })
  //   );

  const openBecomePersonal = () =>
    dispatch(
      handleOpenModal({
        component: ModalComponent,
        icon: 'person',
        type: 'success',
        title: tr('switched_to_personal'),
        buttons: (
          <Button
            size="m"
            text={tr('button_continue')}
            color="primary"
            elastic
            onClick={() => dispatch(handleHideModal('become_personal'))}
          />
        ),
        name: 'become_personal',
      })
    );
  const openBecomeCompany = () =>
    dispatch(
      handleOpenModal({
        component: ModalComponent,
        icon: 'person',
        type: 'success',
        title: tr('switched_to_corporate'),
        buttons: (
          <Button
            size="m"
            text={tr('fill_billing_info')}
            color="primary"
            variant="contained"
            elastic
            onClick={() => {
              dispatch(handleHideModal('become_company'));
              openCompanyEdit();
            }}
          />
        ),
        name: 'become_company',
      })
    );

  const becomeCompanyAction = () => {
    const newClientInfo = { ...accountInfo };
    newClientInfo.options.corporate_member = true;
    dispatch(updateClientInfoAction({ clientData: newClientInfo }, { storeKey }));
  };
  const becomePersonalAction = () => {
    const newClientInfo = { ...accountInfo };
    newClientInfo.options.corporate_member = false;
    dispatch(updateClientInfoAction({ clientData: newClientInfo }, { storeKey }));
  };

  useEffect(() => {
    if (status.loaded) {
      if (corporate) {
        openBecomeCompany();
      } else {
        openBecomePersonal();
      }
    }
  }, [status.loaded]);

  return (
    <Bricks gap={15} columnWidth={300} rowHeight={0}>
      <Bricks.Item>
        <Section title={tr('account_settings')}>
          <Llist>
            {corporate ? (
              <AccountLink
                icon="person"
                text={tr('switch_account_to_personal')}
                handle={becomePersonalAction}
              />
            ) : (
              <AccountLink
                icon="business"
                text={tr('switch_account_to_corporate')}
                handle={becomeCompanyAction}
              />
            )}
            <AccountLink
              icon="lock"
              text={tr('change_password_button')}
              handle={openPasswordChange}
            />
            {/* <AccountLink icon="email" text={tr('change_email_button')} handle={openEmailChange} /> */}
          </Llist>
        </Section>
      </Bricks.Item>
      {corporate && (
        <Bricks.Item>
          <CompanyInformation accountInfo={accountInfo} openCompanyEdit={openCompanyEdit} />
        </Bricks.Item>
      )}
      <Bricks.Item>
        <ContactInformation
          openClientEdit={openClientEdit}
          accountInfo={accountInfo}
          corporate={corporate}
        />
      </Bricks.Item>
      {/*<Bricks.Item>
        <PayInformation payInfo={payInfo} />
      </Bricks.Item>*/}
      <Bricks.Item>
        <Notifications accountInfo={accountInfo} />
      </Bricks.Item>
      {/*<GDPRInfo />*/}
    </Bricks>
  );
}

export default AccountPageContent;
