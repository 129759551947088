import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import { SectionBox, Icon, Button, Col, Box, Typography } from '@3divi/baseui';
import { ButtonModal, ButtonFillBalance, ButtonUpgrade } from 'components/modals/buttons/';
import BtnAdditionalText from 'components/elements/BtnAdditionalText/';
import ButtonFaceMachineServiceRedirect from 'components/elements/ButtonFaceMachineServiceRedirect/';
import Step from '../../../../elements/Step/';
import ButtonNuitrackAnalyticsServiceRedirect from '../../../../elements/ButtonNuitrackAnalyticsServiceRedirect';

const SectionStyled = styled(SectionBox)`
  position: relative;
  width: 100%;
  height: calc(100% - 16px);

  ${props => !props.active && 'pointer-events: none;'}
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 24px 0;

  ${props =>
    props.reverse &&
    `
    flex-direction: row-reverse;
    justify-content: space-between;
  `}
`;

const SkipButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

const IconStyle = styled(Icon)`
  width: 28px;
  height: 28px;
  padding: 4px;
  border: 2px solid ${({ theme }) => theme.background.contrast};
  border-radius: 50%;
  transition: all 0.2s;

  ${props =>
    props.completed &&
    `
    padding: 0;
    margin-right: 8px;
    border: 2px solid ${props.theme.background.brand};
    background-color: ${props.theme.background.brand};
  `}
`;

const Wrap = styled.div`
  margin-top: auto;
  padding-bottom: 16px;
`;

const Text = styled(Typography)`
  min-height: 80px;
`;

const HeaderTitle = styled(Typography)`
  padding: 2px 0;
`;

const SectionBody = styled.div`
  height: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const getButton = btn => {
  switch (btn.type) {
    case 'ButtonModal':
      return <ButtonModal {...btn.props} />;
    case 'ButtonFillBalance':
      return <ButtonFillBalance {...btn.props} />;
    case 'BtnAdditionalText':
      return <BtnAdditionalText {...btn.props} />;
    case 'ButtonUpgrade':
      return <ButtonUpgrade {...btn.props} />;
    case 'ButtonFaceMachineServiceRedirect':
      return <ButtonFaceMachineServiceRedirect {...btn.props} />;
    case 'ButtonNuitrackAnalyticsServiceRedirect':
      return <ButtonNuitrackAnalyticsServiceRedirect {...btn.props} />;
    default:
      return;
  }
};

function StepBlock({ skip, title, icon, step, text, button, reverse, status, ...other }) {
  return (
    <>
      {title && (
        <Col xl={3} l={3} m={4} s={6} xs={12} xxs={12}>
          <SectionStyled active={status === 'active'}>
            <SectionBody>
              <Header reverse={reverse}>
                {step && status !== 'completed' && (
                  <Step active={status === 'active'} space="8" count={step} />
                )}
                {(icon || status === 'completed') && (
                  <IconStyle
                    color={status === 'completed' ? 'light' : 'default'}
                    completed={status === 'completed'}
                    active={status === 'active'}
                    icon={status === 'completed' ? 'mark' : icon}
                  />
                )}
                {title && (
                  <HeaderTitle
                    weight="600"
                    size="17"
                    height="24"
                    color={status === 'active' ? 'primary' : 'default'}
                  >
                    {title}
                  </HeaderTitle>
                )}
                {skip && (
                  <SkipButton
                    onClick={skip}
                    disabled={status !== 'active'}
                    size="xs"
                    text={tr('skip')}
                  />
                )}
              </Header>
              <Box pb={3}>
                <Text color="secondary" size="15" height="20">
                  {text}
                </Text>
              </Box>
              {button && (
                <Wrap>
                  {getButton({
                    ...button,
                    props: {
                      ...button.props,
                      ...button.props.button,
                      disabled: Object.prototype.hasOwnProperty.call(button.props, 'disabled')
                        ? button.props.disabled
                        : status !== 'active',
                      variant: status === 'active' ? 'contained' : 'outlined',
                      color: status === 'active' ? 'primary' : 'default',
                      elastic: true,
                      size: 'm',
                      button: {
                        disabled: status !== 'active',
                        ...button.props.button,
                        variant: status === 'active' ? 'contained' : 'outlined',
                        color: status === 'active' ? 'primary' : 'default',
                        elastic: true,
                        size: 'm',
                      },
                    },
                  })}
                </Wrap>
              )}
            </SectionBody>
          </SectionStyled>
        </Col>
      )}
    </>
  );
}

export default StepBlock;
