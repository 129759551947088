import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSteps } from '../hooks';
import { createLicense, clearLicense } from 'store/licenseBuy/actions';
import { fetchLicensesAction } from 'store/licenses/actions';
import tr from 'locale';

import StartBlocks from './StartBlocks';
import { CURRENT_FACE_MACHINE_PLAN_ID } from '../../../../../Config';

function StartBlocksFaceMachine({
  stepsStore,
  setStep,
  licenses,
  setStorage,
  wasLicenseBought,
  setShowStep,
  platform,
}) {
  const dispatch = useDispatch();
  const price = useSelector(
    state =>
      state.clientdata.prices[platform] &&
      state.clientdata.prices[platform].fm_cloud[CURRENT_FACE_MACHINE_PLAN_ID].cost
  );
  const balance = useSelector(state => state.clientdata.balance);
  const loading = useSelector(state => state.licenseBuy.createLicense.loading);
  const { currentStep, nextStep, status, last } = useSteps(stepsStore, platform);
  const trial = useMemo(() => licenses.find(el => el.config.tag), [licenses]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (trial && currentStep === 'buy') {
      setStep(currentStep, nextStep);
    }

    if (trial && trial.config.upgraded) {
      setStep(currentStep, nextStep);
    }

    if (price && balance && trial && balance >= +price) {
      setStep('fill', 'success');
    }

    if ((last && status === 'completed') || wasLicenseBought) {
      setShow(false);
      setTimeout(() => {
        setShowStep(platform, false);
        setStorage(false);
      }, 350);
    }

    return () => {
      dispatch(clearLicense());
    };
  }, [trial, currentStep, nextStep, balance, status, last, wasLicenseBought]);

  const handlerBuyTrial = async () => {
    const buyTrial = await dispatch(
      createLicense({
        config: { product: 'fmc_subscribe', tag: 'FMC Trial' },
        count: 1,
      })
    );

    if (buyTrial) dispatch(fetchLicensesAction());
  };

  const validateBalance = () => {
    if (!trial) {
      return tr('start_no_trial_yet');
    }

    const defaultDays = 30;
    let days = trial ? trial.dates.days : defaultDays;

    if (balance > 0 && balance < +price)
      return tr('start_fm_add_funds_not_enough_balance', { price: (+price - balance).toFixed(2) });

    return days < 0
      ? tr('start_refill_fm_suspended', { days: -days, price: +price })
      : days === 0
      ? tr('start_refill_fm_today', { price: +price })
      : tr('start_refill_fm', { days: days, price: +price });
  };

  const getTrialButton = () => {
    return {
      type: 'ButtonFaceMachineServiceRedirect',
      props: {
        disabled: currentStep !== 'success' || (trial && trial.config.paused),
        license: trial,
      },
    };
  };

  return (
    <StartBlocks
      stepsStore={stepsStore}
      inTransition={show}
      steps={[
        {
          title: 'Face Machine',
          icon: 'next',
          reverse: true,
          text: tr('face_machine_step_desc'),
        },
        {
          title: tr('start_trial'),
          stepType: 'buy',
          text: tr('start_trial_block_fm', { price: +price }),
          button: {
            type: 'BtnAdditionalText',
            props: {
              text: tr('try_for_free'),
              onClick: handlerBuyTrial,
              loading: loading,
            },
          },
        },
        {
          title: tr('check_balance'),
          stepType: 'fill',
          text: validateBalance(),
          button: {
            type: 'ButtonFillBalance',
            props: {
              platform: platform,
              price: +price,
              button: {
                text: tr('balance_refill'),
                additionalText: '',
              },
            },
          },
        },
        {
          title: tr('start_almost_there'),
          stepType: 'success',
          text:
            trial && trial.config.paused ? tr('start_trial_need_upgrade') : tr('start_refilled_fm'),
          button: getTrialButton(),
        },
      ]}
    />
  );
}

export default StartBlocksFaceMachine;
