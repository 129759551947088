import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import PullOutPage from '../../../../blocks/PullOutPage';
import { Row, Col, SectionHeader, H, Box } from '@3divi/baseui';
import LoaderComponent from '../../../../LoaderComponent';
import LicenseInfoList from '../../../../elements/LicenseInfoList';
import LicenseInfoEditable from '../../../../elements/LicenseInfoEditable';
import Link from '../../../../elements/Link';
import NuitrackAnalyticsSubscriptionCard from './NuitrackAnalyticsSubscriptionCard';
import SubscriptionManageButton from '../../../../../modules/SubscriptionManageButton';
import NuitrackAnalyticsUpgradeBlock from './NuitackAnalyticsUpgradeBlock';
import ButtonNuitrackAnalyticsServiceRedirect from '../../../../elements/ButtonNuitrackAnalyticsServiceRedirect';

const Content = styled.div`
  padding: 16px 24px 32px;

  @media (max-width: 720px) {
    padding: 16px 16px 32px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const Section = styled.div`
  padding: 0 0 16px;
  margin: 0 0 0 -16px;
  box-shadow: none;
`;

const ListWrap = styled.div`
  margin-left: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const Message = styled.div`
  background: ${({ theme }) => theme.background.secondary};
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  text-align: center;
  padding: 6px 12px;
`;

const SubLink = styled.div`
  padding-top: 4px;
  color: ${({ theme }) => theme.text.secondary};
  font: ${({ theme }) => theme.font.xs};
`;

function NuitrackAnalyticsInfoView({
  header,
  isLoaded,
  licenseConfiguration,
  metadata,
  subscriptionManagement,
  info,
  licenseManage,
  message,
}) {
  return (
    <PullOutPage
      backUrl={header.backUrl}
      title={header.title}
      subtitle={header.subtitle}
      buttons={
        header.buttons &&
        header.buttons.serviceRedirect && (
          <ButtonNuitrackAnalyticsServiceRedirect {...header.buttons.serviceRedirect} />
        )
      }
    >
      {!isLoaded && <LoaderComponent />}
      {isLoaded && message && (
        <Message>
          {message.text}
          <Box mx={0.5} pass>
            <Link onClick={message.button.onClick}>{message.button.text}</Link>
          </Box>
        </Message>
      )}
      {isLoaded && (
        <Content>
          <H as="h3" padding="8px 0">
            {header.title}
          </H>
          <StyledRow>
            <Col
              xl={subscriptionManagement && 8}
              l={subscriptionManagement && 8}
              m={subscriptionManagement && 8}
              s={subscriptionManagement && 8}
              xs={12}
            >
              {info && (
                <Section>
                  <SectionHeader title={tr('lic_info')} icon="edit" />
                  <ListWrap>
                    {info.map(el =>
                      el.editKey ? (
                        <LicenseInfoEditable
                          key={el.editKey}
                          license={el.license}
                          editKey={el.editKey}
                        />
                      ) : (
                        <LicenseInfoList key={el.text} items={[el]} />
                      )
                    )}
                  </ListWrap>
                  {licenseManage && (
                    <Box pl={6} py={1.5}>
                      <Link onClick={licenseManage.button.onClick}>
                        {licenseManage.button.text}
                      </Link>
                      {licenseManage.text && <SubLink>{licenseManage.text}</SubLink>}
                    </Box>
                  )}
                </Section>
              )}
              {licenseConfiguration && (
                <Section>
                  <SectionHeader title={tr('license_configuration')} icon="settings" />
                  <ListWrap>
                    <LicenseInfoList items={licenseConfiguration.items} />
                  </ListWrap>
                </Section>
              )}
              {metadata.length > 0 && (
                <Section>
                  <SectionHeader title={tr('metadata')} icon="info" />
                  <ListWrap>
                    <LicenseInfoList items={metadata} />
                  </ListWrap>
                </Section>
              )}
            </Col>
            {subscriptionManagement && (
              <Col>
                {subscriptionManagement.license.isTrial ? (
                  <NuitrackAnalyticsUpgradeBlock license={subscriptionManagement.license} />
                ) : (
                  <>
                    <NuitrackAnalyticsSubscriptionCard license={subscriptionManagement.license} />
                    <SubscriptionManageButton
                      platform={subscriptionManagement.platform}
                      license={subscriptionManagement.license}
                    />
                  </>
                )}
              </Col>
            )}
          </StyledRow>
        </Content>
      )}
    </PullOutPage>
  );
}

export default NuitrackAnalyticsInfoView;
