export const secondsToDaysMonths = seconds => {
  // 1 month is 30 days for our backend. 30 days in seconds is 2592000.
  const month = 2592000;
  // 1 days in seconds is 86400.
  const day = 86400;

  /*
  x | 0 is bitwise OR,
  browser changed x to 32-bit signed int and after it make bitwise OR with 0, which do nothing,
  so it just changed x to 32-bit signed int  */
  const months = (seconds / month) | 0;
  const days = ((seconds / day) | 0) % 30;

  return {
    days,
    months,
  };
};
