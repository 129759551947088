import tr from 'locale';

export const getFaceLicenseConfiguration = license => {
  if (license && license.config.license_content) {
    let licenseContent = JSON.parse(license.config.license_content);
    let adaptedList = Object.entries(licenseContent)
      .filter(([key]) => key !== 'desktop_gpu_support')
      .map(([key, value]) => ({
        text: tr(key),
        value: value,
      }));
    adaptedList.sort((a, b) => (a.text > b.text ? 1 : -1));
    return adaptedList;
  }
  return [];
};
