import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { ButtonFillBalance } from '../modals/buttons';

const StyledBalance = styled.div`
  padding: 0 8px;
`;

const Current = styled.div`
  display: felx;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid ${props => props.theme.border.default};
  border-radius: 4px 4px 0 0;
  margin-bottom: -1px;
`;

const Num = styled.div`
  font: ${props => props.theme.font.l};
  color: ${props => props.theme.text.default};
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Currency = styled.div`
  font: ${props => props.theme.font.xxs};
  color: ${props => props.theme.text.secondary};
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.border.default};
  border-radius: 4px;
`;

class Balance extends React.Component {
  render() {
    return (
      <StyledBalance>
        <Current>
          <Num>{this.props.balance}</Num>
          <Currency>{tr('usd')}</Currency>
        </Current>
        <ButtonFillBalance
          button={{
            additionalText: null,
            variant: 'contained',
            elastic: true,
            size: 's',
            borderRadius: '0 0 4px 4px',
          }}
        />
      </StyledBalance>
    );
  }
}

export default Balance;
