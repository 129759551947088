import React from 'react';
import PromocodeView from './PromocodeView';
import { usePromocode } from './usePromocode';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearPromocodeAction,
  validatePromocodeAction,
} from '../../../../../../store/basket/actions';
import { useParams } from 'react-router';

function Promocode(props = {}) {
  const { platform } = useParams();
  const dispatch = useDispatch();
  const validatePromocode = useSelector(state => state.basket.validatePromocode);

  const promocodeViewProps = usePromocode({
    promocode: validatePromocode.promocode,
    actions: {
      clearPromocode: clearPromocodeAction,
      validatePromocode: validatePromocodeAction,
    },
    dispatch: dispatch,
    platform: platform,
  });

  return <PromocodeView {...promocodeViewProps} />;
}

export default Promocode;
