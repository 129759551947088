// Hooks
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { revokeLicenseAction } from 'store/licenses/actions';
// Other
import tr from 'locale';
import { useHistory } from 'react-router-dom';
// Components
import { Button, Col, Row } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';

function RevokeLicenseModal(props) {
  const { license, platform, hideAction } = props;
  const [storeKey, setStoreKey] = useState(Date.now());
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, success } = useSelector(
    state => state.licenses.revokeLicense[storeKey] || state.licenses.default
  );

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  useEffect(() => {
    if (success) {
      history.push(`/app/${platform}/dashboard/`);
      hideAction();
    }
  }, [success]);

  return (
    <ModalComponent
      {...props}
      icon="warning"
      iconColor="warning"
      title={tr('warning')}
      subtitle={tr('revoke_warning_message')}
      buttons={
        <Row>
          <Col xl={6}>
            <Button
              innerRef={ref}
              autoFocus={true}
              size="m"
              text={tr('button_continue')}
              variant="contained"
              elastic
              loading={loading}
              color="primary"
              onClick={() => dispatch(revokeLicenseAction({ license }, { storeKey }))}
            />
          </Col>
          <Col xl={6}>
            <Button size="m" text={tr('button_cancel')} elastic onClick={hideAction} />
          </Col>
        </Row>
      }
    />
  );
}

export default RevokeLicenseModal;
