import tr from 'locale';

export const getGroupInfoList = (group, productName, licenseType) => {
  if (!group) return [];

  // define editable (or not) title field
  const title = group.is_fake
    ? { text: tr('title'), value: group.title }
    : { group, editKey: 'title', editTextKey: 'group_name' };

  let config = [
    { text: tr('id'), value: group.id },
    productName,
    licenseType,
    title,
    { text: tr('licenses_total'), value: group.used_licenses + group.limit },
    { group, editKey: 'licenses_available' },
    { text: tr('licenses_activated'), value: group.used_licenses },
  ];

  return config;
};
