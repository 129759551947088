import { useDispatch, useSelector } from 'react-redux';
import { setStepAction } from '../../../../../../store/basket/actions';
import { useParams } from 'react-router';

const getPrice = (price, count) => {
  return (price * count).toFixed(2);
};

export const useCart = () => {
  const { checkoutType } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.basket.currentStep);
  const maxBasketSteps = useSelector(state => state.basket.maxBasketSteps);
  const products = useSelector(state => state.basket.basket.products);
  const fullSum = +useSelector(state => state.basket.basket.totalSum);
  const validatePromocode = useSelector(state => state.basket.validatePromocode);

  const promocode = {
    discount: {
      value: validatePromocode.discount,
      type:
        validatePromocode.discount && validatePromocode.discount.indexOf('%') >= 0
          ? 'percent'
          : 'amount',
    },
  };

  promocode.type =
    currentStep !== 1 || checkoutType === 'upgrade' || checkoutType === 'upgrade-y'
      ? 'hidden'
      : 'visible';

  let totalSum =
    promocode.discount.type === 'percent'
      ? fullSum * (1 - parseFloat(promocode.discount.value) / 100)
      : fullSum - +promocode.discount.value;
  totalSum = totalSum < 0.0001 ? (fullSum < 0.0001 ? 0 : 0.5) : totalSum;
  totalSum = validatePromocode.success ? totalSum : fullSum;

  promocode.discount.amount =
    promocode.discount.type === 'percent'
      ? (fullSum - totalSum).toFixed(2)
      : parseFloat(promocode.discount.value).toFixed(2);

  return {
    getPrice,
    products,
    fullSum: fullSum.toFixed(2),
    totalSum: totalSum.toFixed(2),
    promocode: promocode,
    buttons: {
      continue:
        fullSum < 0.0001
          ? { disabled: true }
          : currentStep !== maxBasketSteps
          ? { onClick: () => dispatch(setStepAction(currentStep + 1)) }
          : undefined,
      previous:
        currentStep !== 1 ? { onClick: () => dispatch(setStepAction(currentStep - 1)) } : undefined,
    },
  };
};
