import React from 'react';
import { Link } from 'react-router-dom';
import { useGroups } from '../hooks/';

import Groups from './Groups';

const groupsBuyLink = {
  face_online_all_in_one: {
    link: {
      as: Link,
      to: '/basket/checkout/',
      autoAddLicense: true,
    },
  },
  face_offline_perpetual: {
    link: {
      as: 'a',
      href: 'https://face.3divi.com/pricing#!/tab/131593098-2',
    },
  },
};

function GroupsFace({ groupsStore, platform, ...others }) {
  const groups = useGroups(groupsBuyLink, groupsStore, platform);

  return <Groups groups={groups} platform={platform} {...others} />;
}

export default GroupsFace;
