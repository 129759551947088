import React from 'react';
import { useDispatch } from 'react-redux';
import tr from 'locale';
import { Button } from '@3divi/baseui';
import { handleOpenModal } from '../../store/modals/actions';
import SubscriptionActivateModal from '../../components/modals/SubscriptionActivateModal';
import SubscriptionDeactivateModal from '../../components/modals/SubscriptionDeactivateModal';

function SubscriptionManageButton({ license, platform }) {
  const dispatch = useDispatch();

  const openActivateSubscription = () =>
    dispatch(
      handleOpenModal({
        component: SubscriptionActivateModal,
        name: 'activate',
        license,
        platform,
      })
    );
  const openDeactivateSubscription = () =>
    dispatch(
      handleOpenModal({
        component: SubscriptionDeactivateModal,
        name: 'deactivate',
        license,
      })
    );

  return (
    <Button
      text={license.config.paused ? tr('renew_subscription') : tr('manage_subscription')}
      elastic
      size="m"
      theme={license.config.paused ? 'contained' : 'outline'}
      color="primary"
      disabled={license.license_status === 'holded'}
      onClick={license.config.paused ? openActivateSubscription : openDeactivateSubscription}
    />
  );
}

export default SubscriptionManageButton;
