import ModalComponent from '../../ModalComponent';
import tr from 'locale';
import { updateClientData } from 'api';
import React from 'react';
import { Modal, Button, RadioOrCheckbox } from '@3divi/baseui';
import styled from 'styled-components';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';
import { connect } from 'react-redux';

const Buttons = styled.div`
  padding: 16px 0 8px;
  margin: 0 -8px;

  & > button {
    margin: 0 8px;
  }
`;

class OptionsEdit extends ModalComponent {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.state = {
      news: this.props.accountInfo.options.news_notification,
      bills: this.props.accountInfo.options.bills_mail,
    };
  }

  saveChanges(event) {
    event.preventDefault();
    let formElements = event.target.elements;
    let newInfo = Object.assign({}, this.props.accountInfo);
    newInfo.options.news_notification = formElements.news.checked;
    newInfo.options.bills_mail = formElements.bills.checked;
    // newInfo.options.balance_autofill = formElements.autofill.checked;
    // newInfo.options.updates_notification = formElements.updates.checked;
    updateClientData(newInfo).then(
      success => {
        this.props.hide();
      },
      async failure => {
        const { dispatch } = this.props;
        const { error_code: errorCode } = failure;
        const errorMsg = tr(errorCode || 'internal_server_error');
        if (errorMsg !== errorCode) {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: errorMsg,
            })
          );
        } else {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: tr('network_error'),
            })
          );
        }
      }
    );
  }

  render() {
    return (
      <Modal isOpen close={this.props.hide} title={tr('notifications_header')} fixed={400}>
        <form onSubmit={this.saveChanges}>
          <RadioOrCheckbox
            type="checkbox"
            title={tr('notification_news')}
            name="news"
            onClick={() => this.setState({ news: !this.state.news })}
            checked={this.state.news}
          />
          <RadioOrCheckbox
            type="checkbox"
            title={tr('notification_billing')}
            name="bills"
            onClick={() => this.setState({ bills: !this.state.bills })}
            checked={this.state.bills}
          />
          {/* <AccountCheckbox text={tr("notification_autofill")} name="autofill" checked={prevInfo.balance_autofill}/> */}
          {/* <AccountCheckbox text={tr("notification_updates")} name="updates" checked={prevInfo.updates_notification}/> */}
          <Buttons>
            <Button
              size="m"
              variant="contained"
              text={tr('account_change_save_button')}
              color="primary"
            />
            <Button size="m" text={tr('cancel_button')} onClick={this.props.hide} />
          </Buttons>
        </form>
      </Modal>
    );
  }
}

export default connect()(OptionsEdit);
