import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Input } from '@3divi/baseui';
import fill from 'img/fill.svg';
import styled from 'styled-components';
import tr from 'locale';
import { balanceFillAction, balanceFillClearAction } from 'store/clientdata/actions';
import { useStripe } from '@stripe/react-stripe-js';

import ModalComponent from '../ModalComponent';
import PayStripeButton from '../../elements/PayStripeButton';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MinAmount = styled.span`
  color: ${props => props.less && 'red'};
  transition: color 0.2s;
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

const MIN_AMOUNT = 20;
const DEFAULT_AMOUNT = 100;

function FillBalanceBuy(props) {
  const { subtitle, platform, price = 0, hideAction } = props;
  const stripe = useStripe();
  const dispatch = useDispatch();
  const balanceFillClear = useCallback(() => dispatch(balanceFillClearAction()), []);
  const balance = +useSelector(state => state.clientdata.balance);
  const balanceFill = useSelector(state => state.clientdata.balanceFill);

  let newFill = price > balance && Math.ceil(price - balance);
  const [value, setValue] = useState(Math.max(MIN_AMOUNT, newFill ? newFill : DEFAULT_AMOUNT));
  const [minAmount] = useState(Math.max(newFill, MIN_AMOUNT));
  const location = useLocation();

  // need to certanly get 'platform' value, not undefined
  const platformMemo = useMemo(() => {
    if (platform) return platform;
    else {
      if (location.pathname.includes('/nuitrack/')) return 'nuitrack';
      if (location.pathname.includes('/face/')) return 'face';
      if (location.pathname.includes('/face-machine/')) return 'face-machine';
      if (location.pathname.includes('/nuitrack-analytics/')) return 'nuitrack-analytics';
      return 'nuitrack';
    }
  }, [platform, location.pathname]);

  const setAmount = targetValue => {
    setValue(parseInt(targetValue) || 0);
  };

  const handlerStripeSession = () => {
    return dispatch(
      balanceFillAction(
        {
          amount: +value,
        },
        { platform: platformMemo, stripe, paymentMethod: 'stripe' }
      )
    );
  };

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  return (
    <ModalComponent
      image={fill}
      title={tr('fill_balance')}
      subtitle={subtitle}
      clear={balanceFillClear}
      error={balanceFill.error}
      {...props}
    >
      <StyledContent name="fill_balance">
        <Input
          name="amount"
          label={
            <p>
              {`${tr('amount')}, `}
              <MinAmount less={value < minAmount}>{`${tr('min')} $${minAmount}`}</MinAmount>
            </p>
          }
          placeholder="Amount"
          value={value}
          onChange={setAmount}
          errorText=""
          maxlength={6}
          variant="column"
          size="m"
        />
        <ButtonWrapper>
          <PayStripeButton
            onClick={handlerStripeSession}
            disabled={balanceFill.loading || value < minAmount || !stripe}
            autoFocus={true}
            innerRef={ref}
          />
        </ButtonWrapper>
      </StyledContent>
    </ModalComponent>
  );
}

export default FillBalanceBuy;
