import React from 'react';
import checked from 'img/check.svg';
import styled from 'styled-components';

import Typography from 'components/elements/Typography/';

const StyledIncludeItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  border-top: 1px solid ${({ theme }) => theme.border.default};

  &:first-child {
    border-top: none;
  }

  ${props =>
    props.theme === 'check' &&
    `
    padding-left: 32px;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 14px;
      height: 14px;
    }
  `}
`;

const Title = styled.div`
  padding: 12px 0;
  padding-right: 16px;
  width: 100%;

  ${props =>
    props.variant === 'clear' &&
    `
    width: 172px;
  `}

  ${props =>
    props.variant === 'default' &&
    `
    width: 172px;
    background-image: linear-gradient(to right, hsla(0,0%,100%,1) 0%, hsla(0,0%,98%,1) 100%);
  `}
`;

const Value = styled.div`
  padding: 8px 0 8px 16px;
`;

const StyledTypography = styled(Typography)`
  flex-grow: 1;
`;

function ListItem(props) {
  const { text, value, theme, isHeader, ...other } = props;
  return (
    <StyledIncludeItem {...other} variant={theme}>
      {isHeader ? (
        <Title variant={theme}>
          <Typography weight="800">{text}</Typography>
        </Title>
      ) : (
        <>
          {theme !== 'default' && theme !== 'clear' && (
            <Title variant={theme}>
              <Typography>
                {value && `${value} ×`} {text}
              </Typography>
            </Title>
          )}
          {theme === 'default' && (
            <>
              {text && (
                <Title className="list_items_left" variant={theme}>
                  <StyledTypography>{text}</StyledTypography>
                </Title>
              )}
              {value !== undefined && value !== null && (
                <Value className="list_items_right">
                  <Typography>{value}</Typography>
                </Value>
              )}
            </>
          )}
          {theme === 'clear' && (
            <>
              {text && (
                <Title className="list_items_left" variant={theme}>
                  <StyledTypography>{text}</StyledTypography>
                </Title>
              )}
              {value !== undefined && value !== null && (
                <Value className="list_items_right">
                  <Typography>{value}</Typography>
                </Value>
              )}
            </>
          )}
        </>
      )}
    </StyledIncludeItem>
  );
}

ListItem.defaultProps = {
  theme: 'default',
};

export default ListItem;
