import React, { useCallback, useEffect } from 'react';
import tr from 'locale';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { handlerReactivateLicense, handleResume } from '../../../store/licenseInfo/actions';
import { handleOpenModal, handlerChangeModalData } from '../../../store/modals/actions';
import ModalSubscriptionManagement from '../ModalSubscriptionManagement/ModalSubscriptionManagement';
import ErrorModal from '../ErrorModal';

// same code error when try to create 11th nuitrack trial
// if need to change it, made issue to backend
const codeReactivate = '23504d45';
const codeInsufficientFunds = '9febaea3';

function SubscriptionActivateModal({ license, platform, ...other }) {
  const stripe = useStripe();
  // Actions
  const dispatch = useDispatch();
  const handleResumeAction = useCallback(
    () =>
      dispatch(
        handleResume(license.id, { handledErrors: [codeReactivate, codeInsufficientFunds] })
      ),
    [license.id]
  );
  // Selectors
  const errorCodeResume = useSelector(state => state.licenseInfo.licenseStatusErrorCode);

  const handleReactivate = () => {
    let redirectUrl = `?redirect=dashboard/${license.id}/info`;
    dispatch(
      handlerReactivateLicense(
        {
          licenseId: license.id,
        },
        {
          redirectParams: redirectUrl,
          platform,
          stripe,
        }
      )
    );
  };

  useEffect(() => {
    if (errorCodeResume === codeReactivate) {
      if (license.config.paypal_subscription_id) {
        dispatch(
          handlerChangeModalData('activate', {
            error: tr('renew_subscription_not_implemented_error'),
            button: { disabled: true },
          })
        );
      } else {
        dispatch(
          handlerChangeModalData('activate', {
            error: null,
            text: tr('reactivate_license'),
            action: handleReactivate,
          })
        );
      }
    } else if (errorCodeResume === codeInsufficientFunds) {
      dispatch(
        handleOpenModal({
          component: ErrorModal,
          name: `error-${codeInsufficientFunds}`,
          text: tr(codeInsufficientFunds),
        })
      );
      other.hideAction();
    }
  }, [errorCodeResume]);

  return (
    <ModalSubscriptionManagement
      name="activate"
      license={license}
      action={handleResumeAction}
      {...other}
    />
  );
}

export default SubscriptionActivateModal;
