import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import tr from 'locale';
import {
  LICENSESERVER_DARK,
  SectionBox,
  Typography,
  Row,
  Icon,
  Col,
  SectionHeader,
  Button,
} from '@3divi/baseui';
import PromoBg from 'img/promo_bg.jpg';
import { Link } from 'react-router-dom';

const SectionDark = styled(SectionBox)`
  background-color: hsla(210, 64%, 5%, 1);
  background-image: url('${PromoBg}');
  background-size: cover;
  background-position-y: bottom;
`;

const SectionBody = styled.div`
  padding: 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.border.default};
`;

const ListItem = styled(Typography)`
  position: relative;
  padding: 4px 8px 4px 36px;
  flex: 0 0 50%;

  & > svg {
    position: absolute;
    left: 8px;
  }

  @media (max-width: 720px) {
    flex: 0 0 100%;
  }
`;

const StyledTypography = styled(Typography)`
  position: relative;
  padding: 0 8px;

  &.new:after {
    position: absolute;
    content: 'NEW';
    border-radius: 4px;
    color: ${({ theme }) => theme.text.default};
    padding: 2px 4px;
    border: 1px solid hsla(0, 0%, 100%, 0.4);
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1px;
    top: 2px;
    margin: 0 8px;
  }
`;

function NuitrackPromo({ showAI = true, showPro = true }) {
  return (
    <ThemeProvider theme={LICENSESERVER_DARK}>
      <Col xl={6} l={6} m={8} s={12} xs={12} xxs={12}>
        {showPro && (
          <SectionBox>
            <SectionHeader
              title={
                <StyledTypography weight="600" size="17" height="24" color="default">
                  {tr('table_name_nuitrack')}
                </StyledTypography>
              }
              subtitle={
                <StyledTypography size="15" height="20" color="secondary">
                  {tr('nuitrack_step_desc')}
                </StyledTypography>
              }
            />
          </SectionBox>
        )}
        {showAI && (
          <SectionDark>
            <SectionHeader
              title={
                <StyledTypography
                  className="new"
                  weight="600"
                  size="17"
                  height="24"
                  color="default"
                >
                  {tr('table_name_nuitrack_ai')}
                </StyledTypography>
              }
              subtitle={
                <StyledTypography size="15" height="20" color="secondary">
                  {tr('nuitrack_ai_desc')}
                </StyledTypography>
              }
              buttons={
                <Button
                  text={tr('buy_now')}
                  variant="contained"
                  color="primary"
                  as={Link}
                  to={{
                    pathname: `/app/nuitrack/basket/checkout/`,
                    state: { addLicenseType: 'nuitrack_ai_perpetual' },
                  }}
                />
              }
            />
            <SectionBody>
              <Row>
                <ListItem size="15" height="20">
                  <Icon icon="mark" color="primary" />
                  {tr('nuitrack_ai_promo_feature_1')}
                </ListItem>
                <ListItem size="15" height="20">
                  <Icon icon="mark" color="primary" />
                  {tr('nuitrack_ai_promo_feature_2')}
                </ListItem>
                <ListItem size="15" height="20">
                  <Icon icon="mark" color="primary" />
                  {tr('nuitrack_ai_promo_feature_3')}
                </ListItem>
                <ListItem size="15" height="20">
                  <Icon icon="mark" color="primary" />
                  {tr('nuitrack_ai_promo_feature_4')}
                </ListItem>
              </Row>
            </SectionBody>
          </SectionDark>
        )}
      </Col>
    </ThemeProvider>
  );
}

export default NuitrackPromo;
