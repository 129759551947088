import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import tr from 'locale';
import { Section, Button } from '@3divi/baseui';
import Table from 'components/elements/Table/';
import { H3 } from 'components/elements/Typography/';
import StatusToggleBtn from './components/StatusToggleBtn';
import FilterToggleBtn from './components/FilterToggleBtn';
import DayPicker from '../../../elements/DayPicker';

const Main = styled.main`
  width: 100%;
`;

const StyledSection = styled(Section)`
  position: relative;
`;

const Content = styled.div`
  margin: 0 -16px;
  overflow-x: auto;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 71px;
    top: 0;
    background: ${({ theme }) => theme.background.primary};
    border-bottom: 1px solid ${({ theme }) => theme.border.default};
    z-index: 1;
  }
`;

const FilterWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
`;

const FilterItem = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  padding: 0 16px 8px 1px;
`;

const StyledDayPicker = styled(DayPicker)`
  margin-right: 8px;
  margin-bottom: 8px;

  & > button {
    ${({ theme, to, from }) => !(to || from) && `background: ${theme.background.default}`};
  }

  &:last-child {
    margin: 0;
  }
`;

const Position = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row-reverse;
  padding: 0 16px;
`;

function TableLicenses({
  productFilter,
  statusFilter,
  buttonHeader,
  tableData,
  createdDayPicker,
  expireDayPicker,
  activationDayPicker,
  clearAllFilters,
  isDataFiltered,
}) {
  const history = useHistory();

  const handlerRowClick = (e, data) => {
    let type = e.currentTarget.getAttribute('data-type');
    if (e.currentTarget.id.length > 0)
      history.push(`${history.location.pathname}${e.currentTarget.id}/info/`);
    else
      history.push(
        `${history.location.pathname}${data.group_id}/pre-info/${
          data.license_status === 'holded' ? '?holded' : ''
        }`
      );
  };

  return (
    <Main>
      <H3 title={tr('my_licenses')} />
      <FilterWrap>
        {productFilter && (
          <FilterItem>
            <FilterToggleBtn {...productFilter} />
          </FilterItem>
        )}
        {(createdDayPicker || expireDayPicker || activationDayPicker) && (
          <FilterItem>
            {createdDayPicker && (
              <StyledDayPicker
                text={tr('created_date_filter')}
                spacesTop={4}
                {...createdDayPicker}
              />
            )}
            {expireDayPicker && (
              <StyledDayPicker
                text={tr('expires_date_filter')}
                spacesTop={4}
                {...expireDayPicker}
              />
            )}
            {activationDayPicker && (
              <StyledDayPicker
                text={tr('activated_date_filter')}
                spacesTop={4}
                {...activationDayPicker}
              />
            )}
          </FilterItem>
        )}
        {clearAllFilters && isDataFiltered && (
          <FilterItem>
            <Button iconLeft="close" onClick={clearAllFilters} text={tr('reset_filters')} />
          </FilterItem>
        )}
      </FilterWrap>
      <StyledSection>
        <Content>
          <Header>
            {statusFilter && <StatusToggleBtn {...statusFilter} />}
            {buttonHeader && <Position>{buttonHeader}</Position>}
          </Header>
          <Table
            entitiesName="licenses"
            {...tableData}
            rowClick={useCallback(handlerRowClick, [])}
          />
        </Content>
      </StyledSection>
    </Main>
  );
}

export default TableLicenses;
