import React, { useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './anim.css';
import { Button, SectionHeader } from '@3divi/baseui';

const Page = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999999;
`;

const Background = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.72);
  z-index: 1;
`;

const Main = styled.div`
  display: block;
  height: 100vh;
  width: 100%;
  max-width: ${props => (props.width ? props.width : '800px')};
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.background.default};
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  align-items: center;
  background-color: ${({ theme }) => theme.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  padding: 0 0 0 8px;
  z-index: 100;

  @media (max-width: 720px) {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 8px;
  border-right: 1px solid ${({ theme }) => theme.border.default};
`;

function PullOutPage(props) {
  const { children, backUrl, title, subtitle, buttons, error, width } = props;
  const [show, setShow] = useState(true);
  const history = useHistory();
  let timeout = null;

  useEffect(() => {
    return () => (timeout = null);
  }, []);

  const onKeyDown = e => {
    e.keyCode === 27 && handleBack();
  };

  useLayoutEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  const handleBack = () => {
    setShow(false);
    timeout = setTimeout(() => {
      history.push(backUrl);
    }, 250);
  };

  return (
    <Page>
      <CSSTransition in={show} appear timeout={250} classNames="bg">
        <Background className="bg" onClick={handleBack} />
      </CSSTransition>
      <CSSTransition in={show} appear timeout={250} classNames="content">
        <Main className="content" width={width}>
          <Header>
            <SectionHeader
              subtitle={subtitle && `#${subtitle}`}
              buttons={
                <>
                  <ButtonWrapper>{buttons}</ButtonWrapper>
                  <Button onClick={handleBack} iconLeft="close" round variant="clear" />
                </>
              }
            />
          </Header>
          {children}
        </Main>
      </CSSTransition>
    </Page>
  );
}

export default PullOutPage;
