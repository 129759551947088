import React from 'react';
import tr from 'locale';
import { CopyButton } from '@3divi/baseui';
import './CopyBtn.css';

class CopyBtn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };
  }

  handlerCopied = e => {
    this.setState({
      isCopied: true,
    });
    this.props.onClick(e);
    e.target.addEventListener('mouseleave', this.cleareCopy);
  };

  cleareCopy = e => {
    this.setState({
      isCopied: false,
    });
    e.target.removeEventListener('mouseleave', this.cleareCopy);
  };

  render() {
    const { className, disabled } = this.props;
    return (
      <CopyButton
        size="s"
        theme="contained"
        color="hsla(0, 0%, 100%, 1)"
        disabled={disabled}
        text={{ copy: tr('copy'), copied: tr('copied') }}
        className={className}
        onClick={this.handlerCopied}
      />
    );
  }
}

export default CopyBtn;
