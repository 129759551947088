import americanexpress from './AmericanExpress.svg';
import unknown from './Card.svg';
import dinersclub from './DinersClub.svg';
import discover from './Discover.svg';
import jcb from './JCB.svg';
import mastercard from './MasterCard.svg';
import paypal from './PayPal.svg';
import unionpay from './UnionPay.svg';
import visa from './Visa.svg';

export default {
  americanexpress,
  unknown,
  dinersclub,
  discover,
  jcb,
  mastercard,
  paypal,
  unionpay,
  visa,
};
