import React from 'react';
import ReactDom from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as reducers from './store/reducers';

import AppManager from './AppManager';

import shimMatchAll from 'string.prototype.matchall/shim';
if (!String.prototype.matchAll) shimMatchAll();

export const store = createStore(combineReducers(reducers), applyMiddleware(thunk));

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

ReactDom.render(
  <Provider store={store}>
    <AppManager />
  </Provider>,
  document.getElementById('root')
);
