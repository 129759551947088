// Hooks
import React from 'react';
import { useSelector } from 'react-redux';
// Components
import { GroupsFace, GroupsNuitrack } from './GroupsContainers/';

const groupConfig = {
  nuitrack: GroupsNuitrack,
  face: GroupsFace,
};

function Groups(props) {
  const { platform } = props;
  const GroupsComponent = groupConfig[platform];
  // Stores
  const groupsStore = useSelector(state => state.licenses.sortedDefaultGroups).filter(
    group => group.type === platform
  );
  const groupsLoadComplete = useSelector(state => state.licenses.fetchGroups.success);

  return GroupsComponent ? (
    <GroupsComponent
      platform={platform}
      groupsLoaded={groupsLoadComplete}
      groupsStore={groupsStore}
    />
  ) : null;
}

export default Groups;
