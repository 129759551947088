import React, { useState } from 'react';
import tr from 'locale';
import { Button } from '@3divi/baseui';
import { blobToFile } from '../../../helpers/base64ToFile';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useDispatch } from 'react-redux';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';

function BtnInvoice(props) {
  const { invoiceUrl, invoiceId, ...other } = props;
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [invoiceBlob, setInvoiceBlob] = useState('');

  let tryGetInvoice = async () => {
    try {
      setLoading(true);
      const response = await fetch(invoiceUrl);
      if (isMounted.current) {
        if (response.ok) {
          const responseBlob = await response.blob();
          setInvoiceBlob(responseBlob);
          blobToFile({ blob: responseBlob, filename: `invoice-${invoiceId}.pdf` });
          setLoading(false);
        } else {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error-invoice',
              text: tr('internal_server_error'),
            })
          );
          setLoading(false);
        }
      }
    } catch {
      if (isMounted.current) {
        dispatch(
          handleOpenModal({
            component: ErrorModal,
            name: 'error-invoice',
            text: tr('network_error'),
          })
        );
        setLoading(false);
      }
    }
  };

  return (
    <Button
      size="xs"
      iconLeft="download"
      loading={loading}
      onClick={
        invoiceBlob
          ? () => blobToFile({ blob: invoiceBlob, filename: `invoice-${invoiceId}.pdf` })
          : tryGetInvoice
      }
      {...other}
    />
  );
}

export default BtnInvoice;
