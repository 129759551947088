import { handleActions } from 'redux-actions';

const initialState = () => ({
  plans: {},
  types: {},
  upgradeCostMap: {},
});

const reducer = handleActions(
  {
    FETCH_FACE_MACHINE_PLANS: (state, action) => {
      const { plans } = action.payload;
      const plansMap = {};
      plans.forEach(plan => {
        plansMap[plan.plan_id] = {
          transactions_per_second:
            plan.limits.transactions.per_second === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.transactions.per_second,
          samples_per_workspace:
            plan.limits.samples.per_workspace === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.samples.per_workspace,
          transactions_per_month:
            plan.limits.transactions.per_month === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.transactions.per_month,
          streaming_video_processing_minutes:
            plan.limits.streaming_video_processing_minutes === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.streaming_video_processing_minutes,
          archived_video_processing_minutes:
            plan.limits.archived_video_processing_minutes === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.archived_video_processing_minutes,
          events_retention_period_seconds:
            plan.limits.events_retention_period_seconds === null
              ? Number.POSITIVE_INFINITY
              : plan.limits.events_retention_period_seconds,
        };
      });
      return {
        ...state,
        plans: plansMap,
        filedsTypes: {
          seconds: ['events_retention_period_seconds'],
        },
      };
    },
    GET_UPGRADE_COST_SUCCESS: (state, action) => {
      const { response, id } = action.payload;
      state.upgradeCostMap[id] = {
        ...response,
        amount: +response.amount < 0.001 ? '20.00' : response.amount,
      };
      return state;
    },
  },
  initialState()
);

export default reducer;
