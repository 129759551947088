import React from 'react';
import ReactDOM from 'react-dom';

export default class ModalComponent extends React.Component {
  popup: null;

  constructor(props) {
    super(props);
    this.renderPopup = this.renderPopup.bind(this);
  }
  componentDidMount() {
    if (!this.props.hide) {
      console.error('ModalComponent must contain hide property');
      return;
    }
    this.popup = document.createElement('div');
    document.getElementById('root').appendChild(this.popup);
    ReactDOM.render(this.renderPopup(), this.popup);
  }
  componentDidUpdate() {
    ReactDOM.render(this.renderPopup(), this.popup);
  }
  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.popup);
    document.getElementById('root').removeChild(this.popup);
  }

  renderPopup() {
    return null;
  }

  render() {
    return <noscript></noscript>;
  }
}
