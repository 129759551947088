import React, { useState, useCallback } from 'react';
import tr from 'locale';
import styled from 'styled-components';
import RegPromo from './RegPromo';
import RegStepTwo from './RegStepTwo';
import RegStepOne from './RegStepOne';
import AccountExists from './AccountExists';
import Header from '../../../Header';

const Content = styled.div`
  padding: 36px 48px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @media (max-width: 720px) {
    padding: 32px 24px;
  }
`;

function NuitrackAnalyticsRegistration() {
  const [step, setStep] = useState('registration');
  const stepSetCallback = useCallback(setStep, []);
  return (
    <>
      <Header title={tr('tabtitle_nuitrack_analytics_cloud')} />
      <RegPromo />
      <Content>
        {step === 'exist' && <AccountExists setStep={stepSetCallback} />}
        {step === 'registration' && <RegStepOne setStep={stepSetCallback} />}
        {step === 'fill_info' && <RegStepTwo setStep={stepSetCallback} />}
      </Content>
    </>
  );
}

export default NuitrackAnalyticsRegistration;
