import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button, Portal } from '@3divi/baseui';

let bodyDiv = document.querySelectorAll('body > div');
const portalRoot = bodyDiv[bodyDiv.length - 1];

const DropdownWrap = styled.div``;

const styleConfig = {
  center: `
    left: 50%;
  `,
  right: `
    left: 100%;
    margin-left: 0px;
  `,
};

const ContentWrap = styled.div`
  position: absolute;
  min-width: 264px;
  padding: 0 16px;
  margin-top: ${props => (props.spacesTop ? props.spacesTop : 0)}px;
  width: auto;
  background: #fff;
  border-radius: 4px;
  z-index: 100;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.24);

  ${props =>
    props.arrow &&
    `&::before {
      content: " ";
      position: absolute;
      margin-left: 5px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      transform: translate(-100%, -100%);

      ${props => styleConfig[props.position]}
    }`}
`;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      statusBtn: '',
      style: {},
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.dropdownLeft = this.dropdownLeft.bind(this);
    this.dropdownCenter = this.dropdownCenter.bind(this);
    this.dropdownRight = this.dropdownRight.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.checkResizeElement = this.checkResizeElement.bind(this);
  }

  checkResizeElement() {
    this.setState({
      open: false,
    });
  }

  toggleDropdown() {
    this.el && this.setPosition();
    this.setState({
      open: !this.state.open,
    });
  }

  setPosition() {
    const style = {};
    const dimensions = this.el.getBoundingClientRect();
    style.top = dimensions.top + dimensions.height + this.props.spaces;
    switch (this.props.position) {
      case 'left':
        this.dropdownLeft(style, dimensions);
        break;
      case 'center':
        this.dropdownCenter(style, dimensions);
        break;
      case 'right':
        this.dropdownRight(style, dimensions);
        break;
      default:
        this.dropdownLeft(style, dimensions);
        break;
    }
    this.setState({
      style: style,
    });
  }

  dropdownLeft(style, dimensions) {
    style.left = dimensions.left;
  }

  dropdownCenter(style, dimensions) {
    style.left = dimensions.left + dimensions.width / 2;
    style.transform = `translateX(${-50}%)`;
  }

  dropdownRight(style, dimensions) {
    style.left = dimensions.left + dimensions.width;
    style.transform = `translateX(${-100}%)`;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
    window.removeEventListener('resize', this.checkResizeElement);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
    window.addEventListener('resize', this.checkResizeElement);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode.contains(event.target) && !portalRoot.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  }

  render() {
    const {
      iconLeft,
      iconRight,
      round,
      size,
      color,
      theme,
      text,
      className,
      wrapperClassName,
      portal,
      hoverColor,
      arrow,
      spacesTop,
    } = this.props;

    const DropdownBody = (
      <ContentWrap
        arrow={arrow}
        ref={el => (this.dropDownEl = el)}
        position={this.props.position}
        style={this.state.style}
        spacesTop={spacesTop}
      >
        {this.props.children}
      </ContentWrap>
    );

    return (
      <DropdownWrap className={wrapperClassName}>
        <Button
          addRef={el => (this.el = el)}
          onClick={this.toggleDropdown}
          size={size}
          className={className}
          text={text}
          color={color}
          variant={theme}
          round={round}
          iconLeft={iconLeft}
          iconRight={iconRight}
          hoverColor={hoverColor}
        />
        {portal ? (
          <Portal isOpen={this.state.open}>{DropdownBody}</Portal>
        ) : (
          this.state.open && DropdownBody
        )}
      </DropdownWrap>
    );
  }
}

export default Dropdown;
