import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import Header from 'Header';
import { Input, Button, Box } from '@3divi/baseui';
import { ButtonLine, Extra, StyledLoginContent, LoginTitle } from './styled/components';

const StyledToggleButton = styled.button`
  position: relative;
  font: ${({ theme }) => theme.font.s};
  overflow: hidden;
  flex: 0 0 50%;
  width: 50%;
  min-height: 32px;
  border-radius: 4px 4px 0 0;
  border: none;
  padding: 6px 0;
  border-bottom: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.button.primary : theme.border.default)};
  color: ${({ theme }) => theme.text.default};
  background: transparent;
  transition: 0.2s easy;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.button.primary};
    opacity: ${({ isActive }) => (isActive ? 0.04 : 0)};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:focus {
    color: ${({ theme }) => theme.link.hover};
  }

  &:hover:before {
    opacity: 0.04;
  }

  &:focus:before {
    opacity: 0.08;
  }
`;

const A = styled.a`
  color: ${({ theme }) => theme.link.default};
  font: ${({ theme }) => theme.font.xs};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }
  &:focus {
    color: ${({ theme }) => theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.link.hover};
  }
`;

const Text = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.default};
  text-align: center;
`;

function RegistrationView(props) {
  const { inputs, buttons } = props;

  return (
    <StyledLoginContent>
      <Header title={tr('tabtitle_registration')} />
      <LoginTitle>{tr('signup_title')}</LoginTitle>
      <div className="form-container__buttons">
        <StyledToggleButton {...buttons.corporate}>{tr('signup_corporate')}</StyledToggleButton>
        <StyledToggleButton {...buttons.personal}>{tr('signup_personal')}</StyledToggleButton>
      </div>
      <form>
        <Input
          {...inputs.login}
          size="m"
          variant="column"
          autoFocus={true}
          name="email"
          label={tr('login_email_label')}
          type="text"
          maxlength={151}
        />
        <Input
          {...inputs.password}
          size="m"
          variant="column"
          name="password"
          label={tr('login_password_label')}
          // type={this.state.inputType} TODO? когда-то давно здесь был "глазик" показывающий пароль
          type="password"
          maxlength={257}
        >
          {/* <i className={this.visButtonClass()} onClick={this.toggleVisible} /> */}
        </Input>
        <Input
          {...inputs.repeatPassword}
          size="m"
          variant="column"
          name="repeat"
          label={tr('repeat_password_label')}
          // type={this.state.inputType} TODO? когда-то давно здесь был "глазик" показывающий пароль
          type="password"
          maxlength={257}
        />
        <Box pass pt={1.5} px={1.5}>
          <Text>
            {tr('signup_agree')}{' '}
            <A
              tabIndex="5"
              href="http://3divi.com/downloads/privacy_policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tr('signup_pp')}
            </A>{' '}
            {tr('and')}{' '}
            <A
              tabIndex="6"
              href="https://3divi.com/downloads/3DiVi_SDK_Terms_and_Conditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {tr('signup_terms')}
            </A>
          </Text>
        </Box>
        <ButtonLine>
          <Button
            {...buttons.submit}
            // name="submit"
            type="submit"
            elastic
            size="m"
            color="primary"
            variant="contained"
            text={tr('register_button')}
          />
        </ButtonLine>
      </form>
      <Extra>
        {tr('signup_already')}
        {'\u00A0\u00A0'}
        <Button
          {...buttons.toLogin}
          tabIndex="7"
          variant="clear"
          color="primary"
          text={tr('login_button')}
        />
      </Extra>
    </StyledLoginContent>
  );
}

export default RegistrationView;
