import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getLicenseLockedTo, getLicenseType, getProductName } from '../../../../../helpers';
import { useMetadata } from '../../../../../helpers/useMetadata';
import { handleOpenModal } from '../../../../../store/modals/actions';
import RevokeLicenseModal from '../../../../modals/RevokeLicenseModal';
import UpgradeNuitrackProModal from '../../../../modals/UpgradeNuitrackProModal';
import { getLicenseInfoList } from '../../../../../helpers/getLicenseInfoList';
import { useFetchLicenseInfo } from './hooks/useFetchLicenseInfo';

export const useNuitrackInfo = () => {
  // Routes
  const id = useParams().id;
  // Selectors
  let license = useSelector(state => state.licenseInfo.licenseInfo);
  const partialLicense = useSelector(state => state.licenses.licenses.find(lic => lic.id === +id));
  license = license || partialLicense;
  const relatedGroup = useSelector(state =>
    state.licenses.groups.find(gr => license && gr.id === license.group)
  );
  const licenseLoaded = useSelector(state => state.licenseInfo.licenseLoaded);
  // consts
  const dispatch = useDispatch();
  const isHolded = license && license.license_status === 'holded';
  const platform = 'nuitrack';
  const backUrl = '/app/nuitrack/dashboard/';
  const licenseRevokeable = license && license.group && !license.activation_date;
  const licenseUpgradeable = license && license.upgradeable && license.upgradeable.toAI;

  const activationInfo = license
    ? [
        {
          text: tr('activation_type'),
          value: license.config.online ? tr('online') : tr('offline'),
        },
        { text: tr('locked_to'), value: getLicenseLockedTo(license) },
        { text: tr('serial_sensor'), value: license && license.serial_sensor },
      ]
    : undefined;
  const metadata = useMetadata(license, { platform });
  const relatedGroupInfo =
    license && license.fromCustomGroup
      ? [
          { text: tr('group_id'), value: relatedGroup && relatedGroup.id },
          { text: tr('group_name'), value: relatedGroup && relatedGroup.title },
        ]
      : undefined;
  const licenseInfo = getLicenseInfoList(
    license,
    { text: tr('product_name'), value: tr(getProductName(license)) },
    { text: tr('license_type'), value: getLicenseType(license) }
  );

  useFetchLicenseInfo({ id });

  const openRevoke = () =>
    dispatch(
      handleOpenModal({
        component: RevokeLicenseModal,
        license,
        platform,
        name: 'revoke_license',
      })
    );

  const openUpgradeNuitrackPro = () =>
    dispatch(
      handleOpenModal({
        component: UpgradeNuitrackProModal,
        license,
        platform,
        name: 'upgrade_pro',
      })
    );

  return {
    license,
    platform,
    partialLicense,
    loaded: licenseLoaded,
    relatedGroup,
    isHolded,
    licenseRevokeable,
    licenseUpgradeable,
    activationInfo,
    metadata,
    relatedGroupInfo,
    licenseInfo,
    openRevoke,
    openUpgradeNuitrackPro,
    backUrl,
    urlId: id,
  };
};
