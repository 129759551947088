import { createCachedSelector } from 're-reselect';

const cachedDataSelectors = {};
export const getDataSelector = (data, dataKey, platform) => {
  if (cachedDataSelectors[dataKey]) {
    return cachedDataSelectors[dataKey](data, platform);
  } else {
    const filterSelector = createCachedSelector(
      // selectors
      data => data[dataKey],
      (_, platform) => platform,
      // combiner
      (data, platform) => data.filter(el => el.type === platform)
    )((data, platform) => platform);

    cachedDataSelectors[dataKey] = filterSelector;
    return cachedDataSelectors[dataKey](data, platform);
  }
};
