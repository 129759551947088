import React from 'react';
import styled from 'styled-components';
import { Section } from '@3divi/baseui';

import Typography from 'components/elements/Typography/';

const StyledStepSection = styled(Section)`
  background: transparent;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  @media (max-width: 576px) {
    margin: 0 -16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  font-weight: 500;
`;

const Step = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  margin-right: 8px;
  border: 2px solid hsla(0, 0%, 0%, 1);
  border-radius: 50%;
`;

function StepSection(props) {
  const { step, title, children, ...other } = props;
  return (
    <StyledStepSection
      {...other}
      title={
        <Header>
          <Step>{step}</Step>
          <Typography size="20">{title}</Typography>
        </Header>
      }
    >
      {children}
    </StyledStepSection>
  );
}

export default StepSection;
