export const themeDefault = {
  fontFamily: `'IBM Plex Sans', 'Helvetica', 'Tahoma', sans-serif`,

  whiteColor: 'hsla(0,0%,100%,1)',
  mainColor: 'hsla(0,0%,12%,1)',
  grayColor: 'hsla(0,0%,48%,1)',
  primaryTextColor: 'hsla(120,48%,48%,1)',
  errorColor: 'hsla(0,100%,50%,1)',

  primaryColor: 'hsla(120,48%,56%,1)',
  border: '1px solid hsla(64,4%,94%,1)',
  borderDashed: '1px dashed hsla(64,4%,88%,1)',
  sectionShadow: '0 8px 16px -2px hsla(160,40%,12%,.03), inset 0 -1px 0 0 hsla(160,40%,12%,.11)',

  backgroundColor: 'hsla(0,0%,100%,1)',
  backgroundPrimaryColor: 'hsla(136,64%,96%,1)',

  backgroundGradient: 'linear-gradient(75deg, hsla(156,40%,96%,1) 0%, hsla(156,40%,98%,1) 100%)',

  themeGreenColor: 'hsla(120, 45%, 56%, 1)',
  themeBlueColor: 'hsla(205, 69%, 55%, 1)',
  themeYellowColor: 'hsla(56, 87%, 69%, 1)',
};

// export const device = {
//   xs: '(min-width: 576px)',
//   s: '(min-width: 768px)',
//   m: '(min-width: 992px)',
//   l: '(min-width: 1200px)',
// }
