import tr from 'locale';
import { secondsToDaysMonths } from './secondsToDaysMonths';

export const planConfigurationToTextValue = ({ plan, planFieldsByTypesMap }) => {
  if (!plan) return [];
  return Object.keys(plan)
    .map(field => {
      if (plan[field] === Number.POSITIVE_INFINITY)
        return {
          text: tr(field),
          value: tr('unlimited'),
        };
      if (planFieldsByTypesMap.seconds.some(v => field === v)) {
        const { months, days } = secondsToDaysMonths(plan[field]);
        return {
          text: tr(field),
          value:
            months > 0
              ? `${months} ${months > 1 ? tr('months') : tr('month')}`
              : days > 0
              ? `${days} ${days > 1 ? tr('days') : tr('day')}`
              : tr('less_day'),
        };
      }
      if (plan[field])
        return {
          text: tr(field),
          value: plan[field],
        };
    })
    .filter(v => v);
};
