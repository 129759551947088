import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.span`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.link.default};
  text-align: center;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:active {
    color: ${({ theme }) => theme.link.active};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.link.hover};
    color: ${({ theme }) => theme.link.hover};
  }
`;

function Link({ children, ...other }) {
  return <StyledLink {...other}>{children}</StyledLink>;
}

export default Link;
