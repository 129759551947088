import tr from 'locale';
import { handleOpenModal } from './modals/actions';
import ErrorModal from '../components/modals/ErrorModal';

export const errorHandleMiddleware = (
  action = (dispatch, getState) => new Promise(),
  handledErrors = []
) => {
  return (dispatch, getState) => {
    return action(dispatch, getState).catch(requestError => {
      const { error_code: errorCode, error } = requestError;

      if (error === "Unauthorized user 'AnonymousUser'") {
        dispatch(
          handleOpenModal({
            component: ErrorModal,
            name: 'error-unauthorized',
            text: tr('unauthorized_error'),
          })
        );
        return Promise.reject(requestError);
      }

      if (errorCode === null || errorCode === undefined) {
        // backend error without code
        dispatch(
          handleOpenModal({
            component: ErrorModal,
            name: `error-null`,
            text: tr('internal_server_error'),
          })
        );
        return Promise.reject(requestError);
      }

      if (!handledErrors.includes(errorCode)) {
        const errorMsg = tr(errorCode);
        if (errorMsg !== errorCode) {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: `error-${errorCode}`,
              text: errorMsg,
            })
          );
        } else {
          // unexpected errors
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: `error-${errorCode}`,
              text: tr('internal_server_error'),
            })
          );
          // throw error for external handlers
          return Promise.reject(new Error(`Cognitive error code is '${errorCode}'`));
        }
      } else {
        // throw for component inner handlers
        return Promise.reject(requestError);
      }
    });
  };
};
