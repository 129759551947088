// Hooks
import React, { useState } from 'react';
import { connect } from 'react-redux';
// Other
import tr from 'locale';
import check from 'img/check.svg';
import validateEmail from 'login/validation';
// Components
import { Button } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';
import OnPremiseForm from './OnPremiseForm';

import { sendSalesMessage } from 'store/clientdata/actions';

const MAX_DETAILS_LENGTH = 10000;
const AT_LEAST_DETAILS_LENGTH = 20;

function OnPremiseModal(props) {
  const { clientInfo, sendSalesMessageAction, platform, storedSalesMessage, hideAction } = props;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState(clientInfo.email);
  const [emailError, setEmailError] = useState('');
  const [details, setDetails] = useState('');
  const [detailsError, setDetailsError] = useState('');
  const [storeKey] = useState(Date.now());
  const salesMessage = storedSalesMessage && storedSalesMessage[storeKey];

  return salesMessage && salesMessage.success ? (
    <ModalComponent
      {...props}
      image={check}
      type="success"
      title={tr('sales_message_send_successfully')}
      buttons={<Button size="m" text={tr('close')} elastic onClick={hideAction} />}
    />
  ) : (
    <ModalComponent
      {...props}
      title={tr('Contact sales')}
      buttons={
        <Button
          size="m"
          text={tr('submit')}
          variant="contained"
          elastic
          loading={salesMessage && salesMessage.loading}
          color="primary"
          onClick={() => {
            let wasError = false;
            if (!details) {
              setDetailsError(tr('details_empty_error'));
              wasError = true;
            } else {
              if (details.length === 0) {
                setDetailsError(tr('details_empty_error'));
                wasError = true;
              } else if (details.length < AT_LEAST_DETAILS_LENGTH) {
                setDetailsError(
                  tr('details_low_length_error', { atLeast: AT_LEAST_DETAILS_LENGTH })
                );
                wasError = true;
              }
            }
            if (!name || name.length === 0) {
              setNameError(tr('name_empty_error'));
              wasError = true;
            }
            if (!email) {
              setEmailError(tr('email_empty_error'));
              wasError = true;
            } else {
              if (email.length === 0) {
                setEmailError(tr('email_empty_error'));
                wasError = true;
              } else if (!validateEmail(email)) {
                setEmailError(tr('email_validation_error'));
                wasError = true;
              }
            }
            if (wasError) return;

            sendSalesMessageAction(
              storeKey,
              platform,
              name ? name : '',
              email ? email : '',
              details
            );
          }}
        />
      }
    >
      <OnPremiseForm
        inputs={{
          name: {
            value: name,
            label: tr('name'),
            name: 'name',
            errorText: nameError,
            autoFocus: true,
            onChange: value => {
              if (nameError.length > 0) setNameError('');
              setName(value);
            },
          },
          email: {
            value: email,
            label: tr('user_email'),
            name: 'email',
            errorText: emailError,
            onChange: value => {
              if (emailError.length > 0) setEmailError('');
              setEmail(value);
            },
          },
          details: {
            value: details,
            label: tr('details'),
            name: 'details',
            errorText: detailsError,
            onChange: value => {
              // value validation
              if (value && value.length > MAX_DETAILS_LENGTH) {
                setDetailsError(tr('details_alot_length_error', { maxLength: MAX_DETAILS_LENGTH }));
                return;
              }
              // value is valid, clear error message
              if (detailsError.length > 0) setDetailsError('');

              setDetails(value);
            },
            placeholder: tr('details_placeholder'),
          },
        }}
      />
    </ModalComponent>
  );
}

const mapStateToProps = (store, props) => {
  return {
    clientInfo: store.clientdata.clientInfo,
    storedSalesMessage: store.clientdata.salesMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSalesMessageAction: (storeKey, platform, clientName, clientEmail, message) =>
      dispatch(sendSalesMessage({ platform, clientName, clientEmail, message }, { storeKey })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnPremiseModal);
