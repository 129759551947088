import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { handleOpenModal } from 'store/modals/actions';

import BtnAdditionalText from 'components/elements/BtnAdditionalText/';

function ButtonModal({ modal, button, config, platform, price, ...other }) {
  // Actions
  const dispatch = useDispatch();
  const createModalAction = useCallback(modalData => dispatch(handleOpenModal(modalData)), []);
  const showModal = () => {
    createModalAction({
      ...modal,
      isOpen: true,
      config,
      platform,
      price,
    });
  };

  // auto open modal, if flag was provided
  useEffect(() => {
    if (modal.isOpen) showModal();
  }, []);

  return <BtnAdditionalText onClick={showModal} {...button} {...other} noBorderRadius />;
}

export default ButtonModal;
