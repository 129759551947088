import React from 'react';
import styled from 'styled-components';

import './TableTransition.css';

import TableBody from './TableBody';
import LoaderComponent from 'components/LoaderComponent';

const StyledTable = styled.div`
  width: 100%;
  padding: 8px 0;
`;

function Table(props) {
  const {
    headers,
    rows,
    columns,
    rowClick,
    emptyText,
    isLoaded,
    rowsChangedTime,
    entitiesName,
  } = props;
  return (
    <StyledTable>
      {isLoaded ? (
        <TableBody
          columns={columns}
          rows={rows}
          rowClick={rowClick}
          emptyText={emptyText}
          cells={headers}
          rowsChangedTime={rowsChangedTime}
          entitiesName={entitiesName}
        />
      ) : (
        <LoaderComponent />
      )}
    </StyledTable>
  );
}

export default React.memo(Table);
