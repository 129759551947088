import React, { useState } from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';
import Typography from 'components/elements/Typography/';
import { useSelector, useDispatch } from 'react-redux';
import {
  licenseBuyAction,
  upgradeLicenseAction,
  upgradeGroupLicenseAction,
} from '../../../../../../store/licenseBuy/actions';

const SpaceWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

function PayBalance(props) {
  const { disabled, toBuyProducts, platform, toUpgradeId, checkoutType } = props;
  const dispatch = useDispatch();
  const promocode = useSelector(state => state.basket.validatePromocode.promocode);

  const [clicked, setClicked] = useState(false);

  const balance = useSelector(state => state.clientdata.balance);
  const totalSum = useSelector(state => state.basket.basket.totalSum);

  const onClick = async () => {
    setClicked(true);
    try {
      if (checkoutType === 'upgrade') {
        await dispatch(upgradeLicenseAction(toUpgradeId, { platform, paymentMethod: 'balance' }));
      } else if (checkoutType === 'upgrade-y') {
        await dispatch(
          upgradeGroupLicenseAction(toUpgradeId, { platform, paymentMethod: 'balance' })
        );
      } else {
        await dispatch(
          licenseBuyAction(toBuyProducts, { platform, paymentMethod: 'balance', promocode })
        );
      }
    } finally {
      setClicked(false);
    }
  };

  return (
    <Button
      size="l"
      text={
        <SpaceWrap>
          <Typography size="17" weight="500">
            {tr('basket_pay_from_balance')}
          </Typography>
          <Typography size="17" weight="400">{`$${balance}`}</Typography>
        </SpaceWrap>
      }
      onClick={onClick}
      style={{ button: 'hsla(0,0%,88%,1)', text: 'hsla(0,0%,0%,1)', icon: 'hsla(120,45%,56%,1)' }}
      elastic
      disabled={(disabled && !clicked) || +balance < +totalSum || promocode}
      loading={clicked}
    />
  );
}

export default PayBalance;
