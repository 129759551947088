import tr from 'locale';

const getDefaultTitle = group => {
  if (
    group.config.product === 'nuitrack_ai_perpetual' ||
    group.config.product === 'nuitrack_ai_subscription_monthly' ||
    group.config.product === 'nuitrack_ai_subscription_yearly'
  )
    return tr('default_title_nuitrack_ai');

  return tr(`info_title_${group.type}`);
};

export default getDefaultTitle;
