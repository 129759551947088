import React, { useRef } from 'react';
import tr from 'locale';
import { CopyButton, Input } from '@3divi/baseui';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
`;

const StyledInput = styled(Input)`
  z-index: 1;

  & > label {
    display: none;
  }
`;

const AbsoluteCopyButton = styled(CopyButton)`
  position: absolute;
  top: 8px;
  right: 4px;
  z-index: 10;
`;

function GeneratedKeyField(props) {
  const { license, group } = props;
  const token = (license && license.token) || (group && group.token);

  const inputEl = useRef(null);

  const copy = () => {
    inputEl.current.select();
    document.execCommand('copy');
  };

  return (
    <Wrap>
      <StyledInput size="m" type="text" innerRef={inputEl} value={token} readOnly />
      <AbsoluteCopyButton
        size="s"
        theme="contained"
        color="hsla(120,44%,56%,1)"
        variants={'size_s theme_clear'}
        onClick={copy}
        disabled={group && group.limit === 0}
        text={{ copy: tr('copy'), copied: tr('copied') }}
      />
    </Wrap>
  );
}

export default GeneratedKeyField;
