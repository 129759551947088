import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  getActivationHelpLink,
  getLicenseLockedTo,
  getLicenseType,
  getProductName,
} from '../../../../../helpers';
import { useMetadata } from '../../../../../helpers/useMetadata';
import { handleOpenModal } from '../../../../../store/modals/actions';
import FaceActivateModal from '../../../../modals/FaceActivateModal';
import FaceUSBTokenActivateModal from '../../../../modals/FaceUSBTokenActivateModal';
import RevokeLicenseModal from '../../../../modals/RevokeLicenseModal';
import { getLicenseInfoList } from '../../../../../helpers/getLicenseInfoList';
import { useFetchLicenseInfo } from './hooks/useFetchLicenseInfo';

export const useFaceInfo = () => {
  // Routes
  const id = useParams().id;
  const location = useLocation();
  // Actions
  const dispatch = useDispatch();
  // Selectors
  let license = useSelector(state => state.licenseInfo.licenseInfo);
  const partialLicense = useSelector(state => state.licenses.licenses.find(lic => lic.id === +id));
  license = license || partialLicense;
  const licenseLoaded = useSelector(state => state.licenseInfo.licenseLoaded);

  const isHolded = license && license.license_status === 'holded';
  const platform = 'face';
  const backUrl = '/app/face/dashboard/';
  const licenseRevokeable = license && license.group && !license.activation_date;

  const activationInfo = license
    ? [
        {
          text: tr('activation_type'),
          value: license.config.online ? tr('online') : tr('offline'),
        },
        { text: tr('locked_to'), value: getLicenseLockedTo(license) },
      ]
    : undefined;
  const metadata = useMetadata(license, { platform });
  const licenseInfo = getLicenseInfoList(
    license,
    { text: tr('product_name'), value: tr(getProductName(license)) },
    { text: tr('license_type'), value: getLicenseType(license) }
  );

  useFetchLicenseInfo({ id });

  const getActivationInfoAction = () => {
    if (!license) return null;

    const subtitle =
      license.locked_to === 'usb_token' ? tr('how_run_usb_token') : tr('how_generate_license');

    const left = {
      link: getActivationHelpLink(license),
      title: tr('activation_file'),
      subtitle,
    };

    if (
      (license.config.product === 'face_offline_custom' ||
        license.config.product === 'face_offline_perpetual') &&
      license.config.device_bound &&
      license.activations.length <= 0
    )
      return {
        type: 'Button',
        left,
        right: {
          text: tr('hint_upload_file'),
          props: {
            text: tr('upload_file'),
            onClick: () =>
              dispatch(
                handleOpenModal({
                  component: FaceActivateModal,
                  name: 'activation_face',
                  license,
                  platform,
                  subtitle: getLicenseType(license),
                })
              ),
          },
        },
        license,
      };
    else if (license.locked_to === 'usb_token' && license.activations.length <= 0)
      return {
        type: 'Button',
        left,
        right: {
          text: tr('hint_activate_by_usb'),
          props: {
            text: tr('enter_serial_button'),
            onClick: () =>
              dispatch(
                handleOpenModal({
                  component: FaceUSBTokenActivateModal,
                  name: 'activation_face',
                  license,
                  platform,
                  subtitle: getLicenseType(license),
                })
              ),
          },
        },
        license,
      };
    else
      return {
        type: 'FileLinkB64',
        left,
        right: {
          props: {
            autoAction: location.state && location.state.autoAction,
          },
        },
        license,
      };
  };

  const openRevoke = () =>
    dispatch(
      handleOpenModal({
        component: RevokeLicenseModal,
        license,
        platform,
        name: 'revoke_license',
      })
    );

  return {
    license,
    platform,
    partialLicense,
    loaded: licenseLoaded,
    isHolded,
    licenseRevokeable,
    activationInfo,
    getActivationInfoAction,
    metadata,
    licenseInfo,
    openRevoke,
    backUrl,
    urlId: id,
  };
};
