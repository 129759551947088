import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStatusFilters, useDayPicker, getDataSelector } from '../../../../hooks';
import { filterByKey, filterByDateRange, getLicenseType, getTableData } from '../../../../helpers';
import { getFakeLicenses } from '../../../../store/helpers/getFakeLicenses';
import tr from 'locale';
import getLicenseLockedTo from '../../../../helpers/getLicenseLockedTo';

import TableLicenses from './TableLicenses';

const productFilters = [
  'all',
  'face_trial_tag',
  'face_online_all_in_one',
  'face_online_sub',
  'custom',
];
const productOtherFilters = [];
const licenseStatusFilters = ['all', 'new', 'active', 'suspended'];
const tableConfig = {
  columns: [
    { name: 'id_column', size: 'max-content' },
    { name: 'title_column', size: 'minmax(200px, max-content)' },
    { name: 'license_type_column', size: 'minmax(80px, max-content)' },
    { name: 'license_status_column', size: 'minmax(80px, max-content)' },
    { name: 'subscription_status_column', size: 'minmax(80px, max-content)' },
    { name: 'expire_date_column', size: 'max-content' },
    { name: 'locked_to_column', size: 'minmax(80px, max-content)', visible: 'l' },
  ],
};

const getFaceTableData = license => {
  const getProductFilterAlias = license => {
    // face_offline_perpetual must be showed by filtering face_online_all_in_one,
    // so overlap product with: face_offline_perpetual -> face_online_all_in_one.
    if (license.config.product === 'face_offline_perpetual') return 'face_online_all_in_one';

    if (license.config.tag === 'FaceSDK Trial') return 'face_trial_tag';

    return license.config.product;
  };

  const result = {
    // data for filters and other
    ...license,
    product: getProductFilterAlias(license),
    createdDate: license.dates.createdDate,
    expireDate: license.dates.expireDate,
    activationDate: license.dates.activationDate,
    // cell data
    id_column: license.id,
    title_column: license.title,
    license_type_column: getLicenseType(license),
    license_status_column: tr(license.license_status),
    subscription_status_column: license.subscription_status && tr(license.subscription_status),
    expire_date_column: license.expire_date,
    locked_to_column: {
      cell_type: 'textWithTooltip',
      props: { textWithTooltip: getLicenseLockedTo(license) },
    },
  };
  return result;
};

function TableFace({ platform }) {
  // getting table data
  const storedLicenses = useSelector(state =>
    getDataSelector(state.licenses, 'licenses', platform)
  );
  const groups = useSelector(state => state.licenses.groups);
  const fakeLicenses = useMemo(() => getFakeLicenses(groups, platform), [groups, platform]);
  const licenses = [...storedLicenses, ...fakeLicenses];
  const { tableData, headers } = useMemo(
    () => getTableData(tableConfig, licenses, getFaceTableData),
    [storedLicenses, groups, platform]
  );
  // store and states
  const isLoaded = useSelector(state => state.licenses.fetchLicenses.success);
  const [productFilter, setProductFilter] = useState(productFilters[0]);
  const [statusFilter, setStatusFilter] = useState(licenseStatusFilters[0]);
  const [filterChangedTime, setFilterChangedTime] = useState(Date.now());
  const createdDayPicker = useDayPicker();
  const expireDayPicker = useDayPicker();
  const activationDayPicker = useDayPicker();
  // filters
  const filteredByProduct = useMemo(
    () => filterByKey(tableData, 'product', productFilter, productFilters[0]),
    [tableData, productFilter]
  );
  const filteredByDateFilters = useMemo(() => {
    let data = filteredByProduct;
    data = filterByDateRange(data, 'createdDate', [createdDayPicker.from, createdDayPicker.to]);
    data = filterByDateRange(data, 'expireDate', [expireDayPicker.from, expireDayPicker.to]);
    data = filterByDateRange(data, 'activationDate', [
      activationDayPicker.from,
      activationDayPicker.to,
    ]);
    return data;
  }, [
    filteredByProduct,
    createdDayPicker.from,
    createdDayPicker.to,
    expireDayPicker.from,
    expireDayPicker.to,
    activationDayPicker.from,
    activationDayPicker.to,
  ]);
  const statuses = useStatusFilters(
    filteredByDateFilters,
    'license_status',
    licenseStatusFilters,
    licenseStatusFilters[0]
  );
  const filteredData = useMemo(
    () =>
      filterByKey(filteredByDateFilters, 'license_status', statusFilter, licenseStatusFilters[0]),
    [filteredByDateFilters, statusFilter]
  );
  // conditional filters
  const getProductOthersFilter = () => {
    return productOtherFilters.filter(el => tableData.find(license => license.product === el));
  };
  // custom actions
  const handleFilterByProduct = value => {
    setProductFilter(value);
    setStatusFilter(licenseStatusFilters[0]);
  };

  const clearDayPickersSelection = () => {
    createdDayPicker.clearRange();
    expireDayPicker.clearRange();
    activationDayPicker.clearRange();
  };

  const clearAllFilters = () => {
    clearDayPickersSelection();
    setProductFilter(productFilters[0]);
    setStatusFilter(licenseStatusFilters[0]);
  };

  const isDataFiltered =
    productFilter !== productFilters[0] ||
    statusFilter !== licenseStatusFilters[0] ||
    createdDayPicker.isRangeChoosen ||
    expireDayPicker.isRangeChoosen ||
    activationDayPicker.isRangeChoosen;

  useEffect(() => {
    setFilterChangedTime(Date.now());
  }, [createdDayPicker, expireDayPicker, activationDayPicker, productFilter, statusFilter]);

  return (
    <TableLicenses
      productFilter={{
        filters: [...productFilters, ...getProductOthersFilter()],
        type: 'group',
        value: productFilter,
        callback: handleFilterByProduct,
      }}
      statusFilter={{
        statuses: statuses,
        value: statusFilter,
        isDefault: tableData && tableData.length === 0,
        callback: setStatusFilter,
      }}
      tableData={{
        headers: headers,
        rows: filteredData,
        columns: tableConfig.columns,
        isLoaded: isLoaded,
        emptyText: tr('empty_licenses'),
        rowsChangedTime: filterChangedTime,
      }}
      createdDayPicker={createdDayPicker}
      expireDayPicker={expireDayPicker}
      activationDayPicker={activationDayPicker}
      clearAllFilters={clearAllFilters}
      isDataFiltered={isDataFiltered}
    />
  );
}

export default TableFace;
