import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button, Col, H, Row, SectionHeader, Typography } from '@3divi/baseui';
import PullOutPage from '../../../../blocks/PullOutPage';
import LoaderComponent from '../../../../LoaderComponent';
import ActionBlock from '../../../../elements/LicenseInfoList/LicenseActionBlock';
import FileLinkB64 from '../../../../elements/FileLinkB64';
import { themeDefault } from '../../../../themes/themeDefault';
import LicenseInfoList from '../../../../elements/LicenseInfoList';
import LicenseInfoEditable from '../../../../elements/LicenseInfoEditable';
import SubscriptionManagement from '../../../../../modules/SubscriptionManagement';

const Content = styled.div`
  padding: 16px 24px 32px;

  @media (max-width: 720px) {
    padding: 16px 16px 32px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const Section = styled.div`
  padding: 0 0 16px;
  margin: 0 0 0 -16px;
  box-shadow: none;
`;

const ListWrap = styled.div`
  margin-left: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const ActionWrap = styled.div`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 8px;
`;

function FaceInfoView({
  header,
  isLoaded,
  activationInfo,
  licenseConfiguration,
  metadata,
  subscriptionManagement,
  info,
}) {
  return (
    <PullOutPage
      backUrl={header.backUrl}
      title={header.title}
      subtitle={header.subtitle}
      buttons={
        header.buttons &&
        header.buttons.revoke && (
          <Button text={tr('button_revoke_license')} {...header.buttons.revoke} />
        )
      }
    >
      {!isLoaded && <LoaderComponent />}
      {isLoaded && (
        <Content>
          <H as="h3" padding="8px 0">
            {header.title}
          </H>
          <StyledRow>
            <Col
              xl={subscriptionManagement && 8}
              l={subscriptionManagement && 8}
              m={subscriptionManagement && 8}
              s={subscriptionManagement && 8}
              xs={12}
            >
              {info && (
                <Section>
                  <SectionHeader title={tr('lic_info')} icon="edit" />
                  <ListWrap>
                    {info.map(el =>
                      el.editKey ? (
                        <LicenseInfoEditable
                          key={el.editKey}
                          license={el.license}
                          editKey={el.editKey}
                        />
                      ) : (
                        <LicenseInfoList key={el.text} items={[el]} />
                      )
                    )}
                  </ListWrap>
                </Section>
              )}
              <Section>
                <SectionHeader title={tr('activations_info')} icon="license" />
                <ListWrap>
                  <LicenseInfoList items={activationInfo.items} />
                  <ActionBlock
                    left={activationInfo.action.left}
                    right={
                      <>
                        {activationInfo.action.right.text && (
                          <StyledTypography height="20">
                            {activationInfo.action.right.text}
                          </StyledTypography>
                        )}
                        <ActionWrap>
                          {activationInfo.action.type === 'Button' && (
                            <Button
                              color={themeDefault.themeBlueColor}
                              theme="contained"
                              {...activationInfo.action.right.props}
                            />
                          )}
                          {activationInfo.action.type === 'FileLinkB64' && (
                            <FileLinkB64
                              fileName={'3divi_face_sdk.lic'}
                              license={activationInfo.action.license}
                              {...activationInfo.action.right.props}
                            />
                          )}
                        </ActionWrap>
                      </>
                    }
                  />
                </ListWrap>
              </Section>
              {licenseConfiguration && (
                <Section>
                  <SectionHeader title={tr('license_configuration')} icon="settings" />
                  <ListWrap>
                    <LicenseInfoList items={licenseConfiguration.items} />
                  </ListWrap>
                </Section>
              )}
              {metadata.length > 0 && (
                <Section>
                  <SectionHeader title={tr('metadata')} icon="info" />
                  <ListWrap>
                    <LicenseInfoList items={metadata} />
                  </ListWrap>
                </Section>
              )}
            </Col>
            {subscriptionManagement && (
              <Col>
                <SubscriptionManagement
                  platform={subscriptionManagement.platform}
                  license={subscriptionManagement.license}
                  loaded={subscriptionManagement.loaded}
                />
              </Col>
            )}
          </StyledRow>
        </Content>
      )}
    </PullOutPage>
  );
}

export default FaceInfoView;
