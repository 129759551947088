import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button } from '@3divi/baseui';
import ToggleButtonGroup from 'components/elements/ToggleButtonGroup/';

const StyledToggleBtn = styled(Button)`
  margin-right: -1px;
  margin-bottom: -1px;
  ${({ theme, isActive }) => !isActive && `background: ${theme.background.default}`};
  z-index: ${({ isActive }) => (isActive ? 10 : 1)};

  ${props =>
    props.themeType === 'group' &&
    `
    border-radius: 0;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  `}

  &:hover {
    z-index: 3;
  }

  &:active,
  &:focus {
    z-index: 2;
  }
`;

function FilterToggleBtn(props) {
  const { filters, type, value, callback } = props;
  return (
    <>
      <ToggleButtonGroup callback={callback} defaultValue={value}>
        {filters &&
          filters.map((el, i) => (
            <StyledToggleBtn
              key={el}
              themeType={type}
              value={el}
              variant={value === el ? 'contained' : 'outlined'}
              color={value === el ? 'primary' : 'default'}
              text={tr(el)}
              isActive={value === el}
              hoverColor="primary"
            />
          ))}
      </ToggleButtonGroup>
    </>
  );
}

export default FilterToggleBtn;
