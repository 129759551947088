import React from 'react';
import styled from 'styled-components';

const StepStyle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: ${({ space }) => space}px;
  font-weight: 900;
  font: ${({ theme }) => theme.font.s};
  text-align: center;
  border: 2px solid ${({ theme }) => theme.background.contrast};
  color: ${({ theme }) => theme.text.default};
  border-radius: 50%;
  flex: none;

  ${props =>
    props.active &&
    `
    border: 2px solid ${props.theme.background.brand};
    color: ${props.theme.text.primary};
  `}
`;

function Step({ status, active, count, space = '16', ...other }) {
  return (
    <StepStyle space={space} active={true} status={status} {...other}>
      {count}
    </StepStyle>
  );
}

export default Step;
