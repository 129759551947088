// Hooks
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// Others
import tr from 'locale';
import { clearLicense, createLicense } from 'store/licenseBuy/actions';
import { getPrice, getPriceObject } from '../../../../../helpers/getPrice';
import { useWindowSize } from '../../../../../hooks/';
// Components
import { Link, useHistory } from 'react-router-dom';
import LicenseHeader from './components/LicenseHeader';
import LicenseCard from './components/LicenseCard';
import Trial from './components/Trial';
import { Row, Col } from '@3divi/baseui';
// Theme
import { themeDefault } from 'components/themes/themeDefault';

const Licenses = styled.div`
  padding: 0 16px 40px;
  max-width: 1440px;

  @media (max-width: 1024px) {
    max-width: 800px;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
  height: 100%;
`;

const NUITRACK_OFFLINE_MAX_REACTIVATION_COUNT = 10;

const configs = {
  nuitrack_trial: {
    os_type: null,
    max_reactivation_count: null,
    license_expire_delta: null,
    product: 'nuitrack_trial',
    online: null,
  },
  nuitrack_online: {
    monthly: {
      product: 'nuitrack_online_subscription_monthly',
    },
    annual: {
      product: 'nuitrack_online_subscription_yearly',
    },
  },
  nuitrack_ai_subscribe: {
    monthly: {
      product: 'nuitrack_ai_subscription_monthly',
    },
    annual: {
      product: 'nuitrack_ai_subscription_yearly',
    },
  },
  old: {
    os_type: null,
    max_reactivation_count: NUITRACK_OFFLINE_MAX_REACTIVATION_COUNT,
    license_expire_delta: null,
    product: 'old',
    online: null,
  },
  nuitrack_ai_perpetual: {
    product: 'nuitrack_ai_perpetual',
  },
};

function LicenseNuitrack(props) {
  const { platform, ...other } = props;

  const prices = useSelector(state => state.clientdata.prices);
  const isDeprecated = prices.nuitrack.old.deprecated;
  const subPeriods = isDeprecated ? ['annual'] : ['monthly', 'annual'];
  const subPeriodVerbs = isDeprecated
    ? {
        annual: 'year',
      }
    : {
        monthly: 'month',
        annual: 'year',
      };
  const [subPeriodAI, setSubPeriodAI] = useState(subPeriods[0]);
  const [subPeriodPro, setSubPeriodPro] = useState(subPeriods[0]);

  const [small, setSmall] = useState(false);
  const [order, setOrder] = useState(false);
  const [width] = useWindowSize();

  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, success, license } = useSelector(state => state.licenseBuy.createLicense);

  useEffect(() => {
    let isSmall = width <= 1024;
    setSmall(isSmall);
  }, [width]);

  useEffect(() => {
    let isOrder = width <= 576;
    setOrder(isOrder);
  }, [width]);

  useEffect(() => {
    return () => {
      dispatch(clearLicense());
    };
  }, []);

  useEffect(() => {
    if (success) {
      history.push(`/app/${platform}/dashboard/${license.id}/info/`);
    }
  }, [success]);

  return (
    <Licenses>
      <StyledRow>
        {small ? (
          <Col xl={isDeprecated ? 4 : 2} l={isDeprecated ? 4 : 2} m={12} s={12} xxs={12}>
            <Trial
              title={tr('nuitrack-name')}
              name={tr('trial')}
              subtitle={tr('trial-subtitle')}
              tooltip={tr('trial-subtitle-tooltip')}
              button={{
                props: {
                  text: tr('activate'),
                  onClick: () =>
                    dispatch(
                      createLicense({ config: configs.nuitrack_trial, count: 1, title: '' })
                    ),
                  loading: loading,
                },
              }}
            />
          </Col>
        ) : (
          <Col xl={isDeprecated ? 4 : 2} l={isDeprecated ? 4 : 2} m={4} s={12} xs={12} xxs={12}>
            <LicenseHeader
              title={tr('nuitrack-name')}
              name={tr('trial')}
              subtitle={tr('trial-subtitle')}
              tooltip={tr('trial-subtitle-tooltip')}
            />
          </Col>
        )}
        {!isDeprecated && (
          <Col xl={5} l={5} m={4} s={6} xs={6} xxs={12}>
            <LicenseHeader
              title={tr('nuitrack-name')}
              name={tr('pro')}
              nameColor="hsla(212,96%,64%,1)"
              subtitle={tr('pro-subtitle')}
              tooltip={tr('pro-subtitle-tooltip')}
            />
          </Col>
        )}
        <Col
          xl={isDeprecated ? 8 : 5}
          l={isDeprecated ? 8 : 5}
          m={4}
          s={6}
          xs={6}
          xxs={12}
          order={order && 1}
        >
          <LicenseHeader
            tag={tr('tag-new')}
            title={tr('nuitrack-name')}
            name={tr('ai')}
            nameColor={themeDefault.primaryColor}
            subtitle={tr('ai-subtitle')}
            tooltip={tr('ai-subtitle-tooltip')}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        {!small && (
          <Col xl={isDeprecated ? 4 : 2} l={isDeprecated ? 4 : 2} m={4} s={12} xs={12} xxs={12}>
            <LicenseCard
              price={tr('free')}
              button={{
                props: {
                  text: tr('activate'),
                  onClick: () =>
                    dispatch(
                      createLicense({ config: configs.nuitrack_trial, count: 1, title: '' })
                    ),
                  loading: loading,
                },
              }}
            />
          </Col>
        )}
        {!isDeprecated && (
          <Col xl={5} l={5} m={4} s={6} xs={6} xxs={12}>
            <StyledRow>
              <Col m={12}>
                <LicenseCard
                  lineColor="hsla(212,96%,64%,1)"
                  type="Subscription"
                  price={`${getPrice(
                    { config: configs.nuitrack_online[subPeriodPro], type: 'nuitrack' },
                    prices
                  )}`}
                  priceText={`$${getPrice(
                    { config: configs.nuitrack_online[subPeriodPro], type: 'nuitrack' },
                    prices
                  )} ${tr('nuitrack_subscribe_subtext', {
                    period: tr(subPeriodPro),
                  })}`}
                  tooltip={tr('subscription-tooltip')}
                  switcher={{
                    onChange: setSubPeriodPro,
                    values: subPeriods,
                    current: {
                      adverb: subPeriodPro,
                      verb: subPeriodVerbs[subPeriodPro],
                      value: subPeriodPro,
                    },
                    defaultValue: subPeriods[0],
                  }}
                  button={{
                    props: {
                      text: tr('subscribe'),
                      to: {
                        pathname: `/app/${platform}/basket/checkout/subscriptions/pro/`,
                        state: { addLicenseType: configs.nuitrack_online[subPeriodPro].product },
                      },
                      as: Link,
                    },
                  }}
                />
              </Col>
              <Col m={12}>
                <LicenseCard
                  lineColor="hsla(212,96%,64%,1)"
                  type="Perpetual"
                  tooltip={tr('perpetual-tooltip')}
                  price={`${getPrice({ config: configs.old, type: 'nuitrack' }, prices)}`}
                  oldPrice={
                    getPriceObject({ config: configs.old, type: 'nuitrack' }, prices).old_price
                  }
                  discount={
                    getPriceObject({ config: configs.old, type: 'nuitrack' }, prices).discount
                  }
                  button={{
                    props: {
                      text: tr('buy'),
                      to: {
                        pathname: `/app/${platform}/basket/checkout/`,
                        state: { addLicenseType: configs.old.product },
                      },
                      as: Link,
                    },
                  }}
                />
              </Col>
            </StyledRow>
          </Col>
        )}
        <Col
          xl={isDeprecated ? 8 : 5}
          l={isDeprecated ? 8 : 5}
          m={4}
          s={6}
          xs={6}
          xxs={12}
          order={order && 1}
        >
          <StyledRow>
            <Col m={12}>
              <LicenseCard
                lineColor={themeDefault.primaryColor}
                priority
                type="Subscription"
                tooltip={tr('subscription-tooltip')}
                price={`${getPrice(
                  { config: configs.nuitrack_ai_subscribe[subPeriodAI], type: 'nuitrack' },
                  prices
                )}`}
                priceText={`$${getPrice(
                  { config: configs.nuitrack_ai_subscribe[subPeriodAI], type: 'nuitrack' },
                  prices
                )} ${tr('nuitrack_subscribe_subtext', {
                  period: tr(subPeriodAI),
                })}`}
                switcher={{
                  onChange: setSubPeriodAI,
                  values: subPeriods,
                  current: {
                    adverb: subPeriodAI,
                    verb: subPeriodVerbs[subPeriodAI],
                    value: subPeriodAI,
                  },
                  defaultValue: subPeriods[0],
                }}
                button={{
                  props: {
                    text: tr('subscribe'),
                    to: {
                      pathname: `/app/${platform}/basket/checkout/subscriptions/ai/`,
                      state: { addLicenseType: configs.nuitrack_ai_subscribe[subPeriodAI].product },
                    },
                    as: Link,
                  },
                  priority: true,
                }}
              />
            </Col>
            <Col m={12}>
              <LicenseCard
                lineColor={themeDefault.primaryColor}
                type="Perpetual"
                price={`${getPrice(
                  { config: configs.nuitrack_ai_perpetual, type: 'nuitrack' },
                  prices
                )}`}
                oldPrice={
                  getPriceObject(
                    { config: configs.nuitrack_ai_perpetual, type: 'nuitrack' },
                    prices
                  ).old_price
                }
                discount={
                  getPriceObject(
                    { config: configs.nuitrack_ai_perpetual, type: 'nuitrack' },
                    prices
                  ).discount
                }
                tooltip={tr('perpetual-tooltip')}
                button={{
                  props: {
                    text: tr('buy'),
                    to: {
                      pathname: `/app/${platform}/basket/checkout/`,
                      state: { addLicenseType: configs.nuitrack_ai_perpetual.product },
                    },
                    as: Link,
                  },
                }}
              />
            </Col>
          </StyledRow>
        </Col>
      </StyledRow>
    </Licenses>
  );
}

export default LicenseNuitrack;
