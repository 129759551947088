const routeRedirectToFreshdesk = '/api/service/redirect/?service=freshdesk';

export const CURRENT_FACE_MACHINE_PLAN_ID = 3;
export const CURRENT_NUITRAC_ANALYTICS_PLAN_ID = 4;

export const BASE_URL = '/app';

export const MENU = [
  {
    link: BASE_URL + '/nuitrack/dashboard/',
    title: 'nuitrack_gesture',
    icon: 'nuitrackHand',
  },
  {
    link: BASE_URL + '/face/dashboard/',
    title: 'nuitrack_face',
    icon: 'faceSdk',
  },
  {
    link: BASE_URL + '/face-machine/dashboard/',
    title: 'nuitrack_face_machine',
    icon: 'faceMachine',
  },
  {
    link: BASE_URL + '/nuitrack-analytics/dashboard/',
    title: 'nuitrack_nuitrack_analytics',
    icon: 'nuitrackAnalytics',
  },
  {
    link: BASE_URL + '/group-keys/',
    title: 'lic_tab_2',
    icon: 'key',
  },
  {
    link: BASE_URL + '/payment-doc/',
    title: 'payments_doc',
    icon: 'documents',
  },
  {
    link: routeRedirectToFreshdesk,
    title: 'help_link_support',
    icon: 'help',
  },
  // {"link": BASE_URL + "/face/account/", "title": "profile", "smalltext": "profile", "icon": "nav-icon_profile"},
  // { link: BASE_URL + '/face/account/', icon: 'person', title: "Account" },
  // { link: BASE_URL + '/face/logout/', icon: 'exit', title: "Log Out" },
];
