import styled from 'styled-components';

export const OldPrice = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.font.l};
  min-height: 28px;
  color: ${({ theme }) => theme.text.secondary};
  text-align: center;

  @media (max-width: 1200px) {
    font: ${({ theme }) => theme.font.m};
    min-height: 24px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.border.default};
  }
`;

export const DiscountBlock = styled.div`
  position: absolute;
  background: hsla(360, 100%, 48%, 1);
  left: -1px;
  top: -11px;
  font-family: ${({ theme }) => theme.font.xs};
  padding: 4px 4px 4px 8px;
  color: ${({ theme }) => theme.text.light};

  &:before {
    position: absolute;
    content: '';
    border: 12px solid transparent;
    border-left: 12px solid hsla(360, 100%, 48%, 1);
    right: 0;
    top: 0;
    transform: translateX(100%);
  }
`;
