import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button, Box } from '@3divi/baseui';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TaggedText from '../../elements/TaggedText';

const RegTitle = styled.h2`
  font: ${props => props.theme.font.xl};
  font-weight: 600;
  text-align: center;
  padding-bottom: 16px;
`;

const RegSubtitle = styled.h2`
  font: ${props => props.theme.font.m};
  text-align: center;
  color: ${props => props.theme.text.default};
`;

const Extra = styled.div`
  padding: 16px 0 0;
  text-align: center;
`;

const Email = styled.span`
  font-weight: 500;
`;

function AccountExists({ setStep }) {
  const clientInfo = useSelector(state => state.clientdata.clientInfo);
  return (
    <>
      <RegTitle>{tr('account_exist_na_title')}</RegTitle>
      <RegSubtitle>
        <TaggedText
          text={tr('account_exist_na_subtitle', { email: clientInfo.email || 'email@dot.com' })}
          email={Email}
        />
      </RegSubtitle>
      <Box pt={3}>
        <Button
          tabIndex="1"
          as={Link}
          variant="contained"
          size="m"
          elastic
          color="primary"
          text={tr('login_button')}
          to={'/app/login/nuitrack-analytics/'}
        />
      </Box>
      <Extra>
        <Button
          tabIndex="2"
          color="primary"
          variant="clear"
          text={tr('register_another_na_button')}
          onClick={() => {
            setStep('registration');
          }}
        />
      </Extra>
    </>
  );
}

export default AccountExists;
