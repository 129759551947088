import React, { useRef, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import tr from 'locale';
import { Button, BulletedList, WithIcon, LICENSESERVER_DARK, Typography } from '@3divi/baseui';
import ModalComponent from '../ModalComponent';
import TaggedText from '../../elements/TaggedText';
import StyledLink from '../../elements/StyledLink';

import ModalBg from 'img/modal_bg.png';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getPrice } from '../../../helpers/getPrice';

const ModalContent = styled.div`
  margin: 16px -40px -20px;
  padding: 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.border.default};
  background-image: url('${ModalBg}');
  background-position-y: bottom;
  background-repeat: no-repeat;
`;

const Buttons = styled.div`
  margin: 20px 0 4px;

  & > button {
    margin: 4px 0;
  }
`;

const Box = styled.div`
  text-align: center;
  padding: 16px 0 0;
`;

const TextBox = styled.div`
  padding: 20px 0 0;
`;

const Link = styled.a`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  margin: 0 auto;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }
`;

const P = styled.span`
  color: hsla(0, 0%, 100%, 1);
`;

function UpgradeNuitrackProModal(props) {
  const { hideAction, license, group } = props;
  const history = useHistory();
  const prices = useSelector(state => state.clientdata.prices);

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  return (
    <ThemeProvider theme={LICENSESERVER_DARK}>
      <ModalComponent
        {...props}
        isOpen
        fixed={440}
        modalColor="hsla(210,70%,4%,1)"
        title={tr('modal_upgrade_pro_to_ai')}
        subtitle={tr('nuitrack_ai_desc')}
      >
        <ModalContent>
          <BulletedList
            bullet="mark"
            bulletColor="primary"
            textColor={LICENSESERVER_DARK.text.default}
            list={[
              tr('nuitrack_ai_promo_feature_1'),
              tr('nuitrack_ai_promo_feature_2'),
              tr('nuitrack_ai_promo_feature_3'),
              tr('nuitrack_ai_promo_feature_4'),
            ]}
          />
          {(license && license.upgradeable && license.upgradeable.toAI === 'bySupport') ||
          (group && group.upgradeable && group.upgradeable.toAI === 'bySupport') ? (
            <TextBox>
              <Typography size="15" height="22" color={LICENSESERVER_DARK.text.default}>
                <TaggedText
                  text={tr('upgrade_by_support_information', {
                    cost: getPrice(
                      { config: { product: 'upgrade_nuitrack_pro' }, type: 'nuitrack' },
                      prices
                    ),
                  })}
                  mailto={StyledLink}
                />
              </Typography>
            </TextBox>
          ) : (
            <Buttons>
              <Button
                innerRef={ref}
                autoFocus={true}
                size="m"
                text={tr('button_upgrade_now')}
                variant="contained"
                color="primary"
                elastic
                onClick={() => {
                  if (license)
                    history.push(`/app/nuitrack/basket/checkout/upgrade/pro/${license.id}/`);
                  if (group)
                    history.push(`/app/nuitrack/basket/checkout/upgrade-y/pro/${group.id}/`);
                  hideAction();
                }}
              />
              <Button
                size="m"
                text={<P>{tr('button_maybe_later')}</P>}
                elastic
                onClick={hideAction}
              />
            </Buttons>
          )}
          <Box>
            <Link
              target="_blank"
              rel="noopener norefer"
              href="https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/Nuitrack_AI.md"
            >
              <WithIcon icon="link" size="xxs">
                {tr('learn_more')}
              </WithIcon>
            </Link>
          </Box>
        </ModalContent>
      </ModalComponent>
    </ThemeProvider>
  );
}

export default UpgradeNuitrackProModal;
