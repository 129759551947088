import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onlyDigitsRegexp } from '../../../../../../helpers/regexp';
import {
  fetchLicenseInfo,
  handleClearLicenseInfo,
} from '../../../../../../store/licenseInfo/actions';

export const useFetchLicenseInfo = ({ id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (onlyDigitsRegexp.test(id)) dispatch(fetchLicenseInfo(id));
    return () => dispatch(handleClearLicenseInfo(+id));
  }, []);
};
