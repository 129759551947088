import React, { useState, useEffect, useCallback } from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Icon, GRID_SIZE } from '@3divi/baseui';
import Tooltip from '../Tooltip/';
import { useWindowSize } from '../../../hooks/';
import BtnInvoice from '../BtnInvoice/BtnInvoice';

const cellLeftRightPadding = 8;

const TCell = styled.div`
  padding: ${props => (props.compensator ? 0 : `6px ${cellLeftRightPadding}px`)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme, header }) => (header ? theme.text.secondary : theme.text.default)};
  border-bottom: 1px solid ${({ theme }) => theme.border.default};

  &:first-child {
    padding-left: 16px;
  }

  ${props =>
    props.dataAttr === 'title_column' &&
    `
      white-space: initial;
  `}

  ${props =>
    props.header &&
    `
    color: ${({ theme }) => theme.text.secondary};
  `}

  ${props =>
    props.visible &&
    `
    @media (${GRID_SIZE[props.visible]}) {
      display: none;
    }
  `}
`;

const Upgrade = styled.div`
  position: relative;
  padding: 0 6px;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: ${({ theme }) => theme.link.default};
  background: ${({ theme }) => theme.background.primary};
  border-radius: 4px;
  text-align: center;

  & > span {
    display: block;
  }

  & > svg {
    display: none;
  }

  @media (max-width: 1200px) {
    & > span {
      display: none;
    }

    & > svg {
      display: block;
    }
  }
`;

const TableButton = styled.div`
  margin: -2px 0 -3px;
`;

// nth-last-child(1) is 'Locked to' column with tooltip, it should be always fully visible

function TableCell(props) {
  const {
    text,
    type,
    header,
    i,
    textWithTooltip,
    dataAttr,
    visible,
    compensator,
    ...other
  } = props;
  const [displayTolltip, setDisplay] = useState(false);

  const [small, setSmall] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    let isSmall = width <= 1200;
    setSmall(isSmall);
  }, [width]);

  const isShowTooltip = useCallback(node => {
    if (node !== null) {
      let textWidth = node.getBoundingClientRect().width;
      let parentWidth = node.parentElement.getBoundingClientRect().width;
      setDisplay(parentWidth < textWidth + 2 * cellLeftRightPadding);
    }
  }, []);

  const getContent = type => {
    switch (type) {
      case 'BtnInvoice':
        return (
          <TableButton>
            <BtnInvoice text={text} {...other} />
          </TableButton>
        );
      case 'textWithTooltip':
        return textWithTooltip;
      case 'upgrade':
        return (
          text && (
            <Upgrade>
              <span>{text}</span>
              <Icon icon="upgrade" color="primary" />
            </Upgrade>
          )
        );
      default:
        return text === tr('upgrade_column') && header ? null : (
          <span ref={isShowTooltip}>{text ? text : compensator ? '' : '—'}</span>
        );
    }
  };

  return (
    <>
      {!displayTolltip || (small && typeof text !== 'string') ? (
        <TCell
          data-cell={dataAttr}
          dataAttr={dataAttr}
          type={type}
          header={header}
          visible={visible}
          compensator={compensator}
        >
          {getContent(type)}
        </TCell>
      ) : (
        <Tooltip display={displayTolltip} text={text} spaces={12} side="top">
          <TCell
            data-cell={dataAttr}
            dataAttr={dataAttr}
            type={type}
            header={header}
            visible={visible}
            compensator={compensator}
          >
            {getContent(type)}
          </TCell>
        </Tooltip>
      )}
    </>
  );
}

export default TableCell;
