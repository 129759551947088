// Hooks
import React from 'react';
// Other
import styled from 'styled-components';
import tr from 'locale';
// Components
import ModalComponent from '../ModalComponent';
import { BulletedList, H, Button } from '@3divi/baseui';
import LoaderComponent from '../../LoaderComponent';

const VersionTitle = styled.div`
  text-align: center;
  padding: 32px 0 16px;
`;

const Date = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.secondary};
  padding-bottom: 4px;
`;

const MoreButton = styled.div`
  text-align: center;
  margin: 0 -40px;
  margin-top: 32px;
  margin-bottom: 1px;
  border-top: 1px solid ${({ theme }) => theme.border.default};
`;

const VersionLists = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border.default};
  margin: 0 -40px -24px;
  padding: 0 40px;
  max-height: calc(100vh - 242px);
  overflow-x: hidden;
  overflow-y: auto;
`;

function ReleaseInfoModalView(props) {
  const { changelogs, moreButton, loading, ...other } = props;

  return (
    <ModalComponent title={tr('what_new')} fixed={480} {...other}>
      {loading ? (
        <LoaderComponent />
      ) : (
        <VersionLists>
          {changelogs &&
            changelogs.map((el, i) => (
              <div key={i}>
                <VersionTitle>
                  <Date>{el.date}</Date>
                  <H as="h3">
                    {tr('cognitive_version')} {el.version}
                  </H>
                </VersionTitle>
                <BulletedList
                  bullet
                  list={el.changes}
                  bulletColor={i === 0 ? 'primary' : 'default'}
                />
              </div>
            ))}
          <MoreButton>
            {moreButton && (
              <Button
                text={tr('earlier_releases')}
                variant="clear"
                size="m"
                color="primary"
                elastic
                {...moreButton}
              />
            )}
          </MoreButton>
        </VersionLists>
      )}
    </ModalComponent>
  );
}

export default ReleaseInfoModalView;
