/**
 * Filtering by object keys
 * @param {array} arr - Array for filtering
 * @param {string} key - The key to filter by
 * @param {string} value - Value to compare with
 * @param {string} defaultValue - Default value for the filter
 * @returns {Array} The filtered array
 */
export const filterByKey = (arr, key, value, defaultValue) => {
  if (value === defaultValue) return arr;

  if (Array.isArray(key)) {
    let result = [];
    key.forEach(keyEl => {
      result = [
        ...result,
        ...arr.filter(license => license[keyEl] && license[keyEl].includes(value)),
      ];
    });
    return result;
  }

  return arr.filter(license => license[key].includes(value));
};

export const filterByDateRange = (arr, key, dateRange) => {
  const day = 1000 * 60 * 60 * 24;
  // if 'to' and 'from' conditions true - show in table
  // -  if 'to' empty - 'to' condition is true
  // -  if 'from' empty - 'from' condition is true
  return arr.filter(
    license =>
      (dateRange[0] ? license[key] - dateRange[0] >= 0 : true) &&
      (dateRange[1] ? license[key] - dateRange[1] <= day - 1 : true)
  );
};
