import { BASE_URL } from 'Config';
import { Link } from 'react-router-dom';
import { themeDefault } from 'components/themes/themeDefault';
const routeRedirectToFreshdesk = '/api/service/redirect/?service=freshdesk';

export const configHelpLinks = {
  face: [
    {
      type: 'buy',
      link: {
        as: Link,
        to: `${BASE_URL}/face/basket/license-selection/`,
      },
      variants: 'themeDefault.themeGreenColor',
    },
    {
      type: 'support',
      link: {
        as: Link,
        to: routeRedirectToFreshdesk,
        target: '_blank',
      },
    },
    {
      type: 'doc',
      link: {
        as: 'a',
        href: 'https://github.com/3DiVi/face-sdk#readme',
        target: '_blank',
      },
    },
  ],
  'face-machine': [
    {
      type: 'support',
      link: {
        as: Link,
        to: routeRedirectToFreshdesk,
        target: '_blank',
      },
    },
    {
      type: 'doc',
      link: {
        as: 'a',
        href: 'https://docs.facemachine.3divi.com/',
        target: '_blank',
      },
    },
  ],
  'nuitrack-analytics': [
    {
      type: 'buy',
      link: {
        as: Link,
        to: `${BASE_URL}/nuitrack-analytics/basket/license-selection/`,
      },
      variants: themeDefault.themeGreenColor,
    },
    {
      type: 'support',
      link: {
        as: Link,
        to: routeRedirectToFreshdesk,
        target: '_blank',
      },
    },
    {
      type: 'doc',
      link: {
        as: 'a',
        href: 'https://docs.analytics.nuitrack.com/',
        target: '_blank',
      },
    },
  ],
  nuitrack: [
    {
      type: 'buy',
      link: {
        as: Link,
        to: `${BASE_URL}/nuitrack/basket/license-selection/`,
      },
      variants: themeDefault.themeGreenColor,
    },
    {
      type: 'support',
      link: {
        as: Link,
        to: routeRedirectToFreshdesk,
        target: '_blank',
      },
    },
    {
      type: 'doc',
      link: {
        as: 'a',
        href: 'https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/readme.md',
        target: '_blank',
      },
    },
    {
      type: 'forum',
      link: {
        as: 'a',
        href: 'https://community.nuitrack.com/',
        target: '_blank',
      },
    },
  ],
};
