import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';
import { withRouter } from 'react-router-dom';
import tr from 'locale';
import { useWindowSize } from '../../../hooks/';
import { SectionHeader } from 'components/blocks/Section/';
import { NuitrackLogoB, FaceLogoBlack } from 'components/logo';

const StyledSectionHeader = styled(SectionHeader)`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.background.default};
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;

  & > a {
    flex: none;
    transform: translateX(-50%);
    z-index: 10;
  }

  & > div:nth-last-child(1) {
    flex-basis: 50%;
  }

  @media (max-width: 720px) {
    padding: 8px;
  }
`;

const StyledImg = styled.img`
  width: 172px;
`;

const logos = {
  nuitrack: NuitrackLogoB,
  face: FaceLogoBlack,
  'face-machine': FaceLogoBlack,
  'nuitrack-analytics': FaceLogoBlack, // TODO logo
};

function BasketHeader(props) {
  const { platform, backUrl, buttonText } = props;

  const [small, setSmall] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    let isSmall = width <= 720;
    setSmall(isSmall);
  }, [width]);

  return (
    <StyledSectionHeader
      headerLink={`/app/${platform}/`}
      title={<StyledImg src={logos[platform]} />}
      buttons={
        <Button
          text={!small && (buttonText || tr('go_back'))}
          iconLeft="back"
          onClick={() => props.history.push(backUrl)}
          round={small}
        />
      }
    />
  );
}

export default withRouter(BasketHeader);
