import React from 'react';
import FaceMachineInfoView from './components/FaceMachineInfoView';
import { useCheckLicenseAviability } from './components/hooks/useCheckLicenseAviability';
import { useFaceMachineInfo } from './components/useFaceMachineInfo';

function FaceMachineInfo() {
  const {
    license,
    platform,
    loaded,
    licenseConfiguration,
    metadata,
    licenseInfo,
    backUrl,
    urlId,
    licenseManage,
    message,
  } = useFaceMachineInfo();

  const redirect = useCheckLicenseAviability({ id: urlId, platform, license });

  if (redirect) return redirect;

  return (
    <FaceMachineInfoView
      header={{
        backUrl,
        title: license && license.title,
        subtitle: license && license.id,
        buttons: { serviceRedirect: { disabled: !license || license.license_status !== 'active' } },
      }}
      isLoaded={loaded}
      licenseConfiguration={licenseConfiguration}
      metadata={metadata}
      subscriptionManagement={{
        platform,
        license,
        loaded,
      }}
      info={licenseInfo}
      licenseManage={licenseManage}
      message={message}
    />
  );
}

export default FaceMachineInfo;
