import React, { useEffect, useState } from 'react';
import { useSteps } from '../hooks/';
import tr from 'locale';
import { Link } from 'react-router-dom';

import StartBlocks from './StartBlocks';

const nuitrackName = 'Nuitrack';
const nuitrackLicense = `${nuitrackName} ${tr('license')}`;

function StartBlocksNuitrack({
  stepsStore,
  setStorage,
  wasLicenseBought,
  setStep,
  setShowStep,
  platform,
}) {
  const { currentStep, nextStep, status, last } = useSteps(stepsStore);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (wasLicenseBought && currentStep === 'buy') {
      setStep(currentStep, nextStep);
    }

    if (last && status === 'completed') {
      setShow(false);
      setTimeout(() => {
        setShowStep(platform, false);
        setStorage(false);
      }, 350);
    }
  }, [wasLicenseBought, currentStep, nextStep, last, status, platform]);

  return (
    <StartBlocks
      stepsStore={stepsStore}
      inTransition={show}
      steps={[
        {},
        {
          title: tr('start_licenses_block_title'),
          stepType: 'buy',
          text: tr('choose_license', { license: nuitrackName }),
          button: {
            type: 'BtnAdditionalText',
            props: {
              text: tr('start_licenses_block_button'),
              forwardedAs: Link,
              to: '/app/nuitrack/basket/license-selection/',
            },
          },
        },
        {
          title: tr('start_almost_there'),
          stepType: 'success',
          text: tr('everything_done', { license: nuitrackLicense }),
          button: {
            type: 'BtnAdditionalText',
            props: {
              text: tr('complete'),
              onClick: () => setStep(currentStep),
            },
          },
        },
      ]}
    />
  );
}

export default StartBlocksNuitrack;
