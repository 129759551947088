import React from 'react';
import PayPalButton from '../../../../../elements/PaypalButton';
import {
  licenseActionSuccess,
  licenseBuyAction,
  upgradeLicenseAction,
  upgradeGroupLicenseAction,
} from '../../../../../../store/licenseBuy/actions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../../../../../../store/clientdata/actions';

function PayPaypal(props) {
  const { disabled, toBuyProducts, platform, toUpgradeId, checkoutType } = props;
  const isSubscribe = checkoutType === 'subscriptions';

  const dispatch = useDispatch();
  const promocode = useSelector(state => state.basket.validatePromocode.promocode);

  const createOrder = (data, actions) => {
    if (checkoutType === 'upgrade') {
      return dispatch(upgradeLicenseAction(toUpgradeId, { paymentMethod: 'paypal', platform }));
    } else if (checkoutType === 'upgrade-y') {
      return dispatch(
        upgradeGroupLicenseAction(toUpgradeId, { paymentMethod: 'paypal', platform })
      );
    } else {
      return dispatch(
        licenseBuyAction(toBuyProducts, { platform, paymentMethod: 'paypal', promocode })
      );
    }
  };

  const createSubscription = (data, actions) => {
    return dispatch(
      licenseBuyAction(toBuyProducts, {
        platform,
        paymentMethod: 'paypal',
        isSubscribe: true,
        promocode,
      })
    );
  };

  return (
    <PayPalButton
      createOrder={() => createOrder().then(response => response.payment.provider_data.order_id)}
      createSubscription={
        isSubscribe
          ? () =>
              createSubscription().then(response => response.payment.provider_data.subscription_id)
          : undefined
      }
      onApprove={() => {
        // same as licenseBuyAction success behavior
        dispatch(fetchProfile());
        dispatch(licenseActionSuccess());
      }}
      onError={reason => {
        console.error('PayPaypal.js: ', reason);
      }}
      platform={platform}
      disabled={disabled}
    />
  );
}

export default PayPaypal;
