import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > *:nth-child(2) {
    margin-top: 6px;
  }
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-flow: ${props => props.theme} nowrap;
  align-items: ${props => (props.theme === 'row' ? 'center' : 'flex-start')};
  margin-bottom: 12px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

function FormGroup(props) {
  const { title, subtitle, children, theme, ...other } = props;
  return (
    <Group theme={theme} {...other}>
      {(title || subtitle) && (
        <Label>
          {title && title}
          {subtitle && subtitle}
        </Label>
      )}
      {children}
    </Group>
  );
}

export default FormGroup;
