import React, { useState, useEffect, useMemo, useCallback } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import EditableLicensesInGroupView from './EditableLicensesInGroupView';
import LicenseInfoList from '../LicenseInfoList';
import { getSourceGroup } from '../../../store/helpers/getSourceGroup';
import { setGroupLimitAction } from '../../../store/licenses/actions';

function EditableLicensesInGroup(props) {
  const { editKey, group } = props;
  const dispatch = useDispatch();

  const [storeKey, setStoreKey] = useState(Date.now());
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(group.limit);
  const [error, setError] = useState('');

  const { loading, success } = useSelector(
    state => state.licenses.setGroupLimit[storeKey] || state.licenses.default
  );

  const defaultGroups = useSelector(state => state.licenses.groups.filter(gr => gr.is_default));
  const sourceGroup = useMemo(() => getSourceGroup(group, defaultGroups), [group, defaultGroups]);

  const onSubmit = e => {
    e.preventDefault();
    dispatch(setGroupLimitAction({ id: group.id, newLimit: value }, { storeKey }));
  };
  const onCancel = useCallback(() => {
    setIsEdit(false);
    setValue(group.limit);
    setStoreKey(Date.now());
    setError('');
  }, [group.limit]);

  useEffect(() => {
    if (success) {
      onCancel(); // reset editor's state
    }
  }, [success, onCancel]);

  return isEdit ? (
    <EditableLicensesInGroupView
      value={value}
      maxValue={group.limit + sourceGroup.limit}
      onChange={({ value }) => {
        if (value > group.limit + sourceGroup.limit) {
          setError(tr('source_group_limit_reached', { sourceLimit: sourceGroup.limit }));
          return;
        }

        if (error.length > 0) setError('');
        setValue(value);
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
      errorText={error}
    />
  ) : (
    <LicenseInfoList
      onClick={() => setIsEdit(true)}
      editable={true}
      items={[{ text: tr(editKey), value: group.limit }]}
    />
  );
}

export default EditableLicensesInGroup;
