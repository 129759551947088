import React from 'react';
import ReactDayPicker from 'react-day-picker';

import Dropdown from '../Dropwdown';

import 'react-day-picker/lib/style.css';
import './picker.css';

function DayPicker(props) {
  // props.modifiers adds css classes to ReactDayPicker, like DayPicker-Day--{modifier}
  // this modifiers cotains 'start', 'end' modifier.
  const {
    numberOfMonths,
    to,
    from,
    onDayClick,
    modifiers,
    text,
    initialMonth,
    className,
    spacesTop,
  } = props;

  const isDateChoosen = to || from;

  return (
    <Dropdown
      spacesTop={spacesTop}
      position={'center'}
      theme={isDateChoosen ? 'contained' : 'outlined'}
      color={isDateChoosen ? 'primary' : 'default'}
      text={text}
      iconRight="dropdownActive"
      hoverColor="primary"
      // size="m"
      wrapperClassName={className}
    >
      <ReactDayPicker
        className="Selectable"
        numberOfMonths={numberOfMonths || 2}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        onDayClick={onDayClick}
        initialMonth={initialMonth}
      />
    </Dropdown>
  );
}

export default DayPicker;
