import React, { useEffect } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Redirect, useLocation } from 'react-router';
import { handleOpenModal } from '../../../store/modals/actions';
import ModalComponent from '../../modals/ModalComponent';
import check from 'img/check.svg';
import { useDispatch } from 'react-redux';
import { fetchGroupsAction, fetchLicensesAction } from '../../../store/licenses/actions';
import { fetchProfile } from '../../../store/clientdata/actions';
import Typography from 'components/elements/Typography/';

const StyledA = styled.a`
  color: ${({ theme }) => theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:active {
    color: ${({ theme }) => theme.link.active};
  }
`;

const Text = styled(Typography)`
  padding-top: 28px;
`;

function SuccessPay(props) {
  // props
  const { purchase, platform = 'nuitrack' } = props;
  const location = useLocation();
  const searchObject = new URLSearchParams(location.search);
  // consts
  const modalName = 'success-pay';
  const contactEmail = 'info@3divi.com';
  const emailtoLink = (
    <StyledA target="_top" href={`mailto:${contactEmail}`}>
      {contactEmail}
    </StyledA>
  );
  const fetch = () => {
    switch (purchase) {
      case 'license':
        dispatch(fetchGroupsAction());
        dispatch(fetchLicensesAction());
        break;
      case 'balance':
        dispatch(fetchProfile());
        break;
      default:
        return;
    }
  };
  const getSubTitle = () => {
    switch (purchase) {
      case 'license':
        return tr('license_will_added_soon');
      case 'balance':
        return tr('balance_will_added_soon');
      default:
        return;
    }
  };
  const getText = () => {
    switch (purchase) {
      case 'license':
        return tr('license_apear_waiting_warning');
      case 'balance':
        return tr('balance_apear_waiting_warning');
      default:
        return;
    }
  };
  // hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      handleOpenModal({
        component: ModalComponent,
        image: check,
        type: 'success',
        title: tr('balance_success_title'),
        subtitle: getSubTitle(),
        children: (
          <Text height="20">
            {getText()} {emailtoLink}.
          </Text>
        ),
        name: modalName,
        onClose: fetch,
        platform,
      })
    );
  }, []);

  return <Redirect to={searchObject.get('next') || `/app/${platform}/dashboard/`} />;
}

export default SuccessPay;
