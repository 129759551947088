import React from 'react';
import { useSelector } from 'react-redux';

import ModalItem from './ModalItem';

function ModalsManager() {
  const modals = useSelector(state => state.modals.modalStack);
  return Object.entries(modals).map(([key, value]) => <ModalItem key={key} {...value} />);
}
export default ModalsManager;
