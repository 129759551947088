import { useState, useCallback, useMemo } from 'react';
import { DateUtils } from 'react-day-picker';

const useDayPicker = numberOfMonths => {
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const modifiers = useMemo(() => ({ start: from, end: to }), [to, from]);

  const onDayClick = useCallback(
    day => {
      const range = DateUtils.addDayToRange(day, { to, from });
      setTo(range.to);
      setFrom(range.from);
    },
    [to, from]
  );

  const clearRange = useCallback(() => {
    setTo();
    setFrom();
  }, []);

  return useMemo(
    () => ({
      from: from ? new Date(from.setHours(0)) : from,
      to: to ? new Date(to.setHours(0)) : to,
      modifiers,
      onDayClick,
      numberOfMonths: numberOfMonths ? numberOfMonths : 2,
      initialMonth: from,
      clearRange,
      isRangeChoosen: !!to || !!from,
    }),
    [to, from]
  );
};

export default useDayPicker;
