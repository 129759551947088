import React from 'react';
import tr from 'locale';
import { emailConfirm, isLogged } from 'api';
import { useParams, useLocation } from 'react-router';

import CognitiveInfo from './CognitiveInfo';
import BackendValidation from '../../blocks/BackendValidation/BackendValidation';

function RegistrationConfirm(props) {
  const location = useLocation();
  const { id, token } = useParams();

  const to = isLogged()
    ? '/app/logout/'
    : { pathname: `/app/login/`, state: { ...location.state } };

  return (
    <BackendValidation
      rule={{ type: 'success_fetch', fetch: () => emailConfirm(parseInt(id), token) }}
      failComponent={
        <CognitiveInfo
          text={tr('reg_confirm_error')}
          locationState={{ ...location.state }}
          button={{ text: tr('return_to_login_button') }}
        />
      }
    >
      <CognitiveInfo
        title={tr('reg_confirm_title')}
        text={tr('reg_confirm_text')}
        button={{ text: tr('return_to_login_button'), to: to }}
      />
    </BackendValidation>
  );
}

export default RegistrationConfirm;
