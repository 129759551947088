import { createAction } from 'redux-actions';
import {
  pauseLicense,
  resumeLicense,
  getLicenseData,
  activateOfflineLicense,
  generateOfflineLicense,
  reactivateSubscription,
  setLicenseTitle,
  activateFaceMachine,
  deactivateFaceMachine,
  activateNuitrackAnalytics,
  deactivateNuitrackAnalytics,
} from '../../api';
import { updateLicenseAction, addNewLicenseAction, updateGroupAction } from '../licenses/actions';
import waitConditionLoop from '../helpers/waitConditionLoop';
import { errorHandleMiddleware } from '../errorHandleMiddleware';
import { fetchProfile } from '../clientdata/actions';

const fetchLicenseInfoRequest = createAction('FETCH_LICENSE_INFO_REQUEST');
const fetchLicenseInfoSuccess = createAction('FETCH_LICENSE_INFO_SUCCESS');
const fetchLicenseInfoFilure = createAction('FETCH_LICENSE_INFO_FAILURE');

const licenseChangeStatusRequest = createAction('LICENSE_STATUS_REQUEST');
const licenseChangeStatusSuccess = createAction('LICENSE_STATUS_SUCCESS');
const licenseChangeStatusFail = createAction('LICENSE_STATUS_FAIL');
const clearStatusLicense = createAction('CLEAR_STATUS_LICENSE');

export function fetchLicenseInfo(licenseId) {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      fetchLicenseInfoRequest({
        licenseLoaded: false,
        license: null,
      })
    );
    try {
      let success = await getLicenseData(licenseId);
      waitConditionLoop(
        () =>
          dispatch(
            fetchLicenseInfoSuccess({
              licenseLoaded: true,
              license: success.license,
              prices: getState().clientdata.prices,
              isCustomGroupMap: getState().licenses.isCustomGroupMap,
            })
          ),
        () =>
          getState().clientdata.prices &&
          Object.keys(getState().clientdata.prices).length !== 0 &&
          getState().licenses.fetchGroups.success,
        10
      );
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        fetchLicenseInfoFilure({
          licenseLoaded: true,
          error,
          errorCode,
          license: null,
        })
      );
      throw requestError;
    }
  });
}

export const handlePause = id => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      licenseChangeStatusRequest({
        loading: true,
        loaded: false,
      })
    );
    try {
      let response = await pauseLicense(id);
      setTimeout(() => {
        dispatch(
          licenseChangeStatusSuccess({
            loading: false,
            loaded: true,
            license: response.license,
            prices: getState().clientdata.prices,
            isCustomGroupMap: getState().licenses.isCustomGroupMap,
          })
        );
      }, 250);
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        licenseChangeStatusFail({
          error,
          errorCode,
          loaded: true,
          loading: false,
        })
      );
      throw requestError;
    }
  });
};

export const handleActivateOffline = ({ license }, { data, usbToken }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      licenseChangeStatusRequest({
        loading: true,
        loaded: false,
      })
    );
    try {
      let response;
      if (data) response = await activateOfflineLicense(license.id, { data });
      else if (usbToken) response = await activateOfflineLicense(license.id, { usbToken });
      else throw { error_code: 'internal_server_error', error: 'empty data or usb token' };
      await setTimeout(() => {
        dispatch(
          licenseChangeStatusSuccess({
            loading: false,
            loaded: true,
            license: response.license,
            prices: getState().clientdata.prices,
            isCustomGroupMap: getState().licenses.isCustomGroupMap,
          })
        );
        dispatch(updateLicenseAction(response.license));
      }, 250);
      return response;
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        licenseChangeStatusFail({
          error,
          errorCode,
          loaded: true,
          loading: false,
        })
      );
      throw requestError;
    }
  });
};

export const handleGenerateOffline = (group, data) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      licenseChangeStatusRequest({
        loading: true,
        loaded: false,
      })
    );
    try {
      let response = await generateOfflineLicense(group.id, data);
      await setTimeout(() => {
        dispatch(
          licenseChangeStatusSuccess({
            loading: false,
            loaded: true,
            license: response.license,
            prices: getState().clientdata.prices,
            isCustomGroupMap: getState().licenses.isCustomGroupMap,
          })
        );
        dispatch(addNewLicenseAction(response.license));
        dispatch(updateGroupAction({ ...group, limit: group.limit - 1 }));
      }, 250);
      return response;
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        licenseChangeStatusFail({
          error,
          errorCode,
          loaded: true,
          loading: false,
        })
      );
      throw requestError;
    }
  });
};

export const handleResume = (id, { handledErrors }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      licenseChangeStatusRequest({
        loading: true,
        loaded: false,
      })
    );
    try {
      let response = await resumeLicense(id);
      dispatch(
        licenseChangeStatusSuccess({
          loading: true,
          loaded: true,
          license: response.license,
          prices: getState().clientdata.prices,
          isCustomGroupMap: getState().licenses.isCustomGroupMap,
        })
      );
      dispatch(fetchProfile());
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        licenseChangeStatusFail({
          error,
          errorCode,
          loading: false,
          loaded: false,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};

export const handlerReactivateLicense = (
  { licenseId },
  { stripe, platform, redirectParams, handledErrors }
) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(
      licenseChangeStatusRequest({
        loading: true,
        loaded: false,
      })
    );
    try {
      let response = await reactivateSubscription({ licenseId, platform, redirectParams });
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId: response.payment.provider_data.session_id,
        });
      }
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        licenseChangeStatusFail({
          error,
          errorCode,
          loading: false,
          loaded: false,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};

export const handleClearStatusLicense = () => {
  return dispatch => {
    dispatch(clearStatusLicense());
  };
};

export const setLicenseTitleAction = ({ id, title }, { storeKey, handledErrors }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(setLicenseTitleRequest({ storeKey }));
    try {
      const response = await setLicenseTitle(id, title);
      dispatch(updateLicenseAction(response.license));
      dispatch(
        setLicenseTitleSuccess({
          storeKey,
          license: response.license,
          prices: getState().clientdata.prices,
          isCustomGroupMap: getState().licenses.isCustomGroupMap,
        })
      );
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        setLicenseTitleFailure({
          errorCode,
          storeKey,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};
const setLicenseTitleRequest = createAction('SET_LICENSE_TITLE_REQUEST');
const setLicenseTitleSuccess = createAction('SET_LICENSE_TITLE_SUCCESS');
const setLicenseTitleFailure = createAction('SET_LICENSE_TITLE_FAILURE');

export const activateWorkspaceAction = license => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    let response;
    if (license.type === 'nuitrack-analytics')
      response = await activateNuitrackAnalytics(license.id);
    else response = await activateFaceMachine(license.id);
    dispatch(updateLicenseAction(response.license));
    dispatch(
      activateWorkspaceSuccess({
        license: response.license,
        prices: getState().clientdata.prices,
        isCustomGroupMap: getState().licenses.isCustomGroupMap,
      })
    );
  });
};
const activateWorkspaceSuccess = createAction('ACTIVATE_WORKSPACE_SUCCESS');

export const deactivateWorkspaceAction = license => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    let response;
    if (license.type === 'nuitrack-analytics')
      response = await deactivateNuitrackAnalytics(license.id);
    else response = await deactivateFaceMachine(license.id);
    dispatch(updateLicenseAction(response.license));
    dispatch(
      deactivateWorkspaceSuccess({
        license: response.license,
        prices: getState().clientdata.prices,
        isCustomGroupMap: getState().licenses.isCustomGroupMap,
      })
    );
  });
};
const deactivateWorkspaceSuccess = createAction('DEACTIVATE_WORKSPACE_SUCCESS');

export const handleClearLicenseInfo = id => {
  return dispatch => {
    dispatch(clearLicenseInfoAction({ id }));
  };
};
const clearLicenseInfoAction = createAction('CLEAR_LICENSE_INFO');
