import React, { useState, useEffect, Children } from 'react';

function ToggleButtonGroup(props) {
  const { children, defaultValue, callback } = props;
  const [value, setValue] = useState(defaultValue);

  const changeActive = e => {
    e.preventDefault();
    setValue(e.currentTarget.value);
    callback && callback(e.currentTarget.value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {Children.map(children, child =>
        React.cloneElement(child, {
          onClick: changeActive,
          active: value === child.props.value && !child.props.disabled,
        })
      )}
    </>
  );
}

export default ToggleButtonGroup;
