export const getActivationHelpLink = license => {
  if (!license) return;
  switch (license.type) {
    case 'nuitrack':
      if (license.config.product === 'tvico')
        return 'https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/TVico_User_Guide.md';
      return 'https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/Install.md';
    case 'face':
      if (
        (license.config.product === 'face_offline_custom' ||
          license.config.product === 'face_offline_perpetual') &&
        license.config.device_bound &&
        license.activations.length <= 0
      )
        return 'https://docs.facesdk.3divi.com/docs/licenses#offline-licenses';
      else if (license.locked_to === 'usb_token')
        return 'https://docs.facesdk.3divi.com/docs/licenses#locking-to-a-usb-token';
      else return 'https://docs.facesdk.3divi.com/docs/licenses#license-activation';
    default:
      return;
  }
};
