import React, { useState } from 'react';
import { BASE_URL } from 'Config';
import tr from 'locale';
import { resetPassword } from 'api';
import { Link, useLocation } from 'react-router-dom';
import CognitiveInfo from './CognitiveInfo';
import validateEmail from '../../../login/validation';
import ForgotPasswordView from './ForgotPasswordView';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';
import { useDispatch } from 'react-redux';

function ForgotPassword(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [showInfo, setShowInfo] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if (email.length === 0) {
      setError(tr('email_empty_error'));
      return;
    }
    if (!validateEmail(email)) {
      setError(tr('email_validation_error'));
      return;
    }
    setLoading(true);
    resetPassword({ email: email }, 'nuitrack').then(
      success => {
        setLoading(false);
        setShowInfo(true);
      },
      async failure => {
        setLoading(false);
        const { error_code: errorCode } = failure;
        const errorMsg = tr(errorCode || 'internal_server_error');
        if (errorCode === 'e1a53088') {
          setError(errorMsg);
        } else if (errorMsg !== errorCode) {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: errorMsg,
            })
          );
        } else {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: tr('network_error'),
            })
          );
        }
      }
    );
  };

  return showInfo ? (
    <CognitiveInfo
      title={tr('pasres_success_title')}
      text={tr('pasres_success_text', { email })}
      locationState={{ ...location.state }}
      button={{ text: tr('return_to_login_button') }}
    />
  ) : (
    <ForgotPasswordView
      inputs={{
        email: {
          value: email,
          errorText: error,
          onChange: v => {
            if (v.length > 150) {
              setError(tr('email_max_length_error', { maxLength: 150 }));
              return;
            }
            if (error.length > 0) setError('');

            setEmail(v);
          },
        },
      }}
      buttons={{
        submit: {
          onClick: onSubmit,
          loading: loading,
        },
        toRegistration: {
          as: Link,
          to: { pathname: `${BASE_URL}/registration/`, state: { ...location.state } },
        },
        toLogin: {
          as: Link,
          to: { pathname: '/app/login/', state: { ...location.state } },
        },
      }}
    />
  );
}

export default ForgotPassword;
