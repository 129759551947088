import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Button, Tooltip, WithIcon } from '@3divi/baseui';

const List = styled.div``;

const Title = styled.div`
  position: relative;
  padding: 8px 16px 8px 0;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  width: 172px;

  &:before {
    position: absolute;
    content: '';
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.background.default} 0%,
      ${({ theme }) => theme.background.contrast} 100%
    );
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.03;
  }

  @media (max-width: 720px) {
    background: none;
    width: auto;
    padding: 8px 0 0;
    font: ${({ theme }) => theme.font.xs};
    color: ${({ theme }) => theme.text.secondary};
  }
`;

const Value = styled.div`
  position: relative;
  padding: 8px ${({ editable }) => (editable ? 72 : 16)}px 8px 16px;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  flex: 1 1 0;
  overflow: hidden;
  word-wrap: break-word;
  min-height: 36px;

  & > button {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 10;
  }

  @media (max-width: 720px) {
    padding: 8px ${({ editable }) => (editable ? 72 : 0)}px 8px 0;
  }
`;

const StyledIncludeItem = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  border-top: 1px solid ${({ theme }) => theme.border.default};

  ${props =>
    props.editable &&
    `

    &:hover {
      cursor: pointer;
    }

    &:hover ${Value}:before {
      content: '';
      position: absolute;
      border-radius: 4px;
      border: 1px dashed ${props.theme.border.default};
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      min-height: 30px;

      @media (max-width: 720px) {
        display: none;
      }
    }
  `}

  @media (max-width: 720px) {
    display: block;
  }
`;

function LicenseInfoList(props) {
  const { items, editable, onClick, ...other } = props;
  return (
    <List onClick={onClick} {...other}>
      {items.map((el, i) => (
        <StyledIncludeItem editable={editable} key={el.text}>
          <Title>
            {el.text}
            {el.componentTooltip && (
              <Tooltip component={el.componentTooltip} tooltipColor="contrast">
                <WithIcon color="primary" icon="info" />
              </Tooltip>
            )}
          </Title>
          <Value editable={editable}>
            {el.value ? el.value : '—'}
            {editable && (
              <Button
                size="s"
                iconLeft="pen"
                variant="clear"
                color="primary"
                text={tr('edit_info')}
              />
            )}
            {el.buttons}
          </Value>
        </StyledIncludeItem>
      ))}
    </List>
  );
}

export default LicenseInfoList;
