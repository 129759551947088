import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { handleOpenModal } from '../../../store/modals/actions';
import { useDispatch } from 'react-redux';
import FillBalanceBuy from '../FillBalanceBuy';
import tr from 'locale';

function AddFunds(props) {
  const { platform = 'nuitrack' } = props;
  const location = useLocation();
  const searchObject = new URLSearchParams(location.search);

  const modalName = 'add-funds';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      handleOpenModal({
        component: FillBalanceBuy,
        price: searchObject.get('amount'),
        title: tr('add_funds'),
        name: modalName,
        platform,
      })
    );
  }, []);

  return <Redirect to={`/app/${platform}/dashboard/`} />;
}

export default AddFunds;
