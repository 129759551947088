import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, P } from '@3divi/baseui';

const IconContainer = styled.div`
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.border.default};
  border-radius: 50%;
`;

const IconBackground = styled.div`
  padding: 12px 0 ${props => (props.uploaded ? '12px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin: -2px -2px 0;
  border: 2px dashed ${({ theme }) => theme.border.default};
  border-bottom: none;
  background: ${props =>
    props.uploaded ? ({ theme }) => theme.background.primary : 'transparent'};
`;

const FakeDragAndDrop = styled.div`
  width: 100%;
  border: 2px dashed ${({ theme }) => theme.border.default};
  border-radius: 4px;
  text-align: center;
  z-index: -1;
`;

const StyledDragAndDrop = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${props =>
    props.overArea
      ? `
    & ${IconContainer} {
      border-color: hsla(132, 48%, 56%, 0.48);
    }
  `
      : `
    & ${IconContainer} {
      border-color: ${({ theme }) => theme.border.default};
    }
  `}

  &:hover ${IconContainer} {
    border-color: hsla(132, 48%, 56%, 0.48);
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  position: absolute;
`;

const Label = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;

  ${props =>
    props.loading &&
    `
    &:hover {
      cursor: default;
    }
  `}
`;

function DragAndDrop(props) {
  const { uploaded, onFilesUploaded, ...other } = props;

  const [hovered, setHovered] = useState(false);

  const highlight = e => {
    e.preventDefault();
    setHovered(true);
  };

  const unhighlight = e => {
    e.preventDefault();
    setHovered(false);
  };

  const initDrop = e => {
    e.preventDefault();
    unhighlight(e);
    onFilesUploaded(e.dataTransfer.files);
  };

  return (
    <StyledDragAndDrop
      onDragEnter={highlight}
      onDragOver={highlight}
      onDragLeave={unhighlight}
      onDrop={initDrop}
      overArea={hovered}
    >
      <Input
        id={`lic-file`}
        type="file"
        multiple={false}
        accept="text/*"
        onChange={e => onFilesUploaded(e.target.files)}
      />
      <Label htmlFor={`lic-file`}>
        <FakeDragAndDrop>
          <IconBackground uploaded={uploaded}>
            <IconContainer>
              <Icon icon={uploaded ? 'file' : 'upload'} size="m" />
            </IconContainer>
          </IconBackground>
          <P size="xs" padding={uploaded ? '8px 0' : '8px 0 12px'}>
            {uploaded ? 'Drop or upload another file' : 'Drag and drop your file here'}
          </P>
        </FakeDragAndDrop>
      </Label>
    </StyledDragAndDrop>
  );
}

export default DragAndDrop;
