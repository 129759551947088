import React from 'react';
import styled from 'styled-components';
import { isLight, Tooltip, WithIcon } from '@3divi/baseui';
import { themeDefault } from 'components/themes/themeDefault';

const StyledLicenseHeader = styled.div`
  @media (max-width: 576px) {
    padding-top: 32px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 4px 0;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font: ${({ theme }) => theme.font.l};
  font-weight: 800;
  color: ${({ theme }) => theme.text.default};
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.secondary};
  padding: 4px 8px;
  text-align: center;
`;

const Name = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${props => (isLight(props.nameColor) ? props.theme.text.dark : props.theme.text.light)};
  background: ${props => props.nameColor};
  border-radius: 4px;
  padding: 4px 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 8px;
`;

const Tag = styled.div`
  font: ${({ theme }) => theme.font.xxs};
  min-height: 13px;
  color: ${({ theme }) => theme.text.primary};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;

function LicenseHeader(props) {
  const { tag, title, name, nameColor, subtitle, tooltip, ...other } = props;
  return (
    <StyledLicenseHeader>
      <Tag>{tag}</Tag>
      <TitleBox>
        <Title>{title}</Title>
        {name && <Name nameColor={nameColor}>{name}</Name>}
      </TitleBox>
      {subtitle && (
        <Subtitle>
          {subtitle}
          {tooltip && (
            <Tooltip
              width="240"
              side="top-center"
              tooltipColor={themeDefault.whiteColor}
              text={tooltip}
            >
              <WithIcon icon="info" color="primary"></WithIcon>
            </Tooltip>
          )}
        </Subtitle>
      )}
    </StyledLicenseHeader>
  );
}

LicenseHeader.defaultProps = {
  tag: '',
  title: '',
  name: '',
  nameColor: 'hsla(0,0%,92%,1)',
  subtitle: '',
  tooltip: '',
};

export default LicenseHeader;
