import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Actions
import { handleClearStatusLicense } from 'store/licenseInfo/actions';
import { fetchLicensesAction } from 'store/licenses/actions';
// Others
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tr from 'locale';
import formatDate from 'FormatDate';
import TaggedText from '../../elements/TaggedText';

import ModalComponent from '../ModalComponent';
import { Button } from '@3divi/baseui';
import './icon.css';

const StyledModal = styled(ModalComponent)`
  text-align: center;
`;

const modalType = {
  activate: {
    icon: 'video',
    color: 'hsla(120,45%,56%,1)',
  },
  deactivate: {
    icon: 'warning',
    color: 'hsla(56,80%,64%,1)',
  },
  upgrade: {
    icon: 'upgrade',
    color: 'hsla(120,45%,56%,1)',
  },
};

ModalSubscriptionManagement.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.func,
};

const dateFormat = `MMM DD, YYYY`;

function ModalSubscriptionManagement(props) {
  const {
    name,
    licenseType = '',
    hideAction,
    action,
    text,
    error,
    license,
    upgradeCost,
    button,
    ...other
  } = props;
  // Actions
  const dispatch = useDispatch();
  // Selectors
  const statusLoading = useSelector(state => state.licenseInfo.licenseStatusLoading);
  const statusLoaded = useSelector(state => state.licenseInfo.licenseStatusLoaded);
  const statusError = useSelector(state => state.licenseInfo.licenseStatusError);

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  useEffect(() => {
    if (statusLoaded && !statusError) {
      dispatch(handleClearStatusLicense());
      dispatch(fetchLicensesAction());
      hideAction();
    }
  }, [statusLoaded]);

  useEffect(() => {
    return () => {
      dispatch(handleClearStatusLicense());
    };
  }, []);

  return (
    <StyledModal
      {...other}
      error={error}
      hideAction={hideAction}
      icon={modalType[name].icon}
      title={tr(`${name}_license_title`)}
      /*
        used translations:
          license_manage_subtitle_nuitrack_online_subscription_monthly
          license_manage_subtitle_nuitrack_online_subscription_yearly
          license_manage_subtitle_nuitrack_online
          license_manage_subtitle_nuitrack_ai_subscription_monthly
          license_manage_subtitle_nuitrack_ai_subscription_yearly
          license_manage_subtitle_face_online_custom_subscribe
          license_manage_subtitle_face_online_subscribe
          license_manage_subtitle_fmc_subscribe
          license_manage_subtitle_na_subscribe
      */
      subtitle={tr(`license_manage_subtitle_${license.config.product}`)}
      iconColor={modalType[name].color}
      text={
        text
          ? text
          : tr(`${name}_license_text`, {
              expirationDate: formatDate(license.dates.expireDate, dateFormat),
              price: upgradeCost,
              /*
                used translations:
                  nuitrack-name
                  face-name
                  face-machine-name
                  nuitrack-analytics-name
              */
              licenseName: tr(`${license.type}-name`),
            }).split('|cr|')
      }
      tooltip={
        name === 'deactivate'
          ? {
              component: (
                <TaggedText
                  text={tr(`${name}_license_tooltip`, {
                    expirationDate: formatDate(license.dates.expireDate, dateFormat),
                  })}
                />
              ),
              width: 330,
            }
          : undefined
      }
      buttons={
        <Button
          innerRef={ref}
          size="m"
          text={tr(`${name}_button_text`)}
          variant="contained"
          elastic
          loading={statusLoading}
          color="primary"
          onClick={
            name === 'upgrade'
              ? () => {
                  action();
                  hideAction();
                }
              : action
          }
          {...button}
        />
      }
    />
  );
}

export default ModalSubscriptionManagement;
