import getDefaultTitle from './getDefaultTitle';
import { getUpgradeable } from './getUpgradeable';

const getGroup = group => {
  if (!group) return;

  return {
    ...group,
    title: group.title || `${getDefaultTitle(group)} group #${group.id}`,
    upgradeable: group.is_default ? getUpgradeable(group) : undefined,
    hasAvailableLicense: group.limit > 0 || group.holded_limit > 0,
  };
};

export default getGroup;
