import tr from 'locale';

const injectCells = (tableConfig, data) => {
  let result = {
    ...data,
    cells: tableConfig.columns.map(key => data[key.name]),
  };
  return result;
};

const getTableData = (tableConfig, data, getTableDataFunction) => {
  let tableData;

  if (!data) tableData = [];

  data.sort((a, b) => {
    if (!a.dates.createdDate && !b.dates.createdDate) return 0;
    if (!a.dates.createdDate) return 1;
    if (!b.dates.createdDate) return -1;

    const dateDiff = a.dates.createdDate - b.dates.createdDate;
    // dateDiff > 0 - so a must be higher b, so return -1
    // dateDiff < 0 - so b must be higher a, so return 1
    return dateDiff === 0 ? 0 : dateDiff > 0 ? -1 : 1;
  });
  tableData = data.map(el => injectCells(tableConfig, getTableDataFunction(el)));

  const translateHeaders = tableConfig.columns.map(el => tr(el.name));

  return {
    tableData,
    headers: translateHeaders,
  };
};

export default getTableData;
