import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { themeDefault } from 'components/themes/themeDefault';
import { Button, Tooltip, WithIcon, Typography } from '@3divi/baseui';
import ToggleButtonGroup from 'components/elements/ToggleButtonGroup/';
import tr from 'locale';
import { DiscountBlock, OldPrice } from './styled';

import Lists from '../../../../elements/Lists/Lists';
import Price from '../../../../elements/Price/';
import { ButtonModal } from '../../../../modals/buttons';
import BtnAdditionalText from '../../../../elements/BtnAdditionalText/';

const StyledLicense = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background: ${({ theme }) => theme.background.default};
  border: 1px solid ${({ theme }) => theme.border.default};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;
  ${props => props.priority && 'box-shadow: 0px 0px 48px hsla(0,0%,0%,.08)'};

  ${props =>
    props.discount &&
    `
    &:before {
      position: absolute;
      content: '';
      background: hsla(360,100%,48%,1);
      height: 3px;
      left: -1px;
      right: -1px;
      top: -1px;
    }
  `}

  @media (max-width: 720px) {
    padding: 8px 24px;
  }
`;

const StyledLists = styled(Lists)`
  .list_items_right {
    padding: 12px 0;
  }
`;

const LicenseTitle = styled.div`
  padding: 12px 0 8px 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
`;

const Text = styled.div`
  padding: 12px 0 32px;
`;

const Subtitle = styled.div`
  padding: 0 0 12px 0;
`;

const ToggleButtonGroupStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
  display: flex;
`;

const ToggleButton = styled(Button)`
  position: relative;
  box-sizing: border-box;
  transition: 0.2s ease;
  margin-right: -1px;
  flex: 1 1 ${props => (props.active ? '40%' : '20%')};
  border-color: ${({ active, theme }) => (active ? theme.background.brand : theme.border.default)};
  z-index: ${props => (props.active ? 2 : 1)};
  border-radius: 0;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
  }

  &:hover {
    border-color: ${({ active, theme }) =>
      active ? theme.background.brand : theme.border.default};
  }

  & > span {
    pointer-events: none;
    text-align: center;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const PriceBox = styled.div`
  padding: 12px 0;
`;

const Wrap = styled.div`
  display: flex;
`;

LicenseView.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.element,
  button: PropTypes.element,
  priority: PropTypes.bool,
  lists: PropTypes.object,
};

function TooggleBtn(props) {
  const { active, activeText, text, ...others } = props;
  return <ToggleButton text={active ? activeText : text} {...others} active={active} />;
}

function LicenseView({
  title,
  text,
  subtitle,
  price,
  button,
  priority,
  lists,
  periodToggle,
  discount,
  oldPrice,
  ...other
}) {
  const getButton = btn => {
    switch (btn.type) {
      case 'ButtonModal':
        return <ButtonModal {...btn.props} />;
      case 'BtnAdditionalText':
        return <BtnAdditionalText {...btn.props} />;
      default:
        return;
    }
  };
  return (
    <StyledLicense priority={priority} {...other} discount={discount}>
      {discount && <DiscountBlock>{tr('discount_percents', { discount })}</DiscountBlock>}
      {title && <LicenseTitle>{title}</LicenseTitle>}
      {subtitle && (
        <Subtitle>
          <Row>
            <Typography size="13" height="16" weight="800" spacing="0.5" color={subtitle.textColor}>
              {subtitle.text}
            </Typography>
            <Tooltip text={subtitle.tooltipText} tooltipColor={themeDefault.backgroundColor}>
              <WithIcon icon="help" size="xs" color={subtitle.textColor} />
            </Tooltip>
          </Row>
        </Subtitle>
      )}
      <Text>
        {text && <Typography color="secondary">{text}</Typography>}
        {periodToggle && (
          <ToggleButtonGroupStyled>
            <ToggleButtonGroup
              defaultValue={periodToggle.default}
              callback={periodToggle.setPeriod}
            >
              {periodToggle.periods.map(period => (
                <TooggleBtn
                  key={period.activeText + period.text}
                  text={tr(period.text)}
                  activeText={tr(period.activeText)}
                  value={period.text}
                  elastic
                />
              ))}
            </ToggleButtonGroup>
          </ToggleButtonGroupStyled>
        )}
      </Text>
      {price && (
        <PriceBox>
          <Price {...price} />
          {oldPrice && (
            <Wrap>
              <OldPrice>${oldPrice}</OldPrice>
            </Wrap>
          )}
        </PriceBox>
      )}

      {button && getButton(button)}
      {lists && <StyledLists {...lists} />}
    </StyledLicense>
  );
}

export default LicenseView;
