import React, { useEffect } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
// components
import { Row, Col, P, H, Section, Button } from '@3divi/baseui';
import Header from 'Header';
import HeaderContainer from 'components/HeaderContainer';
import { GroupObject } from 'components/gesture-page';
import CreateGroupKeyModal from '../../modals/CreateGroupKeyModal';
import Footer from 'components/elements/Footer/';
import Feedback from '../../elements/Feedback/';
// actions
import { fetchGroupsAction } from '../../../store/licenses/actions';
import { handleOpenModal } from '../../../store/modals/actions';
// others
import { getDataSelector } from '../../../hooks/useGroupManageData';
import { getProductName, getLicenseType } from '../../../helpers';
import TaggedText from '../../elements/TaggedText/TaggedText';

const Box = styled.main`
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 32px 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 720px) {
    padding: 8px 16px 0;
  }
`;

const Content = styled.div`
  flex: 1 1 320px;
`;

const Wrap = styled.div`
  padding: 16px 0;
`;

const HelpText = styled.div`
  margin-top: 32px;
`;

const InfoContainer = styled.div`
  padding: 8px 8px 24px;

  @media (max-width: 720px) {
    padding: 0 0 16px;
  }
`;

const Bold = styled.span`
  font-weight: 500;
`;

function GroupKeys() {
  const dispatch = useDispatch();

  const groups = useSelector(state => getDataSelector(state.licenses, 'groups', 'nuitrack'));

  useEffect(() => {
    dispatch(fetchGroupsAction());
  }, []);

  const openCreateGroupKeyModal = () =>
    dispatch(
      handleOpenModal({
        component: CreateGroupKeyModal,
        name: 'create_group_key',
      })
    );

  // TODO: need to add page preloader
  // TODO: need to handle fetchGroups errors (network problem, and others)
  // TODO: can be added preloader then groups are fetching,
  // need new info in store 'loaded' means what first fetching was success,
  // and change meaning of 'success' which should mean what current fetching was successful

  return (
    <Box>
      <Content>
        <Feedback />
        <Header title={tr('tabtitle_groups_nuitrack')} />
        <HeaderContainer key="head" pageName={tr('my_group_keys')} />
        <Row>
          <Col>
            <Wrap>
              <Button
                iconLeft="add"
                size="m"
                variant="contained"
                color="primary"
                text={tr('create_group_key')}
                onClick={openCreateGroupKeyModal}
              />
            </Wrap>
          </Col>
        </Row>
        <Row>
          {groups
            .filter(gr => !gr.is_default)
            .map((gr, i) => (
              <GroupObject
                key={`cont-${i}${gr.id}`}
                id={gr.id}
                title={gr.title}
                subTitle={`${tr(getProductName(gr))} | ${getLicenseType(gr)}`}
                limit={gr.limit}
                used={gr.used_licenses}
                token={gr.token}
              />
            ))}
        </Row>
        <HelpText>
          <Row>
            <Col>
              <Section>
                <InfoContainer>
                  <Row>
                    <Col xs={12} s={12} m={6} l={6}>
                      <H as="h3" padding="16px 24px 8px 0">
                        {tr('group_key_info_title_1')}
                      </H>
                      <P size="m" padding="0 24px 8px 0">
                        <TaggedText text={tr('group_key_info_1')} bold={Bold} />
                      </P>
                      <H as="h3" padding="16px 24px 8px 0">
                        {tr('group_key_info_title_2')}
                      </H>
                      <P size="m" padding="8px 24px 8px 0">
                        <TaggedText text={tr('group_key_info_2')} bold={Bold} />
                      </P>
                    </Col>
                    <Col xs={12} s={12} m={6} l={6}>
                      <H as="h3" padding="16px 24px 8px 0">
                        {tr('group_key_info_title_3')}
                      </H>
                      <P size="m" padding="8px 24px 8px 0">
                        <TaggedText text={tr('group_key_info_3')} bold={Bold} />
                      </P>
                    </Col>
                  </Row>
                </InfoContainer>
              </Section>
            </Col>
          </Row>
        </HelpText>
      </Content>
      <Footer />
    </Box>
  );
}

export default GroupKeys;
