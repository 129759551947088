import { createAction } from 'redux-actions';

import {
  getGroupData,
  getLicensesList,
  splitGroupKey,
  setGroupLimit,
  setGroupTitle,
  revokeLicense,
} from 'api';
import waitConditionLoop from '../helpers/waitConditionLoop';
import { errorHandleMiddleware } from '../errorHandleMiddleware';
import { isLogged } from '../../api';

let licenseFetchProgress = false;
export const fetchLicensesAction = () => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    if (licenseFetchProgress || !isLogged()) return;
    licenseFetchProgress = true;
    dispatch(fetchLicensesRequest());
    try {
      const response = await getLicensesList();

      waitConditionLoop(
        () => {
          dispatch(
            fetchLicensesSuccess({
              ...response,
              prices: getState().clientdata.prices,
            })
          );
          licenseFetchProgress = false;
        },
        () =>
          getState().clientdata.prices &&
          Object.keys(getState().clientdata.prices).length !== 0 &&
          getState().licenses.fetchGroups.success,
        50
      );
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        fetchLicensesFailure({
          errorCode,
        })
      );
      licenseFetchProgress = false;
      throw requestError;
    }
  });
};
const fetchLicensesRequest = createAction('FETCH_LICENSES_REQUEST');
const fetchLicensesSuccess = createAction('FETCH_LICENSES_SUCCESS');
const fetchLicensesFailure = createAction('FETCH_LICENSES_FAILURE');

let groupFetchProgress = false;
export const fetchGroupsAction = () => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    if (groupFetchProgress || !isLogged()) return;
    groupFetchProgress = true;
    dispatch(fetchGroupsRequest());
    try {
      const response = await getGroupData();
      dispatch(fetchGroupsSuccess(response));
      groupFetchProgress = false;
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        fetchGroupsFailure({
          errorCode,
        })
      );
      groupFetchProgress = false;
      throw requestError;
    }
  });
};
const fetchGroupsRequest = createAction('FETCH_GROUPS_REQUEST');
const fetchGroupsSuccess = createAction('FETCH_GROUPS_SUCCESS');
const fetchGroupsFailure = createAction('FETCH_GROUPS_FAILURE');

export const updateGroupAction = group => {
  return dispatch => {
    dispatch(updateGroup({ group }));
  };
};
const updateGroup = createAction('UPDATE_GROUP');

export const createCustomGroupAction = ({ sourceId, newLimit, newTitle }, { storeKey }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(createCustomGroupRequest({ storeKey }));
    try {
      const response = await splitGroupKey(sourceId, parseInt(newLimit, 10), newTitle);
      dispatch(
        createCustomGroupSuccess({
          storeKey,
          newGroup: response.dest_group,
          sourceGroup: response.source_group,
        })
      );
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        createCustomGroupFailure({
          storeKey,
          errorCode,
        })
      );
      throw requestError;
    }
  });
};
const createCustomGroupRequest = createAction('CREATE_CUSTOM_GROUP_REQUEST');
const createCustomGroupSuccess = createAction('CREATE_CUSTOM_GROUP_SUCCESS');
const createCustomGroupFailure = createAction('CREATE_CUSTOM_GROUP_FAILURE');

export const setGroupLimitAction = ({ id, newLimit }, { storeKey }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(setGroupLimitRequest({ storeKey }));
    try {
      const response = await setGroupLimit(id, parseInt(newLimit, 10));
      // there is can be only set limit for custom group, and take limit from default group
      dispatch(
        setGroupLimitSuccess({
          storeKey,
          group: response.group,
          defaultGroup: response.default_group,
        })
      );
    } catch (requestError) {
      let { error_code: errorCode, info } = requestError;
      dispatch(
        setGroupLimitFailure({
          storeKey,
          errorCode,
          info,
        })
      );
      throw requestError;
    }
  });
};
const setGroupLimitRequest = createAction('SET_GROUP_LIMIT_REQUEST');
const setGroupLimitSuccess = createAction('SET_GROUP_LIMIT_SUCCESS');
const setGroupLimitFailure = createAction('SET_GROUP_LIMIT_FAILURE');

export const updateLicenseAction = license => {
  return dispatch => {
    dispatch(updateLicense({ license }));
  };
};
const updateLicense = createAction('UPDATE_LICENSE');

export const clearLicenseStoreAction = () => {
  return dispatch => {
    dispatch(clearLicenseStore());
  };
};
const clearLicenseStore = createAction('CLEAR_LICENSE_STORE');

export const addNewLicenseAction = license => {
  return dispatch => {
    dispatch(addNewLicense({ license }));
  };
};
const addNewLicense = createAction('ADD_NEW_LICENSE');

export const setGroupTitleAction = ({ id, title }, { storeKey, handledErrors }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(setGroupTitleActionRequest({ storeKey }));
    try {
      const response = await setGroupTitle(id, title);
      dispatch(
        setGroupTitleActionSuccess({
          group: response.group,
          storeKey,
        })
      );
      dispatch(updateGroupAction(response.group));
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        setGroupTitleActionFailure({
          errorCode,
          storeKey,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};
const setGroupTitleActionRequest = createAction('SET_GROUP_TITLE_REQUEST');
const setGroupTitleActionSuccess = createAction('SET_GROUP_TITLE_SUCCESS');
const setGroupTitleActionFailure = createAction('SET_GROUP_TITLE_FAILURE');

export const revokeLicenseAction = ({ license }, { storeKey }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(revokeLicenseRequest({ storeKey }));
    try {
      await revokeLicense(license.id);
      dispatch(revokeLicenseSuccess({ storeKey, license }));
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        revokeLicenseFailure({
          storeKey,
          errorCode,
        })
      );
      throw requestError;
    }
  });
};
const revokeLicenseRequest = createAction('REVOKE_LICENSE_REQUEST');
const revokeLicenseSuccess = createAction('REVOKE_LICENSE_SUCCESS');
const revokeLicenseFailure = createAction('REVOKE_LICENSE_FAILURE');
