// Hooks
import React from 'react';
import { useHistory } from 'react-router-dom';
// Others
import { themeDefault } from 'components/themes/themeDefault';
import tr from 'locale';
import styled from 'styled-components';
// Components
import { ButtonModal } from 'components/modals/buttons/';
import { ModalSubscriptionManagement } from 'components/modals/';

const ButtonUpgradeWrapper = styled.div`
  padding: 8px 16px;
  background-color: hsla(60, 100%, 88%, 1);
`;

function ButtonUpgrade({ license, platform, attention, ...others }) {
  const history = useHistory();
  const action = () => {
    history.push(`/app/${platform}/basket/checkout/upgrade/trial/${license.id}/`);
  };
  return (
    <ButtonUpgradeWrapper>
      <ButtonModal
        modal={{
          component: ModalSubscriptionManagement,
          name: 'upgrade',
          action: action,
          license,
        }}
        button={{
          text: tr('upgrade'),
          color: themeDefault.themeYellowColor,
          priority: true,
          size: 'm',
          side: 'top',
          colorBg: '#FFFFC2',
          additionalText: attention,
          disabled: license.license_status === 'holded',
          ...others,
        }}
      />
    </ButtonUpgradeWrapper>
  );
}

export default ButtonUpgrade;
