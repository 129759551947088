import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import NuitrackLicensesTable from './LicenseContainers/components/NuitrackLicensesTable';
import LoaderComponent from 'components/LoaderComponent';
import PageTitle from '../../PageTitle';

const StyledLicenses = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: center;
  padding: 8px 8px 48px;
`;

function LicenseSelection(props) {
  const { licenseContainer, platform, loadComplete } = props;
  const LicenseContainer = licenseContainer;

  if (!loadComplete) return <LoaderComponent />;

  return (
    <>
      <PageTitle title={tr(`platform_${platform}_basket`)} />
      <StyledLicenses>
        {licenseContainer && <LicenseContainer platform={platform} />}
      </StyledLicenses>
      {platform === 'nuitrack' && <NuitrackLicensesTable />}
    </>
  );
}

export default LicenseSelection;
