import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import LoaderComponent from '../../LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { checkTokenAction } from '../../../store/clientdata/actions';

const StyledLoaderComponent = styled(LoaderComponent)`
  width: 50%;
  align-self: center;
`;

function BackendValidation(props) {
  const { rule, failComponent } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [storeKey, setStoreKey] = useState(Date.now());
  const storedCheckToken = useSelector(
    state => state.clientdata.checkToken[storeKey] || state.clientdata.default
  );

  useEffect(() => {
    switch (rule.type) {
      case 'valid_token':
        dispatch(checkTokenAction(storeKey, rule.clientId, rule.token));
        break;
      case 'success_fetch':
        rule.fetch().then(
          success => {
            setValid(true);
            setLoading(false);
          },
          failure => {
            setValid(false);
            setLoading(false);
          }
        );
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    if (storedCheckToken.status.success) {
      setValid(true);
      setLoading(false);
    } else if (storedCheckToken.status.failure) {
      setValid(false);
      setLoading(false);
    }
  }, [storedCheckToken.status.success, storedCheckToken.status.failure]);

  return loading ? <StyledLoaderComponent /> : valid ? props.children : failComponent;
}

export default BackendValidation;
