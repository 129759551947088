import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Box, Button, GRID_SIZE } from '@3divi/baseui';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme, border }) => (border ? theme.border.default : 'transparent')};

  @media (${GRID_SIZE.xs}) {
    flex: column nowrap;
    flex-flow: column nowrap;
  }
`;

const Text = styled.p`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.secondary};
  text-align: center;
  flex: 1 1 0;

  @media (${GRID_SIZE.xs}) {
    padding-top: 16px;
  }
`;

function TableFooter({ showed, total, button, entitiesName }) {
  return total === 0 ? null : (
    <Box>
      {showed < total && (
        <Box px={1} mt={1}>
          <Button
            elastic
            size="m"
            text={`↓ ${tr('button_show_more')}`}
            color="primary"
            variant="clear"
            borderRadius="4px 4px 0 0"
            onClick={button.onClick}
          />
        </Box>
      )}
      <Box pass px={1}>
        <Content border={showed < total}>
          <Box pass pt={1} px={1}>
            <Text>{tr('showed_of_total', { showed, total, entitiesName })}</Text>
          </Box>
        </Content>
      </Box>
    </Box>
  );
}

export default TableFooter;
