import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';
import Stepper from 'components/elements/Stepper/';

const CountChar = styled.span`
  padding-right: 8px;
`;

function ProductControls(props) {
  const { type, value, onChange } = props;

  switch (type) {
    case 'stepper':
      return (
        <>
          <CountChar>×</CountChar>
          {!value ? (
            <Button
              text={tr('add_license')}
              color="primary"
              onClick={() => onChange({ value: 1 })}
            />
          ) : (
            <Stepper value={value} callback={onChange} />
          )}
        </>
      );
    case 'radio':
      return <input onChange={() => onChange({ value: 1 })} type="radio" checked={!!value} />;
    case 'none':
      return null;
    default:
      return;
  }
}

export default ProductControls;
