export const getProductName = license => {
  if (!license) return '';

  switch (license.type) {
    case 'nuitrack':
      if (license.config.product === 'nuitrack_ai_perpetual') return 'table_name_nuitrack_ai';
      return 'table_name_nuitrack';
    case 'face':
      return 'table_name_face';
    case 'face-machine':
      return `table_name_${license.config.product}`;
    case 'nuitrack-analytics':
      return `table_name_${license.config.product}`;
    default:
      return '';
  }
};
