import React from 'react';
import InfoItem from './InfoItem';
import tr from 'locale';
import { Section, Button } from '@3divi/baseui';

class CompanyInformation extends React.Component {
  render() {
    let info = this.props.accountInfo.company;
    return (
      <Section
        title={tr('company_info_header')}
        buttons={<Button round iconLeft="pen" onClick={this.props.openCompanyEdit} />}
      >
        <ul className="account__list">
          <InfoItem title={tr('company_name')} text={info.name} />
          <InfoItem title={tr('company_address')} text={info.address} />
          <InfoItem title={tr('company_tax')} text={info.vat} />
          <InfoItem title={tr('company_type')} text={info.type} />
          <InfoItem title={tr('company_site')} text={info.site} />
        </ul>
      </Section>
    );
  }
}

export default CompanyInformation;
