import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { BASE_URL } from 'Config';
import { isLogged } from 'api';

import RegistrationForm from './RegistrationForm';
import ForgotPassword from './ForgotPassword';
import SetPassword from './SetPassword';

function LoginRoute(props) {
  const location = useLocation();
  let platform;
  if (location.state && location.state.platform) platform = location.state.platform;

  return isLogged() ? (
    <Redirect to={`/app/${platform ? `${platform}/` : ''}`} />
  ) : (
    <Switch>
      <Route exact path={`${BASE_URL}/registration/`} component={RegistrationForm} />
      <Route exact path={`${BASE_URL}/forgot-password/`} component={ForgotPassword} />
      <Route exact path={`${BASE_URL}/set-password/:id/:token/`} component={SetPassword} />
      <Redirect to={{ pathname: `${BASE_URL}/`, state: { ...location.state } }} />
    </Switch>
  );
}

export default LoginRoute;
