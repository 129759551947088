import React, { useState, useEffect } from 'react';
import tr from 'locale';
import styled, { ThemeProvider } from 'styled-components';

import { BulletedList, P, Button, Typography, LICENSESERVER, FACEMACHINE } from '@3divi/baseui';
import ActivationTabs from './ActivationTabs';
import DragAndDrop from './DragAndDrop';
import TaggedText from '../../elements/TaggedText';
import { useDispatch } from 'react-redux';
import { handleOpenModal, handleHideModal } from 'store/modals/actions';
import ModalComponent from '../ModalComponent';
import { useHistory } from 'react-router';
import FileLinkB64 from '../../elements/FileLinkB64';
import FaceActivateWarning from '../FaceActivateWarning';
import StyledLink from '../../elements/StyledLink';

const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.text.default};
  width: 18px;
  height: 18px;
  margin: 1px;
  box-sizing: border-box;
  border-radius: 50%;

  &:before {
    content: '${props => props.number}';
    font: ${({ theme }) => theme.font.xxs};
    font-weight: 500;
  }
`;

const Steps = styled.div`
  padding-top: 14px;
`;

const Step = styled.div`
  width: 100%;
`;

const Code = styled.code`
  display: inline-block;
  position: relative;
  font-family: 'Menlo', 'DejaVu Sans Mono', 'Liberation Mono', 'Consolas', 'Ubuntu Mono',
    'Courier New', 'andale mono', 'lucida console', monospace;
  font-size: 13px;
  line-height: 16px;
  padding: 2px 4px 2px;
  color: ${({ theme }) => theme.text.primary};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    border-radius: 4px;
    background: ${({ theme }) => theme.background.contrast};
    opacity: 0.05;
  }
`;

function ActivationFace(props) {
  const {
    title = tr('face_sdk_license_activation'),
    subtitle,
    group,
    license,
    platform,
    ...other
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [b64file, setb64file] = useState('');
  const [newLicense, setNewLicense] = useState();
  const [autoAction, setAutoAction] = useState(true); // by default FileLinkB64 must have autoAction equal true

  useEffect(() => {
    if (newLicense) {
      if (group) history.push(`/app/${platform}/dashboard/${newLicense.id}/info/`);
      dispatch(handleHideModal('activation_face_warning'));
      // after first apear FileLinkB64 (and action emit) need to set flag to false,
      // because switch tabs will mount/unmount components
      setAutoAction(false);
    }
  }, [newLicense]);

  const readFile = files => {
    if (files.length !== 1) return;
    let reader = new FileReader();
    reader.onload = () => {
      try {
        setb64file(btoa(reader.result));
        setFileUploaded(true);
      } catch (err) {
        setb64file('');
        setFileUploaded(false);
      }
    };
    reader.onerror = () => {
      setb64file('');
      setFileUploaded(false);
    };
    reader.readAsText(files[0]);
  };

  const onDownloadClick = () => {
    dispatch(
      handleOpenModal({
        component: FaceActivateWarning,
        name: 'activation_face_warning',
        group,
        license,
        getb64file: () => b64file,
        onActivateLicense: setNewLicense,
      })
    );
  };

  return (
    <ModalComponent isOpen title={title} subtitle={subtitle} fixed={448} {...other}>
      <ActivationTabs onChange={setTab} checkedTab={tab} />
      {tab === 1 && (
        <Steps>
          <BulletedList indent={2} bullet={<Bullet />}>
            <P>
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('win-linux_activate_offline_instruction_1')} code={Code} />
              </ThemeProvider>
            </P>
            <Step>
              <P padding="0 0 16px">
                <ThemeProvider theme={FACEMACHINE}>
                  <TaggedText text={tr('win-linux_activate_offline_instruction_2')} code={Code} />
                </ThemeProvider>
              </P>
              <DragAndDrop uploaded={fileUploaded} onFilesUploaded={readFile} />
            </Step>
            <Step>
              <P padding="0 0 16px">
                <ThemeProvider theme={FACEMACHINE}>
                  <TaggedText text={tr('win-linux_activate_offline_instruction_3')} code={Code} />
                </ThemeProvider>
              </P>
              {newLicense ? (
                <FileLinkB64
                  text={tr('button_download')}
                  color={fileUploaded ? LICENSESERVER.text.primary : LICENSESERVER.text.secondary}
                  elastic
                  theme="contained"
                  size="m"
                  autoAction={autoAction}
                  fileName="3divi_face_sdk.lic"
                  license={newLicense}
                />
              ) : (
                <Button
                  text={tr('button_download')}
                  color={fileUploaded ? 'primary' : 'default'}
                  disabled={!fileUploaded}
                  elastic
                  variant="contained"
                  size="m"
                  onClick={onDownloadClick}
                />
              )}
              <Typography align="center">
                <P padding="32px 0 0">
                  <StyledLink
                    href="https://docs.facesdk.3divi.com/docs/licenses#offline-licenses"
                    target="_blank"
                    rel="noopener noreffer"
                  >
                    {tr('activate_offline_details_link')}
                  </StyledLink>
                </P>
              </Typography>
            </Step>
          </BulletedList>
        </Steps>
      )}
      {tab === 2 && (
        <Steps>
          <BulletedList indent={2} bullet={<Bullet />}>
            <P>
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('ios-android_activate_offline_instruction_1')} code={Code} />
              </ThemeProvider>
            </P>
            <P>{tr('ios-android_activate_offline_instruction_2')}</P>
            <P>
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('ios-android_activate_offline_instruction_3')} code={Code} />
              </ThemeProvider>
            </P>
            <Step>
              <P padding="0 0 16px">
                <ThemeProvider theme={FACEMACHINE}>
                  <TaggedText text={tr('ios-android_activate_offline_instruction_4')} code={Code} />
                </ThemeProvider>
              </P>
              <DragAndDrop uploaded={fileUploaded} onFilesUploaded={readFile} />
            </Step>
            <Step>
              <P padding="0 0 16px">
                <ThemeProvider theme={FACEMACHINE}>
                  <TaggedText text={tr('ios-android_activate_offline_instruction_5')} code={Code} />
                </ThemeProvider>
              </P>
              {newLicense ? (
                <FileLinkB64
                  text={tr('button_download')}
                  color={fileUploaded ? LICENSESERVER.text.primary : LICENSESERVER.text.secondary}
                  elastic
                  theme="contained"
                  size="m"
                  autoAction={autoAction}
                  fileName="3divi_face_sdk.lic"
                  license={newLicense}
                />
              ) : (
                <Button
                  text={tr('button_download')}
                  color={fileUploaded ? 'primary' : 'default'}
                  disabled={!fileUploaded}
                  elastic
                  variant="contained"
                  size="m"
                  onClick={onDownloadClick}
                />
              )}
              <Typography align="center">
                <P padding="32px 0 0">
                  <StyledLink
                    href="https://docs.facesdk.3divi.com/docs/licenses#offline-licenses"
                    target="_blank"
                    rel="noopener noreffer"
                  >
                    {tr('activate_offline_details_link')}
                  </StyledLink>
                </P>
              </Typography>
            </Step>
          </BulletedList>
        </Steps>
      )}
    </ModalComponent>
  );
}

export default ActivationFace;
