import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Button } from '@3divi/baseui';
import ModalComponent from '../ModalComponent';
import { activateWorkspaceAction } from '../../../store/licenseInfo/actions';
import { useDispatch } from 'react-redux';
import { useIsMounted } from '../../../hooks/useIsMounted';

const StyledModal = styled(ModalComponent)`
  text-align: center;
`;

function NuitrackAnalyticsResuming(props) {
  const { hideAction, license } = props;

  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  const resumeLicense = async () => {
    try {
      setLoading(true);
      await dispatch(activateWorkspaceAction(license));
      if (isMounted.current) {
        setLoading(false);
        hideAction();
      }
    } catch {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <StyledModal
      {...props}
      title={tr('resume')}
      subtitle={tr('license_manage_subtitle_na_subscribe')}
      text={tr('nuitrack-analytics_status_manage_resume')}
      icon="video"
      iconColor="hsla(120,45%,56%,1)"
      fixed={440}
      buttons={
        <>
          <Button
            innerRef={ref}
            autoFocus={true}
            size="m"
            elastic
            theme="contained"
            color="primary"
            text={tr('button_resume_license', { licenseName: tr('nuitrack-analytics-name') })}
            loading={loading}
            onClick={resumeLicense}
          />
          <Button size="m" elastic text={tr('button_cancel')} onClick={hideAction} />
        </>
      }
    />
  );
}

export default NuitrackAnalyticsResuming;
