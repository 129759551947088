import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Typography, Box } from '@3divi/baseui';
import Divider from '../../../../../elements/Divider';
import { useSelector } from 'react-redux';

const Container = styled.div`
  margin: 8px 0;
  border: 1px solid ${({ theme }) => theme.border.default};
  border-radius: 4px;
  overflow: hidden;
`;

const Body = styled.div`
  padding: 8px 8px;
  background-color: ${({ theme }) => theme.background.secondary};
`;

const PaymentInfoSection = styled.div`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.background.default};
`;

const ItemPrice = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

function NuitrackAnalyticsSubscriptionCard({ license }) {
  const edgePrice = +useSelector(
    state =>
      state.clientdata.prices[license.type][license.config.product][license.config.plan_id].cost
  );

  return (
    <Container>
      <Body>
        <Box mb={1}>
          <Box py={0.5}>
            <Typography color="secondary" size="11" height="14" weight="500">
              {tr('subscription_status').toUpperCase()}
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography size="15" height="17">
              {tr(license.subscription_status)}
            </Typography>
          </Box>
        </Box>
        <PaymentInfoSection>
          <Box mb={1}>
            <Typography size="15" height="24" weight="700">
              {tr('next_payment_date', { date: license.expire_date })}
            </Typography>
          </Box>
          <Divider size={2} />
          <Box my={1}>
            <ItemPrice>
              <Typography>{tr('fm_subscription')}</Typography>
              <Typography>{`$${edgePrice.toFixed(2)}`}</Typography>
            </ItemPrice>
          </Box>
          <Divider size={1} />
          <Box my={1}>
            <ItemPrice>
              <Typography size="15" height="20">
                {tr('additional_devices')}
              </Typography>
              <Typography size="15" height="20">
                {`$${(edgePrice * (license.config.paid_channels - 1)).toFixed(2)}`}
              </Typography>
            </ItemPrice>
            <Box mt={0.5}>
              <Typography size="13" height="15" color="secondary">
                {`${license.config.paid_channels - 1} × $${edgePrice.toFixed(2)}`}
              </Typography>
            </Box>
          </Box>
          <Divider size={2} />
          <Box mt={1}>
            <ItemPrice>
              <Typography size="15" height="20">
                {tr('basket_total')}
              </Typography>
              <Typography size="32" height="32 ">
                {`$${(edgePrice * license.config.paid_channels).toFixed(2)}`}
              </Typography>
            </ItemPrice>
          </Box>
        </PaymentInfoSection>
      </Body>
    </Container>
  );
}

export default NuitrackAnalyticsSubscriptionCard;
