import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { BASE_URL } from 'Config';
import SuccessPay from '../pages/success-pay/SuccessPay';
import Basket from 'components/pages/basket/';
import Dashboard from 'components/pages/dashboard/';
import Page404 from '../pages/404/Page404';
import NuitrackInfo from '../pages/info/modules/NuitrackInfo';
import FaceInfo from '../pages/info/modules/FaceInfo';
import FaceMachineInfo from '../pages/info/modules/FaceMachineInfo';
import FacePreInfo from '../pages/pre-info/modules/FacePreInfo';
import NuitrackPreInfo from '../pages/pre-info/modules/NuitrackPreInfo';

import AddFunds from '../modals/AddFundsModal/AddFunds';
import NuitrackAnalyticsInfo from '../pages/info/modules/NuitrackAnalyticsInfo';

const SITE_URL = `${BASE_URL}/:platform(nuitrack|face|face-machine|nuitrack-analytics)/`;

function PlatformSite() {
  const params = useParams();
  return (
    <Switch>
      {/* basket have routes */}
      <Route path={`${SITE_URL}basket/`}>
        <Basket />
      </Route>
      <Route exact path={`${SITE_URL}license-purchase-success/`}>
        <SuccessPay platform={params.platform} purchase="license" />
      </Route>
      <Route exact path={`${SITE_URL}balance-purchase-success/`}>
        <SuccessPay platform={params.platform} purchase="balance" />
      </Route>
      <Route exact path={`${SITE_URL}dashboard/`}>
        <Dashboard />
      </Route>
      <Route exact path={`${SITE_URL}dashboard/:id/(info|pre-info)/`}>
        <Dashboard />
        <Switch>
          <Route
            path={`${SITE_URL}dashboard/:id/info/`}
            render={({ match }) => {
              switch (match.params.platform) {
                case 'nuitrack':
                  return <NuitrackInfo />;
                case 'face':
                  return <FaceInfo />;
                case 'face-machine':
                  return <FaceMachineInfo />;
                case 'nuitrack-analytics':
                  return <NuitrackAnalyticsInfo />;
                default:
                  return;
              }
            }}
          />
          <Route
            path={`${SITE_URL}dashboard/:id/pre-info/`}
            render={({ match }) => {
              switch (match.params.platform) {
                case 'nuitrack':
                  return <NuitrackPreInfo />;
                case 'face':
                  return <FacePreInfo />;
                // there is no groups for face-machine & nuitrack-analytics,
                // just return this component to perform redirect to correct platform route
                case 'face-machine':
                  return <FacePreInfo />;
                case 'nuitrack-analytics':
                  return <FacePreInfo />;
                default:
                  return;
              }
            }}
          />
        </Switch>
      </Route>
      <Route exact path={`${SITE_URL}add-funds/`}>
        <AddFunds platform={params.platform} />
      </Route>
      <Route component={Page404} />
    </Switch>
  );
}

export default PlatformSite;
