import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography, Box } from '@3divi/baseui';

import { ButtonModal } from '../../../../modals/buttons';
import BtnAdditionalText from '../../../../elements/BtnAdditionalText';

const StyledLicense = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background: ${({ theme }) => theme.background.default};
  border: 1px solid ${({ theme }) => theme.border.default};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;

  ${props => props.priority && 'box-shadow: 0px 0px 48px hsla(0,0%,0%,.08)'};

  @media (max-width: 720px) {
    padding: 8px 24px;
  }
`;

const LicenseTitle = styled.div`
  padding: 12px 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  line-height: 20px;
  padding: 12px 0 20px;
`;

LicenseView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.element,
  button: PropTypes.element,
  priority: PropTypes.bool,
  lists: PropTypes.object,
};

function LicenseView({ title, subtitle, price, button, priority, lists, periodToggle, ...other }) {
  const getButton = btn => {
    switch (btn.type) {
      case 'ButtonModal':
        return <ButtonModal {...btn.props} />;
      case 'BtnAdditionalText':
        return <BtnAdditionalText {...btn.props} />;
      default:
        return;
    }
  };
  return (
    <StyledLicense priority={priority} {...other}>
      {title && <LicenseTitle>{title}</LicenseTitle>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {price && (
        <Box mb={1}>
          <Typography size="32" height="48" weight="700">
            {price.price}
          </Typography>
          <Typography>{price.text}</Typography>
        </Box>
      )}
      {button && getButton(button)}
    </StyledLicense>
  );
}

export default LicenseView;
