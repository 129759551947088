import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItem } from '@3divi/baseui';
import { MENU } from 'Config';
import tr from 'locale';
import { useSelector } from 'react-redux';
import { getDataSelector } from '../../hooks';

function MenuNav(props) {
  const { pathname, ...other } = props;

  const isLicensesLoaded = useSelector(state => state.licenses.fetchLicenses.success);
  const faceMachineLicenses = useSelector(state =>
    getDataSelector(state.licenses, 'licenses', 'face-machine')
  );

  return MENU.filter(
    el =>
      el.title !== 'nuitrack_face_machine' || (isLicensesLoaded && faceMachineLicenses.length !== 0)
  ).map(el => (
    <MenuItem
      {...el}
      key={el.icon}
      as={NavLink}
      text={tr(el.title)}
      title={tr(el.title)}
      to={`${el.link}`}
      target={el.link.indexOf('/api/service/redirect/') >= 0 ? '_blank' : ''}
    />
  ));
}

function checkPath(elLink, currentPath) {
  return currentPath.includes(elLink);
}

export default MenuNav;
