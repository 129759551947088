import React from 'react';
import {
  licenseBuyAction,
  upgradeLicenseAction,
  upgradeGroupLicenseAction,
} from '../../../../../../store/licenseBuy/actions';
import { useDispatch, useSelector } from 'react-redux';
import PayStripeButton from '../../../../../elements/PayStripeButton';
import { useStripe } from '@stripe/react-stripe-js';

function PayStripe(props) {
  const { disabled, toBuyProducts, platform, toUpgradeId, checkoutType } = props;
  const stripe = useStripe();

  const dispatch = useDispatch();
  const promocode = useSelector(state => state.basket.validatePromocode.promocode);

  const onClick = () => {
    if (checkoutType === 'upgrade') {
      return dispatch(
        upgradeLicenseAction(toUpgradeId, { platform, paymentMethod: 'stripe', stripe })
      );
    } else if (checkoutType === 'upgrade-y') {
      return dispatch(
        upgradeGroupLicenseAction(toUpgradeId, { platform, paymentMethod: 'stripe', stripe })
      );
    } else {
      return dispatch(
        licenseBuyAction(toBuyProducts, {
          platform,
          paymentMethod: 'stripe',
          stripe,
          isSubscribe: checkoutType === 'subscriptions',
          promocode,
        })
      );
    }
  };

  return <PayStripeButton disabled={disabled} onClick={onClick} />;
}

export default PayStripe;
