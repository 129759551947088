import { handleActions } from 'redux-actions';

const initialState = {
  license: null,
  loading: false,
  licenseError: null,
  basket: [],
  createLicense: {},
  createLicenseFromGroup: {},
};

const reducer = handleActions(
  {
    LICENSE_ACTION_REQUEST: (state, action) => {
      return {
        ...state,
        loading: true,
        licenseError: '',
        errorCode: undefined,
      };
    },
    LICENSE_ACTION_SUCCESS: (state, action) => {
      return {
        ...state,
        license: action.payload && action.payload.license,
        loading: false,
        success: true,
      };
    },
    LICENSE_ACTION_FAIL: (state, action) => {
      return {
        ...state,
        licenseError: action.payload.error
          ? action.payload.error
          : 'The server will not be responding. Please try again.',
        errorCode: action.payload.errorCode || 'internal_server_error',
        loading: false,
        success: false,
      };
    },
    CLEAR_LICENSE_ACTION: (state, action) => {
      return { ...initialState };
    },
    CREATE_LICENSE_REQUEST: (state, action) => {
      return {
        ...state,
        createLicense: {
          loading: true,
        },
      };
    },
    CREATE_LICENSE_SUCCESS: (state, action) => {
      const { license } = action.payload;
      return {
        ...state,
        createLicense: {
          success: true,
          license,
        },
      };
    },
    CREATE_LICENSE_FAILURE: (state, action) => {
      return {
        ...state,
        createLicense: {
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
    CREATE_LICENSE_FROM_GROUP_REQUEST: (state, action) => {
      return {
        ...state,
        createLicenseFromGroup: {
          loading: true,
        },
      };
    },
    CREATE_LICENSE_FROM_GROUP_SUCCESS: (state, action) => {
      return {
        ...state,
        createLicenseFromGroup: {
          license: action.payload.license,
          success: true,
        },
      };
    },
    CREATE_LICENSE_FROM_GROUP_FAILURE: (state, action) => {
      return {
        ...state,
        createLicenseFromGroup: {
          licenseError: action.payload.error
            ? action.payload.error
            : 'The server will not be responding. Please try again.',
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
  },
  initialState
);

export default reducer;
