import React, { useCallback } from 'react';
import ModalSubscriptionManagement from '../ModalSubscriptionManagement/ModalSubscriptionManagement';
import { useDispatch } from 'react-redux';
import { handlePause } from '../../../store/licenseInfo/actions';

function SubscriptionDeactivateModal({ license, ...other }) {
  const dispatch = useDispatch();
  const handlePauseAction = useCallback(() => dispatch(handlePause(license.id)), [license.id]);

  return (
    <ModalSubscriptionManagement
      name="deactivate"
      license={license}
      action={handlePauseAction}
      {...other}
    />
  );
}

export default SubscriptionDeactivateModal;
