import React from 'react';
import styled from 'styled-components';
import { Icon } from '@3divi/baseui';

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  left: 2px;
`;

const StyledAccountLink = styled.a`
  display: block;
  position: relative;
  padding: 6px 0 6px 32px;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:focus {
    color: ${({ theme }) => theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.link.hover};
  }
`;

class AccountLink extends React.Component {
  render() {
    return (
      <StyledAccountLink href={this.props.href} onClick={this.props.handle}>
        {this.props.text}
        <StyledIcon icon={this.props.icon} color="primary" />
      </StyledAccountLink>
    );
  }
}

AccountLink.defaultProps = { handle: () => {} };

export { AccountLink };
