import React, { useState } from 'react';
import tr from 'locale';
import { OptionsEdit } from './modals';
import { Section, Button, RadioOrCheckbox } from '@3divi/baseui';

function Notifications(props) {
  const [modalOpen, setModalOpen] = useState(false);
  let notif = props.accountInfo.options;
  return (
    <>
      {modalOpen && (
        <OptionsEdit hide={() => setModalOpen(!modalOpen)} accountInfo={props.accountInfo} />
      )}
      <Section
        title={tr('notifications_header')}
        buttons={<Button round iconLeft="pen" onClick={() => setModalOpen(!modalOpen)} />}
      >
        <div className="account__list">
          <RadioOrCheckbox
            type="checkbox"
            title={tr('notification_news')}
            checked={notif.news_notification}
            disabled
          />
          <RadioOrCheckbox
            type="checkbox"
            title={tr('notification_billing')}
            checked={notif.bills_mail}
            disabled
          />
          {/* <AccountCheckbox text={tr("notification_autofill")} checked={notif.balance_autofill} disabled /> */}
          {/* <AccountCheckbox text={tr("notification_updates")} checked={notif.updates_notification} disabled /> */}
        </div>
      </Section>
    </>
  );
}

export default Notifications;
