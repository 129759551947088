import React, { Component } from 'react';
import { Row, Box } from '@3divi/baseui';

class LicensesWrap extends Component {
  render() {
    const { children } = this.props;
    return (
      <Box mx={-2}>
        <Row>{children}</Row>
      </Box>
    );
  }
}

export default LicensesWrap;
