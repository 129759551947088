import React from 'react';
import styled from 'styled-components';
import { Col, Icon } from '@3divi/baseui';
import tr from 'locale';

const SectionBox = styled.div`
  display: block;
  overflow: hidden;
  background: ${({ theme }) => theme.background.default};
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: hsla(0, 0%, 0%, 0.04) 0px 0px 1px 1px;

  &:hover {
    box-shadow: hsla(0, 0%, 0%, 0.06) 0px 4px 16px 1px;
  }

  &:focus {
    box-shadow: ${({ theme }) => theme.background.brand} 0 0 0 2px;
  }
`;

const StyledHelpLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  min-height: 80px;
  padding: 16px 0 16px 48px;
  color: ${({ theme, color }) => theme.text[color]};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 4px;
  top: 4px;
`;

const HelpLinkContent = styled.div`
  position: relative;
  padding: 0 24px;
  background-color: ${({ theme, color }) => theme.background[color]};

  &:hover {
    cursor: pointer;
  }
`;

const texts = {
  buy: tr('buy-license'),
  support: tr('help_link_support'),
  doc: tr('help_link_doc'),
  forum: tr('help_link_forum'),
};

function HelpLink(props) {
  const { type, link, variants } = props;
  const { as, to, href, target } = link;
  return (
    <Col xl={3} l={3} m={4} s={6} xs={12} xxs={12}>
      <SectionBox as={as} target={target} href={href ? href : null} to={to ? to : null}>
        <HelpLinkContent color={variants ? 'brand' : 'default'}>
          {target === '_blank' && (
            <StyledIcon icon="link" size="xxs" color={variants ? 'light' : 'default'} />
          )}
          <StyledHelpLink
            className={`help-links__ico help-links__ico_${type}`}
            color={variants ? 'light' : 'default'}
            target={target}
          >
            {texts[type]}
          </StyledHelpLink>
        </HelpLinkContent>
      </SectionBox>
    </Col>
  );
}

export default HelpLink;
