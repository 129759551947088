import React from 'react';
import styled from 'styled-components';

const Title = styled.h3`
  padding: 16px 8px;
  text-align: center;
  font: ${({ theme }) => theme.font.xxl};
  font-weight: 500;
  color: ${({ theme }) => theme.text.default};

  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 32px;
    padding: 8px 8px 16px;
  }
`;

function PageTitle(props) {
  const { title, ...other } = props;
  return <Title {...other}>{title}</Title>;
}

PageTitle.defaultProps = {
  title: '',
};

export default PageTitle;
