import React from 'react';
import styled from 'styled-components';
import { Icon } from '@3divi/baseui';

const IconWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.background.brand};
  box-shadow: inset 9px -8px 24px 0 hsla(120, 48%, 48%, 1);
`;

const Row = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
`;

function SuccessIcon(props) {
  return (
    <Row>
      <IconWrap>
        <Icon size="modal" color="hsla(0, 100%, 100%, 1)" icon="mark" />
      </IconWrap>
    </Row>
  );
}

export default SuccessIcon;
