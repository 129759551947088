import moment from 'moment';
import { getPrice } from '../../helpers/getPrice';
import getDefaultTitle from './getDefaultTitle';
import formatDate from '../../FormatDate';
import { getUpgradeable } from './getUpgradeable';

const subscribeProducts = [
  'fmc_subscribe',
  'na_subscribe',
  'face_online_custom_subscribe',
  'nuitrack_online_subscription_monthly',
  'nuitrack_online_subscription_yearly',
  'face_online_subscribe',
  'nuitrack_ai_subscription_monthly',
  'nuitrack_ai_subscription_yearly',
];
const licenseSubscriptionProducts = [
  'fmc_subscribe',
  'na_subscribe',
  'face_online_custom_subscribe',
  'nuitrack_online_subscription_monthly',
  'nuitrack_online_subscription_yearly',
  'nuitrack_online',
  'face_online_subscribe',
  'nuitrack_ai_subscription_monthly',
  'nuitrack_ai_subscription_yearly',
];
const onlyCard = [
  'nuitrack_online_subscription_monthly',
  'nuitrack_online_subscription_yearly',
  'face_online_subscribe',
  'nuitrack_ai_subscription_monthly',
  'nuitrack_ai_subscription_yearly',
];

const getSubscriptionStatus = license => {
  if (
    (license.config.product === 'fmc_subscribe' || license.config.product === 'na_subscribe') &&
    (license.config.tag === 'FMC Trial' || license.config.tag === 'NA Trial') &&
    !license.config.upgraded
  )
    return;
  const isLicenseExpired = moment(new Date().toISOString()).isAfter(license.expire_date);
  if (license.config.product === 'nuitrack_online' && isLicenseExpired) return 'cancelled';
  if (license.config.paused && isLicenseExpired) return 'cancelled';
  if (license.config.paused) return 'paused';
  return 'active';
};

const getLicenseStatus = (license, isLicenseSubscription) => {
  if (license.status === 2) return 'holded';
  if (moment(new Date().toISOString()).isAfter(license.expire_date)) {
    return 'suspended';
  }
  if (license.status === 1) return 'suspended';
  if (!isLicenseSubscription && !license.activation_date) return 'new';
  else return 'active';
};

export function getLicense(license, { prices, isCustomGroupMap }) {
  var signature =
    license.activations &&
    license.activations.length > 0 &&
    license.activations[license.activations.length - 1].signature_properties;
  var signature_properties =
    signature && `${signature.serial || '-'} / ${signature.sensor_name || '-'}`;
  let isSubscription = license && subscribeProducts.includes(license.config.product);
  const isLicenseSubscription =
    license && licenseSubscriptionProducts.includes(license.config.product);
  let isCard = license && onlyCard.includes(license.config.product);
  let diffDate = Math.abs(moment(license.created_date).diff(license.expire_date, 'month'));
  const today = moment();

  return {
    ...license,
    created_date: formatDate(license.created_date),
    expire_date: license.expire_date ? formatDate(license.expire_date) : null,
    activation_date: license.activation_date ? formatDate(license.activation_date) : null,
    isSubscription: isSubscription,
    isTrial: license.config.tag && !license.config.upgraded,
    isCard: isCard,
    fromCustomGroup: isCustomGroupMap[license.group],
    upgradeable: getUpgradeable(license),
    lifetime: diffDate >= 11 ? 'year' : 'month',
    dates: {
      createdDate: license.created_date ? moment(license.created_date) : null,
      activationDate: license.activation_date ? moment(license.activation_date) : null,
      expireDate: license.expire_date ? moment(license.expire_date) : null,
      days: -today.diff(license.expire_date, 'days'),
    },
    price: license && prices && getPrice(license, prices),
    serial_sensor: signature_properties,
    title: license.title ? license.title : `${getDefaultTitle(license)} #${license.id}`,
    license_status: getLicenseStatus(license, isLicenseSubscription),
    subscription_status: isLicenseSubscription ? getSubscriptionStatus(license) : undefined,
  };
}
