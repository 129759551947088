import styled from 'styled-components';
import { LICENSESERVER } from '@3divi/baseui';
import FormGroup from 'components/elements/FormGroup/';

const LicenseGroup = styled(FormGroup)`
  padding: 16px 24px;
  margin-bottom: 0;
  border: 1px solid ${LICENSESERVER.border.default};
  border-bottom: unset;

  &:nth-child(1) {
    border-top: 1px solid ${LICENSESERVER.border.default};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:nth-last-child(1) {
    border-bottom: 1px solid ${LICENSESERVER.border.default};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export default LicenseGroup;
