import React from 'react';
import tr from 'locale';
import Header from 'Header';
import { Input, Button } from '@3divi/baseui';
import { ButtonLine, Extra, StyledLoginContent, LoginTitle } from './styled/components';

function ForgotPasswordView(props) {
  const { inputs, buttons } = props;

  return (
    <StyledLoginContent>
      <Header title={tr('tabtitle_password_recovery')} />
      <LoginTitle>{tr('forgot_your_pass_q')}</LoginTitle>
      <form>
        <Input
          {...inputs.email}
          size="m"
          variant="column"
          autoFocus={true}
          type="text"
          name="email"
          label={tr('user_email')}
          maxlength={151}
        />
        <ButtonLine>
          <Button
            {...buttons.submit}
            tabIndex="2"
            color="primary"
            elastic
            size="m"
            variant="contained"
            text={tr('submit')}
          />
        </ButtonLine>
      </form>
      <Extra>
        {tr('no_account_q')}
        {'\u00A0\u00A0'}
        <Button
          {...buttons.toRegistration}
          tabIndex="3"
          text={tr('register_button')}
          color="primary"
          variant="clear"
        />
        <div />
        {tr('signup_already')}
        {'\u00A0\u00A0'}
        <Button
          {...buttons.toLogin}
          tabIndex="7"
          variant="clear"
          color="primary"
          text={tr('login_button')}
        />
      </Extra>
    </StyledLoginContent>
  );
}

export default ForgotPasswordView;
