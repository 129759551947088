import { createAction } from 'redux-actions';
import { getChangelogData } from 'api';

export const getChangelogAction = () => {
  // should have no errors
  return async dispatch => {
    const response = await getChangelogData();
    dispatch(getChangelogSuccess(response));
  };
};

const getChangelogSuccess = createAction('GET_CHANGELOG_SUCCESS');
