import React, { useMemo } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import BtnAdditionalText from 'components/elements/BtnAdditionalText/';
import BtnCreateLicenseAndRedirect from '../../../../elements/BtnCreateLicenseAndRedirect';
import { WithIcon } from '@3divi/baseui';
import { getProductName, getLicenseType } from '../../../../../helpers';

const StyledA = styled.a`
  overflow-wrap: break-word;
  color: ${props => props.theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.link.hover};
  }

  &:focus {
    color: ${props => props.theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.link.hover};
  }
`;

const useGroups = (groupsBuyLink, groupsStore, platform, loaded) => {
  const groups = useMemo(() => getGroups(groupsBuyLink, groupsStore, platform), [groupsStore]);

  return groups;
};

function getGroups(groupsBuyLink, groupsStore, platform) {
  if (!groupsStore) return [];
  return groupsStore
    .filter(el => el.is_default)
    .map(group => {
      let buyLink = groupsBuyLink[group.config.product];

      const titleProduct = tr(getProductName(group)); // group.type can be 'face' or 'nuitrack'

      const titleType = getLicenseType(group);
      let titleTypeResult = titleType;
      if (group.config.product === 'tvico') {
        titleTypeResult = (
          <StyledA forwardedAs="a" target="_blank" href="https://tvico.io/">
            <WithIcon icon="link" size="xxs" color="hsla(120,45%,56%,1)">
              {titleType}
            </WithIcon>
          </StyledA>
        );
      }

      const title = (
        <>
          {`${titleProduct} | `}
          {titleTypeResult}
        </>
      );

      return {
        title: title,
        availableLicenses: group.limit,
        id: group.id,
        config: { groupId: group.id },
        button: getBtn(group, buyLink, platform),
        reactKey: `${titleProduct}${titleType}`,
      };
    });
}

function getBtn(group, buyLink, platform) {
  if (group.limit > 0) {
    return {
      component: BtnCreateLicenseAndRedirect,
      props: {
        btn: {
          text: tr('generate'),
          group,
        },
      },
    };
  }
  if (buyLink && buyLink.component) {
    return {
      component: buyLink.component,
      props: {
        ...buyLink.props,
        text: tr('buy'),
        color: 'hsla(120,48%,56%,1)',
      },
    };
  }
  if (buyLink && buyLink.link) {
    let linkPath = buyLink.link.to && `/app/${platform}${buyLink.link.to}`;
    let toProp = {
      pathname: linkPath,
      state: {
        addLicenseType: buyLink.link.autoAddLicense ? group.months : undefined,
        ...buyLink.link.state,
      },
    };
    let text = tr('buy');
    if (buyLink.link.href)
      text = (
        <WithIcon icon="link" size="xxs" color="hsla(120,45%,56%,1)">
          {tr('buy')}
        </WithIcon>
      );
    return {
      component: BtnAdditionalText,
      props: {
        btn: {
          forwardedAs: buyLink.link.as,
          href: buyLink.link.href && buyLink.link.href,
          to: toProp,
          text: text,
          target: buyLink.link.href && '_blank',
        },
      },
    };
  }
}

export default useGroups;
