// Hooks
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// components
import ReleaseInfoModalView from './ReleaseInfoModalView';

function ReleaseInfoModal(props) {
  const [updated, setUpdated] = useState(false);

  const success = useSelector(state => state.changelog.success);
  const changelogs = useSelector(state => state.changelog.changelogs);

  return (
    <ReleaseInfoModalView
      {...props}
      changelogs={updated ? changelogs : changelogs.slice(0, 2)}
      moreButton={
        changelogs.length > 2 && !updated ? { onClick: () => setUpdated(true) } : undefined
      }
      loading={!success}
    />
  );
}

export default ReleaseInfoModal;
