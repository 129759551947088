import React, { useState, useEffect, useRef } from 'react';
import tr from 'locale';
import styled, { ThemeProvider } from 'styled-components';

import {
  FACEMACHINE,
  BulletedList,
  P,
  Button,
  Typography,
  LICENSESERVER,
  TEXT_COLORS,
  FONTS,
  Input,
} from '@3divi/baseui';
import ActivationTabs from '../FaceActivateModal/ActivationTabs';
import TaggedText from '../../elements/TaggedText';
import { useDispatch } from 'react-redux';
import { handleOpenModal, handleHideModal } from 'store/modals/actions';
import ModalComponent from '../ModalComponent';
import FileLinkB64 from '../../elements/FileLinkB64';
import StyledLink from '../../elements/StyledLink';
import FaceUSBTokenActivateWarning from '../FaceUSBTokenActivateWarning';

const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${TEXT_COLORS.default};
  width: 18px;
  height: 18px;
  margin: 1px;
  box-sizing: border-box;
  border-radius: 50%;

  &:before {
    content: '${props => props.number}';
    font: ${FONTS.xxs};
    font-weight: 500;
  }
`;

const Steps = styled.div`
  padding-top: 14px;
`;

const Step = styled.div`
  width: 100%;
`;

const Code = styled.code`
  display: inline-block;
  position: relative;
  font-family: 'Menlo', 'DejaVu Sans Mono', 'Liberation Mono', 'Consolas', 'Ubuntu Mono',
    'Courier New', 'andale mono', 'lucida console', monospace;
  font-size: 13px;
  line-height: 16px;
  padding: 2px 4px 2px;
  color: ${({ theme }) => theme.text.primary};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    border-radius: 4px;
    background: ${({ theme }) => theme.background.contrast};
    opacity: 0.05;
  }
`;

function FaceUSBTokenActivateModal(props) {
  const {
    title = tr('face_sdk_license_activation'),
    subtitle,
    license,
    platform,
    ...other
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  const dispatch = useDispatch();
  const [newLicense, setNewLicense] = useState();
  const [autoAction, setAutoAction] = useState(true); // by default FileLinkB64 must have autoAction equal true
  const [token, setToken] = useState('');
  useEffect(() => {
    if (newLicense) {
      dispatch(handleHideModal('activation_usb_face_warning'));
      // after first apear FileLinkB64 (and action emit) need to set flag to false,
      // because switch tabs will mount/unmount components
      setAutoAction(false);
    }
  }, [newLicense]);

  const onDownloadClick = () => {
    dispatch(
      handleOpenModal({
        component: FaceUSBTokenActivateWarning,
        name: 'activation_usb_face_warning',
        license,
        onActivateLicense: setNewLicense,
        usbToken: token,
      })
    );
  };

  return (
    <ModalComponent isOpen title={title} subtitle={subtitle} fixed={448} {...other}>
      <ActivationTabs onlyDesktop={true} />
      <Steps>
        <BulletedList indent={2} bullet={<Bullet />}>
          <P>
            <ThemeProvider theme={FACEMACHINE}>
              <TaggedText text={tr('activate_offline_usb_instruction_1')} code={Code} />
            </ThemeProvider>
          </P>
          <Step>
            <P padding="0 0 12px">
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('activate_offline_usb_instruction_2')} code={Code} />
              </ThemeProvider>
            </P>
          </Step>
          <Step>
            <P padding="0 0 12px">
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('activate_offline_usb_instruction_3')} code={Code} />
              </ThemeProvider>
            </P>
            <Input
              innerRef={ref}
              placeholder="senselock000000000000000b"
              maxlength={256}
              value={token}
              onBlur={() => {
                const trimmed = token.trim();
                if (token !== trimmed) setToken(trimmed);
              }}
              onChange={setToken}
            />
          </Step>
          <Step>
            <P padding="0 0 12px">
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('activate_offline_usb_instruction_4')} code={Code} />
              </ThemeProvider>
            </P>
            {newLicense ? (
              <FileLinkB64
                text={tr('button_download')}
                color={token ? LICENSESERVER.default : 'hsla(0,0%,92%,1)'}
                elastic
                theme="contained"
                size="m"
                autoAction={autoAction}
                fileName="3divi_face_sdk.lic"
                license={newLicense}
              />
            ) : (
              <Button
                text={tr('button_download')}
                color={token ? LICENSESERVER.default : 'hsla(0,0%,92%,1)'}
                disabled={!token}
                elastic
                theme="contained"
                size="m"
                onClick={onDownloadClick}
              />
            )}
          </Step>
          <Step>
            <P padding="0 0 12px">
              <ThemeProvider theme={FACEMACHINE}>
                <TaggedText text={tr('activate_offline_usb_instruction_5')} code={Code} />
              </ThemeProvider>
            </P>
            <Typography align="center">
              <P padding="32px 0 0">
                <StyledLink
                  href="https://docs.facesdk.3divi.com/docs/licenses#locking-to-a-usb-token"
                  target="_blank"
                  rel="noopener noreffer"
                >
                  {tr('activate_offline_details_link')}
                </StyledLink>
              </P>
            </Typography>
          </Step>
        </BulletedList>
      </Steps>
    </ModalComponent>
  );
}

export default FaceUSBTokenActivateModal;
