import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Button } from '@3divi/baseui';
import tr from 'locale';
import { useWindowSize } from '../../../hooks';

const StyledInfoEdit = styled.form`
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border.default};
  background-color: ${({ theme }) => theme.background.default};
  padding: 8px 16px 0;

  @media (max-width: 720px) {
    margin-left: -32px;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 8px 16px 8px 144px;
  margin: 8px -16px 0;
  border-radius: 0 0 4px 4px;
  background: ${({ theme }) => theme.background.primary};

  & > button {
    margin: 0 4px;
  }

  @media (max-width: 720px) {
    padding: 8px;
  }
`;

function EditableTitleView(props) {
  const { input, cancel, onSubmit, loading, infoKey } = props;
  const [small, setSmall] = useState(false);
  const [width] = useWindowSize();

  const cancelSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    let isSmall = width <= 720;
    setSmall(isSmall);
  }, [width]);

  return (
    <StyledInfoEdit onSubmit={loading ? cancelSubmit : onSubmit}>
      <Input
        autoFocus={true}
        theme={small && 'column'}
        name={infoKey}
        label={tr(infoKey)}
        maxlength={9999}
        {...input}
      />
      <Buttons>
        <Button color="primary" variant="contained" text={tr('save_button')} loading={loading} />
        <Button onClick={cancel} text={tr('cancel_button')} />
      </Buttons>
    </StyledInfoEdit>
  );
}

export default EditableTitleView;
