import React from 'react';
import styled from 'styled-components';
import { WithIcon, Typography } from '@3divi/baseui';

const Wrap = styled.div`
  display: flex;
  width: 100%;
`;

const TextWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  border-top: 1px solid ${({ theme }) => theme.border.default};

  ${props =>
    props.left &&
    `
    max-width: 172px;
    width: 100%;
    padding-right: 16px;
    font: ${({ theme }) => theme.font.s};

    &:before {
      position: absolute;
      content: '';
      background-image: linear-gradient(to right, ${props.theme.background.default} 0%, ${
      props.theme.background.contrast
    } 100%);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: .03;
    }
  `}

  ${props =>
    props.right &&
    `
    width: 100%;
    padding-left: 16px;
  `}
`;

const StyledA = styled.a`
  font: ${({ theme }) => theme.font.xs};
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:focus {
    color: ${({ theme }) => theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.link.hover};
  }

  &:focus > * {
    color: ${({ theme }) => theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.link.hover};
  }
`;

function LicenseActionBlock(props) {
  const { left, right } = props;

  return (
    <Wrap>
      <TextWrap left>
        <Wrap>
          <Typography>{left.title}</Typography>
        </Wrap>
        {(left.link || left.subtitle) && (
          <Wrap>
            <StyledA target="_blank" href={left.link}>
              <WithIcon icon="link" size="xxs" color="primary">
                {left.subtitle}
              </WithIcon>
            </StyledA>
          </Wrap>
        )}
      </TextWrap>
      <TextWrap right>{right}</TextWrap>
    </Wrap>
  );
}

export default LicenseActionBlock;
