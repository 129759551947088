import React from 'react';
import styled from 'styled-components';

const StyledStepper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-top: 24px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${props => (props.active ? '3px' : '50%')};
  background: ${props => (props.active ? props.theme.background.brand : 'hsla(0,0%,88%,1)')};
  margin: 0 6px;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.background.brand};
  }
`;

function Stepper(props) {
  const { step, steps, setStep, ...other } = props;
  return (
    <StyledStepper>
      {steps.map((el, i) => (
        <Dot key={i + steps} active={i === step - 1} onClick={e => setStep(i + 1)} />
      ))}
    </StyledStepper>
  );
}

export default Stepper;
