import { handleActions } from 'redux-actions';

const initialState = () => ({
  currentStep: 1,
  maxBasketSteps: 2,
  basket: {
    totalSum: 0,
    products: [],
    productsMap: {},
  },
  validatePromocode: {},
});

const reducer = handleActions(
  {
    ADD_TO_CART: (state, action) => {
      const { config, limit, price, cartTitle, cartSubtitle } = action.payload;
      let productKey = `${config.product}_${config.license_expire_delta}`.toLowerCase();
      const productsMap = state.basket.productsMap;

      if (productsMap[productKey]) {
        if (limit === 0) delete productsMap[productKey];
        else
          productsMap[productKey] = {
            ...productsMap[productKey],
            limit: limit,
          };
      } else
        productsMap[productKey] = {
          config,
          limit: limit || 1, // action can be called with just product info, so add one of it to cart
          title: cartTitle,
          subtitle: cartSubtitle,
          price,
          id: productKey,
        };

      const products = Object.values(productsMap);

      let totalSum = 0;
      products.forEach(el => {
        totalSum += el.price * el.limit;
      });

      return {
        ...state,
        basket: {
          totalSum: totalSum.toFixed(2),
          products: products,
          productsMap: productsMap,
        },
      };
    },
    CLEAR_BASKET: () => {
      return initialState();
    },
    CLEAR_CART: (state, action) => {
      return {
        ...state,
        basket: {
          totalSum: '0.00',
          products: [],
          productsMap: {},
        },
      };
    },
    SET_STEP: (state, action) => {
      const { step } = action.payload;

      let currentStep = step;
      if (step < 1) currentStep = 1;
      if (step > state.maxBasketSteps) currentStep = state.maxBasketSteps;

      return {
        ...state,
        currentStep,
      };
    },
    CLEAR_PROMOCODE: (state, action) => {
      return {
        ...state,
        validatePromocode: {},
      };
    },
    VALIDATE_PROMOCODE_SUCCESS: (state, action) => {
      const { promocode, discount } = action.payload;

      return {
        ...state,
        validatePromocode: {
          success: true,
          promocode,
          discount,
        },
      };
    },
  },
  initialState()
);

export default reducer;
