import { useSelector } from 'react-redux';

export const usePaypalOptions = isSubscribe => {
  const paypalMerchantId = useSelector(state => state.clientdata.paypalMerchantId);
  const paypalClientId = useSelector(state => state.clientdata.paypalClientId);
  return paypalMerchantId && paypalClientId
    ? {
        'client-id': paypalClientId,
        'disable-funding': 'credit,card',
        locale: 'en_US',
        'merchant-id': paypalMerchantId,
        intent: isSubscribe ? 'subscription' : 'capture',
        vault: isSubscribe ? 'true' : 'false',
      }
    : undefined;
};
