import React from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { BASE_URL } from 'Config';
import tr from 'locale';
import Header from 'Header';
import { Input, Button } from '@3divi/baseui';
import { ButtonLine, Extra, StyledLoginContent, LoginTitle } from './styled/components';

const ForgotLink = styled(Link)`
  display: inline-block;
  font: ${({ theme }) => theme.font.s};
  margin-top: 4px;
  color: ${({ theme }) => theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.link.hover};
  }

  &:focus {
    color: ${({ theme }) => theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.link.hover};
  }
`;

function LoginView(props) {
  const { platform, inputs, buttons } = props;
  if (props.auth) {
    return <Redirect to={`${BASE_URL}/${platform}/`} />;
  }
  return (
    <StyledLoginContent>
      <Header title={tr('tabtitle_login')} />
      <LoginTitle>{tr('login_title')}</LoginTitle>
      <form>
        <Input
          {...inputs.login}
          size="m"
          variant="column"
          type="text"
          name="username"
          label={tr('login_email_label')}
          autoFocus={true}
          maxlength={151}
        />
        <Input
          {...inputs.password}
          size="m"
          variant="column"
          type="password"
          name="password"
          label={tr('login_password_label')}
          maxlength={257}
        />
        <ForgotLink tabIndex="4" {...buttons.toForgotPassword}>
          {tr('forgot_pass_q')}
        </ForgotLink>
        <ButtonLine>
          <Button
            {...buttons.submit}
            tabIndex="3"
            variant="contained"
            elastic
            size="m"
            color="primary"
            text={tr('login_button')}
          />
        </ButtonLine>
      </form>
      <Extra>
        {tr('no_account_q')}
        {'\u00A0\u00A0'}
        <Button
          {...buttons.toRegistration}
          tabIndex="5"
          color="primary"
          variant="clear"
          text={tr('register_button')}
        />
      </Extra>
    </StyledLoginContent>
  );
}

export default LoginView;
