import React from 'react';
import styled from 'styled-components';
import { Button, isLight, Tooltip, WithIcon } from '@3divi/baseui';
import { themeDefault } from 'components/themes/themeDefault';

const StyledTrial = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  border: 1px solid hsla(0, 0%, 88%, 1);
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;

const TrialHeader = styled.div`
  flex: 1 1 0;
  padding-right: 16px;
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.secondary};
  padding-top: 4px;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.font.l};
  font-weight: 800;
  color: ${({ theme }) => theme.text.default};
`;

const TitleBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.font.xs};
  color: ${props => (isLight(props.nameColor) ? props.theme.text.dark : props.theme.text.light)};
  background: ${props => props.nameColor};
  border-radius: 4px;
  padding: 4px 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 8px;
`;

function Trial(props) {
  const { title, name, nameColor, subtitle, tooltip, button, ...other } = props;
  return (
    <StyledTrial>
      <TrialHeader>
        <TitleBox>
          <Title>{title}</Title>
          {name && <Name nameColor={nameColor}>{name}</Name>}
        </TitleBox>
        {subtitle && (
          <Subtitle>
            {subtitle}
            {tooltip && (
              <Tooltip
                width="240"
                side="top-center"
                tooltipColor={themeDefault.whiteColor}
                text={tooltip}
              >
                <WithIcon icon="info" color="primary"></WithIcon>
              </Tooltip>
            )}
          </Subtitle>
        )}
      </TrialHeader>
      {button && (
        <Button
          size="m"
          variant={button.priority ? 'contained' : 'outline'}
          color="primary"
          {...button.props}
        />
      )}
    </StyledTrial>
  );
}

Trial.defaultProps = {
  title: '',
  name: '',
  nameColor: 'hsla(0,0%,92%,1)',
  subtitle: '',
  tooltip: '',
};

export default Trial;
