import React from 'react';
import tr from 'locale';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'Config';
import { Button } from '@3divi/baseui';
import { StyledLoginContent, LoginTitle } from './styled/components';

function CognitiveInfo(props) {
  const { title, text, locationState, button } = props;
  return (
    <StyledLoginContent>
      <LoginTitle>{title}</LoginTitle>
      <form>
        {typeof text === 'string' ? (
          <div className="form-container__succes-text">{text}</div>
        ) : (
          text.map(el => (
            <div key={el} className="form-container__succes-text">
              {el}
            </div>
          ))
        )}
        <Button
          as={Link}
          tabIndex="1"
          variant="contained"
          elastic
          size="m"
          color="primary"
          to={{ pathname: `${BASE_URL}/login/`, state: { ...locationState } }}
          text={tr('login_button')}
          {...button}
        />
      </form>
    </StyledLoginContent>
  );
}

export default CognitiveInfo;
