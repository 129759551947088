import { useCallback, useEffect } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  getActivationHelpLink,
  getLicenseLockedTo,
  getLicenseType,
  getProductName,
} from '../../../../../helpers';
import { getFakeLicense } from '../../../../../store/helpers/getFakeLicenses';
import { clearLicense, createLicenseFromGroup } from '../../../../../store/licenseBuy/actions';
import { handleOpenModal } from '../../../../../store/modals/actions';
import UpgradeNuitrackProModal from '../../../../modals/UpgradeNuitrackProModal';
import { getLicenseInfoList } from '../../../../../helpers/getLicenseInfoList';
import { useMetadata } from '../../../../../helpers/useMetadata';

export const useNuitrackPreInfo = () => {
  // Routes
  const { id: groupId } = useParams();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const isHolded = urlSearchParams.has('holded');
  // Selectors
  const group = useSelector(
    state => state.licenses.groups.filter(el => el.id.toString() === groupId)[0]
  );
  const fakeLicense = getFakeLicense(group, { holded: isHolded });
  const groupsLoaded = useSelector(state => state.licenses.fetchGroups.success);
  const { loading, success, license: newLicense } = useSelector(
    state => state.licenseBuy.createLicenseFromGroup
  );

  const platform = 'nuitrack';
  const backUrl = '/app/nuitrack/dashboard/';

  const activationInfo = fakeLicense
    ? [
        { text: tr('activation_type'), value: group.config.online ? tr('online') : tr('offline') },
        { text: tr('locked_to'), value: getLicenseLockedTo(group) },
      ]
    : undefined;
  const metadata = useMetadata(fakeLicense, { platform });
  const licenseInfo = getLicenseInfoList(
    fakeLicense,
    { text: tr('product_name'), value: tr(getProductName(fakeLicense)) },
    { text: tr('license_type'), value: getLicenseType(fakeLicense) }
  );
  // Actions
  const dispatch = useDispatch();
  const createLicenseFromGroupCallback = useCallback(
    () => dispatch(createLicenseFromGroup(group)),
    [group]
  );

  useEffect(() => {
    if (success) {
      dispatch(clearLicense());
      history.push(`/app/${platform}/dashboard/${newLicense.id}/info/`);
    }
  }, [success]);

  const getActivationInfoAction = () => {
    if (!fakeLicense) return null;
    return {
      type: 'Button',
      left: {
        link: getActivationHelpLink(fakeLicense),
        title: tr('activation_key'),
        subtitle: tr('how_activate_license'),
      },
      right: {
        props: {
          onClick: createLicenseFromGroupCallback,
          disabled: isHolded,
          loading: loading,
          text: tr('generate_license_key'),
        },
      },
      license: { license_status: 'new' },
    };
  };

  const openUpgradeNuitrackProGroup = () =>
    dispatch(
      handleOpenModal({
        component: UpgradeNuitrackProModal,
        group,
        platform,
        name: 'upgrade_pro_group',
      })
    );

  return {
    group,
    fakeLicense,
    platform,
    loaded: groupsLoaded,
    isHolded,
    activationInfo,
    metadata,
    licenseInfo,
    getActivationInfoAction,
    openUpgradeNuitrackProGroup,
    backUrl,
    urlId: groupId,
  };
};
