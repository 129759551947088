import React from 'react';

class InfoItem extends React.Component {
  render() {
    return (
      <li className="account__info-item">
        <div className="account__title">{this.props.title}</div>
        <div className="account__text">{this.props.text}</div>
      </li>
    );
  }
}

export default InfoItem;
