import { handleActions } from 'redux-actions';
import { getLicense } from '../helpers/getLicense';
import getGroup from '../helpers/getGroup';
import sortDefaultGroups from '../helpers/sortDefaultGroups';
import { getIsCustomGroupMap } from '../helpers/getIsCustomGroupMap';

const defaultState = {
  licenses: [],
  groups: [],
  sortedDefaultGroups: [],

  fetchLicenses: {},
  fetchGroups: {},
  createCustomGroup: {},
  setGroupLimit: {},
  setGroupTitle: {},
  revokeLicense: {},
  isCustomGroupMap: {},
  groupChagesDuringFetch: [],

  default: {},
};
/*
  fetch: {loading, success, errorCode}
  initial - all undefined
  request - 
    'loading' is true
    'success' same
    'errorCode' is undefined
  success - 
    'loading' is false
    'success' is true
    'errorCode' is undefined
  failure - 
    'loading' is false
    'success' same
    'errorCode' is string
*/

const reducer = handleActions(
  {
    FETCH_LICENSES_REQUEST: (state, action) => {
      return {
        ...state,
        fetchLicenses: {
          ...state.fetchLicenses,
          loading: true,
          errorCode: undefined,
        },
      };
    },
    FETCH_LICENSES_SUCCESS: (state, action) => {
      let { licenses, prices } = action.payload;
      // IDEA: improving selectors recomputing:
      // there is can be made function which precisely check licenses equality,
      // and replace license object with new only when license changed (ofc by using getLicense),
      // otherwise return old license object (because reference check works this way)
      if (licenses.length !== state.licenses.length) {
        return {
          ...state,
          fetchLicenses: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
          licenses: licenses.map(lic =>
            getLicense(lic, { isCustomGroupMap: state.isCustomGroupMap, prices })
          ),
          licensesStringify: JSON.stringify(licenses),
        };
      } else if (JSON.stringify(licenses) === state.licensesStringify) {
        return {
          ...state,
          fetchLicenses: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
        };
      } else {
        return {
          ...state,
          fetchLicenses: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
          licenses: licenses.map(lic =>
            getLicense(lic, { isCustomGroupMap: state.isCustomGroupMap, prices })
          ),
          licensesStringify: JSON.stringify(licenses),
        };
      }
    },
    FETCH_LICENSES_FAILURE: (state, action) => {
      return {
        ...state,
        fetchLicenses: {
          ...state.fetchLicenses,
          loading: false,
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
    FETCH_GROUPS_REQUEST: (state, action) => {
      return {
        ...state,
        fetchGroups: {
          ...state.fetchGroups,
          loading: true,
          errorCode: undefined,
        },
      };
    },
    FETCH_GROUPS_SUCCESS: (state, action) => {
      let { groups } = action.payload;
      groups = groups.map(gr => getGroup(gr));

      if (state.groupChagesDuringFetch.length > 0) {
        state.groupChagesDuringFetch.forEach(groupChanges => {
          groups = groups.map(gr => {
            if (gr.id === groupChanges.id) {
              gr.limit += groupChanges.limit;
              gr.used_license += groupChanges.used_license;
              return {
                ...gr,
              };
            } else {
              return gr;
            }
          });
        });
      }

      if (groups.length !== state.groups.length) {
        return {
          ...state,
          groupChagesDuringFetch: [],
          fetchGroups: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
          groups,
          isCustomGroupMap: getIsCustomGroupMap(groups),
          groupsStringify: JSON.stringify(groups),
          sortedDefaultGroups: sortDefaultGroups(groups.filter(el => el.is_default)),
        };
      } else if (JSON.stringify(groups) === state.groupsStringify) {
        return {
          ...state,
          groupChagesDuringFetch: [],
          fetchGroups: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
        };
      } else {
        return {
          ...state,
          groupChagesDuringFetch: [],
          fetchGroups: {
            loading: false,
            success: true,
            errorCode: undefined,
          },
          groups,
          isCustomGroupMap: getIsCustomGroupMap(groups),
          groupsStringify: JSON.stringify(groups),
          sortedDefaultGroups: sortDefaultGroups(groups.filter(el => el.is_default)),
        };
      }
    },
    FETCH_GROUPS_FAILURE: (state, action) => {
      return {
        ...state,
        fetchGroups: {
          ...state.fetchGroups,
          loading: false,
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
    UPDATE_GROUP: (state, action) => {
      const { group } = action.payload;
      const newGroupChagesDuringFetch = [...state.groupChagesDuringFetch];
      if (state.fetchGroups.loading) {
        newGroupChagesDuringFetch.push({ id: group.id, limit: -1, used_license: 1 });
      }
      const newGroups = state.groups.map(gr => {
        if (gr.id === group.id) {
          return getGroup(group);
        } else {
          return gr;
        }
      });
      const newSortedDefaultGroups = state.sortedDefaultGroups.map(gr => {
        if (gr.id === group.id) {
          return {
            ...gr,
            limit: group.limit,
          };
        } else {
          return gr;
        }
      });

      return {
        ...state,
        groupChagesDuringFetch: state.fetchGroups.loading
          ? newGroupChagesDuringFetch
          : state.groupChagesDuringFetch,
        sortedDefaultGroups: newSortedDefaultGroups,
        groups: newGroups,
        groupsStringify: JSON.stringify(newGroups),
      };
    },
    UPDATE_LICENSE: (state, action) => {
      const { license } = action.payload;
      const newLicenses = state.licenses.map(lic => {
        if (lic.id === license.id) {
          return getLicense(license, { isCustomGroupMap: state.isCustomGroupMap });
        } else {
          return lic;
        }
      });

      return {
        ...state,
        licenses: newLicenses,
        licensesStringify: JSON.stringify(newLicenses),
      };
    },
    ADD_NEW_LICENSE: (state, action) => {
      const { license } = action.payload;
      const newLicenses = [
        ...state.licenses,
        getLicense(license, { isCustomGroupMap: state.isCustomGroupMap }),
      ];
      return {
        ...state,
        licenses: newLicenses,
        licensesStringify: JSON.stringify(newLicenses),
      };
    },
    CLEAR_LICENSE_STORE: (state, action) => {
      return defaultState;
    },
    CREATE_CUSTOM_GROUP_REQUEST: (state, action) => {
      return {
        ...state,
        createCustomGroup: {
          [action.payload.storeKey]: {
            loading: true,
          },
        },
      };
    },
    CREATE_CUSTOM_GROUP_SUCCESS: (state, action) => {
      const { newGroup, sourceGroup } = action.payload;
      // update default group
      const newGroups = state.groups.map(gr => {
        if (gr.id === sourceGroup.id) {
          return getGroup(sourceGroup);
        } else {
          return gr;
        }
      });
      const newSortedDefaultGroups = state.sortedDefaultGroups.map(gr => {
        if (gr.id === sourceGroup.id) {
          return {
            ...gr,
            limit: sourceGroup.limit,
          };
        } else {
          return gr;
        }
      });
      // add new group
      newGroups.push(newGroup);
      return {
        ...state,
        createCustomGroup: {
          [action.payload.storeKey]: {
            success: true,
          },
        },
        groups: newGroups,
        isCustomGroupMap: getIsCustomGroupMap(newGroups),
        groupsStringify: JSON.stringify(newGroups),
        sortedDefaultGroups: newSortedDefaultGroups,
      };
    },
    CREATE_CUSTOM_GROUP_FAILURE: (state, action) => {
      return {
        ...state,
        createCustomGroup: {
          [action.payload.storeKey]: {
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    SET_GROUP_LIMIT_REQUEST: (state, action) => {
      return {
        ...state,
        setGroupLimit: {
          [action.payload.storeKey]: {
            loading: true,
          },
        },
      };
    },
    SET_GROUP_LIMIT_SUCCESS: (state, action) => {
      const { group, defaultGroup } = action.payload;
      const newGroups = state.groups.map(gr => {
        if (gr.id === defaultGroup.id) {
          return getGroup(defaultGroup);
        } else if (gr.id === group.id) {
          return getGroup(group);
        } else {
          return gr;
        }
      });
      const newSortedDefaultGroups = state.sortedDefaultGroups.map(gr => {
        if (gr.id === defaultGroup.id) {
          return {
            ...gr,
            limit: defaultGroup.limit,
          };
        } else {
          return gr;
        }
      });

      return {
        ...state,
        setGroupLimit: {
          [action.payload.storeKey]: {
            success: true,
          },
        },
        groups: newGroups,
        groupsStringify: JSON.stringify(newGroups),
        sortedDefaultGroups: newSortedDefaultGroups,
      };
    },
    SET_GROUP_LIMIT_FAILURE: (state, action) => {
      return {
        ...state,
        setGroupLimit: {
          [action.payload.storeKey]: {
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    SET_GROUP_TITLE_REQUEST: (state, action) => {
      return {
        ...state,
        setGroupTitle: {
          [action.payload.storeKey]: {
            // old style of status storing, cuz its used in EditableTitle.js ~ setLicenseTitleAction
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    SET_GROUP_TITLE_SUCCESS: (state, action) => {
      const { group } = action.payload;
      const newGroups = state.groups.map(el => {
        if (el.id === group.id) {
          return getGroup(group);
        } else {
          return el;
        }
      });
      return {
        ...state,
        setGroupTitle: {
          [action.payload.storeKey]: {
            // old style of status storing, cuz its used in EditableTitle.js ~ setLicenseTitleAction
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
        groups: newGroups,
        groupsStringify: JSON.stringify(newGroups),
      };
    },
    SET_GROUP_TITLE_FAILURE: (state, action) => {
      return {
        ...state,
        setGroupTitle: {
          [action.payload.storeKey]: {
            // old style of status storing, cuz its used in EditableTitle.js ~ setLicenseTitleAction
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    REVOKE_LICENSE_REQUEST: (state, action) => {
      return {
        ...state,
        revokeLicense: {
          [action.payload.storeKey]: {
            loading: true,
          },
        },
      };
    },
    REVOKE_LICENSE_SUCCESS: (state, action) => {
      const { license } = action.payload;
      const newGroups = state.groups.map(gr => {
        if (gr.id === license.group) {
          return {
            ...gr,
            limit: gr.limit + 1,
          };
        } else {
          return gr;
        }
      });
      const newSortedDefaultGroups = state.sortedDefaultGroups.map(gr => {
        if (gr.id === license.group) {
          return {
            ...gr,
            limit: gr.limit + 1,
          };
        } else {
          return gr;
        }
      });
      const newLicenses = state.licenses.filter(el => el.id !== license.id);
      return {
        ...state,
        revokeLicense: {
          [action.payload.storeKey]: {
            success: true,
          },
        },
        licenses: newLicenses,
        licensesStringify: JSON.stringify(newLicenses),
        groups: newGroups,
        groupsStringify: JSON.stringify(newGroups),
        sortedDefaultGroups: newSortedDefaultGroups,
      };
    },
    REVOKE_LICENSE_FAILURE: (state, action) => {
      return {
        ...state,
        revokeLicense: {
          [action.payload.storeKey]: {
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
  },
  defaultState
);

export default reducer;
