// Hooks
import React from 'react';
import { useSelector } from 'react-redux';
// Others
import tr from 'locale';
// Components
import LicenseNuitrackAnalyticsView from './LicenseNuitrackAnalyticsView';
import { Col } from '@3divi/baseui';
import { OnPremiseModal } from '../../../../modals/';
import { Link } from 'react-router-dom';
import { CURRENT_NUITRAC_ANALYTICS_PLAN_ID } from '../../../../../Config.js';

const configs = {
  na_subscribe: {
    product: 'na_subscribe',
  },
};

function LicenseNuitrackAnalytics({ platform }) {
  const prices = useSelector(state => state.clientdata.prices[platform]);

  return (
    <>
      <Col xl={2} l={3} m={4} s={5} xs={6} xxs={12}>
        <LicenseNuitrackAnalyticsView
          title={tr('cloud_subscription')}
          subtitle={tr('cloud_subtitle')}
          priority
          price={{
            price: `$${
              prices[configs.na_subscribe.product][CURRENT_NUITRAC_ANALYTICS_PLAN_ID].cost
            }`,
            text: tr('cloud_after_price_text'),
          }}
          button={{
            type: 'BtnAdditionalText',
            props: {
              priority: true,
              text: tr('subscribe'),
              to: {
                pathname: `/app/${platform}/basket/checkout/`,
                state: { addLicenseType: configs.na_subscribe.product },
              },
              forwardedAs: Link,
            },
          }}
        />
      </Col>
      <Col xl={2} l={3} m={4} s={5} xs={6} xxs={12}>
        <LicenseNuitrackAnalyticsView
          title="On-premise"
          subtitle={tr('on_premise')}
          button={{
            type: 'ButtonModal',
            props: {
              platform: platform,
              id: 'on_premise',
              modal: {
                component: OnPremiseModal,
                name: 'on_premise',
              },
              button: {
                text: tr('contact_sales'),
              },
            },
          }}
        />
      </Col>
    </>
  );
}

export default LicenseNuitrackAnalytics;
