import React from 'react';

export default class LoaderComponent extends React.Component {
  render() {
    return (
      <div className={`loader-container ${this.props.className}`}>
        <div className="loader">
          <div className="loader-dot"></div>
          <div className="loader-dot"></div>
          <div className="loader-dot"></div>
        </div>
      </div>
    );
  }
}
