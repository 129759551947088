import React from 'react';
import GroupKeyInfoView from './components/NuitrackGroupKeyInfoView';
import { useCheckGroupAviability } from './components/hooks/useCheckGroupAviability';
import { useNuitrackGroupKeyInfo } from './components/useNuitrackGroupKeyInfo';

function NuitrackGroupKeyInfo() {
  const {
    group,
    loaded,
    activationInfo,
    getActivationInfoAction,
    metadata,
    info,
    backUrl,
    urlId,
  } = useNuitrackGroupKeyInfo();

  const redirect = useCheckGroupAviability({ id: urlId, group, loaded });

  if (redirect) return redirect;

  return (
    <GroupKeyInfoView
      header={{
        backUrl,
        title: group && group.title,
      }}
      isLoaded={loaded}
      activationInfo={{
        items: activationInfo,
        action: getActivationInfoAction(),
      }}
      metadata={metadata}
      info={info}
    />
  );
}

export default NuitrackGroupKeyInfo;
