import tr from 'locale';
import { secondsToDaysMonths } from './secondsToDaysMonths';

const getFaceLicenseType = license => {
  if (!license) return;

  // exception types naming block
  if (license.config.product === 'face_offline_perpetual')
    return tr('table_type_face_offline_perpetual');
  // exception types naming block end

  const isTrial = license.config.tag === 'FaceSDK Trial';

  let typeName;
  if (isTrial) typeName = tr('table_type_face_trial_tag');
  /**
   * handled products texts:
   * table_type_face_online_all_in_one
   * table_type_face_online_custom_subscribe
   * table_type_face_online_custom
   * table_type_face_offline_custom
   * table_type_face_online_subscribe
   */ else typeName = tr(`table_type_${license.config.product}`);

  let typeTime = '';

  const { days, months } = secondsToDaysMonths(license.config.license_expire_delta);

  if (!license.config.license_expire_delta) {
    typeTime = tr('license_expire_delta_unlimited');
  } else if (
    license.config.product === 'face_online_custom_subscribe' ||
    license.config.product === 'face_online_subscribe'
  ) {
    typeTime = tr('license_expire_delta_montly_subscription');
  } else if (months > 0) {
    typeTime = tr('license_expire_delta_months', { count: months, ending: months > 1 ? 's' : '' });
  } else if (days > 0) {
    typeTime = tr('license_expire_delta_days', { count: days, ending: days > 1 ? 's' : '' });
  } else {
    typeTime = tr('license_expire_delta_less_day');
  }

  let licenseType = `${typeName} (${typeTime})`;

  return licenseType;
};

export default getFaceLicenseType;
