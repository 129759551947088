import React from 'react';
import { useCheckLicenseAviability } from './components/hooks/useCheckLicenseAviability';
import NuitrackAnalyticsInfoView from './components/NuitrackAnalyticsInfoView';
import { useNuitrackAnalyticsInfo } from './components/useNuitrackAnalyticsInfo';

function NuitrackAnalyticsInfo() {
  const {
    license,
    platform,
    loaded,
    licenseConfiguration,
    metadata,
    licenseInfo,
    backUrl,
    urlId,
    licenseManage,
    message,
  } = useNuitrackAnalyticsInfo();

  const redirect = useCheckLicenseAviability({ id: urlId, platform, license });

  if (redirect) return redirect;

  return (
    <NuitrackAnalyticsInfoView
      header={{
        backUrl,
        title: license && license.title,
        subtitle: license && license.id,
        buttons: { serviceRedirect: { disabled: !license || license.license_status !== 'active' } },
      }}
      isLoaded={loaded}
      licenseConfiguration={licenseConfiguration}
      metadata={metadata}
      subscriptionManagement={{
        platform,
        license,
        loaded,
        isOld: license && !Object.prototype.hasOwnProperty.call(license.config, 'paid_channels'),
      }}
      info={licenseInfo}
      licenseManage={licenseManage}
      message={message}
    />
  );
}

export default NuitrackAnalyticsInfo;
