import React from 'react';
import styled from 'styled-components';
import TableCell from './TableCell';
import { Typography } from '@3divi/baseui';

const TRow = styled.div`
  display: contents;

  ${props =>
    !props.header &&
    `
    &:hover > div {
      background: ${props.theme.background.primary};
      ${props.onClick && 'cursor: pointer;'}
    }
  `}

  ${props =>
    !props.header &&
    props.onClick &&
    `
    & > div:first-child {
      color: ${props.theme.link.default};
    }

    &:hover > div:first-child {
      color: ${props.theme.link.hover};
    }
  `}
`;

const GroupsText = styled(Typography)`
  padding: 8px 32px;
  font-size: 15px;
  line-height: 20px;
  background: hsla(0, 0%, 98%, 1);
  border-bottom: 1px solid hsla(120, 8%, 92%, 1);
`;

function TableRow(props) {
  const { id, header, groups, rowClick, cells, rowType, columns, ...others } = props;

  let handlerRowClick;
  if (rowClick) {
    handlerRowClick = e => {
      rowClick && rowClick(e, others); // others contains license data
    };
  }

  return (
    <>
      <TRow data-type={rowType} onClick={handlerRowClick} id={id} header={header}>
        {cells.map((cell, i) => {
          if (cell && typeof cell.cell_type === 'string')
            return (
              <TableCell
                header={header}
                key={i}
                i={i}
                type={cell.cell_type}
                visible={columns[i].visible}
                {...cell.props}
              />
            );
          return (
            <TableCell
              header={header}
              key={i}
              dataAttr={columns[i].name}
              visible={columns[i].visible}
              text={cell}
              i={i}
            />
          );
        })}
        <TableCell compensator />
      </TRow>
      {groups && <GroupsText color="secondary">{groups}</GroupsText>}
    </>
  );
}

export default React.memo(TableRow);
