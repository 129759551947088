import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Typography, Input, Button } from '@3divi/baseui';

const PromoCodeInput = styled.div`
  margin: 0 -24px;
  padding: 16px 24px;
`;

const InputWithButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  & > button {
    margin-top: 4px;
  }

  & > div {
    margin-right: -1px;
  }
`;

function PromocodeView({
  input = { value: '', readOnly: false, onChange: v => {}, errorText: '' },
  button = { onClick: () => {}, loading: false },
  status = 'empty',
}) {
  /*
  status:
    'empty'
    'filled'
    'applied'
  */
  return status === 'empty' ? (
    <PromoCodeInput>
      <Typography size="15" weight="600" height="20">
        {tr('promocode')}
      </Typography>
      <InputWithButton>
        <Input name="promoCode" borderRadius="4px 0 0 4px" {...input} />
        <Button text={tr('button_apply')} borderRadius="0 4px 4px 0" {...button} />
      </InputWithButton>
    </PromoCodeInput>
  ) : status === 'filled' ? (
    <PromoCodeInput>
      <Typography size="15" weight="600" height="20">
        {tr('promocode')}
      </Typography>
      <InputWithButton>
        <Input name="promoCode" borderRadius="4px 0 0 4px" {...input} />
        <Button
          text={tr('button_apply')}
          variant="contained"
          color="primary"
          borderRadius="0 4px 4px 0"
          {...button}
        />
      </InputWithButton>
    </PromoCodeInput>
  ) : status === 'applied' ? (
    <PromoCodeInput>
      <Typography size="15" weight="600" height="20">
        {tr('promocode_applied')}
      </Typography>
      <InputWithButton>
        <Input name="promoCode" borderRadius="4px 0 0 4px" {...input} />
        <Button iconLeft="close" borderRadius="0 4px 4px 0" {...button} />
      </InputWithButton>
    </PromoCodeInput>
  ) : null;
}

export default PromocodeView;
