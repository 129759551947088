import React from 'react';
import styled from 'styled-components';

import ListItem from './ListItem';

const ListWrap = styled.div`
  padding: 16px 0;
`;

function Lists(props) {
  const { theme, items, className, ...other } = props;
  return (
    <ListWrap className={className} {...other}>
      {items.map(el => (
        <ListItem theme={theme} key={el.text} {...el} />
      ))}
    </ListWrap>
  );
}

export default Lists;
