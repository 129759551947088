import styled from 'styled-components';

export const StyledSection = styled.div`
  display: block;
  overflow-x: none;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.background.default};
`;

export const StyledContent = styled.div`
  padding: 24px 0 0;
`;
