import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  font-size: ${props => props.size}px;
  color: ${props => props.color};
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  letter-spacing: ${props => props.spacing}px;
  text-transform: ${props => props.transform};
  line-height: ${props => props.height}px;
  width: ${props => props.width}%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Typography(props) {
  const { children, color, component, size, ...other } = props;
  return (
    <Text as={component} size={size} color={color} {...other}>
      {children}
    </Text>
  );
}

Typography.defaultProps = {
  size: 15,
};

export default Typography;
