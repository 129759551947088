import { useLayoutEffect, useState } from 'react';
import { debounce } from '@3divi/baseui';

function useWindowSize(timer = 500) {
  const [size, setSize] = useState([0, 0]);

  const updateSize = debounce(() => {
    setSize([window.innerWidth, window.innerHeight]);
  }, timer);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export default useWindowSize;
