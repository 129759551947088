import React from 'react';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';

const ButtonWrap = styled.div`
  position: relative;
  margin-top: auto;
  display: flex;
  flex-direction: column;

  ${props =>
    props.additional &&
    `
    background-color: ${props.colorBg ? props.colorBg : '#EBFFEB'};
    border-radius: ${props.noBorderRadius ? 0 : 8}px;
    padding: 16px;
    margin: 0 -16px;
  `}
`;

const StyledButton = styled(Button)`
  z-index: 2;

  ${props =>
    props.side === 'top' &&
    `
    order: 2;
  `}

  ${props =>
    !props.priority &&
    `

    transition: color 0.2s, background 0.2s, border-color 0.1s;

    &:hover {
      background: hsla(120,48%,56%,1);
      border-color: hsla(120,48%,56%,1);

      & > span {
        color: hsla(0,0%,100%,1) !important;
      }
    }
  `}
`;

const AdditionalText = styled.p`
  position: relative;
  padding-top: 16px;
  font-size: 13px;
  line-height: 15px;
  z-index: 1;
  width: 100%;

  ${props =>
    props.side === 'top' &&
    `
    order: 1;
    padding-top: 0;
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
  `}
`;

function BtnAdditionalText(props) {
  const {
    additionalText,
    themeColor,
    priority,
    onClick,
    colorBg,
    side,
    text,
    button,
    noBorderRadius,
    ...other
  } = props;

  return (
    <ButtonWrap colorBg={colorBg} additional={additionalText} noBorderRadius={noBorderRadius}>
      <StyledButton
        side={side}
        priority={priority}
        variant={priority && 'contained'}
        text={text}
        size="l"
        color="primary"
        onClick={onClick}
        elastic
        {...button}
        {...other}
      />
      {additionalText && (
        <AdditionalText side={side} additional={additionalText}>
          {additionalText}
        </AdditionalText>
      )}
    </ButtonWrap>
  );
}

export default BtnAdditionalText;
