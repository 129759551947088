import React from 'react';
import InfoItem from './InfoItem';
import tr from 'locale';
import { Section, Button } from '@3divi/baseui';

class ContactInformation extends React.Component {
  render() {
    let info = this.props.accountInfo;
    return (
      <Section
        title={tr('account_user_header')}
        buttons={<Button round iconLeft="pen" onClick={this.props.openClientEdit} />}
      >
        <ul className="account__list">
          <InfoItem title={tr('user_name')} text={info.name} />
          <InfoItem title={tr('user_lastname')} text={info.last_name} />
          <InfoItem title={tr('user_phone')} text={info.phone} />
          <InfoItem title={tr('user_email')} text={info.email} />
          {this.props.corporate ? (
            <InfoItem title={tr('user_company_position')} text={info.company.position} />
          ) : (
            ''
          )}
        </ul>
      </Section>
    );
  }
}

export default ContactInformation;
