import React from 'react';
import tr from 'locale';
import { getActivationHelpLink } from '../../../../helpers';
import NuitrackInfoView from './components/NuitrackInfoView';
import { useNuitrackInfo } from './components/useNuitrackInfo';
import { useCheckLicenseAviability } from './components/hooks/useCheckLicenseAviability';

function NuitrackInfo() {
  const {
    license,
    platform,
    partialLicense,
    loaded,
    relatedGroup,
    isHolded,
    licenseRevokeable,
    licenseUpgradeable,
    activationInfo,
    metadata,
    relatedGroupInfo,
    licenseInfo,
    openRevoke,
    openUpgradeNuitrackPro,
    backUrl,
    urlId,
  } = useNuitrackInfo();

  const redirect = useCheckLicenseAviability({ id: urlId, platform, license });

  if (redirect) return redirect;

  return (
    <NuitrackInfoView
      header={{
        backUrl,
        title: license && license.title,
        subtitle: license && license.id,
        buttons:
          licenseRevokeable || licenseUpgradeable
            ? {
                revoke: licenseRevokeable ? { disabled: isHolded, onClick: openRevoke } : undefined,
                upgrade: licenseUpgradeable
                  ? { disabled: isHolded, onClick: openUpgradeNuitrackPro }
                  : undefined,
              }
            : undefined,
      }}
      isLoaded={partialLicense || loaded}
      activationInfo={{
        items: activationInfo,
        action: {
          type: 'GeneratedKeyField',
          left: {
            link: getActivationHelpLink(license),
            title: tr('activation_key'),
            subtitle: tr('how_activate_license'),
          },
          license: license,
        },
      }}
      subscriptionManagement={license && license.isSubscription && { platform, license, loaded }}
      metadata={metadata}
      info={licenseInfo}
      relatedGroupInfo={
        license && license.fromCustomGroup
          ? {
              items: relatedGroupInfo,
              action: {
                type: 'GeneratedKeyField',
                left: {
                  title: tr('group_key'),
                },
                group: relatedGroup,
              },
            }
          : undefined
      }
    />
  );
}

export default NuitrackInfo;
