import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import ToggleButtonGroup from 'components/elements/ToggleButtonGroup/';
import { Typography } from '@3divi/baseui';

const FilterButton = styled.button`
  text-align: left;
  padding: 8px 16px;
  flex: 0 0 128px;
  border-radius: 0;
  border: none;
  border-right: 1px solid ${({ theme }) => theme.border.default};
  border-top: 1px solid transparent;
  margin-bottom: -1px;

  background: ${props => (props.active ? props.theme.background.default : 'transparent')};
  z-index: 10;
  box-sizing: border-box;

  .count {
    color: ${props =>
      props.disabled
        ? props.theme.text.disabled
        : props.active
        ? props.theme.text.primary
        : props.theme.text.default};
  }

  &:focus {
    border-top-color: ${props => props.theme.background.brand};
  }
`;

function StatusToggleBtn(props) {
  const { statuses, value, callback, isDefault } = props;

  const getDisabled = (element, index) => {
    if (index === 0 && isDefault) return false;

    return !element.limit;
  };

  return (
    <ToggleButtonGroup callback={callback} defaultValue={value}>
      {statuses.map((el, i) => (
        <FilterButton value={el.text} key={`${el.text}_${i}`} disabled={getDisabled(el, i)}>
          <Typography color="secondary">{tr(el.text)}</Typography>
          <Typography className="count" size={32} height={40}>
            {el.limit ? el.limit : 0}
          </Typography>
        </FilterButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default StatusToggleBtn;
