import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import Typography from 'components/elements/Typography/';

import ProductControls from './ProductControls';
import LicenseGroup from './LicenseGroup';

const Cost = styled(Typography)`
  padding-right: 4px;
`;

function ProductView(props) {
  const { control, title, subtitle, price } = props;

  return (
    <LicenseGroup
      forwardedAs={control.containerAs}
      theme="row"
      title={
        <Typography size="17" weight="500" height="22">
          {tr(title)}
        </Typography>
      }
      subtitle={
        <Typography size="11" weight="500" color="#8F8F8F" spacing="1" transform="uppercase">
          {tr(subtitle)}
        </Typography>
      }
    >
      {price && <Cost>${price}</Cost>}
      <ProductControls {...control} />
    </LicenseGroup>
  );
}

export default ProductView;
