import React, { useEffect } from 'react';
import tr from 'locale';
import { useDispatch } from 'react-redux';
import { onlyDigitsRegexp } from '../../../../../../helpers/regexp';
import { handleOpenModal } from '../../../../../../store/modals/actions';
import ErrorModal from '../../../../../modals/ErrorModal';
import { Redirect } from 'react-router';
import { needPlatformRedirect } from '../../../../../../helpers';

export const useCheckAvailableLicense = ({ loaded, group, id, platform }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if ((loaded && !group) || (loaded && !group.hasAvailableLicense) || !onlyDigitsRegexp.test(id))
      dispatch(
        handleOpenModal({
          component: ErrorModal,
          name: 'error-63f560db',
          text: tr('63f560db'),
        })
      );
  }, [group, loaded, id]);

  return (loaded && !group) ||
    (loaded && !group.hasAvailableLicense) ||
    !onlyDigitsRegexp.test(id) ? (
    <Redirect to={`/app/${platform}/dashboard/`} />
  ) : needPlatformRedirect(group, platform) ? (
    <Redirect to={`/app/${group.type}/dashboard/${group.id}/pre-info/`} />
  ) : null;
};
