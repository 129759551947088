import tr from 'locale';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  getActivationHelpLink,
  getLicenseLockedTo,
  getLicenseType,
  getProductName,
} from '../../../../../helpers';
import { useMetadata } from '../../../../../helpers/useMetadata';
import { getGroupInfoList } from './helpers/getGroupInfoList';

export const useNuitrackGroupKeyInfo = () => {
  // Routes
  const { platform, id: groupId } = useParams();
  // Selectors
  const group = useSelector(state =>
    state.licenses.groups.find(el => el.id.toString() === groupId && !el.is_default)
  );
  const groupsLoaded = useSelector(state => state.licenses.fetchGroups.success);

  const backUrl = '/app/group-keys/';

  const activationInfo = group
    ? [
        { text: tr('activation_type'), value: group.config.online ? tr('online') : tr('offline') },
        { text: tr('locked_to'), value: getLicenseLockedTo(group) },
      ]
    : undefined;

  const metadata = useMetadata(group, { platform });

  const info = getGroupInfoList(
    group,
    { text: tr('product_name'), value: tr(getProductName(group)) },
    { text: tr('license_type'), value: getLicenseType(group) }
  );

  const getActivationInfoAction = () => {
    if (!group) return;

    return {
      type: 'GeneratedKeyField',
      left: {
        link: getActivationHelpLink(group),
        title: tr('group_key'),
        subtitle: tr('how_activate_license'),
      },
      group,
    };
  };

  return {
    group,
    loaded: groupsLoaded,
    activationInfo,
    getActivationInfoAction,
    metadata,
    info,
    backUrl,
    urlId: groupId,
  };
};
