import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { FaceLogoBlack } from 'components/logo';

const logo_base64 = {
  nuitrack: `"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill='%2390C23B' fill-rule='evenodd' d='M19.9999667,0 C31.0456825,0 40,8.95431752 40,19.9999667 C40,31.0456825 31.0456825,40 19.9999667,40 C8.95425098,40 0,31.0456825 0,19.9999667 C0,8.95431752 8.95425098,0 19.9999667,0 Z M23.0914892,5.5546982 C22.9324841,3.27929303 19.9217461,3.04436233 19.6862101,5.48870494 L19.6862101,5.48870494 L18.8973933,18.6727367 L19.2080991,33.0463086 C19.2108549,35.2571494 23.0143606,36.0177001 23.7079867,33.0463086 L23.7079867,33.0463086 L25.8961746,19.3699193 L27.3706157,7.97212514 C27.5000709,6.39523173 24.8495763,5.80059455 24.4440785,7.66189699 L24.4440785,7.66189699 L21.9321364,31.5591674 C21.7742602,32.0568084 21.0617088,32.0633878 21.1430541,31.0660123 L21.1430541,31.0660123 Z M30.29961,19.8807856 C30.8581698,17.0847447 27.6937391,16.4176678 27.0690074,19.4304297 C25.8903974,25.5380963 25.6376626,26.848458 24.4589862,32.9561577 C23.9133753,35.1951436 26.8002692,35.8776389 27.6847745,32.9561577 L27.6847745,32.9561577 Z M17.3342692,7.95737135 C17.2679977,5.52628721 14.0526681,5.82711148 14.0543282,8.00887667 L14.0543282,8.00887667 L14.9573936,26.605519 L10.4503342,20.114952 C8.90381118,18.1447241 5.59246095,19.5500217 7.40114818,23.5594282 L7.40114818,23.5594282 L11.7433256,33.7117573 C12.4028864,35.2539594 14.2907939,34.6241324 13.566323,33.026504 L13.566323,33.026504 L11.3062857,28.0423844 C10.5075415,26.3485573 12.0857394,25.86783 12.7731568,27.2062039 L12.7731568,27.2062039 L16.0834113,34.1144292 C17.2108568,35.3775721 18.380635,34.9379161 18.7425717,34.0502635 L18.7425717,34.0502635 Z M31.1762796,14.7572029 C31.7909511,12.0269559 28.3690709,11.3154515 28.0500314,14.2706936 C27.7548642,16.9260087 30.4213623,17.5930191 31.1762796,14.7572029 Z'/%3E%3C/svg%3E"`,
  '3divi': `"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill='%23FF3B3B' d='M20.0000718,-1.42108547e-14 C26.4647366,-1.42108547e-14 32.2119882,3.06843978 35.8681248,7.82697456 L23.1733378,15.000341 C21.9264326,15.9150094 21.3894257,17.2591301 21.3262907,18.8847498 L21.3388794,39.9543839 C20.8963597,39.9834863 20.4501064,40 20.0000718,40 C19.571146,40 19.1458101,39.9848744 18.7237291,39.9581174 L18.7363178,18.8039044 C18.6731828,17.1784283 18.1361759,15.8343555 16.8892708,14.9194956 L4.19414864,7.74593769 C7.85282211,3.03354558 13.5721679,-1.42108547e-14 20.0000718,-1.42108547e-14 Z M2.00634462,11.2608222 L14.5767283,18.3652618 L14.5767283,39.2553511 C6.16646064,36.8912572 8.8817842e-14,29.1658539 8.8817842e-14,19.9999282 C8.8817842e-14,16.8662951 0.72114677,13.9010063 2.00634462,11.2608222 Z M38.0382185,11.3517194 C39.295702,13.9687364 40,16.9021945 40,19.9999282 C40,29.1432613 33.8643171,36.8536346 25.4858802,39.2381673 L25.4858802,18.4459637 L38.0382185,11.3517194 Z M20.0000718,3.07796508 C17.7833568,3.07796508 15.9861859,4.87513597 15.9861859,7.09170736 C15.9861859,9.30823089 17.7833568,11.1055932 20.0000718,11.1055932 C22.2166432,11.1055932 24.0138141,9.30823089 24.0138141,7.09170736 C24.0138141,4.87513597 22.2166432,3.07796508 20.0000718,3.07796508 Z'/%3E%3C/svg%3E"`,
};

const StyledLoginPromo = styled.div`
  padding: 40px 48px;
  position: relative;
  flex: 1;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    top: 0;
    right: -1px;
    width: 1px;
    background: ${({ theme }) => theme.border.default};
  }

  &:after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0px;
    right: 0px;
    height: 1px;
    background: ${({ theme }) => theme.border.default};
  }

  @media (max-width: 720px) {
    padding: 32px 24px;
  }
`;

const Title = styled.h1`
  font: ${({ theme }) => theme.font.xl};
  color: ${({ theme }) => theme.text.default};
  font-weight: 600;
  text-align: left;
  padding: 0 0 16px;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.m};
  color: ${({ theme }) => theme.text.default};
  text-align: left;
  padding: 0 0 16px;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin: 0 -2px;
  margin-top: 16px;
`;

const Logo = styled.div`
  position: relative;
  flex: 1 1 0;
  padding: 48px 0 2px;
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.default};
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background-image: url(${props => logo_base64[props.logo]});
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(2) {
    border-left: 1px solid ${({ theme }) => theme.border.default};
    border-right: 1px solid ${({ theme }) => theme.border.default};
  }
`;

const LogoHeader = styled.div`
  width: 156px;
  height: 26px;
  background-image: url(${FaceLogoBlack});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 32px;

  @media (max-width: 720px) {
    margin: 0 auto 32px;
  }
`;

function LoginPromo() {
  return (
    <StyledLoginPromo>
      <LogoHeader />
      <Title>{tr('login_promo_title')}</Title>
      <Subtitle>{tr('login_promo_subtitle')}</Subtitle>
      <Logos>
        <Logo logo="nuitrack">Nuitrack</Logo>
        <Logo logo="3divi">Face SDK</Logo>
        <Logo logo="3divi">Face Machine</Logo>
      </Logos>
    </StyledLoginPromo>
  );
}

export default LoginPromo;
