import React, { useState } from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';

const PaymentButton = styled(Button)`
  position: relative;
  padding: 0 24px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.2s ease;

  & > span {
    pointer-events: none;
    text-align: left;
    padding: 0;
  }
`;

const TextWithIcon = styled.div`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  &:before {
    display: inline-block;
    content: '';
    margin-right: 8px;
    width: 22px;
    height: 16px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16'%3E%3Cpath fill='%2359C559' fill-rule='evenodd' d='M22,6 L22,14 C22,15.1045695 21.1045695,16 20,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,6 L22,6 Z M9,8 L3,8 C2.44771525,8 2,8.44771525 2,9 C2,9.55228475 2.44771525,10 3,10 L3,10 L9,10 C9.55228475,10 10,9.55228475 10,9 C10,8.44771525 9.55228475,8 9,8 L9,8 Z M20,0 C21.1045695,-2.02906125e-16 22,0.8954305 22,2 L22,4 L0,4 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L20,0 Z'/%3E%3C/svg%3E");
`;

function PayStripe(props) {
  const { disabled, onClick, autoFocus, innerRef, ...other } = props;

  const [clicked, setClicked] = useState(false);

  return (
    <PaymentButton
      size="l"
      text={<TextWithIcon>{tr('pay_with_credit_card')}</TextWithIcon>}
      style={{ button: 'hsla(0,0%,93%,1)', text: 'hsla(0,0%,0%,1)', icon: 'hsla(120,45%,56%,1)' }}
      variant="contained"
      textAlign="center"
      onClick={async () => {
        setClicked(true);
        try {
          onClick && (await onClick());
        } finally {
          setClicked(false);
        }
      }}
      elastic
      disabled={disabled && !clicked}
      loading={clicked}
      autoFocus={autoFocus}
      innerRef={innerRef}
      {...other}
    />
  );
}

export default PayStripe;
