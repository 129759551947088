import React from 'react';

export default class KeyField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: 'Copy key',
      labelClass: 'invisible',
    };
    this.keyRef = React.createRef();
    this.keyfieldClick = this.keyfieldClick.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  keyfieldClick(event) {
    event.preventDefault();
    event.stopPropagation();
    var textArea = document.createElement('textarea');
    textArea.value = this.props.token;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      this.setState({ label: 'Copied' });
    } catch (err) {
      console.log('copyError');
    }
    document.body.removeChild(textArea);
  }
  onMouseLeave(event) {
    this.setState({
      label: 'Copy key',
      labelClass: 'invisible',
    });
  }

  render() {
    return (
      <span
        className="table__key-cell"
        onClick={this.keyfieldClick}
        onMouseLeave={this.onMouseLeave}
      >
        <span className={`table__key-text`} ref={this.keyRef}>
          {this.props.token}
        </span>
        <span className={`table__key-copy`}>{this.state.label}</span>
      </span>
    );
  }
}
