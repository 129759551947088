import React from 'react';
import styled from 'styled-components';

const DividerView = styled.hr`
  border: none;
  margin: 0;
  flex-shrink: 0;
  background: ${props =>
    props.dividerColor === 'default' || props.dividerColor === 'contrast'
      ? props.theme.border[props.dividerColor]
      : props.dividerColor};

  ${props =>
    props.direction === 'X' &&
    `
    height: ${props.size}px;
  `}
  ${props =>
    props.direction === 'Y' &&
    `
    width: ${props.size}px;
    height: auto;
  `}
`;

function Divider({ direction = 'X', size = 1, dividerColor = 'default' }) {
  return <DividerView direction={direction} size={size} dividerColor={dividerColor} />;
}

export default Divider;
