import React from 'react';
import styled from 'styled-components';

import { Icon, P } from '@3divi/baseui';

const StyledActivationTabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 2px solid ${({ theme }) => theme.border.default};
  box-sizing: border-box;
`;

const Icons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 8px;
  justify-content: center;
  opacity: ${props => (props.checked ? '1' : '.56')};

  & > svg {
    margin: 0 4px;
  }
`;

const Tab = styled.div`
  position: relative;
  padding: 12px 8px;
  flex: 1 1 100%;
  text-align: center;
  color: ${props => (props.checked ? props.theme.text.default : props.theme.text.secondary)};

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.checked &&
    `
    &:before {
      content: "";
      position: absolute;
      height: 2px;
      bottom: -2px;
      left: 0;
      right: 0;
      background: ${props.theme.background.brand};
    }
  `}
`;

function ActivationTabs(props) {
  const { defaultChecked = 1, checkedTab, onChange, onlyDesktop, onlyMobile, ...other } = props;

  let isTabCheckedMap;
  if (onlyDesktop) isTabCheckedMap = { 1: true };
  else if (onlyMobile) isTabCheckedMap = { 2: true };
  else isTabCheckedMap = { [checkedTab || defaultChecked]: true };

  return (
    <StyledActivationTabs>
      {!onlyMobile && (
        <Tab checked={isTabCheckedMap[1]} onClick={onChange ? () => onChange(1) : undefined}>
          <Icons checked={isTabCheckedMap[1]}>
            <Icon icon="windows" />
            <Icon icon="linux" />
          </Icons>
          <P size="xs">Windows/Linux</P>
        </Tab>
      )}
      {!onlyDesktop && (
        <Tab checked={isTabCheckedMap[2]} onClick={onChange ? () => onChange(2) : undefined}>
          <Icons checked={isTabCheckedMap[2]}>
            <Icon icon="android" />
            <Icon icon="apple" />
          </Icons>
          <P size="xs">Android/iOS</P>
        </Tab>
      )}
    </StyledActivationTabs>
  );
}

export default ActivationTabs;
