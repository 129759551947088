import React from 'react';
import styled from 'styled-components';
import { themeDefault } from 'components/themes/themeDefault';
import { Button, Tooltip, WithIcon } from '@3divi/baseui';
import Switch from './Switch';
import tr from 'locale';
import { DiscountBlock, OldPrice } from '../styled';

const StyledLicenseCard = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  border: 1px solid ${({ theme }) => theme.border.default};
  border-radius: 0 0 8px 8px;
  padding: 24px;
  margin: 16px 0 0;
  box-sizing: border-box;
  ${props => props.priority && 'box-shadow: 0 0 48px 0 hsla(0,0%,0%,.12);'}

  &:before {
    position: absolute;
    content: '';
    background: ${props => (props.discount ? 'hsla(360,100%,48%,1)' : props.lineColor)};
    height: 3px;
    left: -1px;
    right: -1px;
    top: -1px;
  }

  @media (max-width: 1200px) {
    padding: 16px;
    height: auto;
  }
`;

const Content = styled.div`
  height: 80px;

  @media (max-width: 1200px) {
    height: 72px;
  }
`;

const TypeName = styled.div`
  font: ${({ theme }) => theme.font.xs};
  min-height: 15px;
  color: ${({ theme }) => theme.text.default};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
`;

const TypeSubscription = styled.div`
  font: ${({ theme }) => theme.font.xs};
  min-height: 15px;
  color: ${({ theme }) => theme.text.default};
  padding: 14px 0;
  text-align: center;
`;

const Price = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 48px;
  line-height: 48px;
  min-height: 48px;
  color: ${({ theme }) => theme.text.default};
  text-align: center;
  font-weight: 300;

  ${props =>
    props.price !== 'Free' &&
    `
    &:before {
      position: absolute;
      content: '$';
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      top: 2px;
      transform: translateX(-16px)
    }
  `}

  @media (max-width: 1200px) {
    font-size: 40px;
    line-height: 40px;
    min-height: 40px;
    font-weight: 300;

    &:before {
      font-size: 15px;
      line-height: 24px;
      transform: translateX(-12px);
    }
  }
`;

const PriceSubtitle = styled.div`
  font: ${({ theme }) => theme.font.xs};
  min-height: 15px;
  color: ${({ theme }) => theme.text.default};
  text-align: center;
`;

const PriceText = styled.div`
  font: ${({ theme }) => theme.font.xs};
  font-size: 13px;
  line-height: 15px;
  min-height: 15px;
  padding: 16px 0;
  color: ${({ theme }) => theme.text.secondary};
  text-align: center;
`;

const Сompensator = styled.div`
  flex: 1 1 0;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const PriceBox = styled.div``;

function LicenseCard(props) {
  const {
    lineColor,
    priority,
    button,
    type,
    price,
    priceText,
    tooltip,
    switcher,
    oldPrice,
    discount,
    ...other
  } = props;

  const ButtonComponent = button.component ? button.component : Button;
  return (
    <StyledLicenseCard priority={priority} lineColor={lineColor} discount={discount}>
      {discount && <DiscountBlock>{tr('discount_percents', { discount })}</DiscountBlock>}
      <Content>
        <TypeName>
          {type}
          {tooltip && (
            <Tooltip side="top-center" tooltipColor={themeDefault.whiteColor} text={tooltip}>
              <WithIcon icon="info" color="primary"></WithIcon>
            </Tooltip>
          )}
        </TypeName>
        {type === 'Perpetual' && <TypeSubscription>{tr('use_forever')}</TypeSubscription>}
        {switcher && <Switch {...switcher} />}
      </Content>
      <PriceBox>
        <Price price={price}>{price}</Price>
        {oldPrice && (
          <Center>
            <OldPrice>${oldPrice}</OldPrice>
          </Center>
        )}
        {/* per_month, per_year texts from locale */}
        <PriceSubtitle>{switcher && tr(`per_${switcher.current.verb}`)}</PriceSubtitle>
      </PriceBox>
      <PriceText>{priceText}</PriceText>
      <Сompensator />
      {button && (
        <ButtonComponent
          elastic
          size="m"
          variant={button.priority ? 'contained' : 'outlined'}
          color="primary"
          {...button.props}
        />
      )}
    </StyledLicenseCard>
  );
}

LicenseCard.defaultProps = {
  lineColor: 'hsla(0,0%,86%,1)',
  priority: false,
  button: undefined,
  type: '',
  price: '',
  priceText: '',
  tooltip: '',
};

export default LicenseCard;
