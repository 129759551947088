import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Redirect, useParams } from 'react-router-dom';
import { BASE_URL } from 'Config';

import { clearLicense } from '../../../../../../store/licenseBuy/actions';

import PayBalance from './PayBalance';
import PayPaypal from './PayPaypal';
import PayStripe from './PayStripe';

const Button = styled.div`
  margin: 8px 0;
`;

const Row = styled.div`
  position: relative;
  margin: 12px 0;
  z-index: 1;
`;

const Or = styled.div`
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.border.default};
    height: 1px;
    left: 0;
    right: 0;
    top: 50%;
    z-index: -1;
  }
`;

const Text = styled.div`
  font: ${({ theme }) => theme.font.m};
  display: inline-block;
  background: hsla(0, 0%, 100%, 1);
  padding: 0 16px;
  z-index: 10;
  color: ${({ theme }) => theme.text.secondary};
`;

function Methods(props) {
  const { platform, checkoutType, toUpgradeId } = useParams();
  const dispatch = useDispatch();

  const toBuyProductsSelector = useMemo(
    () =>
      createSelector(
        state => state.basket.basket.products,
        products =>
          products.map(product => {
            return {
              config: product.config,
              count: product.limit,
            };
          })
      ),
    []
  );

  const toBuyProducts = useSelector(toBuyProductsSelector);
  const licenseBuyLoading = useSelector(state => state.licenseBuy.loading);
  const licenseBuySuccess = useSelector(state => state.licenseBuy.success);

  const disabled = licenseBuyLoading || toBuyProducts.length === 0;

  useEffect(() => {
    return () => dispatch(clearLicense());
  }, []);

  if (licenseBuySuccess)
    if (checkoutType === 'upgrade')
      return (
        <Redirect
          push
          to={`${BASE_URL}/${platform}/license-purchase-success/?next=${BASE_URL}/${platform}/dashboard/${toUpgradeId}/info/`}
        />
      );
    else return <Redirect push to={`${BASE_URL}/${platform}/license-purchase-success/`} />;

  return (
    <>
      {checkoutType !== 'subscriptions' && (
        <>
          <Row>
            <PayBalance
              disabled={disabled}
              toBuyProducts={toBuyProducts}
              platform={platform}
              toUpgradeId={toUpgradeId}
              checkoutType={checkoutType}
            />
          </Row>
          <Row>
            <Or>
              <Text>or</Text>
            </Or>
          </Row>
        </>
      )}
      <Row>
        <Button>
          <PayStripe
            disabled={disabled}
            toBuyProducts={toBuyProducts}
            platform={platform}
            toUpgradeId={toUpgradeId}
            checkoutType={checkoutType}
          />
        </Button>
      </Row>
      <Row>
        <Button>
          <PayPaypal
            disabled={disabled}
            toBuyProducts={toBuyProducts}
            platform={platform}
            toUpgradeId={toUpgradeId}
            checkoutType={checkoutType}
          />
        </Button>
      </Row>
    </>
  );
}

export default Methods;
