import React from 'react';
import styled from 'styled-components';
import { WithIcon } from '@3divi/baseui';

const StyledA = styled.a`
  overflow-wrap: break-word;
  color: ${props => props.theme.link.default};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.link.hover};
  }

  &:focus {
    color: ${props => props.theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.link.hover};
  }
`;

function StyledLink(props) {
  const { size, noIcon, children, ...others } = props;

  return (
    <StyledA {...others}>
      {noIcon ? (
        children
      ) : (
        <WithIcon icon="link" color="primary" size={size}>
          {children}
        </WithIcon>
      )}
    </StyledA>
  );
}

export default StyledLink;
