import { createAction } from 'redux-actions';
import { getFaceMachineRates, getLicenseUpgradeCost } from '../../api';
import { errorHandleMiddleware } from '../errorHandleMiddleware';

export const fetchFaceMachinePlansAction = () => {
  return errorHandleMiddleware(async dispatch => {
    const response = await getFaceMachineRates();
    dispatch(fetchFaceMachinePlans(response));
  });
};
const fetchFaceMachinePlans = createAction('FETCH_FACE_MACHINE_PLANS');

export const getUpgradeCost = ({ id }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    const upgradeCostResponse = getState().plans.upgradeCostMap[id];
    if (upgradeCostResponse) return upgradeCostResponse;

    const response = await getLicenseUpgradeCost(id);
    dispatch(getUpgradeCostSuccess({ response, id }));
    return getState().plans.upgradeCostMap[id];
  });
};
const getUpgradeCostSuccess = createAction('GET_UPGRADE_COST_SUCCESS');
