import { useCallback, useEffect } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  getActivationHelpLink,
  getLicenseLockedTo,
  getLicenseType,
  getProductName,
} from '../../../../../helpers';
import { getFakeLicense } from '../../../../../store/helpers/getFakeLicenses';
import { clearLicense, createLicenseFromGroup } from '../../../../../store/licenseBuy/actions';
import { handleOpenModal } from '../../../../../store/modals/actions';
import FaceActivateModal from '../../../../modals/FaceActivateModal';
import { getLicenseInfoList } from '../../../../../helpers/getLicenseInfoList';
import { useMetadata } from '../../../../../helpers/useMetadata';

export const useFacePreInfo = () => {
  // Routes
  const platform = 'face';
  const backUrl = '/app/face/dashboard/';
  const groupId = useParams().id;
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location.search);
  const isHolded = urlSearchParams.has('holded');
  // Selectors
  const group = useSelector(
    state => state.licenses.groups.filter(el => el.id.toString() === groupId)[0]
  );
  const fakeLicense = getFakeLicense(group, { isHolded });
  const groupsLoaded = useSelector(state => state.licenses.fetchGroups.success);
  const { loading, success, license: newLicense } = useSelector(
    state => state.licenseBuy.createLicenseFromGroup
  );
  const activationInfo = fakeLicense
    ? [
        { text: tr('activation_type'), value: group.config.online ? tr('online') : tr('offline') },
        { text: tr('locked_to'), value: getLicenseLockedTo(group) },
      ]
    : undefined;
  const metadata = useMetadata(fakeLicense, { platform });
  const licenseInfo = getLicenseInfoList(
    fakeLicense,
    { text: tr('product_name'), value: tr(getProductName(fakeLicense)) },
    { text: tr('license_type'), value: getLicenseType(fakeLicense) }
  );
  // Actions
  const dispatch = useDispatch();
  const createLicenseFromGroupCallback = useCallback(
    () => dispatch(createLicenseFromGroup(group)),
    [group]
  );

  useEffect(() => {
    if (success) {
      dispatch(clearLicense());
      history.push(`/app/${platform}/dashboard/${newLicense.id}/info/`, { autoAction: true });
    }
  }, [success]);

  const getActivationInfoAction = () => {
    if (!fakeLicense) return null;
    if (
      (fakeLicense.config.product === 'face_offline_custom' ||
        fakeLicense.config.product === 'face_offline_perpetual') &&
      fakeLicense.config.device_bound
    )
      return {
        type: 'Button',
        left: {
          link: getActivationHelpLink(fakeLicense),
          title: tr('activation_file'),
          subtitle: tr('how_generate_license'),
        },
        right: {
          text: tr('hint_upload_file'),
          props: {
            text: tr('upload_file'),
            onClick: () =>
              dispatch(
                handleOpenModal({
                  component: FaceActivateModal,
                  name: 'activation_face',
                  group,
                  platform,
                  subtitle: getLicenseType(group),
                })
              ),
            disabled: isHolded,
          },
        },
        group,
      };
    else
      return {
        type: 'FileLinkB64',
        left: {
          link: getActivationHelpLink(fakeLicense),
          title: tr('activation_file'),
          subtitle: tr('how_activate_license'),
        },
        right: {
          props: {
            isLicenseFake: true,
            loading: loading,
            createLicenseFromGroup: createLicenseFromGroupCallback,
            disabled: isHolded,
          },
        },
        license: fakeLicense,
      };
  };

  return {
    group,
    fakeLicense,
    platform,
    loaded: groupsLoaded,
    isHolded,
    activationInfo,
    getActivationInfoAction,
    metadata,
    licenseInfo,
    backUrl,
    urlId: groupId,
  };
};
