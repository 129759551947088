import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Box, Button, Typography } from '@3divi/baseui';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModalSubscriptionManagement from '../../../../../modals/ModalSubscriptionManagement/ModalSubscriptionManagement';
import { getPrice } from '../../../../../../helpers/getPrice';
import { handleOpenModal } from '../../../../../../store/modals/actions';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.border.default};
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  width: 100%;
`;

function FaceMachineUpgradeBlock({ license }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const prices = useSelector(state => state.clientdata.prices);

  const openUpgradeModal = () =>
    dispatch(
      handleOpenModal({
        component: ModalSubscriptionManagement,
        license,
        upgradeCost: getPrice(license, prices),
        name: 'upgrade',
        action: () =>
          history.push(`/app/face-machine/basket/checkout/upgrade/trial/${license.id}/`),
      })
    );

  return (
    <>
      <Container>
        <Box py={1.5} px={2}>
          <Typography height="20">
            {license.dates.days < 0
              ? tr('trial_info_expired', {
                  days: -license.dates.days,
                  date: license.dates.expireDate.format('MMM DD, YYYY'),
                })
              : tr('trial_info', {
                  days: license.dates.days === 0 ? 1 : license.dates.days,
                  date: license.dates.expireDate.format('MMM DD, YYYY'),
                })}
          </Typography>
        </Box>
      </Container>
      <Button
        text={tr('button_upgrade_now')}
        elastic
        size="m"
        theme={'contained'}
        color="primary"
        disabled={license.license_status === 'holded'}
        onClick={openUpgradeModal}
      />
    </>
  );
}

export default FaceMachineUpgradeBlock;
