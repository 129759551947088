import React from 'react';
import styled from 'styled-components';

const styles = {
  eyes: {
    neutral: `
      width: 4px;
      height: 4px;
      top: 16px;
      border-radius: 50%;
      background: hsla(0,0%,0%,1);
    `,
    angry: `
      width: 6px;
      height: 3px;
      top: 18px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='4' viewBox='0 0 6 4'%3E%3Cpath fill-rule='evenodd' d='M2.81829973,3.10204082 C4.81829973,3.10204082 6.31829973,0.602040816 5.31829973,0.102040816 C4.31829973,-0.397959184 4.31829973,1.10204082 2.81829973,1.10204082 C1.31829973,1.10204082 1.31829973,-0.397959184 0.318299727,0.102040816 C-0.681700273,0.602040816 0.818299727,3.10204082 2.81829973,3.10204082 Z'/%3E%3C/svg%3E");
    `,
    happy: `
      width: 8px;
      height: 4px;
      top: 14px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4'%3E%3Cpath fill-rule='evenodd' d='M3.74670766,2 C5.74670766,2 6.5,4.5 7.24670766,3.5 C7.99341532,2.5 6.24670766,0 3.74670766,0 C1.24670766,0 -0.50658468,2.5 0.24670766,3.5 C1,4.5 1.74670766,2 3.74670766,2 Z'/%3E%3C/svg%3E");
    `,
  },
  brows: `
    position: absolute;
    content: '';
    width: 7px;
    height: 2px;
    border-radius: 1px;
    background: hsla(0,0%,0%,1);
    top: 14px;
  `,
};

const Container = styled.div`
  padding: 0 8px;
`;

const StyledSmile = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => (props.active ? 'hsla(48, 96%, 64%, 1)' : 'hsla(48, 8%, 88%, 1)')};
  box-shadow: inset 3px -4px 8px 0 hsla(48, 16%, 56%, 0.48);
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: hsla(48, 96%, 64%, 1);
  }
`;

export const Eyes = styled.div`
  &:before {
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    content: '';

    ${props =>
      (props.emotion === 'neutral' || props.emotion === 'sad' || props.emotion === 'fun') &&
      `
      ${styles.eyes.neutral}
      left: 11px;
    `}

    ${props =>
      props.emotion === 'angry' &&
      `
      ${styles.eyes.angry}
      left: 11px;
      transform: rotate(12deg);
    `}

    ${props =>
      props.emotion === 'happy' &&
      `
      ${styles.eyes.happy}
      left: 9px;
    `}
  }

  &:after {
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    content: '';

    ${props =>
      (props.emotion === 'neutral' || props.emotion === 'sad' || props.emotion === 'fun') &&
      `
      ${styles.eyes.neutral}
      right: 11px;
    `}

    ${props =>
      props.emotion === 'angry' &&
      `
      ${styles.eyes.angry}
      right: 11px;
      transform: rotate(-12deg);
    `}

    ${props =>
      props.emotion === 'happy' &&
      `
      ${styles.eyes.happy}
      right: 9px;
    `}
  }
`;

const Brows = styled.div`
  &:before {
    ${styles.brows}
    left: 10px;
    transform: rotate(20deg);
  }

  &:after {
    ${styles.brows}
    right: 10px;
    transform: rotate(-20deg);
  }
`;

export const Mouth = styled.div`
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  left: 50%;
  transform: ${props => props.emotion === 'neutral' && 'rotate(-6deg)'} translateX(-50%);
  opacity: 0.4;

  ${props =>
    props.emotion === 'neutral' &&
    `
    width: 14px;
    height: 2px;
    top: 25px;
    background: hsla(0,0%,0%,1);
    border-radius: 1px;
  `}

  ${props =>
    props.emotion === 'angry' &&
    `
    width: 16px;
    height: 6px;
    top: 24px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='6' viewBox='0 0 16 6'%3E%3Cpath fill-rule='evenodd' d='M7.9870509,2.5 C12.5,2.5 14.3197761,6.39458086 15.5,5.5 C16.6802239,4.60541914 13,0 8,0 C3,0 -0.754894179,4.60541914 0.5,5.5 C1.75489418,6.39458086 3.47410179,2.5 7.9870509,2.5 Z'/%3E%3C/svg%3E");
  `}

  ${props =>
    props.emotion === 'sad' &&
    `
    width: 12px;
    height: 4px;
    top: 24px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='4' viewBox='0 0 12 4'%3E%3Cpath fill-rule='evenodd' d='M6,0 C10.0212961,0 12.8936505,2.67657993 11.7447087,3.7472119 C10.595767,4.81784387 9.44682524,2.5 6,2.5 C2.55317476,2.5 1.40423301,4.81784387 0.255291268,3.7472119 C-0.893650478,2.67657993 1.97870389,0 6,0 Z'/%3E%3C/svg%3E");
  `}

  ${props =>
    props.emotion === 'happy' &&
    `
    width: 16px;
    height: 8px;
    top: 22px;
    border-radius: 0 0 8px 8px;
    background: hsla(0,0%,0%,1);
  `}

  ${props =>
    props.emotion === 'fun' &&
    `
    width: 14px;
    height: 6px;
    top: 24px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='6' viewBox='0 0 14 6'%3E%3Cpath fill-rule='evenodd' d='M7,6 C11.5540347,6 15.2855372,1.26681575 13.5780501,0.173043671 C11.870563,-0.920728406 11.0480308,3.52831128 7,3.52831128 C2.95196917,3.52831128 2.12943696,-0.920728406 0.421949904,0.173043671 C-1.28553715,1.26681575 2.44596532,6 7,6 Z'/%3E%3C/svg%3E");
  `}
`;

function Smile(props) {
  const { emotion, choose, emote, ...other } = props;
  return (
    <Container>
      <StyledSmile emotion={emotion} onClick={choose} active={emote === emotion}>
        {emotion === 'angry' && <Brows />}
        <Eyes emotion={emotion} />
        <Mouth emotion={emotion} />
      </StyledSmile>
    </Container>
  );
}

export default Smile;
