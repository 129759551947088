import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import tr from 'locale';
import { getHref } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import SetPasswordView from './SetPasswordView';
import BackendValidation from '../../blocks/BackendValidation/BackendValidation';
import PasswordLinkExpired from './PasswordLinkExpired';
import { setPassword } from '../../../store/clientdata/actions';
import {
  notAvailableSymbolRegexp,
  upperLatinRegexp,
  lowerLatinRegexp,
} from '../../../helpers/regexp';

const getHandledError = () => ['59991e53', '0c21cb17', '4f37c7da'];

function SetPassword(props) {
  const { id, token } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const storedSetPassword = useSelector(state => state.clientdata.setPassword);
  const [pass, setPass] = useState('');
  const [passError, setPassError] = useState('');
  const [passRepeat, setPassRepeat] = useState('');
  const [passRepeatError, setPassRepeatError] = useState('');

  const hasErrorPass = value => {
    if (!value || value.length < 8) {
      return tr('password_at_least_error', { atLeast: 8 });
    }
    if (notAvailableSymbolRegexp.test(value)) {
      return tr('password_not_available_symbol_error');
    }
    // validation group section.
    // If more than 1 error, then return concat error message.
    // If error just 1, then return its error message.
    let invalidCount = 0;
    let errorMessage;
    const specialRegexp = /[0-9\.,:;?!*+%\-<>@\[\]\{\}\\\/_$#]+/;
    if (!specialRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_special_symbol_error');
    }
    if (!upperLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_upper_error');
    }
    if (!lowerLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_lower_error');
    }
    if (invalidCount > 0) {
      if (invalidCount === 1) return errorMessage;
      else return tr('password_no_all_symbol_types_error');
    }

    return false;
  };

  useEffect(() => {
    if (storedSetPassword.status.failure) {
      let errorCode = storedSetPassword.errorCode;
      const errorMsg = tr(errorCode);
      if (
        errorCode === '59991e53' || // invalid confirmation token
        errorCode === '0c21cb17' || // password equal to previous
        errorCode === '4f37c7da' // password contain part of email
      )
        setPassError(errorMsg);
    }
  }, [storedSetPassword.status.failure]);

  // redirect to 'next' parameters location
  if (storedSetPassword.status.success) {
    const searchObject = new URLSearchParams(location.search);
    const nextValue = searchObject.get('next');
    if (nextValue) {
      const nextHref = getHref(nextValue, `/app/`);
      window.location.href = nextHref;
    } else if (location.state && location.state.platform) {
      history.replace(`/app/${location.state.platform}/`);
    } else {
      history.replace('/app/');
    }
  }
  return (
    <BackendValidation
      rule={{ type: 'valid_token', token: token, clientId: id }}
      failComponent={<PasswordLinkExpired clientId={id} />}
    >
      <SetPasswordView
        inputs={{
          password: {
            value: pass,
            onChange: v => {
              if (v.length > 256) {
                setPassError(tr('password_max_length_error', { maxLength: 256 }));
                return;
              }

              if (passError.length > 0) setPassError('');
              setPass(v);
            },
            errorText: passError,
          },
          passwordRepeat: {
            value: passRepeat,
            onChange: v => {
              if (v.length > 256) {
                setPassRepeatError(tr('password_max_length_error', { maxLength: 256 }));
                return;
              }

              if (passRepeatError.length > 0) setPassRepeatError('');
              setPassRepeat(v);
            },
            errorText: passRepeatError,
          },
        }}
        buttons={{
          submit: {
            onClick: e => {
              e.preventDefault();
              let passErrorMessage = hasErrorPass(pass);
              if (passErrorMessage) {
                setPassError(passErrorMessage);
                return;
              }

              if (pass !== passRepeat) {
                setPassRepeatError(tr('password_match_error'));
                return;
              } else {
                setPassRepeatError('');
              }

              dispatch(
                setPassword(
                  { password: pass, clientId: parseInt(id, 10), clientToken: token },
                  { handledErrors: getHandledError() }
                )
              );
            },
            loading: storedSetPassword.status.progress,
          },
        }}
      />
    </BackendValidation>
  );
}

export default SetPassword;
