import React from 'react';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'Config';
import tr from 'locale';

import { FaceLogoBlack } from '../../logo';
import Typography from 'components/elements/Typography/';
import lostInSpace from './lost.svg';

const RootStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  min-width: 400px;
  width: 100%;
  padding: 8px 80px;
  background: #fff;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
`;

const TypographyStyled = styled(Typography)`
  padding: 8px 0;
  width: 100%;
  &:last-of-type {
    padding: 8px 0 36px 0;
  }
`;

const LostPictureStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${lostInSpace});
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background-color: hsla(0, 0%, 98%, 1);
`;

const LogoStyled = styled.img`
  height: 40px;
`;

function Page404(props) {
  let logo = FaceLogoBlack;

  const title = tr('404_page_title');
  const text = tr('404_page_text');
  return (
    <RootStyled>
      <InfoStyled>
        <LogoContainer>
          <LogoStyled src={logo} />
        </LogoContainer>
        <InfoContainer>
          <TypographyStyled align="center" size="28" height="32" weight="bold">
            {title}
          </TypographyStyled>
          <TypographyStyled align="center" size="20" height="28">
            {text}
          </TypographyStyled>
          <ButtonWrapper>
            <Button
              size="l"
              text={tr('nuitrack-name')}
              elastic
              as={Link}
              to={`${BASE_URL}/nuitrack/`}
              iconLeft="nuitrack"
              iconColor="hsla(120,48%,48%,1)"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              size="l"
              text={tr('face-name')}
              elastic
              as={Link}
              to={`${BASE_URL}/face/`}
              iconLeft="divi"
              iconColor="hsla(360,88%,56%,1)"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              size="l"
              text={tr('nuitrack-analytics-name')}
              elastic
              as={Link}
              to={`${BASE_URL}/nuitrack-analytics/`}
              iconLeft="nuitrackAnalytics"
              iconColor="hsla(120,48%,48%,1)"
            />
          </ButtonWrapper>
        </InfoContainer>
      </InfoStyled>
      <LostPictureStyled />
    </RootStyled>
  );
}

export default Page404;
