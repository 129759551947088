import { useMemo } from 'react';

const useStatusFilters = (tableData, statusKey, statusesConfig, defaultValue) => {
  const statusFilters = useMemo(
    () => getStatuses(tableData, statusKey, statusesConfig, defaultValue),
    [tableData, statusesConfig, statusKey]
  );
  return statusFilters;
};

function getStatuses(tableData, statusKey, statusesConfig, defaultValue) {
  let statusesCounts = { [defaultValue]: tableData.length };
  tableData.forEach(element => {
    if (statusesCounts[element[statusKey]]) statusesCounts[element[statusKey]] += 1;
    else statusesCounts[element[statusKey]] = 1;
  });

  return statusesConfig.map(el => {
    return {
      text: el,
      limit: statusesCounts[el],
    };
  });
}

export default useStatusFilters;
