// Hooks
import React, { useRef, useEffect } from 'react';
// Other
import tr from 'locale';
// Components
import { Button, COLORS } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';

function ErrorModal(props) {
  const { hideAction } = props;

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  return (
    <ModalComponent
      innerRef={ref}
      autoFocus={true}
      icon="warning"
      iconColor={COLORS.error}
      title={tr('an_error_occurred')}
      buttons={<Button size="m" text={tr('ok')} elastic onClick={hideAction} />}
      {...props}
    />
  );
}

export default ErrorModal;
