import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import PullOutPage from '../../../../blocks/PullOutPage';
import LoaderComponent from 'components/LoaderComponent';
import { Row, Col, SectionHeader, H, Button } from '@3divi/baseui';
import ActionBlock from '../../../../elements/LicenseInfoList/LicenseActionBlock';
import LicenseInfoList from '../../../../elements/LicenseInfoList';
import LicenseInfoEditable from '../../../../elements/LicenseInfoEditable';

const Content = styled.div`
  padding: 16px 24px 32px;

  @media (max-width: 720px) {
    padding: 16px 16px 32px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

const Section = styled.div`
  padding: 0 0 16px;
  margin: 0 0 0 -16px;
  box-shadow: none;
`;

const ListWrap = styled.div`
  margin-left: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const ActionWrap = styled.div`
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 -4px;
  justify-content: center;

  & > button {
    margin: 0 4px;
  }
`;

function NuitrackPreInfoView({ header, isLoaded, activationInfo, metadata, info }) {
  return (
    <PullOutPage
      backUrl={header.backUrl}
      title={header.title}
      subtitle={header.subtitle}
      buttons={
        header.buttons && (
          <Buttons>
            {header.buttons.upgrade && (
              <Button
                text={tr('upgrade')}
                color="primary"
                variant="contained"
                iconLeft="upgrade"
                {...header.buttons.upgrade}
              />
            )}
          </Buttons>
        )
      }
    >
      {!isLoaded && <LoaderComponent />}
      {isLoaded && (
        <Content>
          <H as="h3" padding="8px 0">
            {header.title}
          </H>
          <StyledRow>
            <Col xs={12}>
              {info && (
                <Section>
                  <SectionHeader title={tr('lic_info')} icon="edit" />
                  <ListWrap>
                    {info.map(el =>
                      el.editKey ? (
                        <LicenseInfoEditable
                          key={el.editKey}
                          license={el.license}
                          editKey={el.editKey}
                        />
                      ) : (
                        <LicenseInfoList key={el.text} items={[el]} />
                      )
                    )}
                  </ListWrap>
                </Section>
              )}
              <Section>
                <SectionHeader title={tr('activations_info')} icon="license" />
                <ListWrap>
                  <LicenseInfoList items={activationInfo.items} />
                  <ActionBlock
                    left={activationInfo.action.left}
                    right={
                      <ActionWrap>
                        {activationInfo.action.type === 'Button' && (
                          <Button
                            color="hsla(120,48%,56%,1)"
                            theme="contained"
                            {...activationInfo.action.right.props}
                          />
                        )}
                      </ActionWrap>
                    }
                  />
                </ListWrap>
              </Section>
              {metadata.length > 0 && (
                <Section>
                  <SectionHeader title={tr('metadata')} icon="info" />
                  <ListWrap>
                    <LicenseInfoList items={metadata} />
                  </ListWrap>
                </Section>
              )}
            </Col>
          </StyledRow>
        </Content>
      )}
    </PullOutPage>
  );
}

export default NuitrackPreInfoView;
