import React from 'react';
import tr from 'locale';

import ButtonModal from './ButtonModal';
import { FillBalanceBuy } from '../';

function ButtonFillBalance({ button, modal, price, ...other }) {
  let gettingPrice = price || (modal && modal.price);
  return (
    <ButtonModal
      {...other}
      price={gettingPrice}
      modal={{
        component: FillBalanceBuy,
        subtitle: gettingPrice
          ? tr('at_least_funds_to_purchase', { price: gettingPrice })
          : undefined,
        ...modal,
      }}
      button={{
        text: tr('fill_balance'),
        additionalText: tr('at_least_funds_to_purchase', { price: gettingPrice }),
        ...button,
      }}
    />
  );
}

export default ButtonFillBalance;
