import { BASE_URL } from 'Config';
import { store } from './index';

var API_URL = '/api';

// location may be relative ('/app/{...}/') or absolute ('https://google.com')
export const getHref = (location, fallbackLocation) => {
  if (!location) {
    // not provided
    return getHref(fallbackLocation, '/');
  }
  if (location.indexOf('http') !== 0) {
    // relative
    const { host, protocol } = window.location;
    const href = `${protocol}//${host}${location}`;
    return href;
  }
  if (location.indexOf('http') === 0) {
    // absolute
    return location;
  }
};

var getCookies = function () {
  var cookiesMap = {};
  var cookiesList = document.cookie.split(';').map(c => c.trim().split('='));
  cookiesList.map(el => (cookiesMap[el[0]] = el[1]));
  return cookiesMap;
};

export const isLogged = function () {
  return getCookies()['user_status'] === 'logged';
};

/*
  fetchSuccessHandler can be improved, it can throw custom error RequestError, which can have .response field which can be access in try/catch
  // good for future improvements,
  // there is can be new error class inherented from RequestError,
  // may be overhead

  available beatiful log
  console.error(error)

  const errorCode = error.response.error_code; 
  without async/await

  can be type-class checked 
  if (error.name instanceof RequestError){}

  reading response content
  const errorCode = error.response.error_code;
*/
const fetchSuccessHandler = (response = new Response()) => {
  if (response.ok) {
    return response.json().catch(error => {
      console.error('Successful response parsing error: ', error);
      return Promise.reject({ error_code: 'internal_server_error' });
    });
  } else {
    store.dispatch({
      type: 'CHECK_LOGIN',
      payload: { logged: isLogged() },
    });
    return response
      .json()
      .catch(error => {
        console.error('Failed response parsing error: ', error);
        return Promise.reject({ error_code: 'internal_server_error' });
      })
      .then(json => Promise.reject(json));
  }
};
const fetchFailedHandler = reason => {
  console.error('Fetch failed: ', reason);
  return new Promise((resolve, reject) => {
    setTimeout(reject, 2000, { error_code: 'network_error' });
  });
};

const sendGetRequest = function (url, params) {
  let paramsInUrl = '';
  if (params)
    Object.keys(params).forEach(paramName => {
      if (paramsInUrl.length !== 0) paramsInUrl = `${paramsInUrl}&`;
      paramsInUrl = `${paramsInUrl}${paramName}=${params[paramName]}`;
    });
  url = params ? `${url}?${paramsInUrl}` : url;

  return fetch(API_URL + url, { credentials: 'same-origin' }).then(
    fetchSuccessHandler,
    fetchFailedHandler
  );
};

const sendPostRawBodyRequest = function (url, data) {
  let headers = new Headers();
  headers.append('X-CSRFToken', getCookies()['csrftoken']);
  return fetch(API_URL + url, {
    credentials: 'same-origin',
    headers: headers,
    method: 'POST',
    body: JSON.stringify(data),
  }).then(fetchSuccessHandler, fetchFailedHandler);
};

export const getUserInfo = function () {
  return sendGetRequest('/client/');
};

export const setPasswordByToken = function (id, token, password) {
  return sendPostRawBodyRequest('/client/reset-password/', {
    client_id: id,
    confirmation_token: token,
    new_password: password,
  });
};

export const emailConfirm = function (id, token) {
  return sendPostRawBodyRequest('/client/confirm/', { client_id: id, confirmation_token: token });
};

export const purchaseProducts = function (
  purchaseList,
  { platform, paymentMethod, isSubscribe, promocode }
) {
  var successUrl =
    window.location.origin + BASE_URL + '/' + platform + '/license-purchase-success/';
  var cancelUrl = window.location.origin + BASE_URL + '/' + platform + '/dashboard/';
  var data = {
    payment_source: paymentMethod,
    success_url: successUrl,
    cancel_url: cancelUrl,
    items: [],
    promocode,
  };
  purchaseList.map(el =>
    data.items.push({
      config: el.config,
      count: el.count,
    })
  );
  return isSubscribe
    ? sendPostRawBodyRequest('/resource/subscribe/', data)
    : sendPostRawBodyRequest('/resource/buy/', data);
};

export const splitGroupKey = function (groupId, limit, groupName) {
  var data = { title: groupName, limit: limit };
  return sendPostRawBodyRequest('/group/' + groupId + '/split/', data);
};

export const getLicensesList = function () {
  return sendGetRequest('/license/');
};

export const getActivationKey = function ({ groupId, title }) {
  var responseData = { title: title };
  return sendPostRawBodyRequest('/group/' + groupId + '/new-license/', responseData);
};

export const generateOfflineLicense = function (groupId, data) {
  return sendPostRawBodyRequest('/group/' + groupId + '/activate/offline/', { data });
  // return new Promise((resolve, reject) => setTimeout(() => resolve({id: 9999}), 1000));
};

export const activateOfflineLicense = function (licenseId, { data, usbToken }) {
  return sendPostRawBodyRequest('/license/' + licenseId + '/activate/offline/', {
    data: data,
    hardware_key_serial: usbToken,
  });
};

export const getGroupData = function () {
  return sendGetRequest('/group/');
};

export const getLicenseData = function (id) {
  return sendGetRequest(`/license/${id}/`);
};

export const logout = function () {
  return sendGetRequest('/client/logout/');
};

export const loginRequest = function ({ login, password, loginToNA }) {
  return sendPostRawBodyRequest('/client/signin/', {
    username: login,
    password,
    nuitrack_analytics_login: loginToNA,
  });
};

export const validatePromocodeApi = ({ promocode, platform }) => {
  return sendPostRawBodyRequest('/promocode/validate/', { promocode, product: platform });
};

export const registrationRequest = function (email, password, isCorporate, platform) {
  return sendPostRawBodyRequest('/client/signup/', {
    username: email,
    password: password,
    email: email,
    is_corporate: isCorporate,
    segment: platform,
  });
};
export const resetPassword = function (info, platform) {
  let data;
  if (info.email) {
    data = {
      client_email: info.email,
      segment: platform,
    };
  } else if (info.id) {
    data = {
      client_id: info.id,
      segment: platform,
    };
  }
  return sendPostRawBodyRequest('/client/send-password-reset-url/', data);
};

export const confirmAccount = function (email, platform) {
  return sendPostRawBodyRequest('/client/send-confirmation-url/', {
    client_email: email,
    segment: platform,
  });
};

export const upgradeLicenses = (id, { paymentMethod, platform }) => {
  const successUrl = `${window.location.origin}${BASE_URL}/${platform}/license-purchase-success/?next=${BASE_URL}/${platform}/dashboard/${id}/info/`;
  var cancelUrl = window.location.origin + BASE_URL + '/' + platform + '/dashboard/';
  var data = {
    payment_source: paymentMethod,
    success_url: successUrl,
    cancel_url: cancelUrl,
  };
  return sendPostRawBodyRequest(`/license/${id}/upgrade/`, data);
};

export const upgradeGroupLicenses = (id, { paymentMethod, platform }) => {
  const successUrl =
    window.location.origin + BASE_URL + '/' + platform + '/license-purchase-success/';
  const cancelUrl = window.location.origin + BASE_URL + '/' + platform + '/dashboard/';
  const data = {
    success_url: successUrl,
    cancel_url: cancelUrl,
    payment_source: paymentMethod,
    limit: 1,
  };
  return sendPostRawBodyRequest(`/group/${id}/upgrade/`, data);
};

export const pauseLicense = id => {
  return sendPostRawBodyRequest(`/license/${id}/pause/`, {});
};

export const resumeLicense = id => {
  return sendPostRawBodyRequest(`/license/${id}/resume/`, {});
};

export const setLicenseTitle = (id, title) => {
  return sendPostRawBodyRequest(`/license/${id}/`, { title });
};

export const setGroupTitle = (id, title) => {
  return sendPostRawBodyRequest(`/group/${id}/`, { title });
};

export const setGroupLimit = (id, limit) => {
  return sendPostRawBodyRequest(`/group/${id}/resize/`, { limit });
};

export const validateToken = (clientId, token) => {
  return sendGetRequest(`/client/${clientId}/validate-confirmation-token/${token}/`);
};

export const postPaypalApprove = data => {
  return sendPostRawBodyRequest('/paypal/capture-payment/', data);
};

export const getChangelogData = () => {
  return sendGetRequest(`/changelog/`);
};

export const deactivateFaceMachine = id => {
  return sendPostRawBodyRequest(`/facemachine/${id}/deactivate-workspace/`);
};
export const activateFaceMachine = id => {
  return sendPostRawBodyRequest(`/facemachine/${id}/activate-workspace/`);
};
export const deactivateNuitrackAnalytics = id => {
  return sendPostRawBodyRequest(`/nuitrack-analytics/${id}/deactivate-workspace/`);
};
export const activateNuitrackAnalytics = id => {
  return sendPostRawBodyRequest(`/nuitrack-analytics/${id}/activate-workspace/`);
};

export const updateClientData = function (userData) {
  var userDataResponse = {
    company: userData.company,
    email: userData.email,
    last_name: userData.last_name,
    name: userData.name,
    options: {
      balance_autofill: userData.options.balance_autofill,
      bills_mail: userData.options.bills_mail,
      news_notification: userData.options.news_notification,
      updates_notification: userData.options.updates_notification,
      corporate_member: userData.options.corporate_member,
    },
    phone: userData.phone,
    position: userData.position,
  };
  return sendPostRawBodyRequest('/client/', userDataResponse);
};

export const createResource = function (config, title, count) {
  return sendPostRawBodyRequest('/resource/create/', {
    title: title,
    config: config,
    count: count,
  });
};

export const getTransactions = function (product) {
  return sendGetRequest('/transaction/');
};

export const revokeLicense = id => {
  return sendPostRawBodyRequest(`/license/${id}/release/`);
};

export const changePasswordByPassword = (password, newPassword) => {
  return sendPostRawBodyRequest('/client/change-password/', {
    old_password: password,
    new_password: newPassword,
  });
};

export const sendFeedbackMessage = (score, comment) => {
  return sendPostRawBodyRequest('/feedback/', { score: parseInt(score), comment });
};

export const createCustomResource = function ({
  config,
  title,
  count,
  mail,
  customTags,
  customTagsExtra,
}) {
  return sendPostRawBodyRequest('/resource/custom/create/', {
    config,
    title,
    count,
    client_email: mail,
    custom_tags: customTags,
    custom_tags_extra: customTagsExtra,
  });
};
export const reactivateSubscription = function ({ licenseId, platform, redirectParams = '' }) {
  var successUrl = `${window.location.origin}${BASE_URL}/${platform}/license-purchase-success/${redirectParams}`;
  var cancelUrl = window.location.origin + BASE_URL + '/' + platform + '/dashboard/';

  return sendPostRawBodyRequest(`/license/${licenseId}/reactivate-subscription/`, {
    success_url: successUrl,
    cancel_url: cancelUrl,
  });
};
export const postSalesMessage = function (platform, clientName, clientEmail, message) {
  return sendPostRawBodyRequest('/support/contacts-sales/', {
    recipient: platform,
    name: clientName,
    email: clientEmail,
    message,
  });
};

export const getFaceMachineRates = () => {
  return sendGetRequest(`/face-machine/rates/`);
};

export const getLicenseUpgradeCost = id => {
  return sendGetRequest(`/nuitrack-analytics/${id}/upgrade-cost/`);
};

export const sendRegisterNuitrackAnalytics = ({ username, password, reCaptchaToken }) => {
  return sendPostRawBodyRequest(`/nuitrack-analytics/signup/`, {
    username,
    password,
    captcha_token: reCaptchaToken,
  });
};

export const getFSDKVersions = () => {
  return sendGetRequest(`/versions/face/`);
};

export const refillBalance = function ({ amount, platform, paymentMethod = 'stripe' }) {
  var successUrl =
    window.location.origin + BASE_URL + '/' + platform + '/balance-purchase-success/';
  var cancelUrl = window.location.origin + BASE_URL + '/' + platform + '/dashboard/';
  var data = {
    success_url: successUrl,
    cancel_url: cancelUrl,
    amount: amount,
    payment_source: paymentMethod,
  };
  return sendPostRawBodyRequest('/balance/refill/', data);
};
