// Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { handleHideModal } from 'store/modals/actions';
import { handleGenerateOffline, handleActivateOffline } from '../../../store/licenseInfo/actions';
// Other
import tr from 'locale';
// Components
import { Button } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';

function FaceActivateWarning(props) {
  const { onActivateLicense, license, group, getb64file, ...other } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  return (
    <ModalComponent
      icon="warning"
      iconColor="warning"
      title={tr('warning')}
      subtitle={tr('activate_offline_warning_message')}
      buttons={
        <>
          <Button
            innerRef={ref}
            autoFocus={true}
            text={tr('button_continue')}
            elastic
            size="m"
            color="primary"
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              if (license) {
                const response = await dispatch(
                  handleActivateOffline({ license }, { data: getb64file() })
                );
                onActivateLicense(response.license);
                setLoading(false);
              } else if (group) {
                const response = await dispatch(handleGenerateOffline(group, getb64file()));
                onActivateLicense(response.license);
                setLoading(false);
              } else {
                setLoading(false);
              }
            }}
          />
          <Button
            text={tr('button_cancel')}
            elastic
            size="m"
            onClick={() => dispatch(handleHideModal(props.name))}
          />
        </>
      }
      {...other}
    ></ModalComponent>
  );
}

export default FaceActivateWarning;
