import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

const StyledFooter = styled.div`
  margin: 40px -32px 0;
  padding: 10px 32px;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.secondary};
  border-top: 1px solid ${({ theme }) => theme.border.default};
`;

function Footer(props) {
  const { ...other } = props;
  return (
    <StyledFooter {...other}>{tr('copyright', { year: new Date().getUTCFullYear() })}</StyledFooter>
  );
}

Footer.defaultProps = {};

export default Footer;
