import { decode } from 'base64-arraybuffer';

export const base64ToFile = ({ base64, blobOptions, filename }) => {
  var arrBuffer = decode(base64);
  var newBlob = new Blob([arrBuffer], blobOptions);

  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, filename);
  } else {
    var data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    document.body.appendChild(link); //required in FF, optional for Chrome
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }
};

export const blobToFile = ({ blob, filename }) => {
  //IE11 support
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var data = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    document.body.appendChild(link); //required in FF, optional for Chrome
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }
};
