import React, { useState } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Link } from 'react-router-dom';

import { ModalContent, ModalHeader, ModalBody, Button, Box, Form } from '@3divi/baseui';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../../store/clientdata/actions';
import { validEmailRegexp } from '../../../helpers/regexp';
import Header from '../../../Header';

const RecoveryLink = styled(Link)`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.link.default};

  &:hover {
    color: ${({ theme }) => theme.link.hover};
  }

  &:active {
    color: ${({ theme }) => theme.link.active};
  }
`;

const getHandledErrors = () => ['3fc79796', 'e1a53088', 'e6f34eac'];

function NuitrackAnalyticsLogin() {
  const [storeKey, setStoreKey] = useState(Date.now());
  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <ModalContent fixed="440">
      <Header title={tr('tabtitle_nuitrack_analytics_cloud')} />
      <ModalHeader
        // TODO
        logo="3divi"
      />
      <ModalBody title={tr('na_login_title')} light>
        <Form
          type="blur"
          onSubmit={
            loading
              ? undefined
              : async values => {
                  setLoading(true);
                  setLoginError();
                  setPasswordError();
                  try {
                    await dispatch(
                      loginAction(
                        { login: values.login, password: values.password },
                        { storeKey, handledErrors: getHandledErrors(), loginToNA: true }
                      )
                    );
                    window.location.href = '/api/service/redirect/?service=nuitrack-analytics';
                  } catch (requestError) {
                    const { error_code: errorCode } = requestError;
                    const errorMsg = tr(errorCode);
                    if (errorCode === '3fc79796')
                      // wrong password
                      setPasswordError(errorMsg);
                    else if (errorCode === 'e1a53088')
                      // login not exists
                      setLoginError(errorMsg);
                    else if (errorCode === 'e6f34eac')
                      // account not confirmed
                      setLoginError(errorMsg);
                  } finally {
                    setLoading(false);
                  }
                }
          }
        >
          <Form.Input
            label={tr('login_email_label')}
            name="login"
            tabIndex="1"
            autofocus={true}
            variant="column"
            size="m"
            maxlength={999}
            error={{ message: loginError }}
            rules={[
              { validator: v => !!v, message: tr('email_empty_error') },
              {
                validator: v => !v || v.length <= 150,
                message: tr('field_max_length_error', { field: 'email', maxLength: 150 }),
              },
              { validator: v => validEmailRegexp.test(v), message: tr('email_validation_error') },
            ]}
          />
          <Form.Input
            label={tr('login_password_label')}
            type="password"
            name="password"
            tabIndex="2"
            variant="column"
            size="m"
            maxlength={999}
            error={{ message: passwordError }}
            rules={[
              {
                validator: v => !v || v.length <= 256,
                message: tr('field_max_length_error', { field: 'password', maxLength: 256 }),
              },
            ]}
          />
          <Box pt={0.5}>
            <RecoveryLink to="/app/forgot-password/">{tr('na_login_forgot_pass')}</RecoveryLink>
          </Box>
          <Box py={2}>
            <Button
              tabIndex="3"
              variant="contained"
              size="m"
              elastic
              color="primary"
              text={tr('na_login_button')}
              loading={loading}
            />
          </Box>
        </Form>
      </ModalBody>
    </ModalContent>
  );
}

export default NuitrackAnalyticsLogin;
