const sortDefaultGroups = defaultGroups => {
  // sorting groups and adding required
  let sortedDefaultGroups = [];
  const hasAvailableLicense = group => group.limit > 0;
  const requiredNuitrackGroups = ['old', 'tvico', 'nuitrack_ai_perpetual'];
  const nuitrackOrder = [
    'nuitrack_ai_perpetual',
    'old',
    'nuitrack_unlimited',
    'nuitrack_subscribe',
    'tvico',
    'real_dance',
  ];
  // push group to result if it was founded or it is required to showing
  const emplaceNuitrackGroup = (group, product) => {
    if (group) {
      sortedDefaultGroups.push(group);
    } else if (requiredNuitrackGroups.some(el => el === product)) {
      // minimal group info
      sortedDefaultGroups.push({
        type: 'nuitrack',
        is_default: true,
        limit: 0,
        config: { product },
      });
    }
  };
  nuitrackOrder.forEach(product => {
    // these groups have os_type, which should be sorted 0, 1, 2
    if (product === 'nuitrack_subscribe' || product === 'nuitrack_unlimited') {
      const osTypes = [1, 0, 2];
      osTypes.forEach(os => {
        const group = defaultGroups.find(
          gr => gr.config.product === product && gr.config.os_type === os && hasAvailableLicense(gr)
        );
        emplaceNuitrackGroup(group, product);
      });
    } else {
      const group = defaultGroups.find(
        gr => gr.config.product === product && hasAvailableLicense(gr)
      );
      emplaceNuitrackGroup(group, product);
    }
  });
  // push group to result if it was founded or it is required to showing (all facesdk groups are required)
  const emplaceFaceAllInOne = (group, months, seconds) => {
    if (group) {
      group.months = months;
      sortedDefaultGroups.push(group);
    } else {
      sortedDefaultGroups.push({
        type: 'face',
        is_default: true,
        months,
        limit: 0,
        config: { product: 'face_online_all_in_one', license_expire_delta: seconds },
      });
    }
  };
  const emplaceFaceGroup = (group, product) => {
    if (group) {
      sortedDefaultGroups.push(group);
    } else {
      sortedDefaultGroups.push({
        type: 'face',
        is_default: true,
        limit: 0,
        config: { product },
      });
    }
  };
  // for product face_online_all_in_one provides array [months, seconds] from license.config
  // otherwise provide product name
  const faceOrder = [
    [1, 2592000],
    [3, 7776000],
    [6, 15552000],
    [12, 31104000],
  ];
  faceOrder.forEach(el => {
    const [months, seconds] = el;
    const group = defaultGroups.find(
      gr =>
        gr.config.product === 'face_online_all_in_one' &&
        gr.config.license_expire_delta === seconds &&
        hasAvailableLicense(gr)
    );
    emplaceFaceAllInOne(group, months, seconds);
  });

  return sortedDefaultGroups;
};

export default sortDefaultGroups;
