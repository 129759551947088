import React from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { LICENSESERVER } from '@3divi/baseui';
import { FaceLogoBlack } from '../../logo';

const StyledLoginPromo = styled.div`
  padding: 40px 48px;
  position: relative;
  flex: 1;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    top: 0;
    right: -1px;
    width: 1px;
    background: ${LICENSESERVER.border.default};
  }

  &:after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0px;
    right: 0px;
    height: 1px;
    background: ${LICENSESERVER.border.default};
  }

  @media (max-width: 720px) {
    padding: 32px 24px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: left;
  padding: 0 0 16px;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

const Subtitle = styled.div`
  font-size: 17px;
  line-height: 24px;
  text-align: left;
  padding: 0 0 16px;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

const LogoHeader = styled.div`
  height: 24px;
  background-image: url(${FaceLogoBlack});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 32px;

  @media (max-width: 720px) {
    margin: 0 auto 32px;
  }
`;

function RegPromo() {
  return (
    <StyledLoginPromo>
      <LogoHeader />
      <Title>{tr('registration_na_promo_title')}</Title>
      <Subtitle>{tr('registration_na_promo_text')}</Subtitle>
    </StyledLoginPromo>
  );
}

export default RegPromo;
