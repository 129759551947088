import { handleActions } from 'redux-actions';
import { getLicense } from '../helpers/getLicense';

const defaultState = {
  licenseLoaded: false,
  licenseInfo: null,

  setLicenseTitle: {},
  setLicenseTitleDefault: {
    status: {
      progress: false,
      success: false,
      failure: false,
    },
  },
  clearIds: [],
};

const reducer = handleActions(
  {
    FETCH_LICENSE_INFO_REQUEST: (state, action) => {
      return {
        ...state,
        licenseLoaded: action.payload.licenseLoaded,
        licenseInfo: action.payload.license,
        licenseInfoError: null,
      };
    },
    FETCH_LICENSE_INFO_SUCCESS: (state, action) => {
      const { license, prices, isCustomGroupMap } = action.payload;
      if (state.clearIds.some(clearId => clearId === license.id)) {
        // reject response if license was cleared, remove 1 id from clearIds array
        state.clearIds.splice(
          state.clearIds.indexOf(clearId => clearId === license.id),
          1
        );
        return {
          ...state,
          clearIds: state.clearIds,
        };
      } else
        return {
          ...state,
          licenseLoaded: action.payload.licenseLoaded,
          licenseInfo: {
            ...state.licenseInfo,
            ...getLicense(license, { prices, isCustomGroupMap }),
          },
          licenseInfoError: null,
        };
    },
    FETCH_LICENSE_INFO_FAILURE: (state, action) => {
      return {
        ...state,
        licenseLoaded: action.payload.licenseLoaded,
        licenseInfoError: action.payload.error,
        licenseInfoErrorCode: action.payload.errorCode,
        licenseInfo: action.payload.license,
      };
    },
    LICENSE_STATUS_REQUEST: (state, action) => {
      return {
        ...state,
        licenseStatusLoading: action.payload.loading,
        licenseStatusLoaded: action.payload.loaded,
        licenseStatusError: null,
        licenseStatusErrorCode: undefined,
      };
    },
    LICENSE_STATUS_SUCCESS: (state, action) => {
      const { license, prices, isCustomGroupMap } = action.payload;
      return {
        ...state,
        licenseStatusLoaded: action.payload.loaded,
        licenseStatusLoading: action.payload.loading,
        licenseInfo: { ...state.licenseInfo, ...getLicense(license, { prices, isCustomGroupMap }) },
        licenseStatusError: null,
      };
    },
    LICENSE_STATUS_FAIL: (state, action) => {
      return {
        ...state,
        licenseStatusLoaded: action.payload.loaded,
        licenseStatusLoading: action.payload.loading,
        licenseStatusError: action.payload.error,
        licenseStatusErrorCode: action.payload.errorCode,
      };
    },
    CLEAR_STATUS_LICENSE: (state, action) => {
      return {
        ...state,
        licenseStatusLoaded: false,
        licenseStatusLoading: false,
        licenseStatusError: null,
        licenseStatusErrorCode: null,
      };
    },
    SET_LICENSE_TITLE_REQUEST: (state, action) => {
      return {
        ...state,
        setLicenseTitle: {
          [action.payload.storeKey]: {
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    SET_LICENSE_TITLE_SUCCESS: (state, action) => {
      const { license, prices, isCustomGroupMap } = action.payload;

      return {
        ...state,
        licenseInfo: { ...state.licenseInfo, ...getLicense(license, { prices, isCustomGroupMap }) },
        setLicenseTitle: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
      };
    },
    SET_LICENSE_TITLE_FAILURE: (state, action) => {
      return {
        ...state,
        setLicenseTitle: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    ACTIVATE_WORKSPACE_SUCCESS: (state, action) => {
      const { license, prices, isCustomGroupMap } = action.payload;
      return {
        ...state,
        licenseInfo: { ...state.licenseInfo, ...getLicense(license, { prices, isCustomGroupMap }) },
      };
    },
    DEACTIVATE_WORKSPACE_SUCCESS: (state, action) => {
      const { license, prices, isCustomGroupMap } = action.payload;
      return {
        ...state,
        licenseInfo: { ...state.licenseInfo, ...getLicense(license, { prices, isCustomGroupMap }) },
      };
    },
    CLEAR_LICENSE_INFO: (state, action) => {
      const { id } = action.payload;
      const clearIds =
        state.licenseInfo && state.licenseInfo.id === id ? state.clearIds : [...state.clearIds, id];
      return {
        ...state,
        licenseLoaded: false,
        licenseStatusLoaded: false,
        licenseStatusLoading: false,
        licenseInfoError: null,
        licenseInfoErrorCode: null,
        licenseInfo: null,
        clearIds,
      };
    },
  },
  defaultState
);

export default reducer;
