import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSectionHeader = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 16px;
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const Container = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  align-items: start;

  &:hover path {
    fill: ${props => (props.headerLink ? props.theme.icon.color.hover : '')};
  }
`;

const I = styled.i`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: ${({ theme }) => theme.background.primary};

  & > svg {
    margin: 4px;
  }
`;

const TitleContainer = styled.div`
  overflow: hidden;
  padding: 4px 8px;
`;

const Title = styled.h3`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: ${({ theme }) => theme.font.m};
  color: ${({ theme }) => theme.text.default};
  font-weight: 500;
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.secondary};
  padding-top: 4px;
`;

const Buttons = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

function SectionHeader({ title, subtitle, buttons, headerLink, icon, ...props }) {
  const Icon = icon;
  return (
    <StyledSectionHeader {...props}>
      <Container
        as={headerLink ? Link : null}
        to={headerLink ? headerLink : null}
        headerLink={headerLink}
      >
        {icon && (
          <I>
            <Icon />
          </I>
        )}
        {title && (
          <TitleContainer>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleContainer>
        )}
      </Container>
      {buttons && <Buttons>{buttons}</Buttons>}
    </StyledSectionHeader>
  );
}

SectionHeader.defaultProps = {
  title: '',
  subtitle: '',
  headerLink: undefined,
  buttons: undefined,
  icon: undefined,
};

export default SectionHeader;
