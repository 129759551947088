import React from 'react';
import { Switch, Route } from 'react-router';
import { goods } from './goods';
import Checkout from './Checkout';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { usePaypalOptions } from '../../../elements/PaypalButton/usePaypalOptions';
import Page404 from '../../404/Page404';

function CheckoutRoutes(props) {
  const { platform } = props;

  const captureOptions = usePaypalOptions();
  const subscriptionOptions = usePaypalOptions(true);

  return (
    <Switch>
      <Route
        exact
        path={`/app/:platform(nuitrack|face-machine|nuitrack-analytics)/basket/checkout/:checkoutType(upgrade|upgrade-y)/:licenseType(pro|trial)/:toUpgradeId/`}
        render={({ match }) => {
          return (
            <PayPalScriptProvider options={captureOptions}>
              <Checkout
                goods={goods[platform].upgrades[match.params.licenseType]}
                controlType="none"
              />
            </PayPalScriptProvider>
          );
        }}
      />
      <Route
        exact
        path={`/app/:platform(nuitrack|face)/basket/checkout/:checkoutType(subscriptions)/:licenseType/`}
        render={({ match }) => (
          <PayPalScriptProvider options={subscriptionOptions}>
            <Checkout
              goods={goods[platform].subscriptions[match.params.licenseType]}
              controlType="radio"
            />
          </PayPalScriptProvider>
        )}
      />
      <Route exact path={`/app/:platform(nuitrack-analytics)/basket/checkout/`}>
        <PayPalScriptProvider options={captureOptions}>
          <Checkout
            goods={goods[platform].licenses}
            controlType="radio" // because license only 1 type license, and backend creates 1 license (even if choosen more)
          />
        </PayPalScriptProvider>
      </Route>
      <Route exact path={`/app/:platform(nuitrack|face)/basket/checkout/`}>
        <PayPalScriptProvider options={captureOptions}>
          <Checkout goods={goods[platform].licenses} controlType="stepper" />
        </PayPalScriptProvider>
      </Route>
      <Route component={Page404} />
    </Switch>
  );
}

export default CheckoutRoutes;
