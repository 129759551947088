export const getSourceGroup = (group, defaultGroups) => {
  if (!group || group.is_default) return;

  const isProductEqual = (gr1, gr2) => gr1.config.product === gr2.config.product;
  const isOsEqual = (gr1, gr2) => gr1.config.os_type === gr2.config.os_type;

  const osChecking = ['nuitrack_subscribe', 'nuitrack_unlimited'];

  const sourceGroup = defaultGroups.find(gr => {
    // checking product equality by default
    let groupsEqual = isProductEqual(group, gr);
    if (groupsEqual && osChecking.some(product => product === group.config.product)) {
      groupsEqual = isOsEqual(group, gr);
    }
    return groupsEqual;
  });

  return sourceGroup;
};
