import React from 'react';
import EditableTitle from './EditableTitle';
import EditableLicensesInGroup from './EditableLicensesInGroup';

function LicenseInfoEditable(props) {
  switch (props.editKey) {
    case 'title':
      return <EditableTitle {...props} />;
    case 'licenses_available':
      return <EditableLicensesInGroup {...props} />;
    default:
      return;
  }
}

export default LicenseInfoEditable;
