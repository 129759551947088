import React from 'react';
import { Link } from 'react-router-dom';
import { useGroups } from '../hooks/';

import Groups from './Groups';

const groupsBuyLink = {
  old: {
    link: {
      as: Link,
      to: '/basket/checkout/',
      state: { addLicenseType: 'old' },
    },
  },
  tvico: {
    link: {
      as: 'a',
      href: 'https://tvico.io/#buynow',
    },
  },
  nuitrack_ai_perpetual: {
    link: {
      as: Link,
      to: `/basket/checkout/`,
      state: { addLicenseType: 'nuitrack_ai_perpetual' },
    },
  },
};

function GroupsNuitrack({ groupsStore, platform, ...others }) {
  const groups = useGroups(groupsBuyLink, groupsStore, platform);

  return <Groups groups={groups} platform={platform} {...others} />;
}

export default GroupsNuitrack;
