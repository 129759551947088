import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { themeDefault } from 'components/themes/themeDefault';
import { isLight, Col, Tooltip, WithIcon } from '@3divi/baseui';

const tableData = [
  {
    type: 'title',
    data: { title: '', trial: tr('trial'), pro: tr('pro'), ai: tr('ai') },
  },
  {
    title: tr('properties'),
    data: [
      {
        title: tr('session_duration_limit'),
        trial: tr('3_minutes'),
        pro: tr('unlimited'),
        ai: tr('unlimited'),
      },
      { title: tr('commercial_use'), trial: 'no', pro: 'yes', ai: 'yes' },
      {
        title: tr('fully_portable'),
        trial: 'no',
        pro: tr('subscription_license'),
        ai: tr('subscription_license'),
      },
      {
        title: tr('works_offline'),
        trial: 'yes',
        pro: tr('perpetual_license'),
        ai: tr('perpetual_license'),
      },
    ],
  },
  {
    title: tr('hardware_requirements'),
    data: [
      {
        title: tr('desktop_or_pc'),
        ai: tr('requirements_pc_ai'),
        pro: tr('requirements_pc_pro'),
        trial: tr('requirements_pc_trial'),
        tooltip: {
          ai: { text: tr('requirements_pc_ai_tooltip') },
          pro: { text: tr('requirements_pc_pro_tooltip') },
          trial: { text: tr('requirements_pc_trial_tooltip') },
        },
      },
      {
        title: tr('mobile'),
        ai: 'no',
        pro: tr('requirements_mobile_pro'),
        trial: tr('requirements_mobile_trial'),
        tooltip: {
          pro: { text: tr('requirements_mobile_pro_tooltip') },
          trial: { text: tr('requirements_mobile_trial_tooltip') },
        },
      },
    ],
  },
  {
    title: tr('supported_platforms'),
    data: [
      { title: 'Linux x64', trial: 'yes', pro: 'yes', ai: 'yes' },
      { title: 'Linux ARMv7', trial: 'yes', pro: 'yes', ai: 'no' },
      { title: 'Windows x64', trial: 'yes', pro: 'yes', ai: 'yes' },
      { title: 'Windows x86', trial: 'yes', pro: 'yes', ai: 'no' },
      { title: 'Android ARMv7/ARM64', trial: 'yes', pro: 'yes', ai: 'no' },
      { title: 'iOS', trial: 'no', pro: tr('perpetual_license'), ai: 'no' },
    ],
  },
  {
    title: tr('features'),
    data: [
      {
        title: tr('skeletal_tracking'),
        trial: tr('skeletal_tracking_19_6'),
        pro: tr('skeletal_tracking_19_6'),
        ai: tr('skeletal_tracking_19_6'),
      },
      { title: tr('user_segmentation'), trial: 'yes', pro: 'yes', ai: 'yes' },
      { title: tr('face_tracking'), trial: 'yes', pro: 'yes', ai: 'yes' },
      { title: tr('gesture_recognition'), trial: 'yes', pro: 'yes', ai: 'yes' },
      { title: tr('improved_tracking'), trial: 'no', pro: 'no', ai: 'yes' },
      { title: tr('object_detection'), trial: 'no', pro: 'no', ai: 'yes' },
      { title: tr('poses_recognition'), trial: 'no', pro: 'no', ai: 'yes' },
    ],
  },
];

const Background = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background.primary};
`;

const Content = styled.div`
  max-width: 840px;
  margin: 0 auto;
`;

// const Button = styled.div`
//   border: 1px solid hsla(0, 0%, 88%, 1);
//   padding: 5px;
//   width: 32px;
//   height: 32px;
//   box-sizing: border-box;
//   border-radius: 50%;
// `;

const Header = styled.div`
  padding: 56px 16px 32px;
`;

// &:hover {
//   cursor: pointer;
// }

// &:hover ${Button} {
//   border-color: hsla(0, 0%, 80%, 1);
// }

const Title = styled.div`
  font: ${({ theme }) => theme.font.xxl};
  color: ${({ theme }) => theme.text.default};
  font-weight: 500;
  text-align: left;
  text-align: center;
`;

const Table = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  padding: 0 16px 40px;

  @media (max-width: 576px) {
    padding: 0 0 40px;
  }
`;

const TableContent = styled.div`
  position: relative;
  min-width: 540px;
  border-top: 1px solid ${({ theme }) => theme.border.default};

  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 25%;
    background: ${({ theme }) => theme.background.hover};

    @media (max-width: 720px) {
      width: 33.3333%;
    }
  }
`;

const Row = styled.div`
  position: relative;
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
  display: flex;
  flex-flow: row wrap;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.border.default};
    height: 1px;
  }
`;

const Cell = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.text.default};
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  text-align: center;
  font-size: ${props => (props.el === 'yes' || props.el === 'no' ? '0' : '15px')};
  line-height: 20px;
  ${props => (props.el === 'yes' || props.el === 'no' ? 'display: flex;' : '')}
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 720px) {
    padding: 4px 8px 12px;
  }

  ${props =>
    props.el === 'yes' &&
    `
    &:before {
      content: '';
      width: 16px;
      height: 12px;
      display: block;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cpolygon fill='%235DC25D' fill-rule='evenodd' points='828 877.652 826.322 876 817.536 884.628 813.678 880.872 812 882.524 817.536 888' transform='translate(-812 -876)'/%3E%3C/svg%3E");
    }
  `}

  ${props =>
    props.el === 'no' &&
    `
    &:before {
      content: '';
      width: 14px;
      height: 14px;
      display: block;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cpath fill='%238F8F8F' fill-rule='evenodd' d='M621,929 L621,936 L628,936 L628,938 L621,938 L621,945 L619,945 L619,938 L612,938 L612,936 L619,936 L619,929 L621,929 Z' transform='rotate(45 1436.11 -267.956)'/%3E%3C/svg%3E");
    }
  `}
`;

const GroupTitle = styled(Cell)`
  padding: 24px 8px 8px;
  font-weight: 600;
  letter-spacing: 1px;
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.default};
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
  min-width: 200px;

  @media (max-width: 720px) {
    text-align: center;
  }
`;

const RowTitle = styled(Cell)`
  padding-left: 0;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
  justify-content: left;
  min-width: 200px;

  @media (max-width: 720px) {
    text-align: center;
    padding: 12px 8px 4px;
  }
`;

const Name = styled.div`
  font: ${({ theme }) => theme.font.s};
  color: ${props => (isLight(props.nameColor) ? props.theme.text.dark : props.theme.text.white)};
  background: ${props => props.nameColor};
  border-radius: 4px;
  padding: 4px 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
`;

function NuitrackLicensesTable(props) {
  const { ...other } = props;
  // const [open, setOpen] = useState(false);
  return (
    <Background>
      <Content>
        <Header>
          <Title>{tr('license_comprasion')}</Title>
          {/*<Button>
            <Icon icon={open ? 'dropdownActive' : 'dropdown'} />
          </Button>*/}
        </Header>
        <Table>
          <TableContent>
            {tableData.map((el, i) => (
              <>
                {el.type && (
                  <Row key={`${el.type}${Date.now()}${i}`}>
                    <Col xxs={12} xs={12} s={3} m={3} l={3}>
                      <RowTitle />
                    </Col>
                    <Col>
                      <Cell>
                        <Name nameColor="hsla(0,0%,92%,1)">{el.data.trial}</Name>
                      </Cell>
                    </Col>
                    <Col>
                      <Cell>
                        <Name nameColor="hsla(212,96%,64%,1)">{el.data.pro}</Name>
                      </Cell>
                    </Col>
                    <Col>
                      <Cell>
                        <Name nameColor={themeDefault.primaryColor}>{el.data.ai}</Name>
                      </Cell>
                    </Col>
                  </Row>
                )}
                {el.title && (
                  <Row>
                    <GroupTitle>{el.title}</GroupTitle>
                  </Row>
                )}
                {!el.type &&
                  el.data.map(el => (
                    <Row key={`${el.title}${i}`}>
                      <Col xxs={12} xs={12} s={3} m={3} l={3}>
                        <RowTitle>
                          {el.title}
                          {el.title === 'iOS' && (
                            <Tooltip
                              side="top-center"
                              tooltipColor={themeDefault.whiteColor}
                              text={'Occipital sensor'}
                            >
                              <WithIcon icon="info" color="primary"></WithIcon>
                            </Tooltip>
                          )}
                        </RowTitle>
                      </Col>
                      <Col>
                        <Cell el={el.trial}>
                          {el.trial}
                          {el.tooltip && el.tooltip.trial && (
                            <Tooltip
                              side="top-center"
                              tooltipColor={themeDefault.whiteColor}
                              text={el.tooltip.trial.text}
                            >
                              <WithIcon
                                icon={el.tooltip.trial.icon || 'info'}
                                color="primary"
                              ></WithIcon>
                            </Tooltip>
                          )}
                        </Cell>
                      </Col>
                      <Col>
                        <Cell el={el.pro}>
                          {el.pro}
                          {el.tooltip && el.tooltip.pro && (
                            <Tooltip
                              side="top-center"
                              tooltipColor={themeDefault.whiteColor}
                              text={el.tooltip.pro.text}
                            >
                              <WithIcon
                                icon={el.tooltip.pro.icon || 'info'}
                                color="primary"
                              ></WithIcon>
                            </Tooltip>
                          )}
                        </Cell>
                      </Col>
                      <Col>
                        <Cell el={el.ai}>
                          {el.ai}
                          {el.tooltip && el.tooltip.ai && (
                            <Tooltip
                              side="top-center"
                              tooltipColor={themeDefault.whiteColor}
                              text={el.tooltip.ai.text}
                            >
                              <WithIcon
                                icon={el.tooltip.ai.icon || 'info'}
                                color="primary"
                              ></WithIcon>
                            </Tooltip>
                          )}
                        </Cell>
                      </Col>
                    </Row>
                  ))}
              </>
            ))}
          </TableContent>
        </Table>
      </Content>
    </Background>
  );
}

export default NuitrackLicensesTable;
