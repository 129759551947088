// import n from './img/logo_nuitrack.svg';
// import f from './img/logo_face.svg';
import b from './img/logo_nuitrack_b.svg';
import a from './img/logo_3divi_black.svg';
import c from './img/logo_3divi_s_white.svg';

export const NuitrackLogoB = b;
// export const NuitrackLogo = n;
// export const FaceLogo = f;
export const FaceLogoBlack = a;
export const DiviLogoSWhite = c;
