import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStatusFilters, useDayPicker, getDataSelector } from '../../../../hooks';
import { filterByKey, filterByDateRange, getLicenseType, getTableData } from '../../../../helpers';
import tr from 'locale';

import TableLicenses from './TableLicenses';
import ButtonNuitrackAnalyticsServiceRedirect from '../../../elements/ButtonNuitrackAnalyticsServiceRedirect';

const licenseStatusFilters = ['all', 'active', 'suspended'];
const tableConfig = {
  columns: [
    { name: 'id_column', size: 'max-content' },
    { name: 'title_column', size: 'minmax(200px, max-content)' },
    { name: 'license_type_column', size: 'minmax(80px, max-content)' },
    { name: 'license_status_column', size: 'minmax(80px, max-content)' },
    { name: 'subscription_status_column', size: 'minmax(80px, max-content)' },
    { name: 'expire_date_column', size: 'max-content' },
  ],
};

const getNuitrackAnalyticsTableData = license => {
  const result = {
    // data for filters and other
    ...license,
    // product: getProductFilterAlias(license),
    createdDate: license.dates.createdDate,
    expireDate: license.dates.expireDate,
    activationDate: license.dates.activationDate,
    // cell data
    id_column: license.id,
    title_column: license.title,
    license_type_column: getLicenseType(license),
    license_status_column: tr(license.license_status),
    subscription_status_column: license.subscription_status && tr(license.subscription_status),
    expire_date_column: license.expire_date,
  };

  return result;
};

function TableFaceMachine({ platform }) {
  // getting table data
  let licenses = useSelector(state => getDataSelector(state.licenses, 'licenses', platform));
  const { tableData, headers } = useMemo(
    () => getTableData(tableConfig, licenses, getNuitrackAnalyticsTableData),
    [licenses, platform]
  );
  // store and states
  const isLoaded = useSelector(state => state.licenses.fetchLicenses.success);
  const [statusFilter, setStatusFilter] = useState(licenseStatusFilters[0]);
  const [filterChangedTime, setFilterChangedTime] = useState(Date.now());
  const createdDayPicker = useDayPicker();
  const expireDayPicker = useDayPicker();
  // filters
  const filteredByDateFilters = useMemo(() => {
    let data = tableData;
    data = filterByDateRange(data, 'createdDate', [createdDayPicker.from, createdDayPicker.to]);
    data = filterByDateRange(data, 'expireDate', [expireDayPicker.from, expireDayPicker.to]);
    return data;
  }, [
    tableData,
    createdDayPicker.from,
    createdDayPicker.to,
    expireDayPicker.from,
    expireDayPicker.to,
  ]);
  const statuses = useStatusFilters(
    filteredByDateFilters,
    'license_status',
    licenseStatusFilters,
    licenseStatusFilters[0]
  );
  const filteredData = useMemo(
    () =>
      filterByKey(filteredByDateFilters, 'license_status', statusFilter, licenseStatusFilters[0]),
    [filteredByDateFilters, statusFilter]
  );
  // custom actions
  const clearDayPickersSelection = () => {
    createdDayPicker.clearRange();
    expireDayPicker.clearRange();
  };

  const clearAllFilters = () => {
    clearDayPickersSelection();
    setStatusFilter(licenseStatusFilters[0]);
  };

  const isDataFiltered =
    statusFilter !== licenseStatusFilters[0] ||
    createdDayPicker.isRangeChoosen ||
    expireDayPicker.isRangeChoosen;

  useEffect(() => {
    setFilterChangedTime(Date.now());
  }, [createdDayPicker, expireDayPicker, statusFilter]);

  return (
    <TableLicenses
      statusFilter={{
        statuses: statuses,
        isDefault: tableData && tableData.length === 0,
        value: statusFilter,
        callback: setStatusFilter,
      }}
      buttonHeader={
        <ButtonNuitrackAnalyticsServiceRedirect
          disabled={tableData.length <= 0}
          variant="outlined"
        />
      }
      tableData={{
        headers: headers,
        rows: filteredData,
        columns: tableConfig.columns,
        isLoaded: isLoaded,
        emptyText: tr('empty_licenses'),
        rowsChangedTime: filterChangedTime,
      }}
      createdDayPicker={createdDayPicker}
      expireDayPicker={expireDayPicker}
      clearAllFilters={clearAllFilters}
      isDataFiltered={isDataFiltered}
    />
  );
}

export default TableFaceMachine;
