import React, { useMemo, useCallback } from 'react';

// support only 1 level tags
function TaggedText(props) {
  const { text, ...othersProps } = props;

  const getInstance = useCallback((componentInstance, componentClass, props, textInTags) => {
    if (componentInstance) {
      let isTopLevel = true;
      // make a deep clone of nested component and add new children to the last one (or call children factory)
      // also add new props to top level component
      const deepAddChild = (component, newChild) => {
        const newProps = isTopLevel ? props : undefined;
        isTopLevel = false;
        if (component.props && component.props.children) {
          if (React.isValidElement(component.props.children)) {
            return React.cloneElement(
              component,
              newProps,
              deepAddChild(component.props.children, newChild)
            );
          } else if (typeof component.props.children === 'function') {
            return React.cloneElement(component, newProps, component.props.children(newChild));
          } else {
            return React.cloneElement(component, newProps, newChild);
          }
        } else {
          return React.cloneElement(component, newProps, newChild);
        }
      };

      return deepAddChild(componentInstance, textInTags);
    } else {
      return React.createElement(componentClass, props, textInTags);
    }
  }, []);

  const renderResultMemo = useMemo(() => {
    const textParts = [];
    const componentParts = [];
    let afterTagText = text;
    let textOffest = 0;

    const tagRegexp = /<(.+)>(.*?)<\/\1>/g;
    const tagMatches = [...text.matchAll(tagRegexp)];

    tagMatches.forEach((matchResult, i) => {
      const [match, tag, textInTags] = matchResult;
      const beforeMatch = afterTagText.substring(0, matchResult.index - textOffest);
      const afterMatch = afterTagText.substring(matchResult.index + match.length - textOffest);
      textOffest = matchResult.index + match.length;
      afterTagText = afterMatch;
      textParts.push(beforeMatch);

      const tagComponentInstance =
        othersProps[tag] && Array.isArray(othersProps[tag])
          ? othersProps[tag][i]
          : React.isValidElement(othersProps[tag])
          ? othersProps[tag]
          : undefined;
      const tagComponentClass = !tagComponentInstance ? othersProps[tag] : undefined;

      const tagProps =
        !Array.isArray(othersProps[tag]) || tagComponentClass ? { key: `ct-${i}` } : {};

      let componentInstance;

      if (tag === 'a') {
        // <a /> always have link to external resource
        tagProps.target = '_blank';
        tagProps.rel = 'noopener noreffer';
        componentInstance = getInstance(
          tagComponentInstance,
          tagComponentClass || tag,
          tagProps,
          textInTags
        );
      } else if (tag === 'br') {
        componentInstance = React.createElement('br');
      } else if (tag === 'mailto') {
        tagProps.target = '_top';
        tagProps.href = `mailto:${textInTags}`;
        tagProps.noIcon = true;
        componentInstance = getInstance(
          tagComponentInstance,
          tagComponentClass || tag,
          tagProps,
          textInTags
        );
      } else {
        componentInstance = getInstance(
          tagComponentInstance,
          tagComponentClass || tag,
          tagProps,
          textInTags
        );
      }

      componentParts.push(componentInstance);
    });
    textParts.push(afterTagText);

    const renderResult = componentParts.map((component, i) => {
      return textParts[i] ? [<span key={`tt-${i}`}>{textParts[i]}</span>, component] : [component];
    });
    if (textParts[textParts.length - 1])
      renderResult.push(<span key="tt-last">{textParts[textParts.length - 1]}</span>);

    return renderResult;
  }, [text, othersProps, getInstance]);

  return <div style={{ flexWrap: 'wrap' }}>{renderResultMemo}</div>;
}

export default TaggedText;
