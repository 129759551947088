import React, { useState, useCallback, useEffect } from 'react';
import tr from 'locale';
import styled from 'styled-components';
import Smile, { Eyes, Mouth } from './Smile.js';
import { Button, Input, Typography } from '@3divi/baseui';
import { sendFeedbackAction } from '../../../store/clientdata/actions';
import { useDispatch, useSelector } from 'react-redux';
import TaggedText from '../TaggedText';
import StyledLink from '../StyledLink';
import SuccessIcon from './SuccessIcon';

const Content = styled.div`
  padding: 12px 16px;
`;

const StyledFeedback = styled.div`
  position: fixed;
  width: 344px;
  bottom: 12px;
  right: 36px;
  background: ${({ theme }) => theme.background.default};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 12px 40px -4px hsla(0, 0%, 0%, 0.24);
  z-index: 123;
  z-index: 1000000;

  @media (max-width: 768px) {
    right: 16px;
  }

  @media (max-width: 576px) {
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
`;

const Header = styled.div`
  padding: 8px 0 4px;
  text-align: center;
`;

const Title = styled.div`
  font: ${({ theme }) => theme.font.l};
  color: ${({ theme }) => theme.text.default};
  font-weight: 600;
  padding: 4px 0;
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
`;

const Smiles = styled.div`
  padding: 16px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const FeedbackButtonWrap = styled.div`
  position: fixed;
  bottom: 12px;
  right: 36px;
  z-index: 123;
  pointer-events: none;

  @media (max-width: 768px) {
    right: 16px;
  }
`;

const StyledSmile = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

const StyledEyes = styled(Eyes)`
  &:before {
    background: hsla(0, 0%, 100%, 1);
  }
  &:after {
    background: hsla(0, 0%, 100%, 1);
  }
`;

const StyledMouth = styled(Mouth)`
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='6' viewBox='0 0 14 6'%3E%3Cpath fill-rule='evenodd' fill='hsla(0,0%,100%,1)' d='M7,6 C11.5540347,6 15.2855372,1.26681575 13.5780501,0.173043671 C11.870563,-0.920728406 11.0480308,3.52831128 7,3.52831128 C2.95196917,3.52831128 2.12943696,-0.920728406 0.421949904,0.173043671 C-1.28553715,1.26681575 2.44596532,6 7,6 Z'/%3E%3C/svg%3E");
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonWithEmote = styled(Button)`
  & span {
    padding: 0 8px 0 0;
  }
`;

const StyledInput = styled(Input)`
  & textarea:focus {
    z-index: 100;
  }

  & textarea {
    border-radius: 4px 4px 0 0;
  }
`;

const CommentBlock = styled.div`
  padding: 16px;
  margin: 0 -16px -12px;
  background-color: ${({ theme }) => theme.background.primary};
  text-align: center;
`;

const Overlap = styled.div`
  margin-top: -5px;
`;

const Wrap = styled.div`
  margin: 16px 0 0 0;
`;

const CloseButtonWrap = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const ContinueButtonWrap = styled.div`
  margin: 8px 12px 16px;
`;

function Feedback() {
  const [value, setValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showSuccessForm, setShowSuccessForm] = useState(false);
  const [emote, setEmote] = useState('');
  const [ref, setRef] = useState();

  const dispatch = useDispatch();

  const sendFeedback = useSelector(state => state.clientdata.sendFeedback);

  const handleClickOutside = e => {
    if (ref && ref.contains(e.target)) return; // click was on form
    hideForm();
  };

  const hideForm = () => {
    setShowForm(false);
    setShowSuccessForm(false);
  };

  useEffect(() => {
    if (ref) {
      document.addEventListener('click', handleClickOutside, false);
      return () => document.removeEventListener('click', handleClickOutside, false);
    }
  }, [ref]);

  useEffect(() => {
    if (sendFeedback.success) {
      setEmote('');
      setValue('');
      setShowSuccessForm(true);
    }
  }, [sendFeedback.success]);

  const getScore = useCallback(emote => {
    switch (emote) {
      case 'angry':
        return 1;
      case 'sad':
        return 2;
      case 'neutral':
        return 3;
      case 'fun':
        return 4;
      case 'happy':
        return 5;
      default:
        return 0;
    }
  }, []);

  const onSubmit = () => {
    dispatch(sendFeedbackAction({ score: getScore(emote), comment: value }));
  };

  return showForm ? (
    <StyledFeedback ref={setRef}>
      <CloseButtonWrap>
        <Button onClick={hideForm} iconLeft="close" round variant="clear" />
      </CloseButtonWrap>
      <Content>
        {showSuccessForm ? (
          <>
            <SuccessIcon />
            <Header>
              <Title>{tr('send_us_feedback_succes_title')}</Title>
              <Subtitle>{tr('send_us_feedback_succes_subtitle')}</Subtitle>
            </Header>
            <ContinueButtonWrap>
              <Button size="m" text={tr('button_continue')} elastic onClick={hideForm} />
            </ContinueButtonWrap>
          </>
        ) : (
          <>
            <Header>
              <Title>{tr('send_us_feedback')}</Title>
              <Subtitle>{tr('send_us_feedback_subtitle')}</Subtitle>
            </Header>
            <Smiles>
              <Smile emotion="angry" choose={() => setEmote('angry')} emote={emote} />
              <Smile emotion="sad" choose={() => setEmote('sad')} emote={emote} />
              <Smile emotion="neutral" choose={() => setEmote('neutral')} emote={emote} />
              <Smile emotion="fun" choose={() => setEmote('fun')} emote={emote} />
              <Smile emotion="happy" choose={() => setEmote('happy')} emote={emote} />
            </Smiles>
            <CommentBlock>
              <Subtitle>{tr('send_us_feedback_comment_title')}</Subtitle>
              <StyledInput
                maxlength="500"
                lines="3"
                type="textarea"
                value={value}
                onChange={setValue}
              />
              <Overlap>
                <Button
                  text={tr('button_submit_feedback')}
                  elastic
                  borderRadius="0 0 4px 4px"
                  disabled={!emote}
                  color="primary"
                  variant="contained"
                  loading={sendFeedback.loading}
                  onClick={onSubmit}
                />
              </Overlap>
              <Wrap>
                <Typography color="secondary" size="xs" align="center">
                  <TaggedText
                    text={tr('send_us_feedback_comment_support')}
                    a={[
                      <StyledLink
                        key="supp-fresh"
                        size="xxs"
                        href="/api/service/redirect/?service=freshdesk"
                      />,
                    ]}
                  />
                </Typography>
              </Wrap>
            </CommentBlock>
          </>
        )}
      </Content>
    </StyledFeedback>
  ) : (
    <FeedbackButtonWrap>
      <ButtonWithEmote
        borderRadius="21px"
        size="m"
        text={
          <Row>
            <StyledSmile>
              <StyledEyes emotion="fun" />
              <StyledMouth emotion="fun" />
            </StyledSmile>
            {tr('button_feedback')}
          </Row>
        }
        variant="contained"
        color="primary"
        onClick={() => setShowForm(value => !value)}
      />
    </FeedbackButtonWrap>
  );
}

export default Feedback;
