import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from 'Config';
import { handleOpenModal } from '../../store/modals/actions';
import { useWindowSize } from '../../hooks/';

import { Menu as BaseMenu, MenuItem, MenuSection } from '@3divi/baseui';
import { FaceLogoBlack, DiviLogoSWhite } from 'components/logo';
import MenuNav from './MenuNav';
import Balance from './Balance';
import ReleaseInfoModal from '../modals/ReleaseInfoModal';
import StyledLink from '../elements/StyledLink';
import { Link } from 'react-router-dom';

const Logo = styled.div`
  width: auto;
  height: 26px;
  background-image: url(${DiviLogoSWhite});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${props =>
    !props.small &&
    `background-image: url(${FaceLogoBlack});
  `}
`;

const Footer = styled.div`
  flex: 0 0 47px;
`;

const Header = styled.div`
  padding: ${props => (props.small ? '20px 16px' : '24px 20px 0')};
`;

const MenuText = styled.div`
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.default};
  padding: 8px 8px 20px;
  text-align: center;
`;

const FooterText = styled.div`
  padding: 16px;
  text-align: center;
  font: ${({ theme }) => theme.font.xs};
  color: ${({ theme }) => theme.text.secondary};
`;

function getProfileTitle(data) {
  let name = data.name;
  let lastName = data.last_name;
  let email = data.email;
  if (name || lastName) return name + ' ' + lastName;
  else return email;
}

function Menu(props) {
  const { clientInfo, balance, ...other } = props;

  const [light, setLight] = useState(true);
  let profileTitle = clientInfo ? getProfileTitle(clientInfo) : '';

  const currentVersion = useSelector(state => state.changelog.currentVersion);

  const [small, setSmall] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    let isSmall = width <= 1024 && width > 0;
    setLight(!isSmall);
    setSmall(isSmall);
  }, [width]);

  const dispatch = useDispatch();
  const openReleaseInfo = useCallback(() => {
    dispatch(
      handleOpenModal({
        component: ReleaseInfoModal,
        name: 'release_info',
      })
    );
  }, []);

  return (
    <BaseMenu
      small={small}
      header={
        <Header small={small}>
          <Link to={`/app/nuitrack/`}>
            <Logo small={small} />
          </Link>
          {!small && <MenuText>License Management Portal</MenuText>}
        </Header>
      }
      footerText={'Collapse Menu'}
      onCollaps={e => {
        setSmall(!small);
        setLight(!light);
      }}
      {...other}
    >
      {!small && (
        <MenuSection title={'Balance'}>
          <Balance balance={balance} />
        </MenuSection>
      )}
      <MenuSection>
        <MenuNav />
      </MenuSection>
      <MenuSection title={'Account'}>
        <MenuItem to={BASE_URL + '/account/'} icon="person" text={profileTitle} />
        <MenuItem to={BASE_URL + '/logout/'} icon="exit" text={'Log Out'} />
      </MenuSection>
      {!small && (
        <Footer>
          <FooterText>
            {tr('version', { version: currentVersion || ' ... ' })}{' '}
            <StyledLink noIcon={true} onClick={openReleaseInfo}>
              {tr('what_new')}
            </StyledLink>
          </FooterText>
        </Footer>
      )}
    </BaseMenu>
  );
}

Menu.defaultProps = {
  children: '',
};

function mapStateToProps(state) {
  return {
    token: state.clientdata.token,
    balance: state.clientdata.balance,
    clientInfo: state.clientdata.clientInfo,
  };
}

export default connect(mapStateToProps)(Menu);
