import React, { useEffect, useState } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCart, clearBasketAction } from '../../../../store/basket/actions';
import { useLocation, useParams } from 'react-router';
import { getPrice } from '../../../../helpers/getPrice';
import CheckoutView from './CheckoutView';

function Checkout(props) {
  const { goods, controlType } = props;
  const { toUpgradeId, platform, checkoutType } = useParams();

  const [pricedGoods, setPricedGoods] = useState([]);
  const [backUrl, setBackUrl] = useState(`/app/${platform}/dashboard/`);
  const [backText, setBackText] = useState(tr('go_back'));

  const location = useLocation();
  const dispatch = useDispatch();

  const clientDataLoaded = useSelector(state => state.clientdata.loadComplete);
  const prices = useSelector(state => state.clientdata.prices);
  const currentStep = useSelector(state => state.basket.currentStep);

  useEffect(() => {
    if (checkoutType === 'upgrade') {
      setBackText(tr('go_back'));
      setBackUrl(`/app/${platform}/dashboard/${toUpgradeId}/info/`);
    } else if (checkoutType === 'upgrade-y') {
      setBackText(tr('go_back'));
      setBackUrl(`/app/${platform}/dashboard/${toUpgradeId}/pre-info/`);
    } else {
      setBackText(tr('back_to_license_selection_button'));
      setBackUrl(`/app/${platform}/basket/license-selection/`);
    }
    return () => dispatch(clearBasketAction());
  }, []);

  useEffect(() => {
    if (clientDataLoaded) {
      if (location && location.state && location.state.addLicenseType) {
        goods
          .filter(el => el.config)
          .forEach(el => {
            if (
              el.config.product === location.state.addLicenseType ||
              el.months === parseInt(location.state.addLicenseType)
            )
              dispatch(
                AddToCart({
                  ...el,
                  price: getPrice({ config: el.config, type: el.platform }, prices),
                  limit: 1,
                })
              );
          });
      }
      if (checkoutType === 'upgrade' || checkoutType === 'upgrade-y')
        dispatch(
          AddToCart({
            ...goods[0],
            price:
              location && location.state && location.state.upgradeCost
                ? location.state.upgradeCost
                : getPrice({ config: goods[0].config, type: goods[0].platform }, prices),
            limit: 1,
          })
        );
      setPricedGoods(
        goods.map(el => ({
          price:
            checkoutType === 'upgrade' || checkoutType === 'upgrade-y'
              ? null
              : getPrice({ config: el.config, type: el.platform }, prices),
          ...el,
        }))
      );
    }
  }, [clientDataLoaded]);

  return (
    <CheckoutView
      currentStep={currentStep}
      pricesLoaded={clientDataLoaded}
      goods={pricedGoods}
      controlType={controlType}
      platform={platform}
      backUrl={backUrl}
      backText={backText}
    />
  );
}

export default Checkout;
