import React from 'react';
import { Helmet } from 'react-helmet';

class Header extends React.Component {
  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
      </Helmet>
    );
  }
}

export default Header;
