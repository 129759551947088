import React from 'react';
import { Input } from '@3divi/baseui';
import styled from 'styled-components';

const StyledOnPremise = styled.div`
  padding: 8px 0 0;
`;

function OnPremiseForm(props) {
  const { inputs } = props;
  return (
    <StyledOnPremise>
      <Input {...inputs.name} maxlength={30} size="m" variant="column" autoFocus />
      <Input {...inputs.email} maxlength={150} size="m" variant="column" />
      <Input
        {...inputs.details}
        type="textarea"
        lines={6}
        size="m"
        maxlength={99999}
        variant="column"
      />
    </StyledOnPremise>
  );
}

export default OnPremiseForm;
