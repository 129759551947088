import React from 'react';
import tr from 'locale';
import { getAllowedPlatforms } from '.';
import TaggedText from '../components/elements/TaggedText';
import StyledLink from '../components/elements/StyledLink';

export const useMetadata = (license, { platform }) => {
  if (!license) return [];

  let metadataArr = [];

  if (license.created_date || license.activation_date || license.expire_date) {
    metadataArr = [
      { text: tr('created_date'), value: license.created_date },
      { text: tr('activation_date'), value: license.activation_date },
      { text: tr('expire_date'), value: license.expire_date },
    ];
  }

  if (platform === 'face') {
    license.activations.length > 0 &&
      metadataArr.push({
        text: tr('device_signature'),
        value: license.activations[license.activations.length - 1].signature_properties.reg,
      });

    license.config.custom_tags &&
      Object.entries(license.config.custom_tags).forEach(([key, value]) => {
        if (key.includes('_app_id')) {
          metadataArr.push({ text: tr(key), value: value });
        }
        if (key === 'hardware_key_serial' && value)
          metadataArr.push({ text: tr(key), value: value });
      });
  }

  const allowedPlatforms = getAllowedPlatforms(license);
  if (allowedPlatforms) {
    const href =
      license.type === 'nuitrack'
        ? 'https://github.com/3DiVi/nuitrack-sdk/blob/master/doc/Install.md#installation-instructions'
        : license.type === 'face'
        ? 'https://docs.facesdk.3divi.com/docs/system_req'
        : undefined;
    const componentTooltip = (
      <TaggedText text={tr('allowed_platforms_tooltip')} a={[<StyledLink key="1" href={href} />]} />
    );
    metadataArr.push({
      text: tr('allowed_platforms'),
      componentTooltip: componentTooltip,
      value: allowedPlatforms,
    });
  }

  if (platform === 'face') {
    metadataArr.push({
      text: tr('supported_fsdk_version'),
      value: license.config.max_version,
    });
  }

  return metadataArr;
};
