import { handleActions } from 'redux-actions';
import formatDate from '../../FormatDate';
import { sortVersionsDesc } from '../../helpers/sortVersionsDesc';

const getChangelog = changelog => {
  return {
    ...changelog,
    date: formatDate(changelog.date),
    changes: Array.isArray(changelog.changes) ? changelog.changes : [],
  };
};

const initialState = () => ({
  changelogs: [],
});

const reducer = handleActions(
  {
    GET_CHANGELOG_SUCCESS: (state, action) => {
      const { changelog } = action.payload;
      const frontChangelogs = sortVersionsDesc(
        changelog.map(el => getChangelog(el)),
        'version'
      );

      return {
        ...state,
        changelogs: frontChangelogs,
        currentVersion: frontChangelogs.length > 0 ? frontChangelogs[0].version : undefined,
        success: true,
      };
    },
  },
  initialState()
);

export default reducer;
