import React, { useCallback, useEffect, useState } from 'react';
import BtnAdditionalText from '../BtnAdditionalText';
import { createLicenseFromGroup, clearLicense } from '../../../store/licenseBuy/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

function BtnCreateLicenseAndRedirect(props) {
  // props & state
  const { button } = props;
  const { group } = button;
  const [clicked, setClicked] = useState(false); // need because many this component can be re-rendered at one time
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // selectors
  const { license, loading, success, errorCode } = useSelector(
    state => state.licenseBuy.createLicenseFromGroup
  );
  // actions
  const createLicenseFromGroupCallback = useCallback(() => {
    setClicked(true);
    dispatch(createLicenseFromGroup(group));
  }, [group.id]);

  useEffect(() => {
    if (success && clicked) {
      history.push(`${license.id}/info/`);
      dispatch(clearLicense());
      setClicked(false);
    }
  }, [success]);

  useEffect(() => {
    if (errorCode && clicked) {
      setClicked(false);
    }
  }, [errorCode]);

  return (
    <BtnAdditionalText
      onClick={createLicenseFromGroupCallback}
      loading={loading && clicked}
      disabled={loading && !clicked}
      {...props}
    />
  );
}

export default BtnCreateLicenseAndRedirect;
