import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSteps } from '../hooks/';
import { Link } from 'react-router-dom';
import tr from 'locale';

import StartBlocks from './StartBlocks';

const facesdkName = 'Face SDK';
const faceLicense = `${facesdkName} ${tr('license')}`;

function StartBlocksFace({
  stepsStore,
  setStorage,
  wasLicenseBought,
  setStep,
  setShowStep,
  platform,
}) {
  const { currentStep, nextStep, status, last } = useSteps(stepsStore, platform);
  const price = useSelector(
    state =>
      state.clientdata.prices[platform] && state.clientdata.prices[platform].face_online_all_in_one
  );
  const balance = useSelector(state => state.clientdata.balance);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (wasLicenseBought && currentStep === 'buy') {
      setStep(currentStep, nextStep);
    }

    let balanceMore = price && balance > +price[0].price;
    if ((balanceMore || wasLicenseBought) && currentStep === 'fill') {
      setStep(currentStep, nextStep);
    }

    if (last && status === 'completed') {
      setShow(false);
      setTimeout(() => {
        setShowStep(platform, false);
        setStorage(false);
      }, 350);
    }
  }, [wasLicenseBought, currentStep, nextStep, balance, price, status, last]);

  return (
    <StartBlocks
      stepsStore={stepsStore}
      inTransition={show}
      steps={[
        {
          title: 'Face SDK',
          icon: 'next',
          reverse: true,
          text: tr('face_step_desc'),
        },
        {
          title: tr('check_balance'),
          stepType: 'fill',
          skip: () => setStep(currentStep, nextStep),
          text: tr('should_be_balance', { price: price && `${price[0].price}` }),
          button: {
            type: 'ButtonFillBalance',
            props: {
              platform: platform,
              price: price && `${price[0].price}`,
              button: {
                text: tr('balance_refill'),
                additionalText: null,
              },
            },
          },
        },
        {
          title: tr('start_licenses_block_title'),
          stepType: 'buy',
          text: tr('choose_license', { license: facesdkName }),
          button: {
            type: 'BtnAdditionalText',
            props: {
              text: tr('start_licenses_block_button'),
              forwardedAs: Link,
              to: '/app/face/basket/license-selection/',
            },
          },
        },
        {
          title: tr('start_almost_there'),
          stepType: 'success',
          text: tr('everything_done', { license: faceLicense }),
          button: {
            type: 'BtnAdditionalText',
            props: {
              text: tr('complete'),
              onClick: () => setStep(currentStep, nextStep),
            },
          },
        },
      ]}
    />
  );
}

export default StartBlocksFace;
