import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import { SectionBox, Row, Col, WithIcon, Tooltip, Box } from '@3divi/baseui';
import LicensesWrap from '../components/LicensesWrap';
import License from '../components/License';
import LoaderComponent from 'components/LoaderComponent';
import { H3 } from 'components/elements/Typography/';

const Main = styled.main`
  width: 100%;
`;

function Groups({ groupsLoaded, groups, title, platform }) {
  return (
    <Row>
      <Col>
        <Main>
          <H3
            title={
              <>
                {tr('unbound_lic', { name: title ? title : '' })}
                <Tooltip
                  side="top-center"
                  tooltipColor="contrast"
                  text={tr('available_licenses_block_tooltip')}
                >
                  <WithIcon icon="info" color="primary"></WithIcon>
                </Tooltip>
              </>
            }
          />
          <SectionBox>
            <Box px={3}>
              {groupsLoaded ? (
                <LicensesWrap>
                  {groups.map(license => (
                    <License key={license.reactKey} {...license} platform={platform} />
                  ))}
                </LicensesWrap>
              ) : (
                <LoaderComponent />
              )}
            </Box>
          </SectionBox>
        </Main>
      </Col>
    </Row>
  );
}

export default Groups;
