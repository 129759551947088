import {
  purchaseProducts,
  createResource,
  getActivationKey,
  upgradeLicenses,
  upgradeGroupLicenses,
} from 'api';
import { createAction } from 'redux-actions';
import { updateGroupAction, addNewLicenseAction } from '../licenses/actions';
import { fetchProfile } from '../clientdata/actions';
import { errorHandleMiddleware } from '../errorHandleMiddleware';

const licenseActionRequest = createAction('LICENSE_ACTION_REQUEST');
export const licenseActionSuccess = createAction('LICENSE_ACTION_SUCCESS');
const licenseActionFailure = createAction('LICENSE_ACTION_FAIL');

const clearLicenseAction = createAction('CLEAR_LICENSE_ACTION');

export const licenseBuyAction = (
  purchases,
  { platform, paymentMethod = 'balance', stripe, isSubscribe, promocode }
) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(licenseActionRequest());
    try {
      let response = await purchaseProducts(purchases, {
        platform,
        paymentMethod,
        isSubscribe,
        promocode,
      });
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId: response.payment.provider_data.session_id,
        });
      } else if (paymentMethod === 'paypal') {
        return Promise.resolve(response);
      } else {
        setTimeout(() => {
          dispatch(fetchProfile());
          dispatch(licenseActionSuccess());
        }, 250);
      }
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      setTimeout(() => {
        dispatch(
          licenseActionFailure({
            error,
            errorCode,
          })
        );
      }, 250);
      throw requestError;
    }
  });
};

export const createLicenseFromGroup = group => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(createLicenseFromGroupRequest());
    try {
      let response = await getActivationKey({
        groupId: group.id,
      });
      setTimeout(() => {
        dispatch(addNewLicenseAction(response.license));
        dispatch(
          createLicenseFromGroupSuccess({
            license: response.license,
          })
        );
        dispatch(updateGroupAction(response.group));
      }, 250);
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      setTimeout(() => {
        dispatch(
          createLicenseFromGroupFailure({
            error,
            errorCode,
          })
        );
      }, 250);
      throw requestError;
    }
  });
};
const createLicenseFromGroupRequest = createAction('CREATE_LICENSE_FROM_GROUP_REQUEST');
const createLicenseFromGroupSuccess = createAction('CREATE_LICENSE_FROM_GROUP_SUCCESS');
const createLicenseFromGroupFailure = createAction('CREATE_LICENSE_FROM_GROUP_FAILURE');

export const createLicense = ({ config, title, count }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(createLicenseRequest());
    try {
      let response = await createResource(config, title, count);
      let promise = new Promise(function (resolve, reject) {
        setTimeout(() => {
          dispatch(
            createLicenseSuccess({
              license: response.licenses[0],
            })
          );
          // TODO: add 'dispatch' of store.licenses addNewLicense action
          resolve('done');
        }, 250);
      });
      return promise;
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      setTimeout(() => {
        dispatch(
          createLicenseFailure({
            error,
            errorCode,
          })
        );
      }, 250);
      throw requestError;
    }
  });
};
const createLicenseRequest = createAction('CREATE_LICENSE_REQUEST');
const createLicenseSuccess = createAction('CREATE_LICENSE_SUCCESS');
const createLicenseFailure = createAction('CREATE_LICENSE_FAILURE');

export const clearLicense = () => {
  return dispatch => {
    dispatch(clearLicenseAction());
  };
};

export const upgradeLicenseAction = (id, { paymentMethod, platform, stripe }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(licenseActionRequest());
    try {
      const response = await upgradeLicenses(id, { paymentMethod, platform });
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId: response.payment.provider_data.session_id,
        });
      } else if (paymentMethod === 'paypal') {
        return Promise.resolve(response);
      } else {
        dispatch(fetchProfile());
        dispatch(licenseActionSuccess());
      }
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        licenseActionFailure({
          errorCode,
        })
      );
      throw requestError;
    }
  });
};

export const upgradeGroupLicenseAction = (id, { paymentMethod, platform, stripe }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(licenseActionRequest());
    try {
      const response = await upgradeGroupLicenses(id, { paymentMethod, platform });
      if (stripe) {
        stripe.redirectToCheckout({
          sessionId: response.payment.provider_data.session_id,
        });
      } else if (paymentMethod === 'paypal') {
        return Promise.resolve(response);
      } else {
        dispatch(fetchProfile());
        dispatch(licenseActionSuccess());
      }
    } catch (requestError) {
      let { error_code: errorCode } = requestError;
      dispatch(
        licenseActionFailure({
          errorCode,
        })
      );
      throw requestError;
    }
  });
};
