import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStatusFilters, useDayPicker, getDataSelector } from '../../../../hooks';
import { filterByKey, filterByDateRange, getLicenseType, getTableData } from '../../../../helpers';
import { getFakeLicenses } from '../../../../store/helpers/getFakeLicenses';
import tr from 'locale';
import KeyField from 'components/KeyField';
import getLicenseLockedTo from '../../../../helpers/getLicenseLockedTo';

import TableLicenses from './TableLicenses';

const productFilters = ['all', 'nuitrack_trial', 'nuitrack_online', 'old'];
const productOtherFilters = ['tvico', 'nuitrack_subscribe', 'nuitrack_unlimited', 'real_dance'];

const licenseStatusFilters = ['all', 'new', 'active', 'suspended', 'holded'];

const tableConfig = {
  columns: [
    { name: 'id_column', size: 'max-content' },
    { name: 'upgrade_column', size: 'max-content' },
    { name: 'title_column', size: 'minmax(120px, max-content)' },
    { name: 'license_type_column', size: 'minmax(32px, max-content)', visible: 'l' },
    { name: 'license_status_column', size: 'minmax(32px, max-content)', visible: 'l' },
    { name: 'subscription_status_column', size: 'max-content' },
    { name: 'expire_date_column', size: 'max-content' },
    { name: 'serial_sensor_column', size: 'max-content' },
    { name: 'from_custom_group_column', size: 'max-content', visible: 'l' },
    { name: 'activation_key_column', size: 'max-content' },
  ],
};

const getNuitrackTableData = license => {
  const getProductFilterAlias = license => {
    // old == nuitrack_perpetual
    // nuitrack_perpetual filter must show both, 'old' and nuitrack_ai_perpetual
    if (license.config.product === 'nuitrack_ai_perpetual') return 'old';

    if (
      license.config.product === 'nuitrack_ai_subscription_monthly' ||
      license.config.product === 'nuitrack_ai_subscription_yearly'
    )
      return 'nuitrack_online';

    return license.config.product;
  };

  const result = {
    // data for filters and other
    ...license,
    product: getProductFilterAlias(license),
    createdDate: license.dates.createdDate,
    expireDate: license.dates.expireDate,
    activationDate: license.dates.activationDate,
    // cell data
    id_column: license.id,
    upgrade_column: {
      cell_type: 'upgrade',
      props: license && license.upgradeable && { text: tr('upgrade') },
    },
    title_column: license.title,
    license_type_column: getLicenseType(license),
    license_status_column: tr(license.license_status),
    subscription_status_column: license.subscription_status && tr(license.subscription_status),
    expire_date_column: license.expire_date,
    serial_sensor_column: license.serial_sensor && license.serial_sensor,
    from_custom_group_column: license.fromCustomGroup ? tr('yes') : undefined,
    activation_key_column: license.token ? <KeyField token={license.token} /> : null,
    locked_to_column: {
      cell_type: 'textWithTooltip',
      props: { textWithTooltip: getLicenseLockedTo(license) },
    },
  };
  return result;
};

function TableNuitrack({ platform }) {
  // getting table data
  const storedLicenses = useSelector(state =>
    getDataSelector(state.licenses, 'licenses', platform)
  );
  const groups = useSelector(state => state.licenses.groups);
  const fakeLicenses = useMemo(() => getFakeLicenses(groups, platform), [groups, platform]);
  const licenses = [...storedLicenses, ...fakeLicenses];
  const { tableData, headers } = useMemo(
    () => getTableData(tableConfig, licenses, getNuitrackTableData),
    [storedLicenses, groups, platform]
  );
  // store and states
  const isLoaded = useSelector(state => state.licenses.fetchLicenses.success);
  const [productFilter, setProductFilter] = useState(productFilters[0]);
  const [statusFilter, setStatusFilter] = useState(licenseStatusFilters[0]);
  const [filterChangedTime, setFilterChangedTime] = useState(Date.now());
  const createdDayPicker = useDayPicker();
  const expireDayPicker = useDayPicker();
  // filters
  const filteredByProduct = useMemo(
    () => filterByKey(tableData, 'product', productFilter, productFilters[0]),
    [tableData, productFilter]
  );
  const filteredByDateFilters = useMemo(() => {
    let data = filteredByProduct;
    data = filterByDateRange(data, 'createdDate', [createdDayPicker.from, createdDayPicker.to]);
    data = filterByDateRange(data, 'expireDate', [expireDayPicker.from, expireDayPicker.to]);
    return data;
  }, [
    filteredByProduct,
    createdDayPicker.from,
    createdDayPicker.to,
    expireDayPicker.from,
    expireDayPicker.to,
  ]);
  const statuses = useStatusFilters(
    filteredByDateFilters,
    'license_status',
    licenseStatusFilters,
    licenseStatusFilters[0]
  );
  const filteredData = useMemo(
    () =>
      filterByKey(filteredByDateFilters, 'license_status', statusFilter, licenseStatusFilters[0]),
    [filteredByDateFilters, statusFilter]
  );
  // conditional filters
  const getProductOthersFilter = () => {
    return productOtherFilters.filter(el => tableData.find(license => license.product === el));
  };
  // custom actions
  const handleFilterByProduct = value => {
    setProductFilter(value);
    setStatusFilter(licenseStatusFilters[0]);
  };

  const clearDayPickersSelection = () => {
    createdDayPicker.clearRange();
    expireDayPicker.clearRange();
  };

  const clearAllFilters = () => {
    clearDayPickersSelection();
    setProductFilter(productFilters[0]);
    setStatusFilter(licenseStatusFilters[0]);
  };

  const isDataFiltered =
    productFilter !== productFilters[0] ||
    statusFilter !== licenseStatusFilters[0] ||
    createdDayPicker.isRangeChoosen ||
    expireDayPicker.isRangeChoosen;

  useEffect(() => {
    setFilterChangedTime(Date.now());
  }, [createdDayPicker, expireDayPicker, productFilter, statusFilter]);

  return (
    <TableLicenses
      productFilter={{
        filters: [...productFilters, ...getProductOthersFilter()],
        type: 'group',
        value: productFilter,
        callback: handleFilterByProduct,
      }}
      statusFilter={{
        statuses: statuses,
        isDefault: tableData && tableData.length === 0,
        value: statusFilter,
        callback: setStatusFilter,
      }}
      tableData={{
        headers: headers,
        rows: filteredData,
        columns: tableConfig.columns,
        isLoaded: isLoaded,
        emptyText: tr('empty_licenses'),
        rowsChangedTime: filterChangedTime,
      }}
      createdDayPicker={createdDayPicker}
      expireDayPicker={expireDayPicker}
      clearAllFilters={clearAllFilters}
      isDataFiltered={isDataFiltered}
    />
  );
}

export default TableNuitrack;
