import tr from 'locale';
import { useState } from 'react';
import { useIsMounted } from '../../../../../../hooks/useIsMounted';

const getHandledErrors = () => ['c2bcf3bf', '860468cb', '75d895af', 'c8f7c88b'];

export const usePromocode = ({
  promocode = '',
  actions: { validatePromocode = ({ promocode, platform }) => {}, clearPromocode = () => {} },
  dispatch,
  platform = '',
}) => {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [value, setValue] = useState(promocode || '');

  const status = value.length === 0 ? 'empty' : promocode === value ? 'applied' : 'filled';

  return {
    button: {
      onClick:
        status === 'empty'
          ? undefined
          : status === 'applied'
          ? () => {
              setValue('');
              dispatch(clearPromocode());
            }
          : async () => {
              try {
                setLoading(true);
                await dispatch(
                  validatePromocode(
                    { promocode: value, platform },
                    { handledErrors: getHandledErrors() }
                  )
                );
                if (isMounted.current) {
                  setLoading(false);
                }
              } catch (requestError) {
                if (isMounted.current) {
                  if (getHandledErrors().some(error => error === requestError.error_code)) {
                    // ${platform}-name is nuitrack-name, face-name, face-machine-name, nuitrack-analytics-name
                    const errorMessage = tr(requestError.error_code, {
                      platform: tr(`${platform}-name`),
                    });
                    setError(errorMessage);
                  }
                  setLoading(false);
                }
              }
            },
      loading: loading,
    },
    input: {
      value: value,
      onChange: v => {
        if (error.length > 0) setError('');
        setValue(v);
      },
      readOnly: status === 'applied' || loading,
      errorText: error,
    },
    status,
  };
};
