import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDataSelector } from 'hooks/';

import { handlerSetStep, handlerSetInitialSteps } from 'store/steps/actions';

import {
  StartBlocksFaceMachine,
  StartBlocksFace,
  StartBlocksNuitrack,
  StartBlocksNuitrackAnalytics,
} from './StartBlocksLicenses/';

const howStartConfig = {
  face: StartBlocksFace,
  'face-machine': StartBlocksFaceMachine,
  'nuitrack-analytics': StartBlocksNuitrackAnalytics,
  nuitrack: StartBlocksNuitrack,
};

function StepContainer({ platform }) {
  const HowToStartBlocks = howStartConfig[platform];
  // Actions
  const dispatch = useDispatch();
  const handlerSetStepAction = useCallback(
    ({ platform, type, nextStep, email }) =>
      dispatch(handlerSetStep({ platform, type, nextStep, email })),
    []
  );
  const handlerSetInitialStepsAction = useCallback(
    (platform, show) => dispatch(handlerSetInitialSteps(platform, show)),
    []
  );
  // Selectors
  const steps = useSelector(state => state.steps[platform]);
  const stepsShow = useSelector(state => state.steps.showStepBlocks);
  const userEmail = useSelector(state => state.clientdata.clientInfo.email);
  const groups = useSelector(state => getDataSelector(state.licenses, 'groups', platform));
  const licenses = useSelector(state => getDataSelector(state.licenses, 'licenses', platform));
  const wasLicenseBought = useMemo(() => getWasLicenseBought(platform, licenses, groups), [
    licenses,
    groups,
    platform,
  ]);

  useEffect(() => {
    const storage = sessionStorage.getItem(`${userEmail}_${platform}_steps`);
    if (storage) {
      handlerSetInitialStepsAction(platform, storage === 'true');
    } else {
      setSessionsStorage(!wasLicenseBought);
      handlerSetInitialStepsAction(platform, !wasLicenseBought);
    }
  }, [platform, wasLicenseBought]);

  const handlerClickSetStep = (type, nextStep) => {
    handlerSetStepAction({ platform, type, nextStep, email: userEmail });
  };

  const setSessionsStorage = value => {
    sessionStorage.setItem(`${userEmail}_${platform}_steps`, value);
  };

  return stepsShow && stepsShow[platform] ? (
    <HowToStartBlocks
      setShowStep={handlerSetInitialStepsAction}
      setStorage={setSessionsStorage}
      setStep={handlerClickSetStep}
      stepsStore={steps}
      wasLicenseBought={wasLicenseBought}
      platform={platform}
      licenses={licenses}
    />
  ) : null;
}

export default StepContainer;

function getWasLicenseBought(platform, licenses, groups) {
  if (platform === 'nuitrack') {
    return licenses.some(license => license.price) || groups.some(group => group.limit);
  }

  if (platform === 'face') {
    return licenses.length > 0 || groups.some(group => group.limit);
  }

  if (platform === 'face-machine') {
    return (
      licenses.some(license => license.config.upgraded) ||
      (licenses.length > 0 && licenses.some(license => !license.config.tag))
    );
  }

  if (platform === 'nuitrack-analytics') {
    return (
      licenses.some(license => license.config.upgraded) ||
      (licenses.length > 0 && licenses.some(license => !license.config.tag))
    );
  }
}
