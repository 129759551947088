import React from 'react';
import SubscriptionCard from '../SubscriptionCard';
import SubscriptionManageButton from '../SubscriptionManageButton';

function SubscriptionManagement({ platform = '', license = {}, loaded }) {
  return (
    <>
      <SubscriptionCard license={license} loaded={loaded} />
      <SubscriptionManageButton license={license} platform={platform} />
    </>
  );
}

export default SubscriptionManagement;
