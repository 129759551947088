import tr from 'locale';
import { updateClientData } from 'api';
import styled from 'styled-components';
import { updateClientInfo } from 'store/clientdata/actions';
import { connect } from 'react-redux';
import React from 'react';
import { Modal, Input, Button } from '@3divi/baseui';
import { handleOpenModal } from '../../../store/modals/actions';
import ErrorModal from '../../modals/ErrorModal';

const Buttons = styled.div`
  padding: 16px 0 8px;
  margin: 0 -8px;

  & > button {
    margin: 0 8px;
  }
`;

class CompanyEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.accountInfo.company.name,
      address: this.props.accountInfo.company.address,
      vat: this.props.accountInfo.company.vat,
      type: this.props.accountInfo.company.type,
      site: this.props.accountInfo.company.site,
    };
    this.saveChanges = this.saveChanges.bind(this);
  }

  saveChanges(event) {
    event.preventDefault();
    const { hideAction, dispatch, accountInfo } = this.props;
    let newInfo = Object.assign({}, accountInfo);
    newInfo.company.name = this.state.name;
    newInfo.company.site = this.state.site;
    newInfo.company.address = this.state.address;
    newInfo.company.vat = this.state.vat;
    newInfo.company.type = this.state.type;
    updateClientData(newInfo).then(
      success => {
        dispatch(updateClientInfo({ newInfo: success.client_info }));
        hideAction();
      },
      async failure => {
        const { error_code: errorCode } = failure;
        const errorMsg = tr(errorCode || 'internal_server_error');
        if (errorMsg !== errorCode) {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: errorMsg,
            })
          );
        } else {
          dispatch(
            handleOpenModal({
              component: ErrorModal,
              name: 'error',
              text: tr('network_error'),
            })
          );
        }
      }
    );
  }

  render() {
    return (
      <Modal isOpen close={this.props.hideAction} title={tr('company_info_header')} fixed={400}>
        <form onSubmit={this.saveChanges}>
          <Input
            name="name"
            label={tr('company_name')}
            value={this.state.name || ''}
            onChange={val => this.setState({ name: val })}
            errorText=""
            maxlength={30}
            variant="column"
            size="m"
          />
          <Input
            className="limits-form__textarea"
            name="address"
            label={tr('company_address_label')}
            value={this.state.address || ''}
            onChange={val => this.setState({ address: val })}
            errorText=""
            maxlength={999}
            variant="column"
            size="m"
          />
          <Input
            name="vat"
            label={tr('company_tax')}
            value={this.state.vat || ''}
            onChange={val => this.setState({ vat: val })}
            errorText=""
            maxlength={150}
            variant="column"
            size="m"
          />
          <Input
            name="type"
            label={tr('company_type')}
            value={this.state.type || ''}
            onChange={val => this.setState({ type: val })}
            errorText=""
            maxlength={150}
            variant="column"
            size="m"
          />
          <Input
            name="site"
            label={tr('company_site')}
            value={this.state.site || ''}
            onChange={val => this.setState({ site: val })}
            errorText=""
            maxlength={150}
            variant="column"
            size="m"
          />
          <Buttons>
            <Button
              size="m"
              variant="contained"
              text={tr('account_change_save_button')}
              color="primary"
            />
            <Button size="m" text={tr('cancel_button')} onClick={this.props.hideAction} />
          </Buttons>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountInfo: state.clientdata.clientInfo,
  };
}

export default connect(mapStateToProps)(CompanyEdit);
