import React from 'react';
import { createCustomResource, getFSDKVersions } from '../../api';
import { Input } from '@3divi/baseui';
import tr from 'locale';
import Header from 'Header';

import './Adminsite.css';
import { sortVersionsDesc } from '../../helpers/sortVersionsDesc';

const CONFIG_TITLE = {
  // age_gender_estimator: tr("age_gender_estimator"),
  liveness_2d_estimator: tr('liveness_2d_estimator'),
  depth_liveness_estimator: tr('depth_liveness_estimator'),
  detector: tr('detector'),
  age_gender_emotions_estimator: tr('age_gender_emotions_estimator'),
  matcher: tr('matcher'),
  processor: tr('processor'),
  video_client: tr('video_client'),
  video_client_ext: tr('video_client_ext'),
  video_engine_light: tr('video_engine_light'),
};

const PRODUCT_LIST = ['online', 'offline'];
const PRODUCT_MAP = {
  offline: 'face_offline_custom',
  online: 'face_online_custom',
};
const DELTAFORMAT_LIST = ['day', 'month', 'year'];
const LOCKING_CRITERIA = {
  hardware: 'Hardware ID',
  iosAppId: 'iOS App ID',
  androidAppId: 'Android App ID',
  usbToken: 'USB token',
  ios: 'iOS',
  android: 'Android',
  windows: 'Windows',
  linux: 'Linux',
};
const ALLOWED_PLATFORMS = {
  ios: 'ios-arm-64',
  android: 'android-arm-32,android-arm-64',
  windows: 'windows-x86-32,windows-x86-64',
  linux: 'linux-arm-32,linux-arm-64,linux-x86-32,linux-x86-64',
};

function toNum(value) {
  var countClear = value.replace(/\D/g, '');
  return countClear;
}

class AdminSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      count: 1,
      mail: '',

      //config
      product: PRODUCT_LIST[0],
      iosAppId: '',
      androidAppId: '',
      // usbToken: '',
      expireDelta: 1,
      deltaFormat: DELTAFORMAT_LIST[0],
      dbSize: 0,
      content: Object.fromEntries(new Map(Object.keys(CONFIG_TITLE).map(el => [el, 0]))),
      maxVersion: '',

      dropdowns: {
        product: false,
        deltaFormat: false,
        maxVersion: false,
      },
      lockCriteriaEnabled: {
        hardware: true,
        iosAppId: true,
        androidAppId: true,
        usbToken: true,
        ios: true,
        android: true,
        windows: true,
        linux: true,
      },
      lockCriteriaChecked: {
        hardware: false,
        iosAppId: false,
        androidAppId: false,
        usbToken: false,
        ios: false,
        android: false,
        windows: false,
        linux: false,
      },
      maxVersionsList: [],
      GPUAccelereationChecked: false,

      errorText: '',
      successText: '',

      xmlText: '',
    };
    this.createCustomLicense = this.createCustomLicense.bind(this);
    this.closeDropdowns = this.closeDropdowns.bind(this);
    this.openProduct = this.openProduct.bind(this);
    this.openDeltaFormat = this.openDeltaFormat.bind(this);
    this.openMaxVersion = this.openMaxVersion.bind(this);
    this.changeContent = this.changeContent.bind(this);
    this.changeXml = this.changeXml.bind(this);
    this.onLockCriteriaClick = this.onLockCriteriaClick.bind(this);
    this.onGPUAccelerationClick = this.onGPUAccelerationClick.bind(this);

    this.defaultDropdowns = {
      product: false,
      deltaFormat: false,
      maxVersion: false,
    };
    this.lockCriteriaEnabledMap = {
      platformEnabled: {
        ios: true,
        android: true,
        windows: true,
        linux: true,
      },
      allEnabled: {
        hardware: true,
        iosAppId: true,
        androidAppId: true,
        usbToken: true,
        ios: true,
        android: true,
        windows: true,
        linux: true,
      },
      allDisabled: {
        hardware: false,
        iosAppId: false,
        androidAppId: false,
        usbToken: false,
        ios: false,
        android: false,
        windows: false,
        linux: false,
      },
    };
  }

  componentDidMount() {
    getFSDKVersions().then(response => {
      const versions = sortVersionsDesc(response.facesdk_versions, 'version');
      const versionsWithDefault = versions.map(el =>
        el.is_default ? `${el.version} (default)` : el.version
      );
      const defaultVersions = versionsWithDefault.filter(el => el.includes('(default)'));
      this.setState({
        maxVersionsList: versionsWithDefault,
        maxVersion: defaultVersions.length > 0 ? defaultVersions[0] : '',
      });
    });
  }

  createCustomLicense(e) {
    e.preventDefault();
    this.setState({ errorText: '', successText: '' });
    var deltaToSeconds = this.state.expireDelta;
    switch (this.state.deltaFormat) {
      case 'day':
        deltaToSeconds *= 3600 * 24;
        break;
      case 'month':
        deltaToSeconds *= 3600 * 24 * 30;
        break;
      case 'year':
        deltaToSeconds *= 3600 * 24 * 360;
        break;
      default:
        break;
    }
    let lockCriteria;
    let isHardwareLock = false;
    if (this.state.lockCriteriaChecked.hardware) {
      isHardwareLock = true;
    } else {
      if (this.state.lockCriteriaChecked.iosAppId)
        lockCriteria = { ios_app_id: this.state.iosAppId };
      else if (this.state.lockCriteriaChecked.androidAppId)
        lockCriteria = { android_app_id: this.state.androidAppId };
      else if (this.state.lockCriteriaChecked.usbToken) {
        lockCriteria = { hardware_key_serial: true };
      } else {
        let allowedPlatforms = '';
        if (this.state.lockCriteriaChecked.ios)
          allowedPlatforms = `${allowedPlatforms}${allowedPlatforms.length > 0 ? ',' : ''}${
            ALLOWED_PLATFORMS.ios
          }`;
        if (this.state.lockCriteriaChecked.android)
          allowedPlatforms = `${allowedPlatforms}${allowedPlatforms.length > 0 ? ',' : ''}${
            ALLOWED_PLATFORMS.android
          }`;
        if (this.state.lockCriteriaChecked.linux)
          allowedPlatforms = `${allowedPlatforms}${allowedPlatforms.length > 0 ? ',' : ''}${
            ALLOWED_PLATFORMS.linux
          }`;
        if (this.state.lockCriteriaChecked.windows)
          allowedPlatforms = `${allowedPlatforms}${allowedPlatforms.length > 0 ? ',' : ''}${
            ALLOWED_PLATFORMS.windows
          }`;

        if (allowedPlatforms) lockCriteria = { allowed_platforms: allowedPlatforms };
      }
    }
    createCustomResource({
      title: this.state.title,
      count: +this.state.count,
      mail: this.state.mail,
      customTags: lockCriteria,
      customTagsExtra: this.state.xmlText,

      config: {
        product: PRODUCT_MAP[this.state.product],
        license_expire_delta: deltaToSeconds || null,
        device_bound: isHardwareLock,
        max_version: this.state.maxVersion.split(' ')[0], // removing '(default)' text
        license_content: JSON.stringify(
          Object.assign(
            {
              database: this.state.dbSize,
              desktop_gpu_support: this.state.GPUAccelereationChecked ? '1' : '0',
            },
            this.state.content
          )
        ),
      },
    }).then(
      success => {
        this.setState({ successText: `Added ${success.licenses.length} license(s).` });
      },
      failure => {
        this.setState({ errorText: failure.error });
      }
    );
  }
  closeDropdowns() {
    this.setState({
      dropdowns: { ...this.defaultDropdowns },
    });
  }
  openProduct(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dropdowns: { ...this.defaultDropdowns, product: true },
    });
  }
  openDeltaFormat(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dropdowns: { ...this.defaultDropdowns, deltaFormat: true },
    });
  }
  openMaxVersion(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dropdowns: { ...this.defaultDropdowns, maxVersion: true },
    });
  }
  changeContent(option) {
    return value => {
      var content = Object.assign({}, this.state.content);
      content[option] = +toNum(value);
      this.setState({ content: content });
    };
  }
  changeXml(e) {
    e.preventDefault();
    var value = e.currentTarget.value;
    this.setState({
      xmlText: value,
    });
  }
  onGPUAccelerationClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState(state => ({ ...state, GPUAccelereationChecked: !state.GPUAccelereationChecked }));
  }
  onLockCriteriaClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.attributes.disabled) return;

    let lockCriteria = e.currentTarget.attributes.platform.value;
    let lockCriteriaKey;
    switch (lockCriteria) {
      case LOCKING_CRITERIA.hardware:
        lockCriteriaKey = 'hardware';
        break;
      case LOCKING_CRITERIA.iosAppId:
        lockCriteriaKey = 'iosAppId';
        break;
      case LOCKING_CRITERIA.androidAppId:
        lockCriteriaKey = 'androidAppId';
        break;
      case LOCKING_CRITERIA.usbToken:
        lockCriteriaKey = 'usbToken';
        break;
      case LOCKING_CRITERIA.ios:
        lockCriteriaKey = 'ios';
        break;
      case LOCKING_CRITERIA.android:
        lockCriteriaKey = 'android';
        break;
      case LOCKING_CRITERIA.windows:
        lockCriteriaKey = 'windows';
        break;
      case LOCKING_CRITERIA.linux:
        lockCriteriaKey = 'linux';
        break;
    }
    let newState = {
      lockCriteriaChecked: {
        ...this.state.lockCriteriaChecked,
        [lockCriteriaKey]: !this.state.lockCriteriaChecked[lockCriteriaKey],
      },
      lockCriteriaEnabled: { ...this.state.lockCriteriaEnabled },
    };

    if (newState.lockCriteriaChecked.hardware)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allDisabled,
        hardware: true,
      };
    if (newState.lockCriteriaChecked.iosAppId)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allDisabled,
        iosAppId: true,
      };
    if (newState.lockCriteriaChecked.androidAppId)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allDisabled,
        androidAppId: true,
      };
    if (newState.lockCriteriaChecked.usbToken)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allDisabled,
        usbToken: true,
      };
    const isPlatformCriteriaChecked =
      newState.lockCriteriaChecked.ios ||
      newState.lockCriteriaChecked.android ||
      newState.lockCriteriaChecked.linux ||
      newState.lockCriteriaChecked.windows;
    if (isPlatformCriteriaChecked)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allDisabled,
        ...this.lockCriteriaEnabledMap.platformEnabled,
      };
    let isCriteriaChecked = false;
    Object.keys(newState.lockCriteriaChecked).forEach(critera => {
      if (newState.lockCriteriaChecked[critera] && newState.lockCriteriaEnabled[critera])
        isCriteriaChecked = true;
    });
    if (!isCriteriaChecked)
      newState.lockCriteriaEnabled = {
        ...this.lockCriteriaEnabledMap.allEnabled,
      };

    this.setState(newState);
  }

  render() {
    return (
      <div className="ui" onClick={this.closeDropdowns}>
        <Header title={tr('tabtitle_create_custom')} />
        <div className="content">
          <div className="form-container  open">
            <form className="limits-form">
              <ul className="limits-form__list">
                <li className="limits-form__line">
                  <Input
                    maxlength="256"
                    theme="column"
                    value={this.state.title}
                    label="Title"
                    name="title"
                    onChange={v => this.setState({ title: v })}
                  />
                  <Input
                    maxlength="150"
                    theme="column"
                    value={this.state.mail}
                    label="User mail"
                    name="mail"
                    onChange={v => this.setState({ mail: v })}
                  />
                  <Input
                    maxlength="30"
                    theme="column"
                    type=""
                    value={parseInt(this.state.count || 0)}
                    label="Count"
                    name="count"
                    onChange={v => this.setState({ count: toNum(v) })}
                  />
                </li>
              </ul>
              <ul className="limits-form__list">
                <div className="limits-form__label">License type</div>
                <Dropdown
                  tip={String(this.state.product) || 'product'}
                  open={this.state.dropdowns.product}
                  dropdownClick={this.openProduct}
                  list={PRODUCT_LIST}
                  elementClick={el => () => this.setState({ product: el })}
                />
                <li className="limits-form__line" />
                <div className="limits-form__label">Locking criteria</div>
                {Object.keys(LOCKING_CRITERIA).map(key => {
                  return [
                    <Checkbox
                      key={key}
                      platform={LOCKING_CRITERIA[key]}
                      text={LOCKING_CRITERIA[key]}
                      onClick={this.onLockCriteriaClick}
                      disabled={!this.state.lockCriteriaEnabled[key]}
                      checked={this.state.lockCriteriaChecked[key]}
                    />,
                    key === 'iosAppId' &&
                      this.state.lockCriteriaChecked[key] &&
                      this.state.lockCriteriaEnabled[key] && (
                        <Input
                          maxlength="256"
                          theme="column"
                          value={this.state.iosAppId}
                          label={LOCKING_CRITERIA[key]}
                          name="ios"
                          onChange={v => this.setState({ iosAppId: v })}
                        />
                      ),
                    key === 'androidAppId' &&
                      this.state.lockCriteriaChecked[key] &&
                      this.state.lockCriteriaEnabled[key] && (
                        <Input
                          maxlength="256"
                          theme="column"
                          value={this.state.androidAppId}
                          label={LOCKING_CRITERIA[key]}
                          name="android"
                          onChange={v => this.setState({ androidAppId: v })}
                        />
                      ),
                    // key === 'usbToken' &&
                    //   this.state.lockCriteriaChecked[key] &&
                    //   this.state.lockCriteriaEnabled[key] && (
                    //     <Input
                    //       maxlength="256"
                    //       theme="column"
                    //       value={this.state.usbToken}
                    //       label={LOCKING_CRITERIA[key]}
                    //       name="android"
                    //       onChange={v => this.setState({ usbToken: v })}
                    //     />
                    //   ),
                  ];
                })}
                <li className="limits-form__line" />
                <div className="limits-form__label">MAX SDK Version Supported</div>
                <Dropdown
                  tip={String(this.state.maxVersion || '')}
                  open={this.state.dropdowns.maxVersion}
                  dropdownClick={this.openMaxVersion}
                  list={this.state.maxVersionsList}
                  elementClick={el => () => this.setState({ maxVersion: el })}
                />
                <li className="limits-form__line">
                  <Input
                    maxlength="30"
                    theme="column"
                    type=""
                    value={this.state.expireDelta}
                    label="Validity"
                    name="expireDelta"
                    onChange={v => this.setState({ expireDelta: toNum(v) })}
                  />
                </li>
                <Dropdown
                  tip={String(this.state.deltaFormat)}
                  open={this.state.dropdowns.deltaFormat}
                  dropdownClick={this.openDeltaFormat}
                  list={DELTAFORMAT_LIST}
                  elementClick={el => () => this.setState({ deltaFormat: el })}
                />
                <li className="limits-form__line" />
              </ul>
              <div>----------license content----------</div>
              <ul className="limits-form__list">
                <li className="limits-form__line">
                  <Input
                    theme="column"
                    maxlength="30"
                    type=""
                    value={parseInt(this.state.dbSize) || '0'}
                    label="Database size"
                    name="dbsize"
                    onChange={v => this.setState({ dbSize: v })}
                  />
                </li>
                <ContentChangeBlock content={this.state.content} onChange={this.changeContent} />
              </ul>
              <Checkbox
                text={'GPU Acceleration'}
                onClick={this.onGPUAccelerationClick}
                checked={this.state.GPUAccelereationChecked}
              />
              <div style={{ marginTop: '16px' }}>
                <button onClick={this.createCustomLicense}>Create license</button>
              </div>
              <div className="limits-form__container-message">
                <div className="limits-form__title-message">Create license response: </div>
                <div className="limits-form__error-message alarm-text">{this.state.errorText}</div>
                <div className="limits-form__error-message">{this.state.successText}</div>
              </div>
            </form>
          </div>
          <div className="form-container  open">
            <textarea
              style={{ width: '100%' }}
              placeholder={'XML config'}
              value={this.state.xmlText}
              onChange={this.changeXml}
            />
          </div>
        </div>
      </div>
    );
  }
}

function Dropdown(props) {
  return (
    <div className="dropdown">
      <button className="select-button" onClick={props.dropdownClick}>
        {props.tip}
      </button>
      <div className={`dropdown-menu ${props.open ? 'open' : ''}`}>
        <ul className="dropdown-menu__lists">
          {props.list.map(el => (
            <li key={el} className="dropdown-menu__item" onClick={props.elementClick(el)}>
              {String(el)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
function ContentChangeBlock(props) {
  return (
    <div>
      {Object.keys(props.content).map(option => (
        <li className="limits-form__line" key={option}>
          <Input
            theme="column"
            maxlength="30"
            key={option}
            value={parseInt(props.content[option]) || '0'}
            label={CONFIG_TITLE[option]}
            name={option}
            onChange={props.onChange(option)}
          />
        </li>
      ))}
    </div>
  );
}
function Checkbox({ checked, onClick, text, platform, disabled }) {
  return (
    <label
      className={`account__list-item checkbox-label${disabled ? ' checkbox-label__disabled' : ''}`}
      disabled={disabled}
      platform={platform}
      onClick={onClick}
    >
      <input className="checkbox" type="checkbox" checked={checked} readOnly disabled={disabled} />
      <span className="fake-checkbox"></span>
      {text}
    </label>
  );
}

export default AdminSite;
