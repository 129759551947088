import React from 'react';
import tr from 'locale';
import { Button, Input } from '@3divi/baseui';

import Header from 'Header';
import { ButtonLine, StyledLoginContent, LoginTitle } from './styled/components';

function SetPasswordView(props) {
  const { inputs, buttons } = props;

  return (
    <StyledLoginContent>
      <Header title={tr('tabtitle_set_password')} />
      <LoginTitle>{tr('set_password_title')}</LoginTitle>
      <form>
        <Input
          {...inputs.password}
          size="m"
          variant="column"
          name="password"
          label={tr('login_password_label')}
          type={'password'}
          maxlength={257}
        />
        <Input
          {...inputs.passwordRepeat}
          size="m"
          variant="column"
          name="password_repeat"
          label={tr('repeat_password_label')}
          type={'password'}
          maxlength={257}
        />
        <ButtonLine>
          <Button
            {...buttons.submit}
            tabIndex="4"
            elastic
            size="m"
            color="primary"
            variant="contained"
            text={tr('login_button')}
          />
        </ButtonLine>
      </form>
    </StyledLoginContent>
  );
}

export default SetPasswordView;
