import React from 'react';
import tr from 'locale';
import styled from 'styled-components';

import { Modal, Input, Button } from '@3divi/baseui';

const Buttons = styled.div`
  padding: 16px 0 8px;
`;

const Spacer = styled.div`
  display: inline-flex;
  margin: 0 8px;
`;

function PasswordChangeView(props) {
  const { modal, buttons, inputs, success } = props;

  return success ? (
    <Modal
      {...modal}
      fixed={400}
      icon="mark"
      iconColor="hsl(120,45%,56%,1)"
      title={tr('change_password_succes')}
    >
      <Buttons>
        <Button {...buttons.cancel} size="m" elastic text={tr('button_continue')} />
      </Buttons>
    </Modal>
  ) : (
    <Modal {...modal} fixed={400} title={tr('change_password_tittle')}>
      <Input
        {...inputs.password}
        variant="column"
        name="old-password"
        type="password"
        size="m"
        label={tr('current_password_label')}
        maxlength={256}
      />
      <Input
        {...inputs.newPassword}
        variant="column"
        name="new-password"
        type="password"
        size="m"
        label={tr('new_password_label')}
        maxlength={256}
      />
      <Input
        {...inputs.newPasswordRepeat}
        variant="column"
        name="new-password-again"
        type="password"
        size="m"
        label={tr('repeat_new_password_label')}
        maxlength={256}
      />
      <Buttons>
        <Button
          {...buttons.submit}
          size="m"
          variant="contained"
          color="primary"
          text={tr('change_password_button')}
        />
        <Spacer />
        <Button {...buttons.cancel} size="m" text={tr('button_cancel')} />
      </Buttons>
    </Modal>
  );
}

export default PasswordChangeView;
