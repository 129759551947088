import React from 'react';
import styled from 'styled-components';
import { Button } from '@3divi/baseui';
import tr from 'locale';
import Stepper from '../Stepper';

const StyledInfoEdit = styled.form`
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border.default};
  background-color: ${({ theme }) => theme.background.default};
  padding: 8px 16px 0;
`;

const Buttons = styled.div`
  display: flex;
  padding: 8px 16px 8px 144px;
  margin: 8px -16px 0;
  border-radius: 0 0 4px 4px;
  background: ${({ theme }) => theme.background.primary};

  & > button {
    margin: 0 4px;
  }
`;

function EditableLicensesInGroupView(props) {
  const { onCancel, value, onChange, onSubmit, loading, errorText, maxValue } = props;

  const cancelSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledInfoEdit onSubmit={loading ? cancelSubmit : onSubmit}>
      <Stepper
        text={tr('licenses_available')}
        minimum={1}
        autoFocus={true}
        maximum={maxValue}
        value={value}
        callback={onChange}
        errorText={errorText}
      />
      <Buttons>
        <Button color="primary" variant="contained" text={tr('save_button')} loading={loading} />
        <Button onClick={onCancel} text={tr('cancel_button')} />
      </Buttons>
    </StyledInfoEdit>
  );
}

export default EditableLicensesInGroupView;
