import tr from 'locale';
import getFaceLicenseType from './getFaceLicenseType';

export const getLicenseType = license => {
  if (!license) return;

  const osPostfix = typeof license.config.os_type === 'number' ? `_${license.config.os_type}` : '';

  switch (license.type) {
    case 'nuitrack':
      return tr(`table_type_${license.config.product}${osPostfix}`);
    case 'face':
      return getFaceLicenseType(license);
    case 'face-machine':
      return license.isTrial ? tr('trial') : tr(`table_type_${license.config.product}`);
    case 'nuitrack-analytics':
      return license.isTrial ? tr('trial') : tr(`table_type_${license.config.product}`);
    default:
      return;
  }
};
