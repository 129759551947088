import { handleActions } from 'redux-actions';
import moment from 'moment';

var defaultState = {
  clientInfo: {},
  loadComplete: false,
  loadFailure: false,
  balance: 0,
  token: '',
  option: {},

  accountType: 'trial', //??

  stripeKey: '',

  prices: {},

  stripeData: null,

  changeClientPassword: {},

  profileLoadStatus: {
    progress: false,
    success: false,
    failure: false,
  },
  setPassword: {
    status: {
      progress: false,
      success: false,
      failure: false,
    },
  },
  login: {
    status: {
      progress: false,
      success: false,
      failure: false,
    },
  },
  registration: {},
  sendFeedback: {},

  fetchTransactionsStatus: {
    progress: false,
    success: false,
    failure: false,
  },
  checkToken: {},
  updateInfo: {
    status: {
      loaded: false,
      error: false,
    },
  },
  balanceFill: {},
  default: {
    status: {},
  },
  registerNuitrackAnalytics: {},
  transactions: [],
};

const reducer = handleActions(
  {
    CHECK_LOGIN: (state, action) => {
      return {
        ...state,
        logged: action.payload.logged,
      };
    },
    REQUEST_PROFILE: (state, action) => {
      return Object.assign({}, state, {
        profileLoadStatus: {
          progress: true,
          success: false,
          failure: false,
        },
      });
    },
    SUCCESS_PROFILE: (state, action) => {
      const {
        client_info,
        prices,
        stripe_public_key,
        paypal_merchant_id,
        paypal_client_id,
      } = action.payload;
      if (prices.nuitrack) {
        prices.nuitrack.nuitrack_online = {
          month: prices.nuitrack.nuitrack_online,
          year: prices.nuitrack.nuitrack_online_per_year,
        };
      }

      return Object.assign({}, state, {
        loadComplete: true,
        clientInfo: client_info,
        balance: client_info.balance,
        token: client_info.token,
        stripeKey: stripe_public_key,
        paypalMerchantId: paypal_merchant_id,
        paypalClientId: paypal_client_id,
        option: client_info.options,
        prices: {
          nuitrack: prices['nuitrack'],
          face: prices['face'],
          'face-machine': prices['face-machine'],
          'nuitrack-analytics': prices['nuitrack-analytics'],
        },
        profileLoadStatus: {
          progress: false,
          success: true,
          failure: false,
        },
        logged: true,
      });
    },
    FAILURE_PROFILE: (state, action) => {
      return Object.assign({}, state, {
        profileLoadStatus: {
          progress: false,
          success: false,
          failure: true,
        },
      });
    },
    LOGIN_REQUEST: (state, action) => {
      return {
        ...state,
        login: {
          [action.payload.storeKey]: {
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        login: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
        logged: true,
      };
    },
    LOGIN_FAILURE: (state, action) => {
      const { error, errorCode } = action.payload;

      return {
        ...state,
        login: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode: errorCode || 'internal_server_error',
            error,
          },
        },
        logged: false,
      };
    },
    FETCH_TRANSACTIONS_REQUEST: (state, action) => {
      return {
        ...state,
        fetchTransactionsStatus: {
          progress: true,
          success: false,
          failure: false,
        },
      };
    },
    FETCH_TRANSACTIONS_SUCCESS: (state, action) => {
      let transactionsWithDateObjects = [...action.payload.transactions];
      transactionsWithDateObjects.forEach(
        trans =>
          (trans.dates = {
            createdDate: moment(trans.creation_date),
          })
      );
      return {
        ...state,
        transactions: transactionsWithDateObjects,
        fetchTransactionsStatus: {
          progress: false,
          success: true,
          failure: false,
        },
      };
    },
    FETCH_TRANSACTIONS_FAILURE: (state, action) => {
      return {
        ...state,
        fetchTransactionsStatus: {
          progress: false,
          success: false,
          failure: true,
        },
      };
    },
    BALANCE_FILL_REQUEST: (state, action) => {
      return {
        ...state,
        balanceFill: {
          loading: true,
        },
      };
    },
    BALANCE_FILL_SUCCESS: (state, action) => {
      return {
        ...state,
        balanceFill: {
          loading: false,
        },
      };
    },
    BALANCE_FILL_FAIL: (state, action) => {
      return {
        ...state,
        balanceFill: {
          error: action.payload.error,
          errorCode: action.payload.errorCode,
        },
      };
    },
    BALANCE_FILL_CLEAR: (state, action) => {
      return {
        ...state,
        balanceFill: {},
      };
    },
    UPDATE_INFO: (state, action) => {
      return Object.assign({}, state, {
        clientInfo: action.payload.newInfo,
      });
    },
    UPDATE_CLIENT_INFO_REQUEST: (state, action) => {
      return {
        ...state,
        updateInfo: {
          [action.payload.storeKey]: {
            status: {
              loaded: false,
              error: false,
            },
          },
        },
      };
    },
    UPDATE_CLIENT_INFO_SUCCESS: (state, action) => {
      return {
        ...state,
        clientInfo: action.payload.clientInfo,
        updateInfo: {
          [action.payload.storeKey]: {
            status: {
              loaded: true,
              error: false,
            },
          },
        },
      };
    },
    UPDATE_CLIENT_INFO_FAILURE: (state, action) => {
      return {
        ...state,
        updateInfo: {
          [action.payload.storeKey]: {
            status: {
              loaded: false,
              error: true,
            },
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    SET_PASSWORD_REQUEST: (state, action) => {
      return {
        ...state,
        setPassword: {
          status: {
            progress: true,
            success: false,
            failure: false,
          },
        },
      };
    },
    SET_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        setPassword: {
          status: {
            progress: false,
            success: true,
            failure: false,
          },
        },
      };
    },
    SET_PASSWORD_FAILURE: (state, action) => {
      return {
        ...state,
        setPassword: {
          status: {
            progress: false,
            success: false,
            failure: true,
          },
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
    SEND_SALES_MESSAGE_REQUEST: (state, action) => {
      return Object.assign({}, state, {
        salesMessage: {
          [action.payload.storeKey]: {
            loading: true,
          },
        },
      });
    },
    SEND_SALES_MESSAGE_SUCCESS: (state, action) => {
      return Object.assign({}, state, {
        salesMessage: {
          [action.payload.storeKey]: {
            loading: false,
            success: action.payload.success,
          },
        },
      });
    },
    SEND_SALES_MESSAGE_FAIL: (state, action) => {
      return Object.assign({}, state, {
        salesMessage: {
          [action.payload.storeKey]: {
            loading: false,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
          },
        },
      });
    },
    CHANGE_CLIENT_PASSWORD_REQUEST: (state, action) => {
      return {
        ...state,
        changeClientPassword: {
          [action.payload.storeKey]: {
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    CHANGE_CLIENT_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        changeClientPassword: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
      };
    },
    CHANGE_CLIENT_PASSWORD_FAILURE: (state, action) => {
      return {
        ...state,
        changeClientPassword: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode: action.payload.errorCode || 'internal_server_error',
          },
        },
      };
    },
    REGISTRATION_REQUEST: (state, action) => {
      return {
        ...state,
        registration: {
          [action.payload.storeKey]: {
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    REGISTRATION_SUCCESS: (state, action) => {
      return {
        ...state,
        registration: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
      };
    },
    REGISTRATION_FAILURE: (state, action) => {
      const { errorCode } = action.payload;

      return {
        ...state,
        registration: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode: errorCode || 'internal_server_error',
          },
        },
      };
    },
    SEND_FEEDBACK_REQUEST: (state, action) => {
      return {
        ...state,
        sendFeedback: {
          loading: true,
        },
      };
    },
    SEND_FEEDBACK_SUCCESS: (state, action) => {
      return {
        ...state,
        sendFeedback: {
          success: true,
        },
      };
    },
    SEND_FEEDBACK_FAILURE: (state, action) => {
      return {
        ...state,
        sendFeedback: {
          errorCode: action.payload.errorCode || 'internal_server_error',
        },
      };
    },
    CHECK_TOKEN_REQUEST: (state, action) => {
      return {
        ...state,
        checkToken: {
          [action.payload.storeKey]: {
            status: {
              progress: true,
              success: false,
              failure: false,
            },
          },
        },
      };
    },
    CHECK_TOKEN_SUCCESS: (state, action) => {
      return {
        ...state,
        checkToken: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: true,
              failure: false,
            },
          },
        },
      };
    },
    CHECK_TOKEN_FAILURE: (state, action) => {
      const { errorCode } = action.payload;

      return {
        ...state,
        checkToken: {
          [action.payload.storeKey]: {
            status: {
              progress: false,
              success: false,
              failure: true,
            },
            errorCode,
          },
        },
      };
    },
    REGISTER_NUITRACK_ANALYTICS_SUCCESS: (state, action) => {
      const { client_exist, client, url } = action.payload;
      return {
        ...state,
        clientInfo: client,
        registerNuitrackAnalytics: {
          clientExist: client_exist,
          nextURL: url,
        },
      };
    },
  },
  defaultState
);

export default reducer;
