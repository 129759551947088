import { useEffect, useMemo } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getLicenseType, getProductName } from '../../../../../helpers';
import { planConfigurationToTextValue } from '../../../../../helpers/planConfigurationToTextValue';
import { fetchFaceMachinePlansAction } from '../../../../../store/plans/actions';
import { getLicenseInfoList } from '../../../../../helpers/getLicenseInfoList';
import { useFetchLicenseInfo } from './hooks/useFetchLicenseInfo';
import { useMetadata } from '../../../../../helpers/useMetadata';
import { handleOpenModal } from '../../../../../store/modals/actions';
import SubscriptionActivateModal from '../../../../modals/SubscriptionActivateModal';
import NuitrackAnalyticsPausing from '../../../../modals/NuitrackAnalyticsPausing';
import NuitrackAnalyticsResuming from '../../../../modals/NuitrackAnalyticsResuming';

export const useNuitrackAnalyticsInfo = () => {
  // Routes
  const id = useParams().id;
  // Actions
  const dispatch = useDispatch();
  const openActivateSubscription = () =>
    dispatch(
      handleOpenModal({
        component: SubscriptionActivateModal,
        name: 'activate',
        license,
        platform,
      })
    );
  const openResumeNuitrackAnalytics = () =>
    dispatch(
      handleOpenModal({
        component: NuitrackAnalyticsResuming,
        license,
        name: 'na_license_resuming',
      })
    );
  const openPauseNuitrackAnalytics = () =>
    dispatch(
      handleOpenModal({
        component: NuitrackAnalyticsPausing,
        license,
        name: 'na_license_pausing',
      })
    );
  // Selectors
  const license = useSelector(state => state.licenseInfo.licenseInfo);
  const licenseLoaded = useSelector(state => state.licenseInfo.licenseLoaded);
  // TODO or not TODO: plan configuration may be hided
  const plans = useSelector(state => state.plans.plans);
  const planFieldsByTypesMap = useSelector(state => state.plans.filedsTypes);
  const planConfiguration = useMemo(() => {
    if (!license) return [];
    if (!plans[license.config.plan_id]) return [];
    return planConfigurationToTextValue({
      plan: plans[license.config.plan_id],
      planFieldsByTypesMap,
    });
  }, [plans, license]);

  const isHolded = license && license.license_status === 'holded';
  const platform = 'nuitrack-analytics';
  const backUrl = '/app/nuitrack-analytics/dashboard/';
  const licenseName = tr('nuitrack-analytics-name');

  let licenseManage, message;

  if (license)
    if (license.isTrial) {
      licenseManage = null;
      message = null;
    } else {
      if (license.subscription_status === 'cancelled') {
        licenseManage = null;
        message = {
          text: tr('license_expired'),
          button: { text: tr('renew_subscription'), onClick: openActivateSubscription },
        };
      } else {
        if (license.status === 1) {
          // "1" means what workspace stopped now
          licenseManage = {
            button: {
              text: tr('button_resume_license', { licenseName }),
              onClick: openResumeNuitrackAnalytics,
            },
          };
          message = {
            text: tr('license_stopped', { licenseName }),
            button: {
              text: tr('button_resume_license', { licenseName }),
              onClick: openResumeNuitrackAnalytics,
            },
          };
        } else if (license.license_status === 'holded') {
          licenseManage = null;
          message = null;
        } else {
          licenseManage = {
            button: {
              text: tr('button_pause_license', { licenseName }),
              onClick: openPauseNuitrackAnalytics,
            },
            text: tr('license_pause_description'),
          };
          message = null;
        }
      }
    }

  const metadata = useMetadata(license, { platform });
  const licenseInfo = getLicenseInfoList(
    license,
    { text: tr('product_name'), value: tr(getProductName(license)) },
    { text: tr('license_type'), value: getLicenseType(license) }
  );

  useEffect(() => {
    if (Object.keys(plans).length === 0) dispatch(fetchFaceMachinePlansAction());
  }, []);

  useFetchLicenseInfo({ id });

  return {
    license,
    platform,
    loaded: licenseLoaded,
    isHolded,
    licenseConfiguration: null, // TODO
    metadata,
    licenseInfo,
    backUrl,
    urlId: id,
    message,
    licenseManage,
  };
};
