export const sortVersionsDesc = (arr, versionKey) => {
  const result = arr.sort((a, b) => {
    const aVersions = a[versionKey].split('.');
    const bVersions = b[versionKey].split('.');
    const minLength = Math.min(aVersions.length, bVersions.length);
    for (let i = 0; i < minLength; i++) {
      if (isNaN(+aVersions[i])) return 1;
      if (isNaN(+bVersions[i])) return -1;
      if (aVersions[i] - bVersions[i] > 0) return -1;
      if (aVersions[i] - bVersions[i] < 0) return 1;
    }
    if (aVersions.length > bVersions.length) return -1;
    if (aVersions.length < bVersions.length) return 1;
    return 0;
  });
  return result;
};
