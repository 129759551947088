const directPriceSet = new Set([
  'nuitrack_online',
  'nuitrack_online_per_year',
  'fmc_subscribe',
  'upgrade_nuitrack_pro', // not a license, but have same object interface
]);
const amountPriceSet = new Set([
  'nuitrack_online_subscription_yearly',
  'nuitrack_online_subscription_monthly',
  'face_online_subscribe',
  'nuitrack_ai_subscription_monthly',
  'nuitrack_ai_subscription_yearly',
]);
const discountPriceSet = new Set(['old', 'nuitrack_ai_perpetual']);

export const getPrice = (license, prices) => {
  if (!license || !prices || !prices[license.type]) return;

  if (directPriceSet.has(license.config.product))
    return prices[license.type][license.config.product];
  if (amountPriceSet.has(license.config.product))
    return (
      prices[license.type][license.config.product] &&
      prices[license.type][license.config.product].amount
    );
  if (discountPriceSet.has(license.config.product))
    return (
      prices[license.type][license.config.product] &&
      prices[license.type][license.config.product].price
    );

  // custom pricing
  switch (license.type) {
    case 'nuitrack':
      return;
    case 'face':
      if (license.config.product === 'face_online_all_in_one') {
        const activePeriod = license.config.license_expire_delta;
        // price 'face_online_all_in_one' is array of objects {price, period}
        const priceObject = prices.face.face_online_all_in_one.find(el =>
          typeof el.period === 'number'
            ? el.period === +activePeriod
            : el.period === activePeriod.toString()
        );
        return priceObject && priceObject.price;
      }
      if (license.config.product === 'face_online_custom_subscribe') {
        return calcFaceOnlineCustomSubscribe(license, prices);
      }
      return;
    case 'face-machine':
      return;
    case 'nuitrack-analytics':
      // plan_id pricing
      if (typeof license.config.plan_id === 'number') {
        return prices['nuitrack-analytics'][license.config.product][license.config.plan_id].cost;
      }
      return;
  }
};

export const getPriceObject = (license, prices) => {
  if (!license || !prices || !prices[license.type]) return;

  if (
    directPriceSet.has(license.config.product) ||
    amountPriceSet.has(license.config.product) ||
    discountPriceSet.has(license.config.product)
  )
    return prices[license.type][license.config.product];

  // custom pricing
  switch (license.type) {
    case 'nuitrack':
      return;
    case 'face':
      if (license.config.product === 'face_online_all_in_one') {
        const activePeriod = license.config.license_expire_delta;
        // price 'face_online_all_in_one' is array of objects {cost, period}
        return prices.face.face_online_all_in_one.find(el =>
          typeof el.period === 'number'
            ? el.period === +activePeriod
            : el.period === activePeriod.toString()
        );
      }
      if (license.config.product === 'face_online_custom_subscribe') {
        return calcFaceOnlineCustomSubscribe(license, prices);
      }
      return;
    case 'face-machine':
      return;
    case 'nuitrack-analytics':
      return;
  }
};

const calcFaceOnlineCustomSubscribe = (license, prices) => {
  // price 'face_online_custom_subscribe':
  // {matcher: {[dbSize]: cost}, [componentName]: cost}
  let sum = 0,
    config = JSON.parse(license.config.license_content),
    price = prices.face.face_online_custom_subscribe;
  if (prices.face.face_online_custom_subscribe)
    Object.entries(prices.face.face_online_custom_subscribe).forEach(([key, value]) => {
      if (key === 'matcher' && config[key]) {
        sum += +value[config.database] * +config[key];
      } else if (key === 'video_client_ext' && config[key]) {
        sum += (+price.matcher[config.database] + +value) * +config[key];
      } else if (config[key]) {
        sum += +value * +config[key];
      }
    });
  else sum = NaN;
  return sum.toFixed(2);
};
