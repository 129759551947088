import React from 'react';
import { connect } from 'react-redux';

import LicenseSelectionView from './LicenseSelection.jsx';
import { LicenseFace, LicenseNuitrack, LicenseNuitrackAnalytics } from './LicenseContainers/';

function LicenseSelection(props) {
  const { platform, loadComplete } = props;

  const licenses = {
    face: LicenseFace,
    'nuitrack-analytics': LicenseNuitrackAnalytics,
    nuitrack: LicenseNuitrack,
  };

  return (
    <LicenseSelectionView
      platform={platform}
      loadComplete={loadComplete}
      licenseContainer={licenses[platform]}
    />
  );
}

function mapStateToProps(state, props) {
  return {
    loadComplete: state.clientdata.loadComplete,
  };
}

export default connect(mapStateToProps)(LicenseSelection);
