import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import tr from 'locale';

import CognitiveInfo from './CognitiveInfo';
import RegistrationFormView from './RegistrationFormView';
import emailValidation from '../../../login/validation';
import { registrationAction } from '../../../store/clientdata/actions';
import {
  specialRegexp,
  notAvailableSymbolRegexp,
  upperLatinRegexp,
  lowerLatinRegexp,
} from '../../../helpers/regexp';

const getHandledErrors = () => ['4f37c7da', 'e4a5b8f4', '6352926b'];

function RegistrationForm(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [storeKey, setStoreKey] = useState(Date.now());
  const { status, errorCode } = useSelector(
    state => state.clientdata.registration[storeKey] || state.clientdata.default
  );

  const [isCorporate, setIsCorporate] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const hasErrorPass = value => {
    if (!value || value.length < 8) {
      return tr('password_at_least_error', { atLeast: 8 });
    }
    if (notAvailableSymbolRegexp.test(value)) {
      return tr('password_not_available_symbol_error');
    }
    // validation group section.
    // If more than 1 error, then return concat error message.
    // If error just 1, then return its error message.
    let invalidCount = 0;
    let errorMessage;
    if (!specialRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_special_symbol_error');
    }
    if (!upperLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_upper_error');
    }
    if (!lowerLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_lower_error');
    }
    if (invalidCount > 0) {
      if (invalidCount === 1) return errorMessage;
      else return tr('password_no_all_symbol_types_error');
    }

    return false;
  };

  const onSubmit = e => {
    e.preventDefault();

    let wasError = false;

    if (login.length === 0) {
      setLoginError(tr('email_empty_error'));
      wasError = true;
    } else if (!emailValidation(login)) {
      setLoginError(tr('email_validation_error'));
      wasError = true;
    }

    let passwordErrorText = hasErrorPass(password);
    if (passwordErrorText) {
      setPasswordError(passwordErrorText);
      wasError = true;
    }

    if (password !== repeatPassword) {
      setRepeatPasswordError(tr('password_match_error'));
      wasError = true;
    } else {
      setRepeatPasswordError('');
    }

    if (wasError) return;

    dispatch(
      registrationAction(
        { login: login.trim(), password, isCorporate, platform: 'nuitrack' },
        { storeKey, handledErrors: getHandledErrors() }
      )
    );
  };

  useEffect(() => {
    if (status.success) {
      setShowInfo(true);
    }
  }, [status.success]);

  useEffect(() => {
    if (status.failure) {
      const errorMsg = tr(errorCode);
      if (errorCode === '4f37c7da')
        // password contain part of email
        setPasswordError(errorMsg);
      else if (
        errorCode === 'e4a5b8f4' || // email already registered
        errorCode === '6352926b' // invalid email format (domain > 63)
      )
        setLoginError(errorMsg);
    }
  }, [status.failure]);

  return showInfo ? (
    <CognitiveInfo
      title={tr('registration_complete_title')}
      text={tr('registration_complete_text', { email: login })}
      locationState={{ ...location.state }}
      button={{ text: tr('return_to_login_button') }}
    />
  ) : (
    <RegistrationFormView
      inputs={{
        login: {
          value: login,
          onChange: v => {
            if (v.length > 150) {
              setLoginError(tr('email_max_length_error', { maxLength: 150 }));
              return;
            }
            if (loginError.length > 0) setLoginError('');

            setLogin(v);
          },
          errorText: loginError,
        },
        password: {
          value: password,
          onChange: v => {
            if (v.length > 256) {
              setPasswordError(tr('password_max_length_error', { maxLength: 256 }));
              return;
            }

            if (passwordError.length > 0) setPasswordError('');
            setPassword(v);
          },
          errorText: passwordError,
          // type ?
        },
        repeatPassword: {
          value: repeatPassword,
          onChange: v => {
            if (v.length > 256) {
              setRepeatPasswordError(tr('password_max_length_error', { maxLength: 256 }));
              return;
            }

            if (repeatPasswordError.length > 0) setRepeatPasswordError('');
            setRepeatPassword(v);
          },
          errorText: repeatPasswordError,
          // type ?
        },
      }}
      buttons={{
        corporate: {
          isActive: isCorporate,
          onClick: e => {
            setIsCorporate(true);
          },
        },
        personal: {
          isActive: !isCorporate,
          onClick: e => {
            setIsCorporate(false);
          },
        },
        submit: {
          onClick: onSubmit,
          loading: status.progress,
        },
        toLogin: {
          as: Link,
          to: { pathname: '/app/login/', state: { ...location.state } },
        },
      }}
    />
  );
}

export default RegistrationForm;
