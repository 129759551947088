import React, { useState, useEffect } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { postPaypalApprove } from 'api';
import { usePaypalOptions } from './usePaypalOptions';

function Paypal(props) {
  const { disabled, createOrder, createSubscription, onApprove, onError } = props;

  const [clicked, setClicked] = useState(false);

  const options = usePaypalOptions(!!createSubscription);

  const [{ isRejected }, dispatchPaypalScript] = usePayPalScriptReducer();
  useEffect(() => {
    if (isRejected && options) dispatchPaypalScript({ type: 'resetOptions', value: options });
  }, [isRejected, options, dispatchPaypalScript]);

  return (
    (clicked || !disabled) && (
      <PayPalButtons
        createOrder={
          !createSubscription && createOrder
            ? (data, actions) => {
                setClicked(true);
                return createOrder(data, actions);
              }
            : undefined
        }
        createSubscription={
          createSubscription
            ? (data, actions) => {
                setClicked(true);
                return createSubscription(data, actions);
              }
            : undefined
        }
        onApprove={(data, actions) => {
          setClicked(false);
          onApprove && onApprove();
          if (createSubscription)
            return postPaypalApprove({ subscription_id: data.subscriptionID });
          if (createOrder) return postPaypalApprove({ order_id: data.orderID });
        }}
        onError={onError}
        style={{
          label: 'pay',
          shape: 'rect',
          color: 'silver',
        }}
      />
    )
  );
}

export default Paypal;
