import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import formatDate from 'FormatDate';

import { fetchTransactions } from '../../../store/clientdata/actions';
import tr from 'locale';
import { useStatusFilters, useDayPicker } from '../../../hooks';
import { getTableData, filterByKey, filterByDateRange } from '../../../helpers';

import PaymentDocumentsView from './PaymentDocumentsView';

const statusFilters = ['all', 'ok', 'pending', 'declined', 'expired'];
const tableConfig = {
  columns: [
    { name: 'id_column', size: 'max-content' },
    { name: 'amount_column', size: 'minmax(max-content, 160px)' },
    { name: 'created_date_column', size: 'minmax(max-content, 160px)' },
    { name: 'status_column', size: 'minmax(max-content, 160px)' },
    { name: 'invoice_column', size: 'max-content' },
  ],
};

/*
  status:
    0 - ok
    1 - pending
    2 - decline
    3 - expired
*/
const getStatus = statusCode => {
  switch (statusCode) {
    case 0:
      return 'ok';
    case 1:
      return 'pending';
    case 2:
      return 'declined';
    case 3:
      return 'expired';
    default:
      return 'unexpected_status';
  }
};

const getTransactionTableData = transaction => {
  const { status, amount, type, id, dates, invoice_url } = transaction;
  /*
  type:
    0 - add amount to balance
    1 - remove amount from balance
  */
  const result = {
    // showed row data
    status_column: tr(getStatus(status)),
    created_date_column: formatDate(dates.createdDate),
    amount_column: parseFloat(amount) < 0.0001 ? '$0.00' : `${type === 0 ? '+' : '-'}$${amount}`,
    id_column: id,
    invoice_column: invoice_url
      ? {
          cell_type: 'BtnInvoice',
          props: {
            invoiceUrl: invoice_url,
            invoiceId: id,
            text: tr('invoice'),
          },
        }
      : undefined,
    // data for filters
    createdDate: dates.createdDate,
    status: getStatus(status),
  };
  return result;
};

function PaymentDocuments() {
  // Store actions
  const dispatch = useDispatch();
  const fetchTransactionsAction = useCallback(() => dispatch(fetchTransactions()), []);
  // Store selectors
  const transactions = useSelector(state => state.clientdata.transactions);
  const isLoaded = useSelector(state => state.clientdata.fetchTransactionsStatus.success);
  // states
  const [statusFilter, setStatusFilter] = useState(statusFilters[0]);
  const [filterChangedTime, setFilterChangedTime] = useState(Date.now());
  const createdDayPicker = useDayPicker();
  // getting table data
  const { tableData, headers } = useMemo(
    () => getTableData(tableConfig, transactions, getTransactionTableData),
    [transactions]
  );
  // filters
  const filteredByDateFilters = useMemo(() => {
    let data = tableData;
    data = filterByDateRange(data, 'createdDate', [createdDayPicker.from, createdDayPicker.to]);
    return data;
  }, [tableData, createdDayPicker.from, createdDayPicker.to]);
  const statuses = useStatusFilters(
    filteredByDateFilters,
    'status',
    statusFilters,
    statusFilters[0]
  );
  const filteredData = useMemo(
    () => filterByKey(filteredByDateFilters, 'status', statusFilter, statusFilters[0]),
    [filteredByDateFilters, statusFilter]
  );
  // didmount
  useEffect(() => {
    fetchTransactionsAction();
  }, []);
  // custom actions
  const clearDayPickersSelection = () => {
    createdDayPicker.clearRange();
  };

  const clearAllFilters = () => {
    clearDayPickersSelection();
    setStatusFilter(statusFilters[0]);
  };

  const isDataFiltered = statusFilter !== statusFilters[0] || createdDayPicker.isRangeChoosen;

  useEffect(() => {
    setFilterChangedTime(Date.now());
  }, [statusFilter, createdDayPicker]);

  return (
    <PaymentDocumentsView
      statusFilter={{
        statuses: statuses,
        value: statusFilter,
        isDefault: tableData && tableData.length === 0,
        callback: setStatusFilter,
      }}
      tableData={{
        headers: headers,
        rows: filteredData,
        columns: tableConfig.columns,
        isLoaded: isLoaded,
        emptyText: tr('empty_transactions'),
        rowsChangedTime: filterChangedTime,
      }}
      createdDayPicker={createdDayPicker}
      clearAllFilters={clearAllFilters}
      isDataFiltered={isDataFiltered}
    />
  );
}

export default PaymentDocuments;
