import React from 'react';
import tr from 'locale';
import styled from 'styled-components';
import { Typography, Preloader } from '@3divi/baseui';
import Price from '../../components/elements/Price';
import Divider from '../../components/elements/Divider';
import paymentLogos from '../../img/paymentLogos';

const brandToLogosMap = {
  paypal: 'paypal',
  amex: 'americanexpress',
  diners: 'dinersclub',
  discover: 'discover',
  jcb: 'jcb',
  mastercard: 'mastercard',
  unionpay: 'unionpay',
  visa: 'visa',
  unknown: 'unknown',
};

const WordBreakTypography = styled(Typography)`
  word-break: break-all;
`;

const Container = styled.div`
  margin: 8px 0;
  border: 1px solid ${({ theme }) => theme.border.default};
  border-radius: 4px;
  overflow: hidden;
`;

const Body = styled.div`
  padding: 4px 16px;
  background-color: ${({ theme }) => theme.background.primary};
`;

const ElementContainer = styled.div`
  margin: 8px 0 4px;
`;

const TextWrapper = styled.div`
  padding: 2px 0;
`;

const ContainerPayment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImagePayment = styled.img`
  height: 28px;
`;

const Image = styled.div`
  background: ${({ theme }) => theme.background.default};
  border-radius: 4px;
`;

const Header = styled.div`
  padding: 4px 16px;
`;

function Element({ title, children }) {
  return (
    <ElementContainer>
      <TextWrapper>
        <Typography color="secondary" size="11" height="14" weight="500">
          {title}
        </Typography>
      </TextWrapper>
      <TextWrapper>{children}</TextWrapper>
    </ElementContainer>
  );
}

function SubscriptionCard({ license, loaded }) {
  const paymentMethod = license.card_brand
    ? license.card_brand
    : license.paypal_email
    ? 'paypal'
    : 'balance';
  const logo = paymentMethod === 'balance' ? null : paymentLogos[brandToLogosMap[paymentMethod]];

  return (
    <Container>
      <Header>
        <Price text={`$${license.price}`} after={tr(license.lifetime).toLowerCase()} />
      </Header>
      <Body>
        <Element title={tr('subscription_status').toUpperCase()}>
          <Typography size="15" height="20">
            {tr(license.subscription_status)}
          </Typography>
        </Element>
        <Divider />
        <Element title={tr('next_payment').toUpperCase()}>
          <Typography size="15" height="20">
            {license.subscription_status === 'active' ? license.expire_date : '—'}
          </Typography>
        </Element>
        <Divider />
        <Element title={tr('payment_method').toUpperCase()}>
          {loaded ? (
            paymentMethod === 'balance' ? (
              <Typography size="15" height="20">
                {tr('account_balance')}
              </Typography>
            ) : (
              <ContainerPayment>
                <WordBreakTypography>
                  {license.card_last4 ? `•••• ${license.card_last4}` : license.paypal_email}
                </WordBreakTypography>
                <Image>
                  <ImagePayment src={logo}></ImagePayment>
                </Image>
              </ContainerPayment>
            )
          ) : (
            <Preloader padding="8px 0" />
          )}
        </Element>
      </Body>
    </Container>
  );
}

export default SubscriptionCard;
