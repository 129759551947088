import React from 'react';
import { Button } from '@3divi/baseui';
import tr from 'locale';
import { base64ToFile } from '../../../helpers/base64ToFile';

class FileLinkB64 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: null,
      url: null,
    };
  }

  componentDidMount() {
    if (this.props.autoAction) {
      base64ToFile({
        base64: this.props.license.data,
        blobOptions: { type: 'data:text/plain' },
        filename: this.props.fileName,
      });
    }
  }

  render() {
    return (
      <div className="window-activate__file-info">
        {this.props.isLicenseFake ? (
          <Button
            text={tr('download_file')}
            color="primary"
            theme="contained"
            onClick={this.props.createLicenseFromGroup}
            {...this.props}
          />
        ) : (
          <Button
            text={tr('download_file')}
            color="primary"
            theme="contained"
            onClick={() =>
              base64ToFile({
                base64: this.props.license.data,
                blobOptions: { type: 'data:text/plain' },
                filename: this.props.fileName,
              })
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}

export default FileLinkB64;
