import { handleActions } from 'redux-actions';

const initialState = {
  showStepBlocks: null,
  nuitrack: {
    steps: {
      buy: { status: 'active', type: 'buy' },
      success: { status: 'awaiting', type: 'success', last: true },
    },
  },
  face: {
    steps: {
      fill: { status: 'active', type: 'fill' },
      buy: { status: 'awaiting', type: 'buy' },
      success: { status: 'awaiting', type: 'success', last: true },
    },
  },
  'face-machine': {
    steps: {
      buy: { status: 'active', type: 'buy' },
      fill: { status: 'awaiting', type: 'fill' },
      success: { status: 'awaiting', type: 'success', last: true },
    },
  },
  'nuitrack-analytics': {
    steps: {
      buy: { status: 'active', type: 'buy' },
      fill: { status: 'awaiting', type: 'fill' },
      success: { status: 'awaiting', type: 'success', last: true },
    },
  },
};

const reducer = handleActions(
  {
    SET_INITIAL_STEPS: (state, action) => {
      const { platform, show } = action.payload;
      let showStepBlocks = state.showStepBlocks;

      showStepBlocks = {
        ...showStepBlocks,
        [platform]: show,
      };

      return {
        ...state,
        showStepBlocks: showStepBlocks,
      };
    },
    SET_STEPS: (state, action) => {
      const { platform, type, nextStep } = action.payload;
      let newSteps = { ...state[platform].steps };
      newSteps[type] = { ...newSteps[type], status: 'completed' };

      if (newSteps[nextStep]) {
        newSteps[nextStep] = { ...newSteps[nextStep], status: 'active' };
      }

      return {
        ...state,
        [platform]: {
          ...state[platform],
          steps: newSteps,
        },
      };
    },
    CLEAR_STEPS: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);

export default reducer;
