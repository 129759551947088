import { createAction } from 'redux-actions';
import {
  isLogged,
  getUserInfo,
  changePasswordByPassword,
  loginRequest,
  refillBalance,
  postSalesMessage,
  setPasswordByToken,
  getTransactions,
  registrationRequest,
  sendFeedbackMessage,
  validateToken,
  updateClientData,
  sendRegisterNuitrackAnalytics,
} from '../../api';
import { handleHideAllModal } from '../modals/actions';
import { errorHandleMiddleware } from '../errorHandleMiddleware';

const balanceFillRequest = createAction('BALANCE_FILL_REQUEST');
const balanceFillSuccess = createAction('BALANCE_FILL_SUCCESS');
const balanceFillFail = createAction('BALANCE_FILL_FAIL');
const balanceFillClear = createAction('BALANCE_FILL_CLEAR');

const setPasswordRequest = createAction('SET_PASSWORD_REQUEST');
const setPasswordSuccess = createAction('SET_PASSWORD_SUCCESS');
const setPasswordFailure = createAction('SET_PASSWORD_FAILURE');

const sendSalesMessageRequest = createAction('SEND_SALES_MESSAGE_REQUEST');
const sendSalesMessageSuccess = createAction('SEND_SALES_MESSAGE_SUCCESS');
const sendSalesMessageFail = createAction('SEND_SALES_MESSAGE_FAIL');

const fetchTransactionsRequest = createAction('FETCH_TRANSACTIONS_REQUEST');
const fetchTransactionsSuccess = createAction('FETCH_TRANSACTIONS_SUCCESS');
const fetchTransactionsFailure = createAction('FETCH_TRANSACTIONS_FAILURE');

export function fetchProfile() {
  return errorHandleMiddleware((dispatch, getState) => {
    if (!isLogged()) return Promise.resolve();
    dispatch(profileDataRequest());
    return getUserInfo().then(
      success => dispatch(profileDataSuccess(success)),
      failure => {
        dispatch(profileDataFailure());
        return Promise.reject(failure);
      }
    );
  });
}

export function fetchTransactions() {
  return errorHandleMiddleware(async dispatch => {
    if (!isLogged()) return;
    dispatch(fetchTransactionsRequest());
    try {
      let response = await getTransactions();
      dispatch(fetchTransactionsSuccess({ transactions: response.transactions }));
    } catch (requestError) {
      let { error_code: errorCode, error } = requestError;
      dispatch(
        fetchTransactionsFailure({
          error,
          errorCode,
        })
      );
      throw requestError;
    }
  });
}

export function loginAction({ login, password }, { handledErrors, storeKey, loginToNA }) {
  return errorHandleMiddleware(async (dispatch, getState) => {
    try {
      dispatch(loginActionRequest({ storeKey }));
      const response = await loginRequest({ login, password, loginToNA });

      dispatch(profileDataSuccess(response));
      dispatch(loginActionSuccess({ storeKey }));
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(loginActionFailure({ storeKey, errorCode }));
      throw requestError;
    }
  }, handledErrors);
}
const loginActionRequest = createAction('LOGIN_REQUEST');
const loginActionSuccess = createAction('LOGIN_SUCCESS');
const loginActionFailure = createAction('LOGIN_FAILURE');

export const balanceFillAction = ({ amount }, { platform, paymentMethod, stripe }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(balanceFillRequest());
    try {
      let response;
      response = await refillBalance({
        amount,
        platform,
        paymentMethod,
      });
      if (paymentMethod === 'stripe') {
        stripe.redirectToCheckout({
          sessionId: response.payment.provider_data.session_id,
        });
      } else if (paymentMethod === 'paypal') {
        dispatch(balanceFillSuccess());
        return response;
      }
    } catch (requestError) {
      const { error_code: errorCode, error } = requestError;
      dispatch(
        balanceFillFail({
          error,
          errorCode,
        })
      );
      if (errorCode) throw requestError;
      else throw { error_code: 'external_payments_unavailable' };
    }
  });
};

export const sendSalesMessage = ({ platform, clientName, clientEmail, message }, { storeKey }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(
      sendSalesMessageRequest({
        storeKey,
      })
    );
    try {
      await postSalesMessage(platform, clientName, clientEmail, message);
      dispatch(
        sendSalesMessageSuccess({
          success: true,
          storeKey,
        })
      );
    } catch (requestError) {
      const { error_code: errorCode, error } = requestError;
      dispatch(
        sendSalesMessageFail({
          error,
          errorCode,
          storeKey,
        })
      );
      throw requestError;
    }
  });
};

export const balanceFillClearAction = () => {
  return dispatch => {
    dispatch(balanceFillClear());
  };
};

export const checkLogin = () => {
  return async dispatch => {
    const logged = isLogged();
    dispatch(checkLoginAction({ logged }));
    if (!logged) dispatch(handleHideAllModal());
    return logged;
  };
};
const checkLoginAction = createAction('CHECK_LOGIN');

export const changeClientPassword = ({ password, newPassword }, { storeKey, handledErrors }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(changeClientPasswordRequest({ storeKey }));
    try {
      await changePasswordByPassword(password, newPassword);
      dispatch(changeClientPasswordSuccess({ storeKey }));
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(
        changeClientPasswordFailure({
          errorCode,
          storeKey,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};
const changeClientPasswordRequest = createAction('CHANGE_CLIENT_PASSWORD_REQUEST');
const changeClientPasswordSuccess = createAction('CHANGE_CLIENT_PASSWORD_SUCCESS');
const changeClientPasswordFailure = createAction('CHANGE_CLIENT_PASSWORD_FAILURE');

export const setPassword = ({ password, clientId, clientToken }, { handledErrors }) => {
  return errorHandleMiddleware(async (dispatch, getState) => {
    dispatch(setPasswordRequest());
    try {
      const response = await setPasswordByToken(clientId, clientToken, password);
      dispatch(profileDataSuccess(response));
      dispatch(setPasswordSuccess());
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(
        setPasswordFailure({
          errorCode,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};

const profileDataRequest = createAction('REQUEST_PROFILE');
const profileDataSuccess = createAction('SUCCESS_PROFILE');
const profileDataFailure = createAction('FAILURE_PROFILE');

export const updateClientInfo = createAction('UPDATE_INFO');

export const updateClientInfoAction = ({ clientData }, { storeKey } = {}) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(updateClientInfoActionRequest({ storeKey }));
    try {
      const response = await updateClientData(clientData);
      dispatch(updateClientInfoActionSuccess({ storeKey, clientInfo: response.client_info }));
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(updateClientInfoActionFailure({ storeKey, errorCode }));
      throw requestError;
    }
  });
};
const updateClientInfoActionRequest = createAction('UPDATE_CLIENT_INFO_REQUEST');
const updateClientInfoActionSuccess = createAction('UPDATE_CLIENT_INFO_SUCCESS');
const updateClientInfoActionFailure = createAction('UPDATE_CLIENT_INFO_FAILURE');

export const registrationAction = (
  { login, password, isCorporate, platform },
  { storeKey, handledErrors }
) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(registrationActionRequest({ storeKey }));
    try {
      await registrationRequest(login, password, isCorporate, platform);
      dispatch(registrationActionSuccess({ storeKey }));
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(
        registrationActionFailure({
          errorCode,
          storeKey,
        })
      );
      throw requestError;
    }
  }, handledErrors);
};
const registrationActionRequest = createAction('REGISTRATION_REQUEST');
const registrationActionSuccess = createAction('REGISTRATION_SUCCESS');
const registrationActionFailure = createAction('REGISTRATION_FAILURE');

export const checkTokenAction = (storeKey, clientId, token) => {
  // no need to handle any error yet
  // component should know only fact of fail
  return async dispatch => {
    dispatch(checkTokenActionRequest({ storeKey }));
    try {
      await validateToken(clientId, token);
      dispatch(checkTokenActionSuccess({ storeKey }));
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(
        checkTokenActionFailure({
          errorCode,
          storeKey,
        })
      );
    }
  };
};
const checkTokenActionRequest = createAction('CHECK_TOKEN_REQUEST');
const checkTokenActionSuccess = createAction('CHECK_TOKEN_SUCCESS');
const checkTokenActionFailure = createAction('CHECK_TOKEN_FAILURE');

export const sendFeedbackAction = ({ score, comment }) => {
  return errorHandleMiddleware(async dispatch => {
    dispatch(sendFeedbackActionRequest());
    try {
      await sendFeedbackMessage(score, comment);
      dispatch(sendFeedbackActionSuccess());
    } catch (requestError) {
      const { error_code: errorCode } = requestError;
      dispatch(
        sendFeedbackActionFailure({
          errorCode,
        })
      );
      throw requestError;
    }
  });
};
const sendFeedbackActionRequest = createAction('SEND_FEEDBACK_REQUEST');
const sendFeedbackActionSuccess = createAction('SEND_FEEDBACK_SUCCESS');
const sendFeedbackActionFailure = createAction('SEND_FEEDBACK_FAILURE');

export const registerNuitrackAnalyticsAction = (
  { email, password, reCaptchaToken },
  { handledErrors }
) => {
  return errorHandleMiddleware(async dispatch => {
    const response = await sendRegisterNuitrackAnalytics({
      username: email,
      password,
      reCaptchaToken,
    });
    dispatch(registerNuitrackAnalyticsSuccess(response));
    return response;
  }, handledErrors);
};
const registerNuitrackAnalyticsSuccess = createAction('REGISTER_NUITRACK_ANALYTICS_SUCCESS');
