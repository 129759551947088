import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import tr from 'locale';
import { Row, Col, Box as BaseBox, Typography } from '@3divi/baseui';
import { configHelpLinks } from './configHelpLinks';
import { fetchLicensesAction, fetchGroupsAction } from 'store/licenses/actions';
import { H3 } from 'components/elements/Typography/';

import Header from 'Header';
import HeaderContainer from 'components/HeaderContainer';
import HelpLink from 'components/gesture-page/HelpLink';
import Groups from './Groups/';
import StepContainer from './HowToStart/';
import {
  TableNuitrack,
  TableFace,
  TableFaceMachine,
  TableNuitrackAnalytics,
} from './LicensesTable';
import Feedback from '../../elements/Feedback/';
import NuitrackPromo from './Nuitrack/NuitrackPromo';
import Footer from 'components/elements/Footer/';
import { WelcomeModal } from 'components/modals/';

const Box = styled.main`
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 32px 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 720px) {
    padding: 8px 16px 0;
  }
`;

const Content = styled.div`
  flex: 1 1 320px;
`;

const licenseConfig = {
  face: TableFace,
  'face-machine': TableFaceMachine,
  'nuitrack-analytics': TableNuitrackAnalytics,
  nuitrack: TableNuitrack,
};

function Dashboard() {
  // Route params
  const licenseId = useParams().id;
  const platform = useParams().platform;
  // Store actions
  const dispatch = useDispatch();
  // Store selectors
  const LicensesTable = licenseConfig[platform];
  const licensesLoadComplete = useSelector(state => state.licenses.fetchLicenses.success);
  const groupLoadComplete = useSelector(state => state.licenses.fetchGroups.success);
  const stepsShow = useSelector(state => state.steps.showStepBlocks);

  useEffect(() => {
    if (!licenseId || !licensesLoadComplete) {
      dispatch(fetchLicensesAction());
      dispatch(fetchGroupsAction());
    }
  }, [platform, licenseId]);

  return (
    <Box>
      <WelcomeModal />
      <Content>
        <Feedback />
        <Header title={tr(`tabtitle_dashboard_${platform}`)} />
        <HeaderContainer pageName={tr(`dashboard`)} />
        <Row>
          {configHelpLinks[platform].map((helpLink, i) => (
            <HelpLink key={i} {...helpLink} />
          ))}
        </Row>
        {licensesLoadComplete && groupLoadComplete && (
          <Row>
            <Col>
              {stepsShow && stepsShow[platform] && (
                <BaseBox mb={2}>
                  <H3 title={tr('how_start')} />
                  <Typography color="secondary" size="17" height="24">
                    {platform === 'nuitrack' &&
                      tr('subtitle_simple_steps', { count: tr('two'), license: 'Nuitrack' })}
                    {platform === 'face-machine' &&
                      tr('subtitle_simple_steps', { count: tr('three'), license: 'Face Machine' })}
                    {platform === 'nuitrack-analytics' &&
                      tr('subtitle_simple_steps', {
                        count: tr('three'),
                        license: 'Nuitrack Analytics',
                      })}
                    {platform === 'face' &&
                      tr('subtitle_simple_steps', { count: tr('three'), license: 'Face SDK' })}
                  </Typography>
                </BaseBox>
              )}
              <Row>
                {platform === 'nuitrack' && (
                  <NuitrackPromo showPro={stepsShow && stepsShow[platform]} />
                )}
                <StepContainer platform={platform} />
              </Row>
            </Col>
          </Row>
        )}
        <Groups platform={platform} />
        <Row>
          <Col>
            <LicensesTable platform={platform} />
          </Col>
        </Row>
      </Content>
      <Footer />
    </Box>
  );
}

export default Dashboard;
