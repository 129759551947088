// Hooks
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Others
import tr from 'locale';
import { getPrice, getPriceObject } from '../../../../../helpers/getPrice';
// Components
import { Link } from 'react-router-dom';
import { Col } from '@3divi/baseui';
import LicenseView from './LicenseFaceView';
import { themeDefault } from 'components/themes/themeDefault';

const lists = {
  face_online_all_in_one: {
    theme: 'count',
    items: [
      {
        isHeader: true,
        text: tr('components_included'),
      },
      {
        text: tr('age_gender_emotions_estimator'),
        value: 1,
      },
      {
        text: tr('liveness_2d_estimator'),
        value: 1,
      },
      {
        text: tr('depth_liveness_estimator'),
        value: 1,
      },
      {
        text: tr('matcher'),
        value: 1,
      },
      {
        text: tr('processor'),
        value: 1,
      },
      {
        text: tr('video_client'),
        value: 1,
      },
      {
        text: tr('video_client_ext'),
        value: 1,
      },
      {
        text: `${tr('search_index_size', { size: '1000' })}`,
      },
    ],
  },
};

const configs = {
  face_online_subscribe: {
    product: 'face_online_subscribe',
  },
  face_online_all_in_one: {
    1: {
      product: 'face_online_all_in_one',
      license_expire_delta: 2592000,
    },
    3: {
      product: 'face_online_all_in_one',
      license_expire_delta: 7776000,
    },
    6: {
      product: 'face_online_all_in_one',
      license_expire_delta: 15552000,
    },
    12: {
      product: 'face_online_all_in_one',
      license_expire_delta: 31104000,
    },
  },
};

const licensePeriods = [
  { text: '1', activeText: tr('1_month') },
  { text: '3', activeText: tr('3_months') },
  { text: '6', activeText: tr('6_months') },
  { text: '12', activeText: tr('12_months') },
];

function FaceSelection({ platform }) {
  const prices = useSelector(state => state.clientdata.prices);
  const options = useSelector(state => state.clientdata.option);

  const [period, setPeriod] = useState(licensePeriods[3].text);

  return (
    <>
      <Col xl={2} l={3} m={4} s={5} xs={6} xxs={12}>
        <LicenseView
          title={tr('basket_all_in_one_title')}
          subtitle={{
            text: tr('not_renewable'),
            textColor: themeDefault.grayColor,
            tooltipText: tr('not_renewable_tooltip'),
          }}
          text={tr('license_selection_face_subtitle')}
          periodToggle={{
            default: licensePeriods[3].text,
            periods: licensePeriods,
            setPeriod: setPeriod,
          }}
          price={{
            text: `$${getPrice(
              { type: 'face', config: configs.face_online_all_in_one[period] },
              prices
            )}`,
          }}
          oldPrice={
            getPriceObject({ type: 'face', config: configs.face_online_all_in_one[period] }, prices)
              .old_price
          }
          discount={
            getPriceObject({ type: 'face', config: configs.face_online_all_in_one[period] }, prices)
              .discount
          }
          button={{
            type: 'BtnAdditionalText',
            props: {
              text: tr('buy'),
              to: {
                pathname: `/app/${platform}/basket/checkout/`,
                state: { addLicenseType: period },
              },
              forwardedAs: Link,
            },
          }}
          lists={lists.face_online_all_in_one}
        />
      </Col>
      <Col xl={2} l={3} m={4} s={5} xs={6} xxs={12}>
        <LicenseView
          title={tr('basket_face_online_subscribe')}
          subtitle={{
            text: tr('renewable'),
            textColor: themeDefault.themeGreenColor,
            tooltipText: tr('renewable_tooltip'),
          }}
          text={tr('license_selection_face_subtitle')}
          priority
          price={{
            text: `$${getPrice({ type: 'face', config: configs.face_online_subscribe }, prices)}`,
            after: tr('month'),
          }}
          button={{
            type: 'BtnAdditionalText',
            props: {
              text: tr('subscribe'),
              to: {
                pathname: `/app/${platform}/basket/checkout/subscriptions/all-in-one/`,
                state: { addLicenseType: configs.face_online_subscribe.product },
              },
              forwardedAs: Link,
              priority: true,
            },
          }}
          lists={lists.face_online_all_in_one}
        />
      </Col>
      {options.facesdk_allow_subscribe && (
        <Col xl={2} l={3} m={4} s={10} xs={12} xxs={12}>
          <LicenseView
            title={tr('custom_subscription')}
            subtitle={{
              text: tr('renewable'),
              textColor: themeDefault.themeGreenColor,
              tooltipText: tr('renewable_tooltip'),
            }}
            text={tr('license_selection_face_subtitle')}
            button={{
              type: 'BtnAdditionalText',
              props: {
                text: tr('create'),
                to: `/app/${platform}/basket/license-selection/create/`,
                forwardedAs: Link,
              },
            }}
          />
        </Col>
      )}
    </>
  );
}

export default FaceSelection;
