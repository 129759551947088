import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tr from 'locale';
import { Col } from '@3divi/baseui';
import PageTitle from '../../PageTitle';
import StepSection from './components/StepSection/';
import Cart from './components/Cart/';
import PaymentMethod from './components/PaymentMethod/';
import LoaderComponent from 'components/LoaderComponent';
import Product from './components/Product';
import LicenseGroup from './components/Product/LicenseGroup';
import Typography from 'components/elements/Typography/';
import { useWindowSize } from '../../../../hooks/';
import { StyledContent, StyledSection } from '../styled';
import Header from '../../../../Header';
import BasketHeader from '../BasketHeader';
import { useSelector } from 'react-redux';

const Main = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 1020px;
  padding: 0 8px;
  margin: 0 auto;
  padding-bottom: 48px;
`;

function CheckoutView(props) {
  const { pricesLoaded, goods, controlType, currentStep, platform, backText, backUrl } = props;
  const prices = useSelector(state => state.clientdata.prices);
  const isDeprecated = prices?.nuitrack?.old?.deprecated;

  const [small, setSmall] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    let isSmall = width <= 720;
    setSmall(isSmall);
  }, [width]);

  return (
    <StyledSection>
      <Header title={tr(`tabtitle_basket_${platform}`)} />
      <BasketHeader buttonText={backText} platform={platform} backUrl={backUrl} />
      <StyledContent>
        <PageTitle title={tr('checkout')} />
        <Main>
          <Col xl={6} l={6} m={6} s={7} xs={12} xxs={12}>
            {currentStep === 1 && (
              <StepSection title={tr('add_licenses')} step="1">
                {pricesLoaded ? (
                  goods.map((el, i) => {
                    if (
                      ((el.title === 'Nuitrack Pro' && el.subtitle === 'Perpetual') ||
                        (el.title === 'Nuitrack AI' && el.subtitle === 'Monthly subscription')) &&
                      isDeprecated
                    )
                      return null;
                    return el.info ? (
                      // shows some text info instead product
                      <LicenseGroup key={i}>
                        <Typography height="15" size="13" color="#8F8F8F">
                          {el.info}
                        </Typography>
                      </LicenseGroup>
                    ) : (
                      <Product key={i} controlType={controlType} {...el} />
                    );
                  })
                ) : (
                  <LoaderComponent />
                )}
              </StepSection>
            )}
            {currentStep === 2 && (
              <StepSection title={tr('basket_payment_title')} step="2">
                <PaymentMethod />
              </StepSection>
            )}
          </Col>
          <Col xl={4} l={4} m={4} s={5} xs={12} xxs={12} order={small && -1}>
            <Cart />
          </Col>
        </Main>
      </StyledContent>
    </StyledSection>
  );
}

export default CheckoutView;
