import tr from 'locale';
import {
  CURRENT_FACE_MACHINE_PLAN_ID,
  CURRENT_NUITRAC_ANALYTICS_PLAN_ID,
} from '../../../../Config';

export const goods = {
  nuitrack: {
    licenses: [
      {
        platform: 'nuitrack',
        title: 'Nuitrack Pro',
        subtitle: 'Perpetual',
        cartTitle: 'Nuitrack Pro Perpetual',
        // cartSubtitle: '',
        config: {
          os_type: null,
          max_reactivation_count: 10,
          license_expire_delta: null,
          product: 'old',
          online: null,
        },
      },
      {
        platform: 'nuitrack',
        title: 'Nuitrack AI',
        subtitle: 'Perpetual',
        cartTitle: 'Nuitrack AI Perpetual',
        // cartSubtitle: '',
        config: {
          product: 'nuitrack_ai_perpetual',
        },
      },
    ],
    subscriptions: {
      pro: [
        {
          platform: 'nuitrack',
          title: 'Nuitrack Pro',
          subtitle: tr('monthly_subscription'),
          cartTitle: 'Nuitrack Pro',
          cartSubtitle: tr('monthly_subscription'),
          config: {
            product: 'nuitrack_online_subscription_monthly',
          },
        },
        {
          platform: 'nuitrack',
          title: 'Nuitrack Pro',
          subtitle: tr('annual_subscription'),
          cartTitle: 'Nuitrack Pro',
          cartSubtitle: tr('annual_subscription'),
          config: {
            product: 'nuitrack_online_subscription_yearly',
          },
        },
      ],
      ai: [
        {
          platform: 'nuitrack',
          title: 'Nuitrack AI',
          subtitle: tr('monthly_subscription'),
          cartTitle: 'Nuitrack AI',
          cartSubtitle: tr('monthly_subscription'),
          config: {
            product: 'nuitrack_ai_subscription_monthly',
          },
        },
        {
          platform: 'nuitrack',
          title: 'Nuitrack AI',
          subtitle: tr('annual_subscription'),
          cartTitle: 'Nuitrack AI',
          cartSubtitle: tr('annual_subscription'),
          config: {
            product: 'nuitrack_ai_subscription_yearly',
          },
        },
      ],
    },
    upgrades: {
      pro: [
        {
          platform: 'nuitrack',
          title: tr('basket_upgrade_pro_to_ai'),
          subtitle: 'Perpetual',
          cartTitle: tr('basket_upgrade_pro_to_ai'),
          cartSubtitle: 'Perpetual',
          config: {
            product: 'upgrade_nuitrack_pro', // used to get price
          },
        },
      ],
    },
  },

  face: {
    licenses: [
      {
        platform: 'face',
        title: '3DiVi Face SDK All-in-One',
        subtitle: tr('for_license_expire_delta_1_month'),
        cartTitle: '3DiVi Face SDK All-in-One',
        cartSubtitle: tr('for_license_expire_delta_1_month'),
        config: getFaceSDKDefaultLicense(30),
        months: 1,
      },
      {
        platform: 'face',
        title: '3DiVi Face SDK All-in-One',
        subtitle: tr('for_license_expire_delta_3_months'),
        cartTitle: '3DiVi Face SDK All-in-One',
        cartSubtitle: tr('for_license_expire_delta_3_months'),
        config: getFaceSDKDefaultLicense(90),
        months: 3,
      },
      {
        platform: 'face',
        title: '3DiVi Face SDK All-in-One',
        subtitle: tr('for_license_expire_delta_6_months'),
        cartTitle: '3DiVi Face SDK All-in-One',
        cartSubtitle: tr('for_license_expire_delta_6_months'),
        config: getFaceSDKDefaultLicense(180),
        months: 6,
      },
      {
        platform: 'face',
        title: '3DiVi Face SDK All-in-One',
        subtitle: tr('for_license_expire_delta_1_year'),
        cartTitle: '3DiVi Face SDK All-in-One',
        cartSubtitle: tr('for_license_expire_delta_1_year'),
        config: getFaceSDKDefaultLicense(360),
        months: 12,
      },
      {
        info: tr('basket_all_in_one_info'),
      },
    ],
    subscriptions: {
      'all-in-one': [
        {
          platform: 'face',
          title: '3DiVi Face SDK All-in-One',
          subtitle: tr('monthly_subscription'),
          cartTitle: '3DiVi Face SDK All-in-One',
          cartSubtitle: tr('monthly_subscription'),
          config: {
            product: 'face_online_subscribe',
          },
        },
      ],
    },
    upgrades: {},
  },

  'face-machine': {
    licenses: [
      {
        platform: 'face-machine',
        title: '3DiVi Face Machine Cloud',
        subtitle: 'Monthly subscription',
        cartTitle: '3DiVi Face Machine Cloud',
        cartSubtitle: 'Monthly subscription',
        config: {
          product: 'fmc_subscribe',
          plan_id: CURRENT_FACE_MACHINE_PLAN_ID,
        },
      },
    ],
    subscriptions: [],
    upgrades: {
      trial: [
        {
          platform: 'face-machine',
          title: tr('basket_upgrade_fm_trial'),
          subtitle: '',
          cartTitle: tr('basket_upgrade_fm_trial'),
          // cartSubtitle: '',
          config: {
            product: 'fmc_subscribe', // used to get price
          },
        },
      ],
    },
  },

  'nuitrack-analytics': {
    licenses: [
      {
        platform: 'nuitrack-analytics',
        title: '3DiVi Nuitrack Analytics Cloud',
        subtitle: 'Monthly subscription',
        cartTitle: '3DiVi Nuitrack Analytics Cloud',
        cartSubtitle: 'Monthly subscription',
        config: {
          product: 'na_subscribe',
          plan_id: CURRENT_NUITRAC_ANALYTICS_PLAN_ID,
        },
      },
    ],
    subscriptions: [],
    upgrades: {
      trial: [
        {
          platform: 'nuitrack-analytics',
          title: tr('basket_upgrade_na_trial'),
          subtitle: '',
          cartTitle: tr('basket_upgrade_na_trial'),
          config: {
            product: 'na_subscribe',
          },
        },
      ],
    },
  },
};

function getFaceSDKDefaultLicense(expire_delta) {
  const DEFAULT_FACE_LICENSE_CONTENT = {
    database: 1000,
    age_gender_emotions_estimator: 1,
    depth_liveness_estimator: 1,
    liveness_2d_estimator: 1,
    detector: 1,
    matcher: 1,
    processor: 1,
    video_client: 1,
    video_client_ext: 1,
  };

  return {
    product: 'face_online_all_in_one',
    license_content: JSON.stringify(DEFAULT_FACE_LICENSE_CONTENT),
    license_expire_delta: expire_delta * 86400,
    max_reactivation_count: 1,
    online: {
      offline_timeout: 86400,
      update_timeout: 3600,
      try_update_timeout: 300,
      auto_prolongation: false,
    },
  };
}
