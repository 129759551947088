import React from 'react';

function ModalItem(props) {
  const { component, isOpen, ...other } = props;
  const ModalComponent = component;

  return isOpen && <ModalComponent isOpen={isOpen} {...other} />;
}

export default ModalItem;
