// Hooks
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { handleHideModal } from 'store/modals/actions';
import { handleActivateOffline } from '../../../store/licenseInfo/actions';
// Other
import tr from 'locale';
// Components
import { Button } from '@3divi/baseui';
import ModalComponent from '../ModalComponent/';
import { useIsMounted } from '../../../hooks/useIsMounted';

function FaceUSBTokenActivateWarning(props) {
  const { onActivateLicense, license, usbToken, ...other } = props;
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <ModalComponent
      icon="warning"
      iconColor="warning"
      title={tr('warning')}
      subtitle={tr('activate_offline_usb_warning_message')}
      buttons={
        <>
          <Button
            text={tr('button_continue')}
            elastic
            size="m"
            color="primary"
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              try {
                if (license) {
                  const response = await dispatch(handleActivateOffline({ license }, { usbToken }));
                  isMounted && onActivateLicense(response.license);
                }
              } finally {
                isMounted && setLoading(false);
              }
            }}
          />
          <Button
            text={tr('button_cancel')}
            elastic
            size="m"
            onClick={() => dispatch(handleHideModal(props.name))}
          />
        </>
      }
      {...other}
    ></ModalComponent>
  );
}

export default FaceUSBTokenActivateWarning;
