import React from 'react';
import { getFaceLicenseConfiguration } from '../../../../helpers/getFaceLicenseConfiguration';
import { useFaceInfo } from './components/useFaceInfo';
import FaceInfoView from './components/FaceInfoView';
import { useCheckLicenseAviability } from './components/hooks/useCheckLicenseAviability';

function FaceInfo() {
  const {
    license,
    platform,
    partialLicense,
    loaded,
    isHolded,
    licenseRevokeable,
    activationInfo,
    getActivationInfoAction,
    metadata,
    licenseInfo,
    openRevoke,
    backUrl,
    urlId,
  } = useFaceInfo();

  const redirect = useCheckLicenseAviability({ id: urlId, platform, license });

  if (redirect) return redirect;

  return (
    <FaceInfoView
      header={{
        backUrl: backUrl,
        title: license && license.title,
        subtitle: license && license.id,
        buttons: licenseRevokeable
          ? { revoke: { onClick: openRevoke, disabled: isHolded } }
          : undefined,
      }}
      isLoaded={partialLicense || loaded}
      activationInfo={{
        items: activationInfo,
        action: getActivationInfoAction(),
      }}
      licenseConfiguration={{ items: getFaceLicenseConfiguration(license) }}
      metadata={metadata}
      subscriptionManagement={
        license &&
        license.isSubscription && {
          platform,
          license,
          loaded,
        }
      }
      info={licenseInfo}
    />
  );
}

export default FaceInfo;
