import React, { useEffect } from 'react';
import { Modal, WithIcon, Tooltip } from '@3divi/baseui';
import { themeDefault } from '../../themes/themeDefault';
import styled from 'styled-components';
import Typography from 'components/elements/Typography/';

const StyledContent = styled.div`
  margin-top: 16px;

  & > * {
    margin-bottom: 12px;
    text-align: center;
  }

  & > *:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const ImageWrap = styled.div`
  width: 96px;
  height: 96px;
  margin: auto;
  border-radius: 50%;
  position: relative;
  border: 4px solid
    ${props => {
      if (props.type === 'success') return themeDefault.themeGreenColor;
      else if (props.type === 'fail') return themeDefault.backgroundPrimaryColor;
      return '#EBEBEB';
    }};
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 28px;
`;

const SubText = styled.div`
  margin-top: 16px;
`;

const StyledTypography = styled(Typography)`
  margin: 16px 0 24px 0;
`;

function ModalComponent(props) {
  const {
    icon,
    iconColor,
    image,
    header,
    imageClasses,
    title,
    subtitle,
    buttons,
    text,
    type,
    subText,
    children,
    clear,
    hideAction,
    onClose,
    tooltip,
  } = props;

  useEffect(() => {
    return () => {
      clear && clear();
      onClose && onClose();
    };
  }, []);

  return (
    <Modal
      header={
        (image || imageClasses || header) && (
          <>
            {(image || imageClasses) && (
              <ImageWrap className={imageClasses && imageClasses} type={type}>
                {image && <Image src={image} />}
              </ImageWrap>
            )}
            {header}
          </>
        )
      }
      icon={icon}
      iconColor={
        iconColor
          ? iconColor
          : type === 'success'
          ? themeDefault.primaryColor
          : themeDefault.backgroundPrimaryColor
      }
      title={title}
      subtitle={subtitle}
      fixed="428"
      {...props}
      close={hideAction}
    >
      {text && Array.isArray(text) ? (
        <StyledTypography>
          {text.map((v, i) => (
            <Typography key={`mod-text-bold-${i}`} height="20" color="#001200" weight="500">
              {v}
              {tooltip && i === text.length - 1 && (
                <Tooltip tooltipColor="hsla(0,0%,100%,1)" {...tooltip}>
                  <WithIcon icon="info" color="primary" />
                </Tooltip>
              )}
            </Typography>
          ))}
        </StyledTypography>
      ) : (
        text && (
          <StyledTypography height="20" color="#001200" weight="500">
            {text}
            {tooltip && (
              <Tooltip tooltipColor="hsla(0,0%,100%,1)" {...tooltip}>
                <WithIcon icon="info" color={themeDefault.themeGreenColor} />
              </Tooltip>
            )}
          </StyledTypography>
        )
      )}
      {children}
      {buttons && <StyledContent>{buttons}</StyledContent>}
      {subText && <SubText>{subText}</SubText>}
    </Modal>
  );
}

export default ModalComponent;
