import React, { useState, useEffect } from 'react';
import LoginView from './LoginView';
import { Link, useLocation, useHistory } from 'react-router-dom';
import emailValidation from '../../../login/validation';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../../store/clientdata/actions';
import { getHref } from 'api';
import tr from 'locale';
import NotConfirmed from './NotConfirmed';
import { isLogged } from '../../../api';

const getHandledErrors = () => ['3fc79796', 'e1a53088', 'e6f34eac'];

function Login(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [storeKey, setStoreKey] = useState(Date.now());
  const { status, errorCode } = useSelector(
    state => state.clientdata.login[storeKey] || state.clientdata.default
  );
  const cookieLogged = isLogged();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [notConfirmed, setNotConfirmed] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    let wasError = false;

    if (login.length === 0) {
      setLoginError(tr('email_empty_error'));
      wasError = true;
    } else if (!emailValidation(login)) {
      setLoginError(tr('email_validation_error'));
      wasError = true;
    }
    if (wasError) return;

    dispatch(
      loginAction(
        {
          login: login.trim(),
          password: password,
        },
        {
          storeKey,
          handledErrors: getHandledErrors(),
        }
      )
    );
  };

  useEffect(() => {
    if (status.failure) {
      const errorMsg = tr(errorCode);
      if (errorCode === '3fc79796')
        // wrong password
        setPasswordError(errorMsg);
      else if (errorCode === 'e1a53088')
        // login not exists
        setLoginError(errorMsg);
      else if (errorCode === 'e6f34eac')
        // account not confirmed
        setNotConfirmed(true);
    }
  }, [status.failure]);

  useEffect(() => {
    if (status.success || cookieLogged) {
      const searchObject = new URLSearchParams(location.search);
      const nextValue = searchObject.get('next');
      if (nextValue) {
        const nextHref = getHref(nextValue, `/app/`);
        window.location.href = nextHref;
      } else if (location.state && location.state.next) {
        history.push({ pathname: location.state.next, search: location.search });
      } else if (location.state && location.state.platform) {
        history.push(`/app/${location.state.platform}/`);
      } else {
        history.push('/app/');
      }
    }
  }, [status.success, cookieLogged]);

  return notConfirmed ? (
    <NotConfirmed
      email={login}
      platform={location.state && location.state.platform}
      toLogin={() => setNotConfirmed(false)}
    />
  ) : (
    <LoginView
      inputs={{
        login: {
          value: login,
          onChange: v => {
            if (v.length > 150) {
              setLoginError(tr('email_max_length_error', { maxLength: 150 }));
              return;
            }
            if (loginError.length > 0) setLoginError('');

            setLogin(v);
          },
          errorText: loginError,
        },
        password: {
          value: password,
          onChange: v => {
            if (v.length > 256) {
              setPasswordError(tr('password_max_length_error', { maxLength: 256 }));
              return;
            }
            if (passwordError.length > 0) setPasswordError('');

            setPassword(v);
          },
          errorText: passwordError,
        },
      }}
      buttons={{
        submit: {
          onClick: onSubmit,
          loading: status.progress,
        },
        toRegistration: {
          as: Link,
          to: { pathname: `/app/registration/`, state: { ...location.state } },
        },
        toForgotPassword: {
          to: { pathname: `/app/forgot-password/`, state: { ...location.state } },
        },
      }}
    />
  );
}

export default Login;
