import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductView from './ProductView';
import { AddToCart, clearFromCart } from '../../../../../../store/basket/actions';

function Product(props) {
  const { title, subtitle, config, price, controlType, cartTitle, cartSubtitle } = props;
  const dispatch = useDispatch();
  const cartProduct = useSelector(
    state => state.basket.basket.productsMap[`${config.product}_${config.license_expire_delta}`]
  );

  const handlerAddProduct = data => {
    const { value, wasError } = data;
    if (!wasError) {
      let limitUpdate = typeof value === 'number' ? value : 1;
      const cartProduct = {
        limit: limitUpdate,
        price,
        config,
        cartTitle,
        cartSubtitle,
      };
      if (controlType === 'stepper') {
        dispatch(AddToCart(cartProduct));
      } else if (controlType === 'radio') {
        dispatch(clearFromCart());
        dispatch(AddToCart(cartProduct));
      }
    }
  };

  return (
    <ProductView
      title={title}
      subtitle={subtitle}
      price={price}
      control={{
        type: controlType,
        value: cartProduct ? cartProduct.limit : 0,
        onChange: handlerAddProduct,
        containerAs: controlType === 'radio' ? 'label' : undefined,
      }}
    />
  );
}

export default Product;
