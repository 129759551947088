import getDefaultTitle from './getDefaultTitle';

/*
default groups:
.limit + .used_licenses = N licenses was bought
- .limit is count of license which can be made from group
- .used_licenses is count of license which make from group

facesdk:
    {
      "limit": 5,
      "used_licenses": 0,
      "config": {
        "online": {
        },
        "product": "face_online_all_in_one",
        "license_content": "<...>",
        "license_expire_delta": 2592000,      // in seconds; can be 2592000, 7776000, 15552000, 31104000 is 1, 3, 6, 12 months
        "max_reactivation_count": 1
      },
      "type": "face",
      "is_default": true,
    }
nuitrack:
    {
      "limit": 11,
      "used_licenses": 0,
      "config": {
        "online": null,
        "os_type": 0,                         // 0, 1, 2
        "product": "nuitrack_unlimited",      // nuitrack_subscribe, nuitrack_unlimited
        "license_expire_delta": null,         // null, 31536000
        "max_reactivation_count": 10
      },
      "type": "nuitrack",
      "is_default": true,
    }
    {
      "limit": 11,
      "used_licenses": 0,
      "config": {
        "online": null,
        "os_type": null,
        "product": "old",                     // old, real_dance, tvico
        "license_expire_delta": null,
        "max_reactivation_count": 10
      },
      "type": "nuitrack",
      "is_default": true,
    },
*/
const platformsDeviceBound = { face: true };

export const getFakeLicenses = (groups, platform) => {
  let result = [];

  groups.forEach(el => {
    if (el.type === platform && el.is_default)
      result = [...result, ...getFakeLicensesFromGroup(el)];
  });

  return result;
};

export const getFakeLicense = (defaultGroup, options) => {
  if (!defaultGroup) return;
  let result = {
    is_fake: true,
    config: defaultGroup.config,
    title: getDefaultTitle(defaultGroup),
    type: defaultGroup.type,
    license_status: options && options.holded ? 'holded' : 'new',
    dates: {},
    group_id: defaultGroup.id,
    activations: [],
    locked_to: defaultGroup.locked_to,
    upgradeable: defaultGroup.upgradeable,
  };
  return result;
};

function getFakeLicensesFromGroup(defaultGroup) {
  let result = [];
  for (let i = 0; i < defaultGroup.limit; i++) {
    result.push(getFakeLicense(defaultGroup));
  }
  const holdOption = { holded: true };
  for (let i = 0; i < defaultGroup.holded_limit; i++) {
    result.push(getFakeLicense(defaultGroup, holdOption));
  }

  return result;
}
