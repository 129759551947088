import React from 'react';
import { getLicenseType } from '../../../../helpers';
import { useCheckAvailableLicense } from './components/hooks/useCheckAvailableLicense';
import NuitrackPreInfoView from './components/NuitrackPreInfoView';
import { useNuitrackPreInfo } from './components/useNuitrackPreInfo';

function NuitrackPreInfo() {
  const {
    group,
    fakeLicense,
    platform,
    loaded,
    isHolded,
    activationInfo,
    metadata,
    licenseInfo,
    getActivationInfoAction,
    openUpgradeNuitrackProGroup,
    backUrl,
    urlId,
  } = useNuitrackPreInfo();

  const redirect = useCheckAvailableLicense({ loaded, group, id: urlId, platform });

  if (redirect) return redirect;

  return (
    <NuitrackPreInfoView
      header={{
        backUrl,
        title: fakeLicense && fakeLicense.title,
        subtitle: fakeLicense && getLicenseType(fakeLicense),
        buttons:
          group && group.upgradeable && group.upgradeable.toAI
            ? { upgrade: { disabled: isHolded, onClick: openUpgradeNuitrackProGroup } }
            : undefined,
      }}
      isLoaded={loaded}
      activationInfo={{
        items: activationInfo,
        action: getActivationInfoAction(),
      }}
      metadata={metadata}
      info={licenseInfo}
    />
  );
}

export default NuitrackPreInfo;
