import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tr from 'locale';

const StyledSwitch = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0;
  justify-content: center;
`;

const StyledBg = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 ${props => (props.len === 1 ? 80 : 160)}px;
  border: 1px solid ${({ theme }) => theme.border.default};
  border-radius: 12px;
  ${props =>
    props.len !== 1
      ? `&:hover {
    cursor: pointer;
  }`
      : ''}

  &:before {
    position: absolute;
    content: '';
    top: -1px;
    width: ${props => (props.len === 1 ? '100%' : 'calc(50% + 2px)')};
    height: 100%;
    border-radius: 12px;
    border: 1px solid ${props => (props.active ? 'hsla(120,48%,56%,1)' : 'hsla(212,96%,64%,1)')};
    ${props => (props.active ? 'right: -1px;' : 'left: -1px;')}
  }
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 11px;
  line-height: 20px;
  color: ${props => (props.active ? props.color : props.theme.text.secondary)};
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1px 10px;
  flex: 0 0 80px;
  text-align: center;
`;

function Switch(props) {
  const { defaultValue, values, onChange, ...other } = props;
  const [left, right] = values;
  const [active, setActive] = useState(defaultValue === values[1]);

  const onClick = () => {
    setActive(v => !v);
  };

  useEffect(() => {
    onChange && onChange(active ? right : left);
  }, [active]);

  return (
    <StyledSwitch>
      <StyledBg len={values.length} onClick={values.length !== 1 && onClick} active={active}>
        {values.length === 1 ? (
          <Title active={!active} color="hsla(212,96%,64%,1)">
            {tr(left)}
          </Title>
        ) : (
          <>
            <Title active={!active} color="hsla(212,96%,64%,1)">
              {tr(left)}
            </Title>
            <Title active={active} color="hsla(120,48%,40%,1)">
              {tr(right)}
            </Title>
          </>
        )}
      </StyledBg>
    </StyledSwitch>
  );
}

export default Switch;
