import React from 'react';
import styled from 'styled-components';

const PriceWrap = styled.div`
  font-weight: 400;
  font: ${({ theme }) => theme.font.xxl};
  color: ${({ theme }) => theme.text.default};
  padding: 4px 0;
`;

const SmallText = styled.span`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.default};
`;

function Price({ text = '', before = '', after = '', className }) {
  return (
    <PriceWrap className={className}>
      {before && <SmallText>{before} </SmallText>}
      {text && text}
      {after && <SmallText> / {after}</SmallText>}
    </PriceWrap>
  );
}

export default Price;
