import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '@3divi/baseui';

const Title = styled.h1`
  font: ${({ theme }) => theme.font.xl};
  color: ${({ theme }) => theme.text.default};
  font-weight: 800;
  margin: 16px 0;
`;

class HeaderContainer extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <Title className="header__title">{this.props.pageName}</Title>
        </Col>
      </Row>
    );
  }
}

export default HeaderContainer;
