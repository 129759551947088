import React, { useState } from 'react';
import { Button, Input } from '@3divi/baseui';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TextContent = styled.p`
  margin-right: 8px;
`;

const StepperWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  text-align: center;
  margin: 0 -1px;
  padding: 0;

  & input {
    width: 56px;
    text-align: center;
    margin: 0;
  }

  & input:focus {
    z-index: 10;
  }
`;

const ErrorText = styled.div`
  color: hsla(360, 100%, 48%, 1);
  font-size: 13px;
  line-height: 15px;
  margin-left: 12px;
`;

function Stepper(props) {
  const {
    text,
    value = 0,
    increment = 1,
    callback,
    minimum = 0,
    maximum = 999,
    errorText,
    autoFocus,
  } = props;
  const [count, setCount] = useState(value);

  const handleInput = value => {
    let newCount = Math.min(Math.max(parseInt(+value), minimum), maximum);
    if (newCount !== +value) {
      // just invoke callback with value which reached maximum or minimum,
      // because parent component should know about this 'error' value.
      // Stepper state and value not changing
      callback && callback({ value: newCount, wasError: true, errorValue: +value });
    } else {
      setCount(newCount);
      callback && callback({ value: newCount });
    }
  };

  const inc = e => {
    e.preventDefault();
    handleInput(count + increment);
  };

  const dec = e => {
    e.preventDefault();
    handleInput(count - increment);
  };

  return (
    <Container>
      {text && <TextContent>{text}</TextContent>}
      <StepperWrap>
        <Button as="div" size="s" borderRadius="4px 0 0 4px" onClick={dec} side="right" text="-" />
        <StyledInput
          size="s"
          borderRadius="0"
          maxlength={3}
          autoFocus={autoFocus}
          onChange={v => handleInput(v.replace(/[^\d]/g, ''))}
          value={count}
          name="stepper"
        />
        <Button as="div" size="s" borderRadius="0 4px 4px 0" onClick={inc} side="left" text="+" />
      </StepperWrap>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
}

export default Stepper;
