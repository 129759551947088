import React, { useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { logout, isLogged } from 'api';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { BASE_URL } from 'Config';
import { LICENSESERVER, MAIN, FACEMACHINE } from '@3divi/baseui';
import 'reset.css';
import 'styles.css';
import { useDispatch, useSelector } from 'react-redux';
import AdminSite from 'components/admin-site';
import ModalsManager from './components/modals/ModalsManager/ModalsManager';
import LoginPromo from './components/pages/login/LoginPromo';
import LoginRoute from './components/pages/login/LoginRoute';
import RegistrationConfirm from './components/pages/login/RegistrationConfirm';
import { checkLogin, fetchProfile } from 'store/clientdata/actions';
import { clearLicenseStoreAction } from 'store/licenses/actions';
import { handlerClearSteps } from 'store/steps/actions';
import Menu from 'components/menu';
import PlatformSite from './components/site';
import PaymentDocuments from './components/pages/payments';
import AccountPage from 'components/account-page';
import GroupKeys from './components/pages/group-keys/GroupKeys';
import NuitrackGroupKeyInfo from './components/pages/group-key-info/modules/NuitrackGroupKeyInfo';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getChangelogAction } from './store/changelog/actions';
import { usePaypalOptions } from './components/elements/PaypalButton/usePaypalOptions';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Login from './components/pages/login/Login';
import Page404 from './components/pages/404/Page404';
import NuitrackAnalyticsLogin from './components/pages/NuitrackAnalyticsLogin';
import NuitrackAnalyticsRegistration from './components/pages/NuitrackAnalyticsRegistration/NuitrackAnalyticsRegistration';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.background.secondary};
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const Ui = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const LoginContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 80px 0;
  max-width: 880px;
  align-items: stretch;
  background: ${({ theme }) => theme.background.default};
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: 720px) {
    flex-flow: column nowrap;
    max-width: 480px;
  }
`;

const lazy = {
  get stripe() {
    if (this.stripeKey) {
      delete this.stripe;
      return (this.stripe = loadStripe(this.stripeKey));
    } else {
      return null;
    }
  },
};

const loginRoutes = 'login|registration|set-password|forgot-password|confirm|registration-confirm';
const platformRoutes = 'nuitrack|face|face-machine|nuitrack-analytics';

function AppManager() {
  const dispatch = useDispatch();
  const profileLoadStatus = useSelector(state => state.clientdata.profileLoadStatus);
  const logged = useSelector(state => state.clientdata.logged); // this component must observe this status
  useEffect(() => {
    if (isLogged() && !profileLoadStatus.progress && !profileLoadStatus.success) {
      dispatch(fetchProfile());
    }
  }, [profileLoadStatus.success, profileLoadStatus.loading]);

  useEffect(() => {
    dispatch(getChangelogAction());
  }, []);

  const storeStripeKey = useSelector(state => state.clientdata.stripeKey);
  lazy.stripeKey = storeStripeKey;

  return (
    <ThemeProvider theme={LICENSESERVER}>
      <GlobalStyle />
      <BrowserRouter>
        <Elements stripe={lazy.stripe}>
          <PayPalScriptProvider options={usePaypalOptions()}>
            <ModalsManager />
          </PayPalScriptProvider>
          <Switch>
            {/* standart redirects */}
            <Redirect exact from="/" to={BASE_URL} />
            <Redirect exact from={`${BASE_URL}/`} to={`${BASE_URL}/nuitrack/`} />
            <Redirect
              exact
              from={`${BASE_URL}/face/face-machine/`}
              to={`${BASE_URL}/face-machine/`}
            />
            <Redirect
              exact
              from={`${BASE_URL}/:platform(${platformRoutes})/`}
              to={`${BASE_URL}/:platform/dashboard/`}
            />
            {/* logout */}
            <Route exact path={`${BASE_URL}/logout/`}>
              <Logout />
            </Route>

            <Redirect
              exact
              from={`${BASE_URL}/registrate/face-machine/`}
              to={`${BASE_URL}/registrate/nuitrack-analytics/`}
            />
            <Route exact path={`${BASE_URL}/registrate/nuitrack-analytics/`}>
              <ThemeProvider theme={FACEMACHINE}>
                <Content>
                  <LoginContent>
                    <NuitrackAnalyticsRegistration />
                  </LoginContent>
                </Content>
              </ThemeProvider>
            </Route>

            <Redirect
              exact
              from={`${BASE_URL}/login/face-machine/`}
              to={`${BASE_URL}/login/nuitrack-analytics/`}
            />
            <Route exact path={`${BASE_URL}/login/nuitrack-analytics/`}>
              <ThemeProvider theme={FACEMACHINE}>
                <Content>
                  <NuitrackAnalyticsLogin />
                </Content>
              </ThemeProvider>
            </Route>
            <Route path={`${BASE_URL}/(${loginRoutes})/`}>
              <ThemeProvider theme={MAIN}>
                <GlobalStyle />
                <Content>
                  <LoginContent>
                    <LoginPromo />
                    <Switch>
                      {/* 'isLogged() == false' routes */}
                      <Route
                        path={`${BASE_URL}/(registration|set-password|forgot-password|confirm)/`}
                        component={LoginRoute}
                      />
                      {/* route without isLogged() condition */}
                      <Route
                        exact
                        path={`${BASE_URL}/registration-confirm/:id/:token/`}
                        component={RegistrationConfirm}
                      />
                      {/* manual handling isLogged() status */}
                      <Route exact path={`${BASE_URL}/login/`} component={Login} />
                      <Redirect to={`${BASE_URL}/`} />
                    </Switch>
                  </LoginContent>
                </Content>
              </ThemeProvider>
            </Route>
            {/* 'isLogged() == true' routes */}
            <Route
              // for now all platformRoutes contains :after part
              path={`${BASE_URL}/:platform(${platformRoutes})/:after/`}
              render={({ match, location }) => {
                // perform redirect from old to new loginRoutes (new without platform part)
                if (
                  platformRoutes.indexOf(match.params.platform) >= 0 &&
                  loginRoutes.indexOf(match.params.after) >= 0
                )
                  return (
                    <Redirect
                      push
                      to={{
                        pathname: location.pathname.replace(`/${match.params.platform}`, ''),
                        state: { ...location.state, platform: match.params.platform },
                        search: location.search,
                      }}
                    />
                  );
                if (!isLogged()) {
                  return (
                    <Redirect
                      push
                      to={{
                        pathname: `${BASE_URL}/login/`,
                        state: { platform: match.params.platform, next: location.pathname },
                        search: location.search,
                      }}
                    />
                  );
                }
                return (
                  <Ui>
                    <Route
                      exact
                      path={[
                        `${BASE_URL}/(${platformRoutes})/dashboard/:id/(info|pre-info)/`,
                        `${BASE_URL}/(${platformRoutes})/dashboard/`,
                      ]}
                    >
                      <Menu />
                    </Route>
                    <Switch>
                      <Route path={`${BASE_URL}/:platform(${platformRoutes})/:after/`}>
                        <PlatformSite />
                      </Route>
                      <Route component={Page404} />
                    </Switch>
                  </Ui>
                );
              }}
            />
            <Route
              path={`${BASE_URL}/:platform(payment-doc|account|group-keys)/`}
              render={({ match, location }) => {
                return (
                  <Switch>
                    {!isLogged() && (
                      <Redirect
                        push
                        to={{
                          pathname: `${BASE_URL}/login/`,
                          state: { platform: match.params.platform, next: location.pathname },
                          search: location.search,
                        }}
                      />
                    )}
                    <Ui>
                      <Route
                        exact
                        path={[
                          `${BASE_URL}/group-keys/:id/group-key-info/`,
                          `${BASE_URL}/(payment-doc|account|group-keys)/`,
                        ]}
                      >
                        <Menu />
                      </Route>
                      <Switch>
                        <Route exact path={`${BASE_URL}/payment-doc/`}>
                          <PaymentDocuments />
                        </Route>
                        <Route exact path={`${BASE_URL}/account/`}>
                          <AccountPage />
                        </Route>
                        <Route exact path={`${BASE_URL}/group-keys/`}>
                          <GroupKeys />
                        </Route>
                        <Route exact path={`${BASE_URL}/group-keys/:id/group-key-info/`}>
                          <GroupKeys />
                          <NuitrackGroupKeyInfo />
                        </Route>
                        <Route component={Page404} />
                      </Switch>
                    </Ui>
                  </Switch>
                );
              }}
            />
            {/* not public routes */}
            <Route path={`/marketing/face_sdk_custom/create/`} component={AdminSite} />
            <Route component={Page404} />
          </Switch>
        </Elements>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.cookie = 'user_status=; path=/;';
    dispatch(clearLicenseStoreAction());
    dispatch(handlerClearSteps());
    logout();
    dispatch(checkLogin());
  }, []);

  return <Redirect to={`${BASE_URL}/login/`} />;
};

export default AppManager;
