import React from 'react';
import styled from 'styled-components';
import tr from 'locale';

import Feedback from '../../elements/Feedback/';
import { Section, Button } from '@3divi/baseui';
import Table from 'components/elements/Table/';
import StatusToggleBtn from '../dashboard/LicensesTable/components/StatusToggleBtn';
import DayPicker from '../../elements/DayPicker';
import HeaderContainer from 'components/HeaderContainer';
import Footer from 'components/elements/Footer/';

const Box = styled.main`
  flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 32px 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (max-width: 720px) {
    padding: 8px 16px 0;
  }
`;

const Content = styled.div`
  flex: 1 1 320px;
`;

const SectionContent = styled.div`
  margin: 0 -16px;
  overflow-x: auto;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background: ${({ theme }) => theme.background.primary};
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const DayPickerWrap = styled.div`
  display: inline-flex;
  margin-bottom: 16px;
`;

const StyledDayPicker = styled(DayPicker)`
  margin-left: 8px;

  &:first-child {
    margin: 0;
  }

  & > button {
    ${({ theme, to, from }) => !(to || from) && `background: ${theme.background.default}`};
  }
`;

const FilterWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const FilterItem = styled.div`
  display: inline-flex;
  padding: 0 16px 8px 0;
`;

function PaymentDocumentsView(props) {
  const { statusFilter, tableData, createdDayPicker, clearAllFilters, isDataFiltered } = props;
  return (
    <Box>
      <Content>
        <Feedback />
        <HeaderContainer pageName={tr('payments_doc')} />
        <FilterWrap>
          {createdDayPicker && (
            <FilterItem>
              <StyledDayPicker
                text={tr('created_date_filter')}
                spacesTop={4}
                {...createdDayPicker}
              />
            </FilterItem>
          )}
          {clearAllFilters && isDataFiltered && (
            <FilterItem>
              <Button iconLeft="close" onClick={clearAllFilters} text={tr('reset_filters')} />
            </FilterItem>
          )}
        </FilterWrap>
        <Section>
          <SectionContent>
            <Header>{statusFilter && <StatusToggleBtn {...statusFilter} />}</Header>
            {tableData && <Table entitiesName="transactions" {...tableData} />}
          </SectionContent>
        </Section>
      </Content>
      <Footer />
    </Box>
  );
}

export default PaymentDocumentsView;
