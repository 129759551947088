import { useMemo } from 'react';

const useSteps = (stepsStore, platform) => {
  const currentStep = useMemo(
    () =>
      Object.keys(stepsStore.steps).find(
        el => stepsStore.steps[el].status === 'active' || stepsStore.steps[el].last
      ),
    [stepsStore.steps, platform]
  );
  const currentIndex =
    Object.keys(stepsStore.steps).findIndex(el => stepsStore.steps[el].status === 'active') + 1;
  const nextStep = useMemo(() => Object.keys(stepsStore.steps)[currentIndex], [currentStep]);

  return {
    ...stepsStore.steps[currentStep],
    currentStep: currentStep,
    nextStep: nextStep,
  };
};

export default useSteps;
