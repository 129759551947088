import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { BASE_URL } from 'Config';
import { Input } from '@3divi/baseui';
import styled from 'styled-components';
import tr from 'locale';
import { databaseCustom, DATABASE_COUNT_TITLE, licenseConfig } from './configCustomLicense';

import Stepper from 'components/elements/Stepper/';
import FormGroup from 'components/elements/FormGroup/';
import Typography from 'components/elements/Typography/';
import PullOutPage from 'components/blocks/PullOutPage/';
import { ButtonFillBalance } from 'components/modals/buttons/';
import BtnAdditionalText from 'components/elements/BtnAdditionalText/';
import LoaderComponent from 'components/LoaderComponent';
import { licenseBuyAction, clearLicense } from '../../../store/licenseBuy/actions';

const Content = styled.div`
  padding: 16px 32px;

  @media (max-width: 576px) {
    padding: 16px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormSection = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
  border-radius: 2px;

  &:nth-last-child(1) {
    border: none;
    padding-bottom: 0;
  }
`;

const StyledTypography = styled(Typography)`
  padding-right: 5px;
`;

const Subtext = styled(Typography)`
  margin-top: 16px;
`;

const KEY_LOCAL_STORAGE = 'custom_subscribe';

function CreateCustomLicense(props) {
  const history = useHistory();
  const platform = useParams().platform;
  let storage = JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE));
  // Store Selectors
  const prices = useSelector(
    state =>
      state.clientdata.prices.face && state.clientdata.prices.face.face_online_custom_subscribe
  );
  const balance = useSelector(state => state.clientdata.balance);
  const licenseError = useSelector(state => state.licenseBuy.licenseError);
  const loading = useSelector(state => state.licenseBuy.loading);
  const success = useSelector(state => state.licenseBuy.success);
  // Actions
  const dispatch = useDispatch();
  const clearLicenseAction = useCallback(() => dispatch(clearLicense()), []);
  // States
  const [name, setName] = useState(storage ? storage.licenseName : '');
  const [config, setConfig] = useState(storage ? storage.configCounts : {});
  const [newPrices, setNewPrices] = useState(storage ? storage.newPrices : {});
  const [total, setTotal] = useState(storage ? (storage.total ? storage.total : NaN) : 0);
  const [database, setDatabase] = useState(storage ? storage.database : databaseCustom[0].cost);

  useEffect(() => {
    if (!storage && prices) {
      let licenseContent = {};
      let newPrices = {};
      Object.keys(prices).forEach(el => {
        licenseContent[el] = 0;
        if (el === 'matcher') newPrices[el] = +databaseCustom[0].cost;
        else if (el === 'video_client_ext') newPrices[el] = +databaseCustom[0].cost + +prices[el];
        else newPrices[el] = +prices[el];
      });
      setNewPrices(newPrices);
      setConfig({
        ...config,
        ...licenseContent,
        database: 0,
      });
    }
  }, [prices]);

  useEffect(() => {
    return () => {
      clearLicenseAction();
    };
  }, []);

  const handleChangeDatabase = e => {
    setDatabase(e.target.value);
    let sizeDatabase = e.target.querySelector(':checked').getAttribute('data-size');
    let videoClient = +prices.video_client_ext + +e.target.value;
    setNewPrices(newPrices => ({
      ...newPrices,
      matcher: e.target.value,
      video_client_ext: videoClient,
    }));
    setConfig(config => ({
      ...config,
      database: sizeDatabase,
    }));
  };

  const handleChangeCost = (value, id) => {
    setConfig(config => ({
      ...config,
      [id]: value,
    }));
  };

  useEffect(() => {
    let sum = 0;
    Object.entries(newPrices).forEach(([key, value]) => (sum += +value * +config[key]));
    setTotal(sum);
    localStorage.setItem(
      KEY_LOCAL_STORAGE,
      JSON.stringify({
        ...storage,
        licenseName: name,
        configCounts: config,
        newPrices: newPrices,
        total: sum,
        database,
      })
    );
  }, [config, newPrices, database, name]);

  const handleBuy = e => {
    e.preventDefault();
    dispatch(
      licenseBuyAction(
        [
          {
            config: {
              ...licenseConfig,
              license_content: JSON.stringify(config),
            },
            count: 1,
          },
        ],
        { platform, paymentMethod: 'balance' }
      )
    );
    localStorage.removeItem(KEY_LOCAL_STORAGE);
  };

  useEffect(() => {
    if (success) history.push(`${BASE_URL}/${platform}/license-purchase-success/`);
  }, [success]);

  return (
    <PullOutPage
      title={tr('create')}
      subtitle={tr('face_custom_license')}
      platform={platform}
      backUrl={`${BASE_URL}/${platform}/basket/license-selection/`} /* app/face/basket/license-selection/ */
      width="560px"
    >
      <Content>
        {!prices && <LoaderComponent />}
        {prices && (
          <Form>
            <FormSection>
              <Input
                name="name"
                label={tr('license_name')}
                id="license_name"
                errorText=""
                maxlength={256}
                theme="column"
                size="m"
                value={name}
                onChange={setName}
              />
            </FormSection>
            <FormSection>
              {Object.keys(prices).map(el => {
                if (el !== 'matcher' && el !== 'video_client_ext') {
                  return (
                    <FormGroup key={el} theme="row" title={<Typography>{tr(el)}</Typography>}>
                      <Stepper
                        value={config[el]}
                        text={`$${prices[el]} ×`}
                        callback={({ value, wasError }) => !wasError && handleChangeCost(value, el)}
                      />
                    </FormGroup>
                  );
                }
                return;
              })}
            </FormSection>
            <FormSection>
              <FormGroup
                theme="row"
                title={<Typography>{tr('database_limits')}</Typography>}
                subtitle={
                  <Typography size={13} width={90} color="#8F8F8F">
                    {tr('for_matchdb_vee')}
                  </Typography>
                }
              >
                <select onChange={handleChangeDatabase}>
                  {databaseCustom.map(el => (
                    <option
                      key={el.upper_bound}
                      selected={storage && +el.cost === +storage.database}
                      data-size={el.upper_bound}
                      value={el.cost}
                    >
                      {DATABASE_COUNT_TITLE[el.upper_bound]}
                    </option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup theme="row" title={<Typography>{tr('matcher')}</Typography>}>
                <Stepper
                  value={config.matcher}
                  callback={({ value, wasError }) =>
                    !wasError && handleChangeCost(value, 'matcher')
                  }
                  text={`$${newPrices.matcher} ×`}
                />
              </FormGroup>
              <FormGroup
                theme="row"
                title={<StyledTypography>{tr('video_client_ext')}</StyledTypography>}
              >
                <Stepper
                  value={config.video_client_ext}
                  callback={({ value, wasError }) =>
                    !wasError && handleChangeCost(value, 'video_client_ext')
                  }
                  text={`$${newPrices.video_client_ext} ×`}
                />
              </FormGroup>
            </FormSection>
            <FormSection>
              <FormGroup theme="row" title={<Typography>{tr('total_per_month')}</Typography>}>
                <Typography component="h3" size={24} color="#001200">
                  ${total.toFixed(2)}
                </Typography>
              </FormGroup>
            </FormSection>
            {balance < total ? (
              <ButtonFillBalance priority price={total} platform={platform} />
            ) : (
              <BtnAdditionalText
                priority
                disabled={total <= 0}
                loading={loading}
                text={tr('buy')}
                onClick={handleBuy}
              />
            )}
            <Subtext weight="500" height="20">
              {!licenseError &&
                `$${total.toFixed(2)} ${tr('custom_license_subtext', { date: tr('month') })}`}
            </Subtext>
          </Form>
        )}
      </Content>
    </PullOutPage>
  );
}

export default CreateCustomLicense;
