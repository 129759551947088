import React from 'react';
import styled from 'styled-components';
import { Section, Button, TEXT_COLORS } from '@3divi/baseui';
import tr from 'locale';

import Typography from 'components/elements/Typography/';
import { useCart } from './useCart';
import Promocode from '../Promocode';

const SectionCart = styled(Section)`
  width: 100%;
  padding: 0 8px;
  box-shadow: 0px 0px 48px hsla(0, 0%, 0%, 0.08);
  border: 1px solid ${({ theme }) => theme.border.default};

  &:hover {
    box-shadow: 0px 0px 48px hsla(0, 0%, 0%, 0.08);
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const Subname = styled(Typography)`
  padding-top: 4px;
`;

const Result = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border.default};
`;

const Total = styled(CartItem)`
  align-item: flex-start;
  border-bottom: none;
  padding: 8px 0 16px;
`;

const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0;
`;

const Price = styled(Typography)`
  flex-shrink: 0;
`;

const WrapButton = styled.div`
  padding-bottom: 12px;
`;

function Cart() {
  const { getPrice, buttons, products, totalSum, fullSum, promocode } = useCart();

  return (
    <SectionCart
      title={
        <Typography size="20" weight="600" height="28">
          {tr('your_order')}
        </Typography>
      }
    >
      {products.length > 0 &&
        products.map(el => (
          <CartItem key={el.id}>
            <Typography height="20">
              {el.limit} × {el.title}
              {el.subtitle && (
                <Subname color="#8F8F8F" size="13">
                  {el.subtitle}
                </Subname>
              )}
            </Typography>
            <Price height="20">${getPrice(el.price, el.limit)}</Price>
          </CartItem>
        ))}
      <Result>
        {promocode.discount.value && (
          <>
            <PriceLine>
              <Typography height="20">{tr('subtotal')}</Typography>
              <Price height="20">{`$${fullSum}`}</Price>
            </PriceLine>
            <PriceLine>
              <Typography height="20">
                {tr('promocode')}
                {promocode.discount.type === 'percent' && (
                  <Typography color={TEXT_COLORS.gray} size="13" height="15">
                    {tr('discount_value')}: {promocode.discount.value}
                  </Typography>
                )}
              </Typography>
              <Price height="20">-${promocode.discount.amount}</Price>
            </PriceLine>
          </>
        )}
        <Total>
          <Typography>{tr('basket_total')}</Typography>
          <Typography weight="bold" size="24" color="#001200">{`$${totalSum}`}</Typography>
        </Total>
      </Result>
      {promocode.type === 'visible' && <Promocode />}
      {buttons.continue && (
        <WrapButton>
          <Button
            color="primary"
            variant="contained"
            elastic
            size="m"
            text={tr('button_continue')}
            {...buttons.continue}
          />
        </WrapButton>
      )}
      {buttons.previous && (
        <WrapButton>
          <Button
            size="m"
            elastic
            text={tr('button_return_to_step', { step: 1 })}
            {...buttons.previous}
          />
        </WrapButton>
      )}
    </SectionCart>
  );
}

export default Cart;
