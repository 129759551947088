import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tr from 'locale';

import { setLicenseTitleAction } from '../../../store/licenseInfo/actions';
import { setGroupTitleAction } from '../../../store/licenses/actions';
import EditableTitleView from './EditableTitleView';
import LicenseInfoList from '../LicenseInfoList';

const getHandledErrors = () => ['d097efb8'];

function EditableTitle(props) {
  // Actions
  const dispatch = useDispatch();
  // Selectors
  const [storeKey, setStoreKey] = useState(Date.now());
  const fallbackRequestInfo = useSelector(state => state.licenseInfo.setLicenseTitleDefault);
  const storedSetLicenseTitle = useSelector(state => state.licenseInfo.setLicenseTitle[storeKey]);
  const storedSetGroupTitle = useSelector(state => state.licenses.setGroupTitle[storeKey]);
  // Props
  const { license, group, editTextKey = 'title' } = props;
  const entity = license || group;
  const storedRequest =
    (license ? storedSetLicenseTitle : storedSetGroupTitle) || fallbackRequestInfo;
  // State
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(entity.title);
  const [error, setError] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    const trimmedValue = value.trim();
    if (trimmedValue.length !== value.length) setValue(trimmedValue);

    if (trimmedValue.length === 0) {
      setError(tr('field_empty_error'));
      return;
    }
    if (license)
      dispatch(
        setLicenseTitleAction(
          { id: license.id, title: trimmedValue },
          { storeKey, handledErrors: getHandledErrors() }
        )
      );
    else if (group)
      dispatch(
        setGroupTitleAction(
          { id: group.id, title: trimmedValue },
          { storeKey, handledErrors: getHandledErrors() }
        )
      );
  };
  const onCancel = () => {
    setIsEdit(false);
    setValue(entity.title);
    setStoreKey(Date.now());
    setError('');
  };

  useEffect(() => {
    if (storedRequest.status.success) {
      setIsEdit(false);
    }
  }, [storedRequest.status.success]);

  useEffect(() => {
    if (storedRequest.status.failure) {
      const errorMsg = tr(storedRequest.errorCode);
      if (storedRequest.errorCode === 'd097efb8') {
        setError(errorMsg, { maxLength: 256 });
      }
    } else {
      setError('');
    }
  }, [storedRequest.status.failure]);

  return isEdit ? (
    <EditableTitleView
      infoKey={editTextKey}
      input={{
        onChange: v => {
          if (v.length > 256) {
            setError(tr('license_title_max_length_error', { maxLength: 256 }));
            return;
          }

          if (error) setError('');

          setValue(v);
        },
        value,
        errorText: error,
      }}
      cancel={onCancel}
      onSubmit={onSubmit}
      loading={storedRequest.status.progress}
    />
  ) : (
    <LicenseInfoList
      onClick={() => setIsEdit(true)}
      editable={true}
      items={[{ text: tr(editTextKey), value: entity.title }]}
    />
  );
}

export default EditableTitle;
