export const databaseCustom = [
  {
    cost: 1.0,
    upper_bound: 0,
  },
  {
    cost: 2.0,
    upper_bound: 100,
  },
  {
    cost: 8.0,
    upper_bound: 500,
  },
  {
    cost: 14.0,
    upper_bound: 1000,
  },
  {
    cost: 34.0,
    upper_bound: 5000,
  },
  {
    cost: 50.0,
    upper_bound: 10000,
  },
  {
    cost: 110.0,
    upper_bound: 50000,
  },
  {
    cost: 160.0,
    upper_bound: 100000,
  },
  {
    cost: 300.0,
    upper_bound: 500000,
  },
  {
    cost: 400.0,
    upper_bound: 1000000,
  },
  {
    cost: 730.0,
    upper_bound: 5000000,
  },
  {
    cost: 1000.0,
    upper_bound: 10000000,
  },
];

export const DATABASE_COUNT_TITLE = {
  0: 'no Database',
  100: 'up to 100',
  500: 'up to 500',
  1000: 'up to 1K',
  5000: 'up to 5K',
  10000: 'up to 10K',
  50000: 'up to 50K',
  100000: 'up to 100K',
  500000: 'up to 500K',
  1000000: 'up to 1M',
  5000000: 'up to 5M',
  10000000: 'up to 10M',
};

export const licenseConfig = {
  license_content: '',
  product: 'face_online_custom_subscribe',
  max_reactivation_count: null,
  license_expire_delta: null,
  online: {
    offline_timeout: 86400,
    update_timeout: 3600,
    try_update_timeout: 300,
    auto_prolongation: false,
  },
};
