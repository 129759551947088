import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tr from 'locale';

import PasswordChangeView from './PasswordChangeView';
import { changeClientPassword } from '../../../store/clientdata/actions';
import {
  notAvailableSymbolRegexp,
  specialRegexp,
  upperLatinRegexp,
  lowerLatinRegexp,
} from '../../../helpers/regexp';

const getHandledErrors = () => ['3fc79796', '0c21cb17'];

function PasswordChange(props) {
  const { isOpen, hideAction } = props;
  const [storeKey, setStoreKey] = useState(Date.now());
  const dispatch = useDispatch();
  const storedChangeClientPassword = useSelector(state => state.clientdata.changeClientPassword);
  const { status, errorCode } = storedChangeClientPassword[storeKey] || {
    status: { progress: false, success: false, failure: false },
  };

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPasswordRepeatError, setNewPasswordRepeatError] = useState('');

  const hasErrorPass = value => {
    if (!value || value.length < 8) {
      return tr('new_password_at_least_error', { atLeast: 8 });
    }
    if (notAvailableSymbolRegexp.test(value)) {
      return tr('password_not_available_symbol_error');
    }
    // validation group section.
    // If more than 1 error, then return concat error message.
    // If error just 1, then return its error message.
    let invalidCount = 0;
    let errorMessage;
    if (!specialRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_special_symbol_error');
    }
    if (!upperLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_upper_error');
    }
    if (!lowerLatinRegexp.test(value)) {
      invalidCount++;
      errorMessage = tr('password_no_lower_error');
    }
    if (invalidCount > 0) {
      if (invalidCount === 1) return errorMessage;
      else return tr('password_no_all_symbol_types_error');
    }

    return false;
  };

  useEffect(() => {
    if (status.failure) {
      const errorMsg = tr(errorCode);
      if (errorCode === '3fc79796') {
        setPasswordError(errorMsg);
      } else if (errorCode === '0c21cb17') {
        setNewPasswordError(errorMsg);
      }
    }
  }, [status.failure]);

  return (
    <PasswordChangeView
      modal={{
        isOpen: isOpen,
        close: hideAction,
      }}
      inputs={{
        password: {
          value: password,
          errorText: passwordError,
          onChange: v => {
            if (passwordError) setPasswordError('');

            setPassword(v);
          },
        },
        newPassword: {
          value: newPassword,
          errorText: newPasswordError,
          onChange: v => {
            if (newPasswordError) setNewPasswordError('');

            setNewPassword(v);
          },
        },
        newPasswordRepeat: {
          value: newPasswordRepeat,
          errorText: newPasswordRepeatError,
          onChange: v => {
            if (newPasswordRepeatError) setNewPasswordRepeatError('');

            setNewPasswordRepeat(v);
          },
        },
      }}
      buttons={{
        cancel: {
          onClick: hideAction,
        },
        submit: {
          onClick: () => {
            const newPasswordErrorMessage = hasErrorPass(newPassword);
            if (newPasswordErrorMessage) {
              setNewPasswordError(newPasswordErrorMessage);
            }

            const hasNewPasswordRepeatError =
              newPassword !== newPasswordRepeat || newPasswordRepeat.length === 0;
            if (newPasswordRepeat.length === 0) {
              setNewPasswordRepeatError(' ');
            } else if (newPassword !== newPasswordRepeat) {
              setNewPasswordRepeatError(tr('password_match_error'));
            } else {
              setNewPasswordRepeatError('');
            }

            const isPasswrodEmpty = password.length === 0;
            if (isPasswrodEmpty) {
              setPasswordError(tr('password_empty_error'));
            }

            if (newPasswordErrorMessage || hasNewPasswordRepeatError || isPasswrodEmpty) return;

            dispatch(
              changeClientPassword(
                { password, newPassword },
                { storeKey, handledErrors: getHandledErrors() }
              )
            );
          },
          loading: status.progress,
        },
      }}
      success={status.success}
    />
  );
}

export default PasswordChange;
