import React, { useEffect } from 'react';
import tr from 'locale';
import { useDispatch, useSelector } from 'react-redux';
import { needPlatformRedirect } from '../../../../../../helpers';
import { onlyDigitsRegexp } from '../../../../../../helpers/regexp';
import { handleOpenModal } from '../../../../../../store/modals/actions';
import ErrorModal from '../../../../../modals/ErrorModal';
import { Redirect } from 'react-router';

export const useCheckLicenseAviability = ({ id, license, platform }) => {
  const dispatch = useDispatch();
  const licenseInfoErrorCode = useSelector(state => state.licenseInfo.licenseInfoErrorCode);

  useEffect(() => {
    if (!onlyDigitsRegexp.test(id)) {
      dispatch(
        handleOpenModal({
          component: ErrorModal,
          name: 'error-63f560db',
          text: tr('63f560db'),
        })
      );
    }
  }, [id]);

  return licenseInfoErrorCode || !onlyDigitsRegexp.test(id) ? (
    <Redirect to={`/app/${platform}/dashboard/`} />
  ) : needPlatformRedirect(license, platform) ? (
    <Redirect to={`/app/${license.type}/dashboard/${license.id}/info/`} />
  ) : null;
};
